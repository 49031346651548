import React from 'react'

import './ContactPersonDetails.scss'

// const documents = [
//     {
//         document: 'Carte de identitate',
//         number: 'AR112233',
//         issued: lightFormat(new Date(), 'dd/MM/yyyy'),
//         expires: lightFormat(new Date(), 'dd/MM/yyyy')
//     },
//     {
//         document: 'Pasaport',
//         number: '12345123456789',
//         issued: lightFormat(new Date(), 'dd/MM/yyyy'),
//         expires: lightFormat(new Date(), 'dd/MM/yyyy')
//     }
// ]

export default function ContactPersonDetails() {
    return (
        <p>Contact person details</p>
        // <ResourceDetailsLayout
        //     title={client.name}
        //     content={<ContactPersonInfo client={client} contactPerson={contactPerson}/>}
        // />
    )
}

// function ContactPersonInfo(props) {
//     const {client, contactPerson} = props

//     return (
//         <div className='person-info'>
//             <p className='person-name'>
//                 Persoană de contact: {contactPerson.lastName} {contactPerson.firstName}
//             </p>
//             <div className="info-container">
//                 <div className="left">
//                     <p className='info-type'>Date generale</p>
//                     <KeyValueComponent keyName='Nume' value={contactPerson.lastName}/>
//                     <KeyValueComponent keyName='Prenume' value={contactPerson.firstName} />
//                     <KeyValueComponent keyName='Nume mijlociu' value={contactPerson.middleName} />
//                     <KeyValueComponent keyName='CNP' value={contactPerson.uniqueCode} />
//                     <KeyValueComponent keyName='Data nașterii' value={contactPerson.birthDate} />
//                     <KeyValueComponent keyName='Email' value={contactPerson.email} />
//                     <KeyValueComponent keyName='Telefon' value={contactPerson.phone} />
//                 </div>
//                 <div className="right">
//                     <div className="company-data">
//                         <p className="info-type">Date din cadrul companiei {client.name}</p>
//                         <KeyValueComponent keyName='Calitate'value={contactPerson.role} />
//                         <KeyValueComponent keyName='Reprezentat legal' value={contactPerson.legal} />
//                         <KeyValueComponent keyName='Email'value={client.email} />
//                         <KeyValueComponent keyName='Telefon'value={client.phone} />
//                     </div>
//                     <div className="other-companies">
//                         <p className="info-type">Alte companii la care este persoana de contact</p>
//                         <div className="companies-list">
//                             <p>Nu exista companii</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="documents">
//                 <p className='info-type'>Documente oficiale</p>
//                 <table>
//                     <tbody>
//                         {documents.map((document) => (
//                             <tr key={document.document}>
//                                 <td>{document.document}</td>
//                                 <td>{document.number}</td>
//                                 <td>Emis la {document.issued}</td>
//                                 <td>Expiră la {document.expires}</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     )
// }
