import {Form, Formik} from 'formik'
import React, {Component} from 'react'

import {values as _values, debounce, filter, includes, isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {slideoverTypes} from '../../../../redux/slideovers'

import {clientGroupsSchema} from '../../../../assets/validations'
import {debounceWait} from '../../../../utils/constants'

import {Button} from '../../../../components/Button'
import {ErrorsList} from '../../../../components/ErrorComponents'
import {Input} from '../../../../components/Input'
import {Loader} from '../../../../components/Loader'
import {Select} from '../../../../components/Select'
import {Slideover} from '../../../../components/Slideover'

export class EditClientGroupSlideover extends Component {
    constructor() {
        super()

        this.state = {
            clientsQuery: ''
        }
    }

    handleFetchClients = (query = this.state.clientsQuery) => {
        const {searchClients} = this.props

        searchClients(query)
    }

    debounceSearchClients = debounce(this.handleFetchClients, debounceWait)

    handleChangeProjectsSearchField = (value) => {
        this.setState({clientsQuery: value})
        this.debounceSearchClients(value)
    }

    getInitialClientsArray = () => {
        const {clients, clientGroup} = this.props

        return filter(clients, (client) => includes(clientGroup.client_ids, client.id))
    }

    render() {
        const {
            open,
            clientGroup,
            clients,
            isLoadingClients,
            isLoading,
            updateClientGroup,
            selectedEntityID,
            fieldErrors,
            nonFieldErrors
        } = this.props

        return (
            <Slideover
                open={open && !isEmpty(clientGroup)}
                title="Editează grup clienți">
                {!isEmpty(clients) ? (
                    <div className="slideover-form-container">
                        <ErrorsList errors={nonFieldErrors} />
                        <Formik
                            initialValues={{
                                name: clientGroup.name,
                                clientIds: this.getInitialClientsArray()
                            }}
                            validationSchema={clientGroupsSchema}
                            onSubmit={(values) => {
                                const clientData = {
                                    name: values.name,
                                    entity_id: selectedEntityID,
                                    client_ids: values.clientIds.map((client) => client.id)
                                }

                                updateClientGroup(clientData, clientGroup.id)
                            }}>
                            {({handleChange, handleBlur, setFieldValue, handleSubmit, values, errors, touched}) => (
                                <>
                                    <Form className="slideover-form">
                                        <Input
                                            label="Nume"
                                            value={values.name}
                                            onChange={handleChange('name')}
                                            onBlur={handleBlur('name')}
                                            name="name"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.name}
                                            fullWidth
                                        />
                                        <Select
                                            label="Clienți"
                                            value={values.clientIds}
                                            options={clients}
                                            onChange={(e) => setFieldValue('clientIds', e)}
                                            onInputChange={(value) => this.handleChangeProjectsSearchField(value)}
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => option.name}
                                            onBlur={handleBlur('clientIds')}
                                            name="clientIds"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.clientIds}
                                            loading={isLoadingClients}
                                            isMulti
                                            fullWidth
                                        />
                                    </Form>
                                    <div className="buttons-container">
                                        <Button
                                            title="Salvează"
                                            onClick={handleSubmit}
                                            loading={isLoading}
                                            type="submit"
                                            fullWidth
                                        />
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                ) : isLoading || isLoadingClients ? (
                    <div className="loader-container">
                        <Loader size="large" />
                    </div>
                ) : null}
            </Slideover>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.EDIT_CILENT_GROUP,
    clientGroup: state.clientGroups.currentClientGroup,
    isLoading: state.clientGroups.isLoading,
    fieldErrors: state.clientGroups.fieldErrors,
    nonFieldErrors: state.clientGroups.nonFieldErrors,
    clients: _values(state.clients.searchData),
    isLoadingClients: state.clients.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    searchClients: (search) => dispatch(RESOURCES.clients.search(search)),
    updateClientGroup: (values, clientGroupID) => dispatch(RESOURCES.clientGroups.update(values, clientGroupID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditClientGroupSlideover)
