import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'

import {ArrowLeftIcon} from '@heroicons/react/24/outline'

import {lightFormat} from 'date-fns'
import {endsWith, findLast, isEmpty, isUndefined, startsWith} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {goBack} from 'connected-react-router'
import {connect} from 'react-redux'

import {Button} from '../../components/Button'
import {Loader} from '../../components/Loader'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {actionIds, actions} from './constants'
import {InvoiceActionButtons, InvoiceActivityReport, InvoiceMetaInfo, InvoicePayments, InvoicePreview} from './partials'

import './InvoiceDetails.scss'

export const InvoiceDetails = ({
    invoice,
    isLoading,
    match,
    retrieveInvoice,
    retrieveInvoiceReports,
    goBack,
    location,
    history
}) => {
    const {invoiceID, primaryAction} = match.params

    useEffect(() => {
        retrieveInvoice(invoiceID)
        retrieveInvoiceReports(invoiceID)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const urlData = {prefix: `/invoices/${invoiceID}`, params: match.params, location, history}

    switch (primaryAction) {
        case actionIds.INFO:
            return (
                <InvoiceDetailsLayout
                    urlData={urlData}
                    invoice={invoice}
                    loading={isLoading}
                    goBack={() => goBack()}
                    component={<InvoiceMetaInfo match={match} />}
                />
            )
        case actionIds.PREVIEW:
            return (
                <InvoiceDetailsLayout
                    urlData={urlData}
                    invoice={invoice}
                    loading={isLoading}
                    goBack={() => goBack()}
                    component={<InvoicePreview match={match} />}
                />
            )
        case actionIds.REPORTS:
            return (
                <InvoiceDetailsLayout
                    urlData={urlData}
                    invoice={invoice}
                    loading={isLoading}
                    goBack={() => goBack()}
                    component={<InvoiceActivityReport />}
                />
            )
        case actionIds.PAYMENTS:
            return (
                <InvoiceDetailsLayout
                    urlData={urlData}
                    invoice={invoice}
                    loading={isLoading}
                    goBack={() => goBack()}
                    component={
                        <InvoicePayments
                            match={match}
                            urlData={urlData}
                        />
                    }
                />
            )

        default:
            return <Redirect to={`${urlData.prefix}/${actionIds.INFO}`} />
    }
}

export function InvoiceDetailsLayout({invoice, loading, component, urlData, goBack}) {
    return (
        <div className="resource-details-content invoice-details-content">
            <div className="back-button-container">
                <Button
                    title="Mergi înapoi"
                    size="small"
                    color="gray"
                    variant="outlined"
                    onClick={goBack}
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            <div className="header-resource-title-container">
                {!isEmpty(invoice) && !loading ? (
                    <div className="invoice-name-container">
                        <p className="header-resource-title">
                            Factură {invoice.series_name}
                            {invoice.series_number} din {lightFormat(new Date(invoice.issue_date), 'dd/MM/yyyy')}
                        </p>
                    </div>
                ) : (
                    <div className="loader-container">
                        <Loader />
                    </div>
                )}
            </div>
            <SecondaryNavbar
                actions={actions}
                urlData={urlData}
            />
            <div className="content-container">
                <InvoiceActionButtons />
                {component}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const sourceLocation = findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/invoices' || (startsWith(pathname, '/projects') && endsWith(pathname, '/invoices'))
    )

    return {
        invoice: state.invoices.currentInvoice,
        isLoading: state.invoices.isLoading,
        sourceLocation: !isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/invoices'
    }
}

const mapDispatchToProps = (dispatch) => ({
    goBack: () => dispatch(goBack()),
    retrieveInvoice: (invoiceID) => dispatch(RESOURCES.invoices.retrieve(invoiceID)),
    retrieveInvoiceReports: (invoiceID) => dispatch(RESOURCES.invoiceReports.retrieve(invoiceID))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails)
