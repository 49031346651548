import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function DeleteEmpowermentModal({open, empowerment, isLoading, closeModal, deleteEmpowerment}) {
    return (
        <Modal
            open={open && !isEmpty(empowerment)}
            title="Șterge împuternicire">
            {!isEmpty(empowerment) && (
                <div className="delete-modal">
                    <p className="message">
                        Ești sigur că vrei să ștergi împuternicirea "<span>{empowerment.name}</span>"?
                    </p>
                    <div className="buttons-container">
                        <Button
                            title="Nu, renunță"
                            onClick={closeModal}
                            variant="outlined"
                            loading={isLoading}
                            color="gray"
                        />
                        <Button
                            title="Da, șterge"
                            onClick={() => deleteEmpowerment(empowerment)}
                            loading={isLoading}
                            color="red"
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_EMPOWERMENT,
    isLoading: state.empowerments.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteEmpowerment: (empowerment) => dispatch(RESOURCES.empowerments.destroy(empowerment))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteEmpowermentModal)
