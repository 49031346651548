import React, {useCallback} from 'react'

import {isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import '../../assets/scss/DeleteModals.scss'

function DeleteSubscriptionModal({open, subscription, isLoading, deleteSubscription, closeModal, doAfterDelete}) {
    const handleDelete = useCallback(() => {
        deleteSubscription(subscription)

        if (doAfterDelete) {
            doAfterDelete()
        }
    }, [deleteSubscription, doAfterDelete, subscription])

    return (
        <Modal
            open={open && !isEmpty(subscription)}
            title="Șterge abonarea">
            {!isEmpty(subscription) && (
                <div className="delete-modal">
                    <p className="message">
                        Ești sigur că vrei să ștergi sursa de date pentru{' '}
                        <span>"{subscription.litigation_number}"</span>?
                    </p>
                    <div className="buttons-container">
                        <Button
                            title="Nu, renunță"
                            onClick={closeModal}
                            variant="outlined"
                            loading={isLoading}
                            color="gray"
                        />
                        <Button
                            title="Da, șterge"
                            onClick={handleDelete}
                            loading={isLoading}
                            color="red"
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_SUBSCRIPTION,
    subscription: state.subscriptions.currentSubscription,
    isLoading: state.subscriptions.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteSubscription: (subscription) => dispatch(RESOURCES.subscriptions.destroy(subscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSubscriptionModal)
