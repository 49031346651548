import {lightFormat} from 'date-fns'

export const prefix = '/invoices'

export const actionIds = {
    INVOICES: 'invoices',
    PROFORMAS: 'proformas',
    PAYMENTS: 'payments'
}

export const externalSyncStates = {
    NOT_STARTED: 'not_started',
    SUCCESSFUL: 'successful',
    FAILED: 'failed',
    MANUAL_NEEDS_UPDATE: 'manual_needs_update',
    MANUAL_UPDATE: 'manual_update'
}

export const externalSyncActions = {
    CREATE: 'create',
    MANUAL_UPDATE: 'manual_update',
    CANCEL: 'cancel',
    DELETE: 'delete'
}

export const actions = {
    [actionIds.INVOICES]: {
        id: actionIds.INVOICES,
        label: 'Facturi fiscale'
    },
    // [actionIds.PROFORMAS]: {
    //     id: actionIds.PROFORMAS,
    //     label: 'Facturi proforme'
    // },
    [actionIds.PAYMENTS]: {
        id: actionIds.PAYMENTS,
        label: 'Încasări'
    }
}

export const proformasColumns = [
    {
        Header: 'Data',
        accessor: 'issue_date',
        Cell: ({value: issueDate}) => (issueDate ? lightFormat(new Date(issueDate), 'dd/MM/yyyy') : '-')
    },
    {
        Header: 'Serie',
        accessor: 'series_name'
    },
    {
        Header: 'Număr',
        accessor: 'series_number'
    },
    {
        Header: 'Moneda',
        accessor: 'currency'
    }
]
