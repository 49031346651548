import React, {Component} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {history, persistor, store} from './redux/store'

import MainLayout from './layouts/MainLayout'

import {ErrorsModalContainer} from './components/ErrorsModal'

import {AccountActivation} from './pages/AccountActivation'
import {Dashboard} from './pages/Dashboard'
import {ForgotPassword} from './pages/ForgotPassword'
import {Login} from './pages/Login'
import {NotFound} from './pages/NotFound'
import {ResetPassword} from './pages/ResetPassword'
import {SelectEntity} from './pages/SelectEntity'
import {TermsAndConditions} from './pages/TermsAndConditions'

import {ClientAdd} from './pages/ClientAdd'
import {ClientDetails} from './pages/ClientDetails'
import {ClientsList} from './pages/ClientsList'

import {ContactPersonDetails} from './components/ContactPersonDetails'

import {ClientGroupAdd} from './pages/ClientGroupAdd'
import {ClientGroupDetails} from './pages/ClientGroupDetails'

import {ProjectAdd} from './pages/ProjectAdd'
import {ProjectDetails} from './pages/ProjectDetails'
import {ProjectsList} from './pages/ProjectsList'

import {MDSubscriptionDetails} from './pages/MonitorDosare'

import {ExternalData} from './pages/ExternalData'

import {TaskDetails} from './pages/TaskDetails'
import {Tasks} from './pages/Tasks'

import {ContractAdd} from './pages/ContractAdd'
import {ContractDetails} from './pages/ContractDetails'
import {Contracts} from './pages/Contracts'

import {AddendumAdd} from './pages/AddendumAdd'
import {AddendumDetails} from './pages/AddendumDetails'

import {EmpowermentAdd} from './pages/EmpowermentAdd'
import {EmpowermentDetails} from './pages/EmpowermentDetails'

import {DocumentDetails} from './pages/DocumentDetails'
import {DocumentsList} from './pages/DocumentsList'

import {Notifications} from './pages/Notifications'

import {Settings} from './pages/Settings'

import {ExpenseDetails, ExpensePaymentDetails, ExpensesPage} from './pages/Expenses'

import {InvoiceAdd} from './pages/InvoiceAdd'
import {InvoiceDetails} from './pages/InvoiceDetails'
import {InvoiceEdit} from './pages/InvoiceEdit'
import {Invoices} from './pages/Invoices'
import {Reports} from './pages/Reports'

// Todo: Make Document Edit more generic
import {AddendumEdit} from './pages/AddendumEdit'
import {ContractEdit} from './pages/ContractEdit'
import {DocumentEdit} from './pages/DocumentEdit'
import {EmpowermentEdit} from './pages/EmpowermentEdit'
import {DocumentTemplatesEdit} from './pages/Settings/partials'

import {GeneralModals} from './components/GeneralModals'
import {Support} from './pages/Support'
import {QueryParamProvider} from 'use-query-params'
import {ReactRouter5Adapter} from 'use-query-params/adapters/react-router-5'
import {parse, stringify} from 'query-string'
import {CalendarPage} from './pages/CalendarPage'

const queryClient = new QueryClient()

export default class App extends Component {
    render() {
        return (
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <PersistGate
                        loading={null}
                        persistor={persistor}>
                        <ErrorsModalContainer />
                        <GeneralModals />
                        <ToastContainer
                            autoClose={3000}
                            theme="colored"
                        />
                        <ConnectedRouter history={history}>
                            <QueryParamProvider
                                adapter={ReactRouter5Adapter}
                                options={{
                                    searchStringToObject: parse,
                                    objectToSearchString: stringify
                                }}>
                                <div className="App">
                                    <Switch>
                                        <Redirect
                                            exact
                                            from="/"
                                            to="/dashboard"
                                        />
                                        <Route
                                            exact
                                            path="/login"
                                            component={Login}
                                        />
                                        {/*<Route exact path='/register' component={Register} />*/}
                                        <Route
                                            exact
                                            path="/forgot-password"
                                            component={ForgotPassword}
                                        />
                                        <Route
                                            path="/password-confirmation/:resetPasswordUID/:resetPasswordCode"
                                            component={ResetPassword}
                                        />
                                        <Route
                                            path="/account-activation/:activationCode"
                                            component={AccountActivation}
                                        />
                                        <Route
                                            exact
                                            path="/terms-and-conditions"
                                            component={TermsAndConditions}
                                        />
                                        <Route
                                            exact
                                            path="/select-entity"
                                            component={SelectEntity}
                                        />
                                        <Route
                                            exact
                                            path="/dashboard"
                                            component={(props) => (
                                                <MainLayout pageTitle="Dashboard - Panoul Principal">
                                                    <Dashboard {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Redirect
                                            exact
                                            from="/clients"
                                            to="/clients/active"
                                        />
                                        <Route
                                            exact
                                            path="/clients/add"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ClientAdd {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/clients/:primaryAction(active|groups|inactive)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ClientsList {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/clients/:clientID(\d+)/contact-persons/:contactPersonID"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ContactPersonDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/clients/:clientID(\d+)/:primaryAction?/:secondaryAction?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ClientDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/clients/groups/add"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ClientGroupAdd {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/clients/groups/:clientGroupID(\d+)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ClientGroupDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            // eslint-disable-next-line max-len
                                            path="/clients/:clientID(\d+)/external-data/monitor-dosare/:subscriptionID(\d+)/:primaryAction?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <MDSubscriptionDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Redirect
                                            exact
                                            from="/projects"
                                            to="/projects/active"
                                        />
                                        <Route
                                            exact
                                            path="/projects/add"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ProjectAdd {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/projects/:primaryAction(active|inactive)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ProjectsList {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/projects/:projectID(\d+)/:primaryAction?/:secondaryAction?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ProjectDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            // eslint-disable-next-line max-len
                                            path="/projects/:projectID(\d+)/external-data/monitor-dosare/:subscriptionID(\d+)/:primaryAction?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <MDSubscriptionDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Redirect
                                            exact
                                            from="/tasks"
                                            to="/tasks/list"
                                        />
                                        <Route
                                            exact
                                            path="/tasks/:taskID(\d+)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <TaskDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Redirect
                                            exact
                                            from="/tasks/time-logs"
                                            to="/tasks/time-logs/week"
                                        />
                                        <Redirect
                                            exact
                                            from="/tasks/calendar"
                                            to="/tasks/calendar/week"
                                        />
                                        <Route
                                            exact
                                            // eslint-disable-next-line max-len
                                            path="/tasks/:primaryAction(list|calendar|time-logs)/:secondaryAction(week|day|month)?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <Tasks {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/tasks/:taskID(\d+)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <TaskDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Redirect
                                            exact
                                            from="/contracts"
                                            to="/contracts/contracts"
                                        />
                                        <Route
                                            exact
                                            path="/contracts/:primaryAction(contracts|addendums|empowerments)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <Contracts {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/contracts/add"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ContractAdd {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            // eslint-disable-next-line max-len
                                            path="/contracts/:contractID(\d+)/:primaryAction(info|settings|preview|signed|addendums|empowerments)?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ContractDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/contracts/:contractID(\d+)/edit"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ContractEdit {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/contracts/addendums/add"
                                            component={(props) => (
                                                <MainLayout>
                                                    <AddendumAdd {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            // eslint-disable-next-line max-len
                                            path="/contracts/addendums/:addendumID(\d+)/:primaryAction(info|settings|preview|signed)?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <AddendumDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/contracts/addendums/:addendumID(\d+)/edit"
                                            component={(props) => (
                                                <MainLayout>
                                                    <AddendumEdit {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/contracts/empowerments/add"
                                            component={(props) => (
                                                <MainLayout>
                                                    <EmpowermentAdd {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            // eslint-disable-next-line max-len
                                            path="/contracts/empowerments/:empowermentID(\d+)/:primaryAction(info|settings|preview|signed)?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <EmpowermentDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/contracts/empowerments/:empowermentID(\d+)/edit"
                                            component={(props) => (
                                                <MainLayout>
                                                    <EmpowermentEdit {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/documents"
                                            component={(props) => (
                                                <MainLayout>
                                                    <DocumentsList {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/documents/:documentID(\d+)/:primaryAction(archive|preview|signed)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <DocumentDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/documents/:documentID(\d+)/edit/:versionID(\d+)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <DocumentEdit {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/notifications"
                                            component={(props) => (
                                                <MainLayout>
                                                    <Notifications {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/settings/documents/templates/:documentTemplateID(\d+)/edit"
                                            component={(props) => (
                                                <MainLayout>
                                                    <DocumentTemplatesEdit {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/settings/:primaryAction(profile|documents)?/:secondaryAction?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <Settings {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/support"
                                            component={(props) => (
                                                <MainLayout>
                                                    <Support {...props} />
                                                </MainLayout>
                                            )}
                                        />

                                        <Redirect
                                            exact
                                            from="/expenses"
                                            to="/expenses/list"
                                        />
                                        <Route
                                            exact
                                            path="/expenses/:primaryAction(list|payments)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ExpensesPage {...props} />
                                                </MainLayout>
                                            )}
                                        />

                                        <Route
                                            exact
                                            path="/expenses/:expenseID(\d+)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ExpenseDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/expense-payments/:expensePaymentID(\d+)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ExpensePaymentDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Redirect
                                            exact
                                            from="/invoices"
                                            to="/invoices/invoices"
                                        />
                                        <Route
                                            exact
                                            path="/invoices/:primaryAction(invoices|proformas|payments)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <Invoices {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Redirect
                                            exact
                                            from="/reports"
                                            to="/reports/time-logs"
                                        />
                                        <Route
                                            exact
                                            path="/reports/:primaryAction(time-logs|some-other-reports)"
                                            component={(props) => (
                                                <MainLayout>
                                                    <Reports {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/invoices/add"
                                            component={(props) => (
                                                <MainLayout>
                                                    <InvoiceAdd {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/invoices/:invoiceID(\d+)/edit"
                                            component={(props) => (
                                                <MainLayout>
                                                    <InvoiceEdit {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            // eslint-disable-next-line max-len
                                            path="/invoices/:invoiceID(\d+)/:primaryAction(info|preview|reports|payments)?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <InvoiceDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/external-data/monitor-dosare/:subscriptionID(\d+)/:primaryAction?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <MDSubscriptionDetails {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route
                                            exact
                                            // eslint-disable-next-line max-len
                                            path="/external-data/:primaryAction(monitor-dosare|monitor-bpi)/:secondaryAction?"
                                            component={(props) => (
                                                <MainLayout>
                                                    <ExternalData {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Redirect
                                            exact
                                            from="/external-data"
                                            to="/external-data/monitor-dosare"
                                        />

                                        <Route
                                            exact
                                            path="/calendar"
                                            component={(props) => (
                                                <MainLayout>
                                                    <CalendarPage {...props} />
                                                </MainLayout>
                                            )}
                                        />
                                        <Route component={NotFound} />
                                    </Switch>
                                </div>
                            </QueryParamProvider>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
            </QueryClientProvider>
        )
    }
}
