import React, {useState} from 'react'

import {Form, Formik} from 'formik'

import {connect} from 'react-redux'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {modalTypes} from '../../../../redux/modals'

// @ts-ignore
import {Button} from '../../../../components/Button'
// @ts-ignore
import {DatePicker} from '../../../../components/DatePicker'
// @ts-ignore
import {Input} from '../../../../components/Input'
// @ts-ignore
import {Modal} from '../../../../components/Modal'

import {roundFixed} from '../../../../utils/math'

import './TimeLogBillableHoursModal.scss'

interface TimeLogBillableHoursModalProps {
    open: boolean
    timeLogId: number
    defaultDurationBillableAsHours: number
    updateTimeLog: (data: any, taskTimeLogID: number) => void
}

const convertDecimalToDate = (value: number) => {
    const date = new Date(0, 0, 0, 0, 0, 0, 0)
    date.setSeconds(Number(value) * 3600)
    return date
}

const convertDateToDecimal = (value: Date) => {
    return roundFixed(value.getHours() + value.getMinutes() / 60, 2)
}

const TimeLogBillableHoursModal: React.FC<TimeLogBillableHoursModalProps> = ({
    open,
    timeLogId,
    defaultDurationBillableAsHours,
    updateTimeLog
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    return (
        <Modal
            open={open}
            title="Modifică pontaj">
            <Formik
                initialValues={{
                    duration_billable: convertDecimalToDate(defaultDurationBillableAsHours),
                    duration_billable_as_hours: defaultDurationBillableAsHours
                }}
                onSubmit={(values) => {
                    const diffInSec = values.duration_billable_as_hours * 3600
                    setIsSubmitting(true)
                    updateTimeLog({duration_billable: `00:00:${diffInSec}`}, timeLogId)
                    setIsSubmitting(false)
                }}>
                {({handleBlur, setFieldValue, handleSubmit, values, errors, touched}) => (
                    <Form className="time-log-billable-hours-form">
                        <DatePicker
                            label="Ore facturabile (timp)"
                            value={values.duration_billable}
                            onChange={(date: Date) => {
                                setFieldValue('duration_billable', date)
                                setFieldValue('duration_billable_as_hours', convertDateToDecimal(date))
                            }}
                            timeFormat="HH:mm"
                            timeSelect
                            fullWidth
                        />
                        <Input
                            label="Ore facturabile (fracție)"
                            value={values.duration_billable_as_hours}
                            onChange={(e: any) => {
                                const dateAsDecimal = e.target.value
                                setFieldValue('duration_billable_as_hours', dateAsDecimal)
                                setFieldValue('duration_billable', convertDecimalToDate(dateAsDecimal))
                            }}
                            name="duration_billable_as_hours"
                            frontendErrors={errors}
                            touched={touched.duration_billable_as_hours}
                            onBlur={handleBlur}
                            type="number"
                            fullWidth
                        />
                        <hr />
                        <Button
                            title="Salvează"
                            onClick={handleSubmit}
                            loading={isSubmitting}
                            color="secondary"
                            fullWidth
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.EDIT_TASK_TIME_LOG_BILLABLE_HOURS
})

const mapDispatchToProps = (dispatch: any) => ({
    updateTimeLog: (data: any, taskTimeLogID: number) => dispatch(RESOURCES.taskTimeLogs.update(data, taskTimeLogID))
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeLogBillableHoursModal)
