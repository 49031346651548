import {lightFormat} from 'date-fns'
import {CheckCell} from '../../../../components/CheckCell'
import React from 'react'
import {ProgressPie} from '../../../../components/ProgressPie'
import {TableLink} from '../../../../components/TableLink'

export const columns = [
    {
        Header: 'Data',
        accessor: 'date',
        Cell: ({value: start}) => (start ? lightFormat(new Date(start), 'dd/MM/yyyy') : '-'),
        style: {
            width: '75px'
        }
    },
    {
        Header: 'Suma',
        accessor: 'amount',
        Cell: ({value, row: {original}}) => `${value} ${original.currency}`
    },
    {
        Header: 'Descriere',
        accessor: 'description'
    },
    {
        Header: '',
        accessor: 'uncovered_allocation',
        Cell: ({value, row: {original}}) =>
            value === null ? (
                <CheckCell />
            ) : (
                <ProgressPie percentage={100 - (value.amount_RON / original.amount_RON) * 100} />
            ),
        style: {
            width: '55px'
        }
    }
]

export const columnsExtended = [
    {
        Header: 'Data',
        accessor: 'date',
        Cell: ({value: start}) => (start ? lightFormat(new Date(start), 'dd/MM/yyyy') : '-'),
        style: {
            width: '75px'
        }
    },
    {
        Header: 'Suma',
        accessor: 'amount',
        Cell: ({value, row: {original}}) => `${value} ${original.currency}`
    },
    {
        Header: 'Descriere',
        accessor: 'description'
    },
    {
        Header: 'Clienți',
        accessor: 'clients',
        Cell: ({
            row: {
                original: {project}
            }
        }) => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {project?.clients.map((client, index) => (
                    <>
                        <TableLink to={`/clients/${client.id}`}>{client.name}</TableLink>
                        {index + 1 !== project.clients.length && ', '}
                    </>
                ))}
            </div>
        ),
        style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Proiect',
        accessor: 'project',
        Cell: ({value: project}) =>
            project ? <TableLink to={`/projects/${project.id}`}>{project.name}</TableLink> : '-'
    },
    {
        Header: '',
        accessor: 'uncovered_allocation',
        Cell: ({value, row: {original}}) =>
            value === null ? (
                <CheckCell />
            ) : (
                <ProgressPie percentage={100 - (value.amount_RON / original.amount_RON) * 100} />
            ),
        style: {
            width: '55px'
        }
    }
]

export const isCompletedOptions = {
    FALSE: {
        label: 'Active',
        value: false
    },
    TRUE: {
        label: 'Finalizate',
        value: true
    }
}
