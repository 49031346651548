import React, {useCallback, useMemo, useState} from 'react'

import {lightFormat} from 'date-fns'
import {filter, isEmpty, isNull, toString} from 'lodash'

import {connect} from 'react-redux'

import {upperSnakeCase} from '../../../../utils'
import {billingPaymentMethods, currencies, successBonusTypes, taxPercentageOptions} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DetailsCard} from '../../../../components/DetailsCard'
import {EditContractSettingsDisclaimerModal} from '../../../../components/EditContractSettingsDisclaimerModal'

import {EditContractForm} from './partials'

export const ContractSettings = ({contract, isLoading}) => {
    const [editMode, setEditMode] = useState(false)

    const isHourlyBlended = useMemo(
        () => !isEmpty(contract) && contract.billing_payment_method === billingPaymentMethods.HOURLY_BLENDED.value,
        [contract]
    )

    const joinArray = useCallback((array) => {
        if (array && !isEmpty(array)) {
            return array.join(', ')
        }

        return ''
    }, [])

    const ammountToRecover = useMemo(() => {
        if (contract.amount_to_recover && contract.amount_to_recover_currency) {
            return `${contract.amount_to_recover} ${
                currencies[upperSnakeCase(contract.amount_to_recover_currency)].label
            }`
        }

        return ''
    }, [contract.amount_to_recover, contract.amount_to_recover_currency])

    const billingAmount = useMemo(() => {
        if (contract.billing_amount) {
            return `${contract.billing_amount} ${currencies[upperSnakeCase(contract.billing_currency)].label}`
        }

        return ''
    }, [contract.billing_amount, contract.billing_currency])

    const amountForExtraHours = useMemo(() => {
        if (contract.billing_amount_for_extra_hours) {
            return `${contract.billing_amount_for_extra_hours} ${
                currencies[upperSnakeCase(contract.billing_currency)].label
            }`
        }
        return ''
    }, [contract.billing_amount_for_extra_hours, contract.billing_currency])

    const successBonus = useMemo(() => {
        if (!isNull(contract.billing_success_bonus_value) && !isNull(contract.billing_success_bonus_type)) {
            const bonusType =
                contract.billing_success_bonus_type === successBonusTypes.PERCENT.value
                    ? '%'
                    : ` ${currencies[upperSnakeCase(contract.billing_success_bonus_currency)].label}`

            return `${contract.billing_success_bonus_value}${bonusType}`
        }

        return ''
    }, [
        contract.billing_success_bonus_currency,
        contract.billing_success_bonus_type,
        contract.billing_success_bonus_value
    ])

    const rows = useMemo(() => {
        if (!isEmpty(contract)) {
            return filter(
                [
                    {
                        label: 'Proiecte',
                        value: joinArray(contract.projects.map((project) => project.name))
                    },
                    {
                        label: 'Clienți',
                        value: joinArray(contract.clients.map((client) => client.name))
                    },
                    {
                        label: 'Serie internă',
                        value: `${contract.internal_series_name} (${contract.internal_series_number})`
                    },
                    {
                        label: 'Serie barou',
                        value: `${contract.bar_series_name} (${contract.bar_series_number})`
                    },
                    {label: 'Obiect contract', value: contract.legal_object},
                    {
                        label: 'Data semnării',
                        value: contract.signed_date ? lightFormat(new Date(contract.signed_date), 'dd/MM/yyyy') : ''
                    },
                    {
                        label: 'Data producere efente',
                        value: contract.start_date ? lightFormat(new Date(contract.start_date), 'dd/MM/yyyy') : ''
                    },
                    {
                        label: 'Data încetare',
                        value: contract.end_date ? lightFormat(new Date(contract.end_date), 'dd/MM/yyyy') : ''
                    },
                    {
                        label: 'Sumă de recuperat',
                        value: ammountToRecover
                    },
                    {
                        label: 'Tip facturare',
                        value: contract.billing_payment_method
                            ? billingPaymentMethods[upperSnakeCase(contract.billing_payment_method)].label
                            : ''
                    },
                    {
                        label: isHourlyBlended ? 'Ore minime' : null,
                        value: isHourlyBlended && contract.billing_minimum_hours ? contract.billing_minimum_hours : ''
                    },
                    {
                        label: 'Sumă',
                        value: billingAmount
                    },
                    {
                        label: isHourlyBlended ? 'Sumă ore suplimentare' : null,
                        value: amountForExtraHours
                    },
                    {
                        label: 'Taxă adăugtă',
                        value:
                            taxPercentageOptions[parseInt(contract.billing_tax_percentage)]?.label ||
                            `${contract.billing_tax_percentage}%`
                    },
                    {
                        label: 'Recurent',
                        value: !isNull(contract.billing_recurring_months)
                            ? `${contract.billing_recurring_months} luni`
                            : 'Nu'
                    },
                    {
                        label: 'Onorariu succes',
                        value: successBonus
                    },
                    {label: 'Șablon', value: toString(contract.template.name)}
                ],
                (row) => !isNull(row.label)
            )
        }

        return []
    }, [ammountToRecover, amountForExtraHours, billingAmount, contract, isHourlyBlended, joinArray, successBonus])

    return (
        <div className="contract-details-page-container">
            <EditContractSettingsDisclaimerModal />
            {!isEmpty(contract) ? (
                <div className="contract-info-cards-container">
                    {editMode ? (
                        <EditContractForm handleExitEditMode={() => setEditMode(false)} />
                    ) : (
                        <DetailsCard
                            title="Setări contract"
                            rows={rows}
                            headerButton={() => (
                                <Button
                                    title="Editează"
                                    onClick={() => setEditMode(true)}
                                    size="small"
                                />
                            )}
                            loading={isLoading}
                        />
                    )}
                </div>
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading
})

export default connect(mapStateToProps)(ContractSettings)
