import React from 'react'
import {connect} from 'react-redux'

export const CalendarDayView = () => {
    return <div>CalendarDayView</div>
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDayView)
