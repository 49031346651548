import React, {useState} from 'react'

import {keepPreviousData, useQuery} from '@tanstack/react-query'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {useDebouncedValue} from '../../utils/hooks'

import {Select} from '../Select'

const fetchData = async (filters, search) => {
    const {data} = await performRequest(RESOURCES_V2.tasks.list({...filters, search}))
    return data.results
}

export const TaskCreatable = ({
    value,
    disabled,
    onChange,
    onBlur,
    name,
    errors,
    frontendErrors,
    touched,
    projectId,
    selectedEntityID,
    entityProfileID
}) => {
    const [tasksQuery, setTasksQuery] = useState('')
    const debouncedSearchQuery = useDebouncedValue(tasksQuery, 300)

    const handleChangeTasksSearchField = (value) => setTasksQuery(value)

    const filters = {
        project_id: projectId,
        entity_id: selectedEntityID,
        is_completed: false,
        page_size: 15
    }

    const {data, isFetching, isPlaceholderData} = useQuery({
        queryKey: ['tasks-creatable', filters, debouncedSearchQuery],
        queryFn: async () => await fetchData(filters, debouncedSearchQuery),
        placeholderData: keepPreviousData,
        staleTime: 1000 * 5
    })

    const handleCreateTask = () => {
        const taskData = {
            title: tasksQuery,
            project_id: projectId,
            entity_id: selectedEntityID,
            owners_ids: [entityProfileID],
            billable: true
        }

        performRequest(RESOURCES.tasks.create(taskData)).then((response) => {
            onChange(response.data)
            setTasksQuery('')
        })
    }

    return (
        <Select
            label="Sarcină"
            placeholder="Alege o sarcină"
            value={value}
            options={data}
            loading={isFetching || isPlaceholderData}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            onInputChange={handleChangeTasksSearchField}
            noOptionsMessage={() => (
                <div
                    className="avoapp-select-no-options-custom-message"
                    onClick={handleCreateTask}>
                    Crează sarcină <span>"{tasksQuery}"</span>
                </div>
            )}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            errors={errors}
            frontendErrors={frontendErrors}
            touched={touched}
            isClearable
            fullWidth
        />
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    entityProfileID: state.localConfigs.entityProfileID
})

export default connect(mapStateToProps)(TaskCreatable)
