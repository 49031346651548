import React, {useMemo} from 'react'
import {Link} from 'react-router-dom'

import {isYesterday, lightFormat} from 'date-fns'
import _ from 'lodash'

import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import './AutoStoppedTaskTimeLogModal.scss'

export const AutoStoppedTaskTimeLogModal = ({open, currentTimeLog, closeModal}) => {
    const isCurrentTimeLogLoaded = useMemo(() => {
        return !_.isNil(currentTimeLog) && !_.isEmpty(currentTimeLog)
    }, [currentTimeLog])

    const dateStarted = useMemo(() => {
        if (isCurrentTimeLogLoaded) {
            if (isYesterday(new Date(currentTimeLog.date))) {
                return 'ieri'
            }

            return `în data de ${lightFormat(new Date(currentTimeLog.date), 'dd/MM/yyyy')}`
        }
    }, [currentTimeLog.date, isCurrentTimeLogLoaded])

    return (
        <Modal
            open={open && isCurrentTimeLogLoaded}
            title="Pontaj oprit automat"
            disableClosing>
            <div className="auto-stopper-task-time-log-modal-content-container">
                <p className="auto-stopped-task-time-log-message">
                    Am detectat un pontaj pornit <span>{dateStarted}</span> și l-am oprit automat.
                </p>
                <p className="auto-stopped-task-time-log-disclaimer">
                    Un pontaj poate rula pana la ora 23:59:59 în data în care a fost pornit. Dacă un pontaj continua
                    după această ora, acesta va fi oprit automat
                </p>
                <div className="auto-stopper-task-time-log-modal-buttons-container">
                    {!_.isNil(currentTimeLog.task) && (
                        <Link to={`/tasks/${currentTimeLog.task.id}`}>
                            <Button
                                title="Vezi sarcina asociată pontajului"
                                onClick={closeModal}
                                variant="outlined"
                                color="gray"
                            />
                        </Link>
                    )}
                    <Button
                        title="Am înțeles"
                        onClick={closeModal}
                        color="secondary"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.AUTO_STOPPED_TASK_TIME_LOG,
    currentTimeLog: state.taskTimeLogs.currentTaskTimeLog
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AutoStoppedTaskTimeLogModal)
