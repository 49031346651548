import {ArrowLeftIcon} from '@heroicons/react/24/outline'
import React, {useEffect} from 'react'

import {lightFormat} from 'date-fns'
import {get, isEmpty, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {Button} from '../../../../components/Button'
import {PageLoader} from '../../../../components/PageLoader'

import {push} from 'connected-react-router'
import {AddExpensePaymentAllocationModal} from '../../../../components/AddExpensePaymentAllocationModal'
import {Datatable} from '../../../../components/Datatable'
import {DatatableRowButtons} from '../../../../components/DatatableRowButtons'
import {modalTypes, openModal} from '../../../../redux/modals'
import {datatablePageSize} from '../../../../utils/datatables'
import {columns} from '../../partials/ExpensesList/constants'
import './ExpensePaymentDetails.scss'

const ExpensePaymentDetails = ({
    match: {
        params: {expensePaymentID}
    },
    expensePayment,
    isLoading,
    navigate,
    retrieveExpensePayment,
    expenses,
    listExpenses,
    selectedEntityID,
    modalIsOpen,
    openModal,
    history: {goBack}
}) => {
    useEffect(() => {
        if (!modalIsOpen) {
            retrieveExpensePayment(expensePaymentID)
            listExpenses(selectedEntityID, null, {expense_payment_id: expensePaymentID})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen])

    const fileName = get(expensePayment.file_meta, 'name')

    return (
        <div className="resource-details-content">
            <div className="back-button-container">
                <Button
                    title="Mergi înapoi"
                    onClick={goBack}
                    size="small"
                    color="gray"
                    variant="outlined"
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            {!isEmpty(expensePayment) ? (
                <>
                    <div className="header-container">
                        <div className="header-resource-title-container">
                            <p className="header-resource-title">{expensePayment.title}</p>
                        </div>
                    </div>
                    <div className="content-container expense-payment-details">
                        <div className="task-info-card">
                            <div className="task-info-card-header">
                                <p className="header-title">Informații plată</p>
                            </div>
                            <div className="task-info-card-content">
                                <div className="task-info-card-row">
                                    <p className="label">Data</p>
                                    <p className="value">{lightFormat(new Date(expensePayment.date), 'dd/MM/yyyy')}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Proiect</p>
                                    <p className="value">{expensePayment.project?.name || '-'}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Contract</p>
                                    <p className="value">{expensePayment.contract?.name || '-'}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Sumă</p>
                                    <p className="value">
                                        {expensePayment.amount} {expensePayment.currency}
                                    </p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Curs Valutar</p>
                                    <p className="value">{expensePayment.exchange_rate || '-'}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Sumă RON</p>
                                    <p className="value">{expensePayment.amount_RON}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Document</p>
                                    <p className="value">
                                        {expensePayment.file_meta ? (
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                href={expensePayment.file_meta.url_public}>
                                                {fileName}
                                            </a>
                                        ) : (
                                            '-'
                                        )}
                                    </p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Informații plată</p>
                                    <p className="value">
                                        {expensePayment.description ? (
                                            <p className="task-description">{expensePayment.description}</p>
                                        ) : (
                                            '-'
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="task-activities-container">
                            <Datatable
                                title="Cheltuieli"
                                headerButton={() =>
                                    expensePayment.uncovered_allocation && (
                                        <Button
                                            title="Adaugă legatura"
                                            color="secondary"
                                            onClick={() => openModal(modalTypes.ADD_EXPENSE_PAYMENT_ALLOCATION)}
                                        />
                                    )
                                }
                                loading={isLoading}
                                data={!isLoading ? expenses : []}
                                columns={[
                                    ...columns,
                                    {
                                        Header: 'Acțiuni',
                                        accessor: 'id',
                                        Cell: ({value: expenseID}) => (
                                            <DatatableRowButtons
                                                mainButtonTitle="Vezi"
                                                onMainButtonClick={() => {
                                                    navigate(`/expenses/${expenseID}`)
                                                }}
                                            />
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <AddExpensePaymentAllocationModal
                        expensePaymentID={expensePaymentID}
                        allocatedExpenses={expenses}
                        expensePaymentAmountRON={expensePayment.amount_RON}
                        projectID={expensePayment.project_id}
                    />
                </>
            ) : isLoading ? (
                <PageLoader />
            ) : null}
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const {
        match: {
            params: {expensePaymentID}
        }
    } = props

    return {
        modalIsOpen: state.modals.type === modalTypes.ADD_EXPENSE_PAYMENT_ALLOCATION,
        expenses: values(state.expenses.data),
        expensePayment: get(state.expensePayments.data, expensePaymentID, {}),
        selectedEntityID: state.localConfigs.selectedEntityID,
        isLoading: state.expensePayments.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    navigate: (route) => dispatch(push(route)),
    retrieveExpensePayment: (expensePaymentID) => dispatch(RESOURCES.expensePayments.retrieve(expensePaymentID)),
    listExpenses: (entityID, search, filters, page) =>
        dispatch(
            RESOURCES.expenses.list(
                {
                    ...filters,
                    entity_id: entityID,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        ),
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpensePaymentDetails)
