import React, {useEffect} from 'react'
import {ArrowDownTrayIcon, ArrowUpTrayIcon, TrashIcon} from '@heroicons/react/24/outline'

import {isNull, isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {Button} from '../../../../components/Button'
import {Loader} from '../../../../components/Loader'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'

import {DeleteSignedEmpowermentModal} from '../modals'

import './SignedEmpowermentPreview.scss'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getTokenAndUploadFile} from '../../../../utils/files'
import DocumentViewer from '../../../../components/DocumentViewer/DocumentViewer'

export const SignedEmpowermentPreview = ({
    match: {
        params: {empowermentID}
    },
    isLoadingData,
    openModal,
    selectedEntityID,
    user
}) => {
    const [document, setDocument] = React.useState({signed_file_meta: null})
    const [isProcessing, setIsProcessing] = React.useState(false)

    const handleDropSignedFile = async (selectedFile, empowermentID) => {
        if (!isEmpty(selectedFile)) {
            setIsProcessing(true)
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            // updateDocument({signed_file: fileURL}, documentID)
            await performRequest(RESOURCES.empowerments.update({signed_file: fileURL}, empowermentID))
            const response = await performRequest(RESOURCES.empowerments.retrieve(empowermentID))
            const {data} = response
            setDocument(data)
            setIsProcessing(false)
        }
    }
    const isLoading = isLoadingData || isProcessing

    useEffect(() => {
        setIsProcessing(true)
        performRequest(RESOURCES.empowerments.retrieve(empowermentID)).then((response) => {
            const {data} = response
            setDocument(data)
            setIsProcessing(false)
        })
    }, [empowermentID])

    return (
        <div className="page-info">
            <div className="signed-empowerment-preview-page-container">
                <div className="signed-empowerment-preview-page-header">
                    <div className="header-buttons-container">
                        {!isNull(document.signed_file_meta) && !isLoading && (
                            <>
                                <DeleteSignedEmpowermentModal />
                                <a
                                    href={document.signed_file_meta.url_public}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Button
                                        title="Descarcă"
                                        icon={() => <ArrowDownTrayIcon />}
                                    />
                                </a>
                                <Button
                                    title="Șterge"
                                    onClick={() => openModal(modalTypes.DELETE_SIGNED_DOCUMENT)}
                                    icon={() => <TrashIcon />}
                                    color="red"
                                />
                            </>
                        )}
                        <SignedFileDropzone
                            onChange={(selectedFile) => handleDropSignedFile(selectedFile, document.id)}
                            customButton={() => (
                                <Button
                                    title="Încarcă"
                                    icon={() => <ArrowUpTrayIcon />}
                                    color="orange"
                                />
                            )}
                        />
                    </div>
                </div>
                {!!document.signed_file_meta && !isLoading ? (
                    <div className="signed-empowerment-preview-container">
                        <DocumentViewer
                            fileMeta={document.signed_file_meta}
                            user={user}
                        />
                    </div>
                ) : isLoading ? (
                    <div className="loader-container">
                        <Loader />
                    </div>
                ) : isNull(document.signed_file_meta) ? (
                    <p className="no-signed-empowerment">
                        Nu există niciun empowerment semnat asociat acestui empowerment
                    </p>
                ) : null}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoadingData: state.empowerments.isLoading,
    user: state.users.user,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignedEmpowermentPreview)
