import {Form, Formik} from 'formik'
import React, {useEffect, useMemo, useState} from 'react'

import {values as _values, debounce, filter, find, includes, isEmpty, isNull, omit, orderBy, parseInt} from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {contractsSchema} from '../../../../../../../assets/validations'
import {getFieldOptions, objectKeysToSnakeCase, toApiDateFormat} from '../../../../../../../utils'
import {debounceWait} from '../../../../../../../utils/constants'
import {documentSeriesTypes, taxPercentageOptions} from '../../../../../../../utils/types'
import {documentTemplateTypes} from '../../../../../constants'

import {DatePicker} from '../../../../../../../components/DatePicker'
import {ErrorsList} from '../../../../../../../components/ErrorComponents'
import {FormSection} from '../../../../../../../components/FormSection'
import {Input} from '../../../../../../../components/Input'
import {PageLoader} from '../../../../../../../components/PageLoader'
import {RequiredFieldsText} from '../../../../../../../components/RequiredFieldsText'
import {Select} from '../../../../../../../components/Select'
import {Toggle} from '../../../../../../../components/Toggle'

export const EditContractForm = ({
    contract,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    selectedEntityID,
    projects,
    isLoadingProjects,
    clients,
    isLoadingClients,
    documentSeries,
    isLoadingDocumentSeries,
    documentTemplates,
    isLoadingDocumentTemplates,
    updateContract,
    handleExitEditMode,
    contractOptions,
    getContractOptions,
    searchProjects,
    searchClients,
    listDocumentTemplates,
    listAllDocumentSeries
}) => {
    const [clientsSearch, setClientsSearch] = useState('')

    useEffect(() => {
        searchProjects()
        handleFetchClients()
        getContractOptions()
        handleFetchDocumetTemplates()
        listAllDocumentSeries({entity_id: selectedEntityID})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const amountToRecoverCurrencies = useMemo(
        () => getFieldOptions(contractOptions, 'amount_to_recover_currency'),
        [contractOptions]
    )

    const billingPaymentMethods = useMemo(
        () => getFieldOptions(contractOptions, 'billing_payment_method'),
        [contractOptions]
    )

    const billingCurrencies = useMemo(() => getFieldOptions(contractOptions, 'billing_currency'), [contractOptions])

    const billingSuccessBonusTypes = useMemo(
        () => getFieldOptions(contractOptions, 'billing_success_bonus_type'),
        [contractOptions]
    )

    const billingSuccessBonusCurrencies = useMemo(
        () => getFieldOptions(contractOptions, 'billing_success_bonus_currency'),
        [contractOptions]
    )

    const handleFetchClients = (query = clientsSearch) => {
        let params = {project_id: contract.projects_ids}

        searchClients(query, params)
    }

    const debounceSearchClients = debounce(handleFetchClients, debounceWait)

    const handleChangeClientsSearchField = (value) => {
        setClientsSearch(value)
        debounceSearchClients(value)
    }

    const handleFetchDocumetTemplates = () => {
        let params = {
            entity_id: selectedEntityID,
            type: documentTemplateTypes.find((type) => type.value === 'contract').value
        }

        listDocumentTemplates(params)
    }

    return (
        <div className="contract-add-form-container">
            {!isEmpty(contractOptions) && !isEmpty(projects) && !isEmpty(clients) ? (
                <Formik
                    initialValues={{
                        projectsIds: filter(projects, (project) => includes(contract.projects_ids, project.id)),
                        clientsIds: filter(clients, (client) => includes(contract.clients_ids, client.id)),
                        internalSeriesId: find(
                            documentSeries,
                            (series) => parseInt(series.id) === parseInt(contract.internal_series_id)
                        ),
                        barSeriesId: find(
                            documentSeries,
                            (series) => parseInt(series.id) === parseInt(contract.bar_series_id)
                        ),
                        name: contract.name,
                        legalObject: contract.legal_object || '',
                        signedDate: !isNull(contract.signed_date) ? new Date(contract.signed_date) : null,
                        startDate: new Date(contract.start_date),
                        endDate: !isNull(contract.end_date) ? new Date(contract.end_date) : null,
                        hasAmountToRecover: Boolean(
                            !isNull(contract.amount_to_recover) && !isNull(contract.amount_to_recover_currency)
                        ),
                        amountToRecover: contract.amount_to_recover || '',
                        amountToRecoverCurrency: !isNull(contract.amount_to_recover_currency)
                            ? find(amountToRecoverCurrencies, ['value', contract.amount_to_recover_currency])
                            : null,
                        billingPaymentMethod: find(billingPaymentMethods, ['value', contract.billing_payment_method]),
                        billingAmount: contract.billing_amount,
                        billingCurrency: find(billingCurrencies, ['value', contract.billing_currency]),
                        billingTaxPercentage: taxPercentageOptions[parseInt(contract.billing_tax_percentage)],
                        billingMinimumHours: contract.billing_minimum_hours || '',
                        billingAmountForExtraHours: contract.billing_amount_for_extra_hours || '',
                        isRecurring: !isNull(contract.billing_recurring_months),
                        billingRecurringMonths: !isNull(contract.billing_recurring_months)
                            ? contract.billing_recurring_months
                            : '',
                        hasSuccessBonus: !isNull(contract.billing_success_bonus_value),
                        billingSuccessBonusValue: contract.billing_success_bonus_value || '',
                        billingSuccessBonusType: !isNull(contract.billing_success_bonus_type)
                            ? find(billingSuccessBonusTypes, ['value', contract.billing_success_bonus_type])
                            : null,
                        billingSuccessBonusCurrency: !isNull(contract.billing_success_bonus_currency)
                            ? find(billingSuccessBonusCurrencies, ['value', contract.billing_success_bonus_currency])
                            : null,
                        templateId: find(
                            documentTemplates,
                            (template) => parseInt(template.id) === parseInt(contract.template_id)
                        )
                    }}
                    validationSchema={contractsSchema.update}
                    onSubmit={(values) => {
                        const contractData = {
                            ...objectKeysToSnakeCase(
                                omit(values, [
                                    'projectsIds',
                                    'internalSeriesId',
                                    'barSeriesId',
                                    'hasAmountToRecover',
                                    'isRecurring',
                                    'hasSuccessBonus',
                                    'templateId'
                                ])
                            ),
                            entity_id: selectedEntityID,
                            clients_ids: values.clientsIds.map((client) => client.id),
                            signed_date: toApiDateFormat(values.signedDate),
                            start_date: toApiDateFormat(values.startDate),
                            end_date: toApiDateFormat(values.endDate),
                            amount_to_recover_currency: values.hasAmountToRecover
                                ? values.amountToRecoverCurrency.value
                                : null,
                            billing_payment_method: values.billingPaymentMethod.value,
                            billing_currency: values.billingCurrency.value,
                            billing_tax_percentage: values.billingTaxPercentage.value,
                            billing_recurring_months: values.isRecurring ? values.billingRecurringMonths : null,
                            billing_success_bonus_type: values.hasSuccessBonus
                                ? values.billingSuccessBonusType.value
                                : null,
                            billing_success_bonus_currency:
                                values.hasSuccessBonus &&
                                values.billingSuccessBonusType === find(billingSuccessBonusTypes, ['value', 'value'])
                                    ? values.billingSuccessBonusCurrency.value
                                    : null
                        }

                        updateContract(contractData, contract.id)
                    }}>
                    {({handleChange, handleBlur, setFieldValue, handleSubmit, values, touched, errors, isValid}) => (
                        <Form>
                            <ErrorsList errors={nonFieldErrors} />
                            <div className="space-y-6">
                                <FormSection
                                    autoHeight
                                    renderForm={() => (
                                        <>
                                            <p className="form-section-title">Setări generale</p>
                                            <div className="split-row">
                                                <Select
                                                    label="Proiecte*"
                                                    value={values.projectsIds}
                                                    options={projects}
                                                    loading={isLoadingProjects}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(e) => setFieldValue('projectsIds', e)}
                                                    onBlur={handleBlur('projectsIds')}
                                                    name="projectsIds"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.projectsIds}
                                                    fullWidth
                                                    isMulti
                                                    disabled
                                                />
                                                <Select
                                                    label="Clienți*"
                                                    value={values.clientsIds}
                                                    options={clients}
                                                    loading={isLoadingClients}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(e) => setFieldValue('clientsIds', e)}
                                                    onInputChange={(value) => handleChangeClientsSearchField(value)}
                                                    onBlur={handleBlur('clientsIds')}
                                                    disabled={isNull(values.projectsIds)}
                                                    name="clientsIds"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.clientsIds}
                                                    fullWidth
                                                    isMulti
                                                />
                                            </div>
                                            <div className="split-row">
                                                <Select
                                                    label="Serie interna*"
                                                    value={values.internalSeriesId}
                                                    options={filter(documentSeries, [
                                                        'type',
                                                        documentSeriesTypes.CONTRACT_INTERNAL.value
                                                    ])}
                                                    loading={isLoadingDocumentSeries}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} (${option.current_number})`
                                                    }
                                                    onChange={(e) => setFieldValue('internalSeriesId', e)}
                                                    onBlur={handleBlur('internalSeriesId')}
                                                    name="internalSeriesId"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.internalSeriesId}
                                                    disabled
                                                    fullWidth
                                                />
                                                <Select
                                                    label="Serie barou*"
                                                    value={values.barSeriesId}
                                                    options={filter(documentSeries, [
                                                        'type',
                                                        documentSeriesTypes.CONTRACT_BAR.value
                                                    ])}
                                                    loading={isLoadingDocumentSeries}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} (${option.current_number})`
                                                    }
                                                    onChange={(e) => setFieldValue('barSeriesId', e)}
                                                    onBlur={handleBlur('barSeriesId')}
                                                    name="barSeriesId"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.barSeriesId}
                                                    disabled
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="split-row">
                                                <Input
                                                    label="Nume contract*"
                                                    value={values.name}
                                                    onChange={handleChange('name')}
                                                    onBlur={handleBlur('name')}
                                                    name="name"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.name}
                                                    fullWidth
                                                />
                                                <Input
                                                    label="Obiect contract"
                                                    value={values.legalObject}
                                                    onChange={handleChange('legalObject')}
                                                    onBlur={handleBlur('legalObject')}
                                                    name="legalObject"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.legalObject}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="split-row-3">
                                                <DatePicker
                                                    label="Data semnării"
                                                    value={values.signedDate}
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            'signedDate',
                                                            isNull(value) ? value : new Date(value)
                                                        )
                                                    }
                                                    onBlur={handleBlur('signedDate')}
                                                    name="signedDate"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.signedDate}
                                                    fullWidth
                                                />
                                                <DatePicker
                                                    label="Data producere efecte*"
                                                    value={values.startDate}
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            'startDate',
                                                            isNull(value) ? value : new Date(value)
                                                        )
                                                    }
                                                    onBlur={handleBlur('startDate')}
                                                    name="startDate"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.startDate}
                                                    fullWidth
                                                />
                                                <DatePicker
                                                    label="Data încetare"
                                                    value={values.endDate}
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            'endDate',
                                                            isNull(value) ? value : new Date(value)
                                                        )
                                                    }
                                                    onBlur={handleBlur('endDate')}
                                                    name="endDate"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.endDate}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="split-row-3">
                                                <Toggle
                                                    label="Sumă de recuperat"
                                                    checked={values.hasAmountToRecover}
                                                    onChange={(e) => setFieldValue('hasAmountToRecover', e)}
                                                    onBlur={handleBlur('hasAmountToRecover')}
                                                    name="hasAmountToRecover"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.hasAmountToRecover}
                                                />
                                                {values.hasAmountToRecover && (
                                                    <>
                                                        <Input
                                                            label="Suma*"
                                                            value={values.amountToRecover}
                                                            onChange={handleChange('amountToRecover')}
                                                            onBlur={handleBlur('amountToRecover')}
                                                            name="amountToRecover"
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.amountToRecover}
                                                            fullWidth
                                                        />
                                                        <Select
                                                            label="Moneda*"
                                                            value={values.amountToRecoverCurrency}
                                                            options={amountToRecoverCurrencies}
                                                            onChange={(e) =>
                                                                setFieldValue('amountToRecoverCurrency', e)
                                                            }
                                                            onBlur={handleBlur('amountToRecoverCurrency')}
                                                            name="amountToRecoverCurrency"
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.amountToRecoverCurrency}
                                                            fullWidth
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            <RequiredFieldsText />
                                        </>
                                    )}
                                />
                                <FormSection
                                    autoHeight
                                    renderForm={() => (
                                        <>
                                            <p className="form-section-title">Setări facturare</p>
                                            <div className="split-row-4">
                                                <Select
                                                    label="Tip facturare*"
                                                    value={values.billingPaymentMethod}
                                                    options={billingPaymentMethods}
                                                    onChange={(e) => setFieldValue('billingPaymentMethod', e)}
                                                    onBlur={handleBlur('billingPaymentMethod')}
                                                    name="billingPaymentMethod"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingPaymentMethod}
                                                    fullWidth
                                                />
                                                <Input
                                                    label="Suma*"
                                                    value={values.billingAmount}
                                                    onChange={handleChange('billingAmount')}
                                                    onBlur={handleBlur('billingAmount')}
                                                    name="billingAmount"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingAmount}
                                                    fullWidth
                                                />
                                                <Select
                                                    label="Moneda*"
                                                    value={values.billingCurrency}
                                                    options={billingCurrencies}
                                                    onChange={(e) => setFieldValue('billingCurrency', e)}
                                                    onBlur={handleBlur('billingCurrency')}
                                                    name="billingCurrency"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingCurrency}
                                                    fullWidth
                                                />
                                                <Select
                                                    label="Taxă adăugată*"
                                                    value={values.billingTaxPercentage}
                                                    options={_values(orderBy(taxPercentageOptions, 'value', 'desc'))}
                                                    onChange={(e) => setFieldValue('billingTaxPercentage', e)}
                                                    onBlur={handleBlur('billingTaxPercentage')}
                                                    name="billingTaxPercentage"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingTaxPercentage}
                                                    fullWidth
                                                />
                                            </div>
                                            {values.billingPaymentMethod ===
                                                find(billingPaymentMethods, ['value', 'hourly_blended']) && (
                                                <div className="split-row">
                                                    <Input
                                                        label="Ore minime"
                                                        value={values.billingMinimumHours}
                                                        onChange={handleChange('billingMinimumHours')}
                                                        onBlur={handleBlur('billingMinimumHours')}
                                                        name="billingMinimumHours"
                                                        errors={fieldErrors}
                                                        frontendErrors={errors}
                                                        touched={touched.billingMinimumHours}
                                                        type="number"
                                                        fullWidth
                                                    />
                                                    <Input
                                                        label="Sumă ore suplimentare"
                                                        value={values.billingAmountForExtraHours}
                                                        onChange={handleChange('billingAmountForExtraHours')}
                                                        onBlur={handleBlur('billingAmountForExtraHours')}
                                                        name="billingAmountForExtraHours"
                                                        errors={fieldErrors}
                                                        frontendErrors={errors}
                                                        touched={touched.billingAmountForExtraHours}
                                                        type="number"
                                                        fullWidth
                                                    />
                                                </div>
                                            )}
                                            <div className="split-row-4">
                                                <Toggle
                                                    label="Recurent"
                                                    checked={values.isRecurring}
                                                    onChange={(e) => setFieldValue('isRecurring', e)}
                                                    onBlur={handleBlur('isRecurring')}
                                                    name="isRecurring"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.isRecurring}
                                                />
                                                {values.isRecurring && (
                                                    <div className="recurring-months-input-container">
                                                        <Input
                                                            value={values.billingRecurringMonths}
                                                            onChange={handleChange('billingRecurringMonths')}
                                                            onBlur={handleBlur('billingRecurringMonths')}
                                                            name="billingRecurringMonths"
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.billingRecurringMonths}
                                                            fullWidth
                                                        />
                                                        <p>luni*</p>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="split-row-4">
                                                <Toggle
                                                    label="Onorariu succes"
                                                    checked={values.hasSuccessBonus}
                                                    onChange={(e) => setFieldValue('hasSuccessBonus', e)}
                                                    onBlur={handleBlur('hasSuccessBonus')}
                                                    name="hasSuccessBonus"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.hasSuccessBonus}
                                                />
                                                {values.hasSuccessBonus && (
                                                    <>
                                                        <Select
                                                            label="Tip*"
                                                            value={values.billingSuccessBonusType}
                                                            options={billingSuccessBonusTypes}
                                                            onChange={(e) =>
                                                                setFieldValue('billingSuccessBonusType', e)
                                                            }
                                                            onBlur={handleBlur('billingSuccessBonusType')}
                                                            name="billingSuccessBonusType"
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.billingSuccessBonusType}
                                                            fullWidth
                                                        />
                                                        <Input
                                                            label="Număr*"
                                                            value={values.billingSuccessBonusValue}
                                                            onChange={handleChange('billingSuccessBonusValue')}
                                                            onBlur={handleBlur('billingSuccessBonusValue')}
                                                            name="billingSuccessBonusValue"
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.billingSuccessBonusValue}
                                                            fullWidth
                                                        />
                                                        {values.billingSuccessBonusType ===
                                                            find(billingSuccessBonusTypes, ['value', 'value']) && (
                                                            <Select
                                                                label="Monedă"
                                                                value={values.billingSuccessBonusCurrency}
                                                                options={billingSuccessBonusCurrencies}
                                                                onChange={(e) =>
                                                                    setFieldValue('billingSuccessBonusCurrency', e)
                                                                }
                                                                onBlur={handleBlur('billingSuccessBonusCurrency')}
                                                                name="billingSuccessBonusCurrency"
                                                                errors={fieldErrors}
                                                                frontendErrors={errors}
                                                                touched={touched.billingSuccessBonusCurrency}
                                                                fullWidth
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <RequiredFieldsText />
                                        </>
                                    )}
                                />
                                <FormSection
                                    autoHeight
                                    renderForm={() => (
                                        <>
                                            <p className="form-section-title">Conținut contract</p>
                                            <Select
                                                label="Șablon*"
                                                value={values.templateId}
                                                options={documentTemplates}
                                                loading={isLoadingDocumentTemplates}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e) => setFieldValue('templateId', e)}
                                                onBlur={handleBlur('templateId')}
                                                name="templateId"
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.templateId}
                                                menuPlacement="top"
                                                disabled
                                                fullWidth
                                            />
                                            <RequiredFieldsText />
                                        </>
                                    )}
                                    buttonDisabled={!isValid}
                                    onSubmit={handleSubmit}
                                    submitButtonTitle="Salvează"
                                    onSkip={handleExitEditMode}
                                    skipButtonTitle="Renunță"
                                    loading={isLoading}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : isLoadingClients || isLoadingProjects ? (
                <PageLoader />
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    contractOptions: state.contracts.options,
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading,
    fieldErrors: state.contracts.fieldErrors,
    nonFieldErrors: state.contracts.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    projects: _values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    clients: _values(state.clients.searchData),
    isLoadingClients: state.clients.isLoading,
    documentSeries: _values(state.documentSeries.data),
    isLoadingDocumentSeries: state.documentSeries.isLoading,
    documentTemplates: filter(state.documentTemplates.data, [
        'type',
        find(documentTemplateTypes, ['value', 'contract']).value
    ]),
    isLoadingDocumentTemplates: state.documentTemplates.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    getContractOptions: () => dispatch(RESOURCES.contracts.getOptions()),
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search)),
    searchClients: (search, params) => dispatch(RESOURCES.clients.search(search, params)),
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll({...params, active: true})),
    listDocumentTemplates: (params) => dispatch(RESOURCES.documentTemplates.listAll(params)),
    updateContract: (contractData, contractId) => dispatch(RESOURCES.contracts.update(contractData, contractId))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditContractForm)
