import React, {useState} from 'react'

import {Navbar} from '../components/Navbar'
import {Sidebar} from '../components/Sidebar'

export default function MainLayout({pageTitle, children}) {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <Navbar setSidebarOpen={setSidebarOpen} />
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        {pageTitle && (
                            <div className="mx-auto px-4 sm:px-6 md:px-8">
                                <h1 className="text-2xl font-semibold text-gray-900">{pageTitle}</h1>
                            </div>
                        )}
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">{children}</div>
                    </div>
                </main>
            </div>
        </div>
    )
}
