import React, {useCallback, useMemo} from 'react'

import {push} from 'connected-react-router'
import {Link} from 'react-router-dom'

import {ArrowLeftIcon} from '@heroicons/react/24/outline'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {setCurrent as setCurrentSubscription} from 'avoapp-react-common/dist/redux/subscriptions'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../redux/modals'

import {useQueryResourceDetail} from '../../queries/rest'
import {MDSubscriptionSources} from '../../utils/constants'

import {Button} from '../../components/Button'
import {DeleteSubscriptionModal} from '../../components/DeleteSubscriptionModal'
import {PageLoader} from '../../components/PageLoader'

import {actionIds as clientActionIds} from '../ClientDetails/constants'
import {actionIds as projectActionIds} from '../ProjectDetails/constants'
import {AppealsSection, HearingsSection, PartiesSection} from './partials'

import './MDSubscriptionDetails.scss'

const MDSubscriptionDetails = ({
    match: {
        params: {projectID, clientID, subscriptionID}
    },
    navigate
}) => {
    const {data: subscription, isFetching} = useQueryResourceDetail(RESOURCES.subscriptions, subscriptionID)

    const backLinkOptions = useMemo(() => {
        if (projectID) {
            return {
                link: `/projects/${projectID}/${projectActionIds.EXTERNAL_DATA}/${projectActionIds.EXTERNAL_DATA_MD}`,
                text: 'Mergi înapoi la proiect'
            }
        }

        if (clientID) {
            return {
                link: `/clients/${clientID}/${clientActionIds.EXTERNAL_DATA}/${clientActionIds.EXTERNAL_DATA_MD}`,
                text: 'Mergi înapoi la client'
            }
        }

        return {
            link: `/${clientActionIds.EXTERNAL_DATA}/${clientActionIds.EXTERNAL_DATA_MD}`,
            text: 'Mergi înapoi la date externe'
        }
    }, [clientID, projectID])

    const isManualSubscription = useMemo(() => {
        return subscription?.source === MDSubscriptionSources.MANUAL.value
    }, [subscription?.source])

    const navigateAfterDelete = useCallback(() => navigate(backLinkOptions.link), [navigate, backLinkOptions])

    if (isFetching) {
        return <PageLoader />
    }

    if (_.isNil(subscription)) {
        return <React.Fragment />
    }

    return (
        <div className="resource-details-content">
            <div className="back-button-container">
                {backLinkOptions && (
                    <Link to={backLinkOptions.link}>
                        <Button
                            title={backLinkOptions.text}
                            size="small"
                            color="gray"
                            variant="outlined"
                            icon={() => <ArrowLeftIcon />}
                            iconLeft
                        />
                    </Link>
                )}
            </div>
            <div className="header-resource-title-container">
                <p className="header-resource-title">{subscription.litigation_number}</p>
                <div
                    className={`subscription-active-badge-container ${
                        subscription.active ? 'subscription-active' : 'subscription-inactive'
                    }`}>
                    <p className="subscription-active-badge-text">
                        {subscription.active ? 'Abonare Activă' : 'Abonare Inactivă'}
                    </p>
                </div>
            </div>
            <div className="details-div">
                <div className="details-content">
                    <div className="page-subscription-show">
                        <div className="parties-appeals-hearings-container">
                            <div className="parties-appeals-container">
                                <PartiesSection
                                    subscription={subscription}
                                    isManualSubscription={isManualSubscription}
                                />
                                <AppealsSection
                                    subscription={subscription}
                                    isManualSubscription={isManualSubscription}
                                />
                            </div>
                            <HearingsSection
                                subscription={subscription}
                                isManualSubscription={isManualSubscription}
                            />
                        </div>
                        <div className="flex justify-end">
                            <Button
                                title="Șterge abonare"
                                color="red"
                                size="large"
                                onClick={() => {
                                    setCurrentSubscription(subscription)
                                    openModal(modalTypes.DELETE_SUBSCRIPTION)
                                }}
                            />
                            <DeleteSubscriptionModal doAfterDelete={navigateAfterDelete} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    setCurrentSubscription: (subscription) => dispatch(setCurrentSubscription(subscription)),
    navigate: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(MDSubscriptionDetails)
