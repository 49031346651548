import React, {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'

import {isUndefined} from 'lodash'

import {connect} from 'react-redux'
import {closeSlideover} from '../../redux/slideovers'

import {classNames} from '../../utils'

export const Slideover = ({open, title, children, onClose, closeSlideover, disableClosing}) => {
    const handleClose = () => {
        if (!isUndefined(onClose)) {
            onClose()
        }

        if (!disableClosing) {
            closeSlideover()
        }
    }

    return (
        <Transition.Root
            show={open}
            as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden z-10 bg-gray-300 bg-opacity-70"
                onClose={handleClose}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform ease-in-out duration-300"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform ease-in-out duration-300"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full">
                            <div className="w-screen max-w-md">
                                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                    <div className="py-6 px-4 bg-primary sm:px-6">
                                        <div className="flex items-center justify-between">
                                            <Dialog.Title className="text-xl font-bold text-white">
                                                {title}
                                            </Dialog.Title>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    className={classNames(
                                                        'bg-transparent rounded-md text-white hover:text-white',
                                                        'focus:outline-none focus:ring-2 focus:ring-white'
                                                    )}
                                                    onClick={closeSlideover}>
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative flex-1 py-6 px-4 sm:px-6">{children}</div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover())
})

export default connect(null, mapDispatchToProps)(Slideover)
