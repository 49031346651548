import React from 'react'

import {connect} from 'react-redux'

import {useQuery} from '@tanstack/react-query'
// @ts-ignore
import DocumentViewer from '../../../../../../components/DocumentViewer/DocumentViewer'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {Loader} from '../../../../../../components/Loader'

import {ArrowLeftIcon} from '@heroicons/react/24/outline'
import './DocumentTemplatesEdit.scss'

// @ts-ignore
import {Button} from '../../../../../../components/Button'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {goBack} from 'connected-react-router'
// @ts-ignore
import Save from '../../../../../../assets/icons/Save'

interface DocumentTemplatesEditProps {
    match: {params: {documentTemplateID: number}}
    user: any
    goBack: () => void
}

const DocumentTemplatesEdit: React.FC<DocumentTemplatesEditProps> = ({
    match: {
        params: {documentTemplateID}
    },
    user,
    goBack
}) => {
    const {data, isFetching} = useQuery({
        queryKey: [RESOURCES.documentTemplates.name, documentTemplateID],
        queryFn: async () => {
            const {data} = await performRequest(RESOURCES.documentTemplates.retrieve(documentTemplateID))
            return data
        },
        staleTime: Infinity // The file must not be re-fetched
    })

    return (
        <div className="document-editor-content">
            <div className="back-button-container">
                <Button
                    title="Mergi înapoi"
                    size="small"
                    color="gray"
                    variant="outlined"
                    onClick={goBack}
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            <div className="header-resource-title-container">
                {!isFetching ? (
                    <div className="document-name-container">
                        <p className="header-resource-title">
                            Editare document: <span>{data.file_meta.name}</span>
                        </p>

                        <div className="header-resource-disclaimer">
                            Pentru a salva documentul, folosește butonul de salvare din stânga editorului de mai jos
                            <Save />
                        </div>
                    </div>
                ) : null}
            </div>
            {!!data && !isFetching ? (
                <div className="file-container">
                    <DocumentViewer
                        fileMeta={data.file_meta}
                        user={user}
                        editable={true}
                    />
                </div>
            ) : (
                <div className="flex flex-row items-center gap-4">
                    <span className="pb-2">
                        <Loader />
                    </span>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.users.user
})

const mapDispatchToProps = (dispatch: any) => ({
    goBack: () => dispatch(goBack())
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTemplatesEdit)
