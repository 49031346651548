import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, values as _values, debounce, filter, includes, isNull} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../../redux/modals'

import {debounceWait} from '../../../../../../utils/constants'

import {Modal} from '../../../../../../components/Modal'
import {Select} from '../../../../../../components/Select'
import {Button} from '../../../../../../components/Button'
import {ErrorsList} from '../../../../../../components/ErrorComponents'

import './AssignContractModal.scss'

export class AssignContractModal extends Component {
    constructor() {
        super()

        this.state = {
            contractsQuery: ''
        }
    }

    componentDidMount = () => this.handleFetchContracts()

    handleFetchContracts = (query = this.state.contractsQuery) => {
        const {searchContracts} = this.props

        searchContracts(query)
    }

    debounceSearchContracts = debounce(this.handleFetchContracts, debounceWait)

    handleChangeContractsSearchField = (value) => {
        this.setState({contractsQuery: value})
        this.debounceSearchContracts(value)
    }

    render() {
        const {open, project, contracts, isLoadingContracts, fieldErrors, nonFieldErrors, updateContract, closeModal} =
            this.props

        return (
            <>
                {!isEmpty(project) ? (
                    <Modal
                        open={open && !isEmpty(project)}
                        title={`Assign contract to project "${project.name}"`}>
                        <Formik
                            initialValues={{contract: null}}
                            onSubmit={(values) => {
                                const contractData = {
                                    projects_ids: [...values.contract.projects_ids, project.id]
                                }

                                updateContract(contractData, values.contract.id)
                            }}>
                            {({handleBlur, setFieldValue, handleSubmit, values, touched, errors}) => (
                                <Form className="assign-contract-modal">
                                    <ErrorsList errors={nonFieldErrors} />
                                    <Select
                                        label="Contract"
                                        value={values.contract}
                                        options={filter(
                                            contracts,
                                            (contract) => !includes(contract.projects_ids, project.id)
                                        )}
                                        loading={isLoadingContracts}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => setFieldValue('contract', e)}
                                        onInputChange={(value) => this.handleChangeContractsSearchField(value)}
                                        onBlur={handleBlur('contract')}
                                        name="contract"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.contract}
                                        fullWidth
                                    />
                                    <div className="buttons-container">
                                        <Button
                                            title="Renunță"
                                            onClick={closeModal}
                                            variant="outlined"
                                            color="gray"
                                        />
                                        <Button
                                            title="Assign"
                                            onClick={handleSubmit}
                                            disabled={isNull(values.contract)}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                ) : null}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.ASSIGN_CONTRACT,
    project: state.projects.currentProject,
    contracts: _values(state.contracts.searchData),
    isLoadingContracts: state.contracts.isLoading,
    fieldErrors: state.contracts.fieldErrors,
    nonFieldErrors: state.contracts.nonFieldErrors
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    searchContracts: (search) => dispatch(RESOURCES.contracts.search(search)),
    updateContract: (contractData, contractID) => dispatch(RESOURCES.contracts.update(contractData, contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignContractModal)
