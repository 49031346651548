import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {ArrowDownTrayIcon, PlusIcon, TrashIcon} from '@heroicons/react/24/outline'

import {lightFormat} from 'date-fns'
import _, {isNil} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {getFieldOptions} from '../../../../utils'
import {RON_CURRENCY_VALUE} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'

import {AddInvoicePaymentDistributionModal} from '../../../../components/AddInvoicePaymentDistributionModal'
import {AddInvoicePaymentModal} from '../../../../components/AddInvoicePaymentModal'
import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DeleteInvoicePayment} from '../../../../components/DeleteInvoicePayment'
import {DeleteInvoicePaymentDistribution} from '../../../../components/DeleteInvoicePaymentDistribution'
import {Loader} from '../../../../components/Loader'

import {XMarkIcon} from '@heroicons/react/24/solid'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import {GenericWarningModal} from '../../../../components/GenericWarningModal'
import {InvoiceExternalSyncResolveModal} from '../../../../components/InvoiceExternalSyncResolveModal'
import {externalSyncStates} from '../../../Invoices/constants'
import InvoiceExternalSyncIcon from '../InvoiceExternalSyncIcon/InvoiceExternalSyncIcon'
import './InvoicePayments.scss'

export const InvoicePayments = ({
    openModal,
    anyModalClosed,
    invoice,
    match: {
        params: {invoiceID}
    },
    invoicePaymentsOptions,
    getInvoicePaymentsOptions,
    invoicePaymentDistributionsOptions,
    getInvoicePaymentDistributionsOptions,
    invoicePayments,
    isLoadingPayments,
    totalPaymentsPages,
    nextPaymentsPage,
    previousPaymentsPage,
    currentPaymentsPage,
    invoicePaymentDistributions,
    isLoadingDistributions,
    totalDistributionsPages,
    nextDistributionsPage,
    previousDistributionsPage,
    currentDistributionsPage,
    listInvoicePayments,
    listInvoicePaymentDistributions,
    invoiceReports,
    retrieveInvoiceReports,
    isLoadingInvoiceReports,
    openInvoiceExternalSyncResolveModal,
    usersPreferences
}) => {
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [selectedDistribution, setSelectedDistribution] = useState(null)

    useEffect(() => {
        listInvoicePayments(invoiceID)
    }, [invoiceID, listInvoicePayments])

    useEffect(() => {
        listInvoicePaymentDistributions(invoiceID)
    }, [invoiceID, listInvoicePaymentDistributions])

    useEffect(() => {
        if (anyModalClosed) retrieveInvoiceReports(invoiceID)
    }, [invoiceID, anyModalClosed, retrieveInvoiceReports])

    const handlePaymentsChangePage = useCallback(
        (page) => {
            if (!isLoadingPayments) return listInvoicePayments(invoiceID, page)
        },
        [invoiceID, isLoadingPayments, listInvoicePayments]
    )

    const handleDistributionsChangePage = useCallback(
        (page) => {
            if (!isLoadingDistributions) return listInvoicePaymentDistributions(invoiceID, page)
        },
        [invoiceID, isLoadingDistributions, listInvoicePaymentDistributions]
    )

    useEffect(() => {
        getInvoicePaymentsOptions()
    }, [getInvoicePaymentsOptions])
    useEffect(() => {
        getInvoicePaymentDistributionsOptions()
    }, [getInvoicePaymentDistributionsOptions])

    const invoicePaymentTypes = useMemo(() => {
        return getFieldOptions(invoicePaymentsOptions, 'type')
    }, [invoicePaymentsOptions])

    const invoicePaymentDistributionExternalType = useMemo(() => {
        return getFieldOptions(invoicePaymentDistributionsOptions, 'external_type')?.reduce((result, item) => {
            result[item.value] = item
            return result
        }, {})
    }, [invoicePaymentDistributionsOptions])

    const getPaymentType = useCallback(
        ({value: type}) => {
            if (!_.isNil(type)) {
                const typeObj = _.find(invoicePaymentTypes, ['value', type])

                if (!_.isNil(typeObj)) {
                    return typeObj.label
                }
            }

            return '-'
        },
        [invoicePaymentTypes]
    )

    const invoiceTotals = useMemo(() => {
        let total

        if (invoice.currency === RON_CURRENCY_VALUE) {
            total = `${invoice.total_with_VAT_RON} ${invoice.currency}`
        } else {
            total = `${invoice.total_with_VAT} ${invoice.currency}`
        }

        return {
            total,
            paid: `${invoiceReports.amount_paid} ${invoice.currency}`,
            unpaid: `${invoiceReports.amount_unpaid} ${invoice.currency}`,
            distributed: `${invoiceReports.amount_distributed} ${invoice.currency}`
        }
    }, [invoice, invoiceReports])

    const handleOpenDeletePaymentModal = useCallback(
        (payment) => {
            setSelectedPayment(payment)
            openModal(modalTypes.DELETE_INVOICE_PAYMENT)
        },
        [openModal]
    )

    const handleOpenCancelPaymentModal = useCallback(
        (payment) => {
            setSelectedPayment(payment)
            openModal(modalTypes.GENERIC_WARNING_MODAL)
        },
        [openModal]
    )

    const getCanceledClassName = useCallback((invoicePayment) => {
        if (invoicePayment.state === 'canceled') {
            return 'payment-canceled'
        }

        return ''
    }, [])

    const smartbillColumn = useMemo(() => {
        if (usersPreferences.has_smartbill_integration) {
            return {
                Header: 'SmartBill',
                accessor: 'sync_state_smartbill',
                Cell: ({value, row: {original: invoicePayment}}) => (
                    <span
                        onClick={() => {
                            setSelectedPayment(invoicePayment)
                            if (value === externalSyncStates.MANUAL_NEEDS_UPDATE) {
                                openInvoiceExternalSyncResolveModal()
                            }
                        }}>
                        <InvoiceExternalSyncIcon state={value} />
                    </span>
                ),
                style: {
                    width: '55px'
                }
            }
        }

        return null
    }, [openInvoiceExternalSyncResolveModal, usersPreferences.has_smartbill_integration])

    const paymentsColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'date',
                Cell: ({value: date, row: {original: invoicePayment}}) => {
                    return (
                        <span className={getCanceledClassName(invoicePayment)}>
                            {!_.isNil(date) ? lightFormat(new Date(date), 'dd/MM/yyyy') : '-'}
                        </span>
                    )
                }
            },
            {
                Header: 'Metoda',
                accessor: 'type',
                Cell: ({value: type, row: {original: invoicePayment}}) => {
                    return <span className={getCanceledClassName(invoicePayment)}>{getPaymentType({value: type})}</span>
                }
            },
            {
                Header: 'Sumă încasată',
                accessor: 'amount',
                Cell: ({row: {original: invoicePayment}}) => {
                    if (!_.isNil(invoicePayment.amount)) {
                        return (
                            <span className={getCanceledClassName(invoicePayment)}>
                                {`${invoicePayment.amount} ${invoicePayment.currency}`}
                            </span>
                        )
                    }

                    return '-'
                }
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value: project, row: {original: invoicePayment}}) => {
                    return (
                        <span className={getCanceledClassName(invoicePayment)}>
                            {!_.isNil(project) ? project.name : '-'}
                        </span>
                    )
                }
            },
            {
                Header: 'Client',
                accessor: 'client',
                Cell: ({value: client, row: {original: invoicePayment}}) => (
                    <span className={getCanceledClassName(invoicePayment)}>{!_.isNil(client) ? client.name : '-'}</span>
                )
            },
            {
                Header: 'Serie chitanță',
                accessor: 'series_id',
                Cell: ({row: {original: invoicePayment}}) => {
                    if (invoicePayment.type === 'cash') {
                        return (
                            <span className={getCanceledClassName(invoicePayment)}>
                                {`${invoicePayment.series_name} ${invoicePayment.series_number}`}
                            </span>
                        )
                    }

                    return '-'
                }
            },
            smartbillColumn,
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: invoicePayment}}) => (
                    <div className="datatable-row-buttons-container">
                        {!_.isNil(invoicePayment.file_pdf) && (
                            <a
                                href={invoicePayment.file_pdf.url_public}
                                target="_blank"
                                rel="noopener noreferrer">
                                <Button
                                    title="Descarcă chitanță"
                                    icon={() => <ArrowDownTrayIcon />}
                                    size="small"
                                />
                            </a>
                        )}
                        {!invoicePayment.deletion_prevented && (
                            <Button
                                icon={() => <TrashIcon />}
                                onClick={() => handleOpenDeletePaymentModal(invoicePayment)}
                                size="small"
                                color="red"
                            />
                        )}
                        {invoicePayment.state === 'issued' && invoicePayment.type === 'cash' && (
                            <Button
                                title="Anulează"
                                icon={() => <XMarkIcon />}
                                onClick={() => {
                                    handleOpenCancelPaymentModal(invoicePayment)
                                }}
                                variant="contained"
                                size="small"
                                color="red"
                            />
                        )}
                    </div>
                )
            }
        ].filter((col) => !isNil(col))
    }, [
        smartbillColumn,
        getCanceledClassName,
        getPaymentType,
        handleOpenDeletePaymentModal,
        handleOpenCancelPaymentModal
    ])

    const getDistributionTarget = useCallback(
        ({row: {original: distribution}}) => {
            switch (distribution.type) {
                case 'lawyer': {
                    if (!_.isNil(distribution.entity_profile)) {
                        return distribution.entity_profile.full_name
                    }

                    return 'Avocat'
                }
                case 'external': {
                    if (!_.isNil(distribution.external_type)) {
                        return `Extern - ${invoicePaymentDistributionExternalType[distribution.external_type].label}`
                    }

                    return 'Extern'
                }
                default:
                    return 'Societate'
            }
        },
        [invoicePaymentDistributionExternalType]
    )

    const getDistributionReason = useCallback(
        ({row: {original: distribution}}) => {
            let amout = distribution.amount
            let reason = 'motiv nespecificat'

            if (distribution.unit === 'percent') {
                amout = `${amout}%`
            } else {
                amout = `${amout} ${invoice.currency}`
            }

            if (distribution.reason === 'client_bonus') {
                reason = 'pentru client adus'
            } else if (distribution.reason === 'taxes') {
                reason = 'pentru TVA'
            }

            return `${amout} (${reason})`
        },
        [invoice.currency]
    )

    const handleOpenDeleteDistributionModal = useCallback(
        (distribution) => {
            setSelectedDistribution(distribution)
            openModal(modalTypes.DELETE_INVOICE_PAYMENT_DISTRIBUTION)
        },
        [openModal]
    )

    const distributionsColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'created',
                Cell: ({value: date}) => (!_.isNil(date) ? lightFormat(new Date(date), 'dd/MM/yyyy') : '-')
            },
            {
                Header: 'Către cine',
                accessor: '_target',
                Cell: getDistributionTarget
            },
            {
                Header: 'Sumă distribuită',
                accessor: 'amount_calculated',
                Cell: ({value: amount}) => (!_.isNil(amount) ? `${amount} ${invoice.currency}` : '-')
            },
            {
                Header: 'Regula aplicată',
                accessor: '_RULE',
                Cell: getDistributionReason
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: paymentDistribution}}) => (
                    <Button
                        title="Șterge"
                        icon={() => <TrashIcon />}
                        onClick={() => handleOpenDeleteDistributionModal(paymentDistribution)}
                        color="red"
                        size="small"
                    />
                )
            }
        ]
    }, [getDistributionReason, getDistributionTarget, handleOpenDeleteDistributionModal, invoice.currency])

    const isInvoiceCanceled = useMemo(() => {
        return invoice.state === 'canceled'
    }, [invoice.state])

    return (
        <>
            <div className="w-full">
                <Datatable
                    title="Încasări factură"
                    data={invoicePayments}
                    columns={paymentsColumns}
                    headerButton={() => (
                        <Button
                            title="Adaugă încasare"
                            icon={() => <PlusIcon />}
                            onClick={() => openModal(modalTypes.ADD_INVOICE_PAYMENT)}
                            disabled={isInvoiceCanceled}
                            color="secondary"
                        />
                    )}
                    customHeader={() =>
                        !_.isNil(isLoadingInvoiceReports) && !isLoadingInvoiceReports ? (
                            <div className="invoice-payments-custom-header-container">
                                <p>
                                    Sumă totală factură: <span>{invoiceTotals.total}</span>
                                </p>
                                <p>
                                    Sumă încasată: <span>{invoiceTotals.paid}</span>
                                </p>
                                <p>
                                    Sumă de încasat: <span>{invoiceTotals.unpaid}</span>
                                </p>
                            </div>
                        ) : (
                            <Loader />
                        )
                    }
                    loading={isLoadingPayments}
                    totalPages={totalPaymentsPages}
                    nextPage={nextPaymentsPage}
                    previousPage={previousPaymentsPage}
                    currentPage={currentPaymentsPage}
                    onChangePage={handlePaymentsChangePage}
                    emptyText="Nu există încasări pentru această factură"
                />
                <AddInvoicePaymentModal invoiceID={invoiceID} />
                {selectedPayment && <DeleteInvoicePayment payment={selectedPayment} />}
                <InvoiceExternalSyncResolveModal
                    selectedInvoicePayment={selectedPayment}
                    reloadData={() => {
                        listInvoicePayments(invoiceID, 1)
                    }}
                />
                {selectedPayment && (
                    <GenericWarningModal
                        text={`Ești sigur ca dorești sa anulezi această plată 
                        (${selectedPayment.series_name} ${selectedPayment.series_number})?`}
                        title={'Anulare plată'}
                        action={async () => {
                            const {status} = await performRequest(
                                RESOURCES.invoicePayments.update({state: 'canceled'}, selectedPayment.id)
                            )
                            if (status === 200) {
                                toast.success('Plata a fost anulată.')
                            } else {
                                toast.error('A apărut o eroare la anularea plății.')
                            }
                            listInvoicePayments(invoiceID, 1)
                        }}
                    />
                )}
            </div>
            <div className="w-full">
                <Datatable
                    title="Distribuiri încasări"
                    data={invoicePaymentDistributions}
                    columns={distributionsColumns}
                    headerButton={() => (
                        <Button
                            title="Adaugă distribuire"
                            icon={() => <PlusIcon />}
                            onClick={() => openModal(modalTypes.ADD_INVOICE_PAYMENT_DISTRIBUTION)}
                            disabled={isInvoiceCanceled}
                            color="secondary"
                        />
                    )}
                    customHeader={() => (
                        <div className="invoice-payments-custom-header-container">
                            <p>
                                Sumă încasată: <span>{invoiceTotals.paid}</span>
                            </p>
                            <p>
                                Sumă distribuită din încasări: <span>{invoiceTotals.distributed}</span>
                            </p>
                        </div>
                    )}
                    loading={isLoadingDistributions}
                    totalPages={totalDistributionsPages}
                    nextPage={nextDistributionsPage}
                    previousPage={previousDistributionsPage}
                    currentPage={currentDistributionsPage}
                    onChangePage={handleDistributionsChangePage}
                    emptyText="Nu există distribuiri pentru această factură"
                />
                <AddInvoicePaymentDistributionModal invoiceID={invoiceID} />
                <DeleteInvoicePaymentDistribution distribution={selectedDistribution} />
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    invoice: state.invoices.currentInvoice,
    invoicePaymentsOptions: state.invoicePayments.options,
    invoicePaymentDistributionsOptions: state.invoicePaymentDistributions.options,
    invoicePayments: _.values(state.invoicePayments.data),
    isLoadingPayments: state.invoicePayments.isLoading,
    totalPaymentsPages: state.invoicePayments.totalPages,
    nextPaymentsPage: state.invoicePayments.next,
    previousPaymentsPage: state.invoicePayments.previous,
    currentPaymentsPage: state.invoicePayments.current,
    invoicePaymentDistributions: _.values(state.invoicePaymentDistributions.data),
    isLoadingDistributions: state.invoicePaymentDistributions.isLoading,
    totalDistributionsPages: state.invoicePaymentDistributions.totalPages,
    nextDistributionsPage: state.invoicePaymentDistributions.next,
    previousDistributionsPage: state.invoicePaymentDistributions.previous,
    currentDistributionsPage: state.invoicePaymentDistributions.current,
    invoiceReports: state.invoiceReports.data,
    isLoadingInvoiceReports: state.invoiceReports.isLoading,
    anyModalClosed: state.modals.type === null,
    usersPreferences: state.usersPreferences.data
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    getInvoicePaymentsOptions: () => dispatch(RESOURCES.invoicePayments.getOptions()),
    getInvoicePaymentDistributionsOptions: () => dispatch(RESOURCES.invoicePaymentDistributions.getOptions()),
    retrieveInvoiceReports: (invoiceID) => dispatch(RESOURCES.invoiceReports.retrieve(invoiceID)),
    openInvoiceExternalSyncResolveModal: () => dispatch(openModal(modalTypes.INVOICE_EXTERNAL_MANUAL_RESOLVE_MODAL)),
    listInvoicePayments: (invoiceID, page = 1) =>
        dispatch(
            RESOURCES.invoicePayments.list(
                {
                    invoice_id: invoiceID,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        ),
    listInvoicePaymentDistributions: (invoiceID, page = 1) =>
        dispatch(
            RESOURCES.invoicePaymentDistributions.list(
                {
                    invoice_id: invoiceID,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayments)
