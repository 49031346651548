import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {createClientNote, listClientNotes} from 'avoapp-react-common/dist/redux/clientNotes'
import {modalTypes, openModal} from '../../../../redux/modals'

import {getTimePassedFromDate} from '../../../../utils'

import {Button} from '../../../../components/Button'
import {Loader} from '../../../../components/Loader'

import {DeleteClientNoteModal} from './modals'

import './InfoClientNotes.scss'

export class InfoClientNotes extends Component {
    constructor() {
        super()

        this.state = {
            selectedClientNote: null
        }
    }

    componentDidMount = () => {
        const {listClientNotes, selectedEntityID, match} = this.props
        const {clientID} = match.params

        listClientNotes({entity_id: selectedEntityID}, clientID)
    }

    handleOpenDeleteClientNoteModal = (note) => {
        const {openModal} = this.props

        this.setState({selectedClientNote: note})
        openModal(modalTypes.DELETE_CLIENT_NOTE)
    }

    render() {
        const {selectedClientNote} = this.state
        const {clientNotes, isLoading, match, createClientNote} = this.props
        const {clientID} = match.params

        return (
            <div className="page-client-show">
                <DeleteClientNoteModal
                    clientID={clientID}
                    clientNote={selectedClientNote}
                    onClose={() => this.setState({selectedClientNote: null})}
                />
                <div className="info-notes-container">
                    <div className="info-notes-inner-container">
                        <div className="title-container">
                            <h2 className="title">Observații</h2>
                            {isLoading && <Loader />}
                        </div>
                        <div className="notes-container">
                            {!isEmpty(clientNotes) ? (
                                <ul className="notes-list">
                                    {clientNotes.map((note) => (
                                        <li
                                            key={note.id}
                                            className="note-container">
                                            <p className="note-user-name">
                                                {note.entity_profile.first_name} {note.entity_profile.last_name}
                                            </p>
                                            <p className="note-body">{note.text}</p>
                                            <div className="note-footer-container">
                                                <p className="note-date">{getTimePassedFromDate(note.created)}</p>
                                                <span className="font-medium text-gray-500">&middot;</span>{' '}
                                                <Button
                                                    title="Șterge"
                                                    className="delete-note-button"
                                                    onClick={() => this.handleOpenDeleteClientNoteModal(note)}
                                                    variant="text"
                                                    color="red"
                                                />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : isEmpty(clientNotes) && !isLoading ? (
                                <p className="no-notes-text">Nu există observații</p>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                    <div className="add-note-form-container">
                        <Formik
                            initialValues={{text: ''}}
                            onSubmit={(values) => createClientNote(values, clientID)}>
                            {({setFieldValue, values, handleSubmit}) => (
                                <Form className="flex-1">
                                    <textarea
                                        value={values.text}
                                        className="note-textarea"
                                        placeholder="Adaugă o observație"
                                        onChange={(e) => setFieldValue('text', e.target.value)}
                                        rows={3}
                                    />
                                    <div className="button-container">
                                        <Button
                                            type="submit"
                                            title="Adaugă observație"
                                            disabled={isEmpty(values.text)}
                                            loading={!isEmpty(values.text) && isLoading}
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientNotes: orderBy(state.clientNotes.data, 'id', 'desc'),
    isLoading: state.clientNotes.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    listClientNotes: (params, clientID) => dispatch(listClientNotes(params, clientID)),
    createClientNote: (values, clientID) => dispatch(createClientNote(values, clientID))
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoClientNotes)
