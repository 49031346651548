import React from 'react'
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid'

import './CalendarPagination.scss'

export default function CalendarPagination({onPrevious, onToday, onNext}) {
    return (
        <div className="avo-calendar-pagination-container">
            <button
                className="avo-calendar-pagination-button previous"
                onClick={onPrevious}
                type="button">
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon
                    className="avo-calendar-pagination-button-icon"
                    aria-hidden="true"
                />
            </button>
            <button
                className="avo-calendar-pagination-today-button"
                onClick={onToday}
                type="button">
                Astăzi
            </button>
            <span className="avo-calendar-pagination-buttons-divider" />
            <button
                className="avo-calendar-pagination-button next"
                onClick={onNext}
                type="button">
                <span className="sr-only">Next month</span>
                <ChevronRightIcon
                    className="avo-calendar-pagination-button-icon"
                    aria-hidden="true"
                />
            </button>
        </div>
    )
}
