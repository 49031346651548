import React from 'react'

import {keepPreviousData, useQuery} from '@tanstack/react-query'

import {isNil} from 'lodash'

// @ts-ignore
import {Resource} from 'avoapp-react-common/dist/redux'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'

// @ts-ignore
import {Datatable} from '../Datatable'

type Props = {
    resource: Resource
    columns: Array<object>
    title: string
    emptyText: string
    customHeader: () => React.ReactNode
    filters: object
}

const fetchData = async (resource: Resource, filters: object) => {
    const {data} = await performRequest(resource.list(filters))
    return data
}

type APIResponse = {
    results: Array<object>
    pages: {
        next: number
        previous: number
    }
    number_of_pages: number
}

const ResourceDatatable = ({columns, resource, title, emptyText = 'Fără rezultate', customHeader, filters}: Props) => {
    const [page, setPage] = React.useState(1)

    const {data, isFetching, isPlaceholderData} = useQuery({
        queryKey: [resource.name, page, filters],
        queryFn: async () => await fetchData(resource, {...filters, page}),
        placeholderData: keepPreviousData,
        staleTime: 1000 * 10
    })

    if (isNil(data)) {
        return null
    }

    const {results, pages, number_of_pages}: APIResponse = data

    return (
        <Datatable
            title={title}
            data={results}
            columns={columns}
            customHeader={customHeader}
            loading={isFetching || isPlaceholderData}
            totalPages={number_of_pages}
            nextPage={pages.next}
            previousPage={pages.previous}
            currentPage={page}
            onChangePage={setPage}
            emptyText={emptyText}
        />
    )
}

export default ResourceDatatable
