import React, {useCallback, useMemo, useState} from 'react'

import {useFormik} from 'formik'
import _ from 'lodash'
import {toast} from 'react-toastify'

import {connect} from 'react-redux'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {customPhase, phasesArray} from 'avoapp-react-common/dist/constants'

// @ts-ignore
import {litigationsCheckData} from '../../assets/data/choices'
// @ts-ignore
import {objectKeysToSnakeCase} from '../../utils'
// @ts-ignore

// @ts-ignore
import {Select} from '../Select'
// @ts-ignore
import {Input} from '../Input'
// @ts-ignore
import {Button} from '../Button'
// @ts-ignore
import {DatePicker} from '../DatePicker'
// @ts-ignore
import {RequiredFieldsText} from '../RequiredFieldsText'

import './PhaseForm.scss'

interface PhaseFormProps {
    currentSubscription: any
    goToNextStep: () => void
    setCurrentPhase: (phase: any) => void
}

export const PhaseForm = ({currentSubscription, goToNextStep, setCurrentPhase}: PhaseFormProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const subscriptionSelectOption = useMemo(() => {
        return {
            label: currentSubscription.litigation.number,
            value: currentSubscription.litigation.id
        }
    }, [currentSubscription.litigation.id, currentSubscription.litigation.number])

    const institutions = useMemo(() => {
        const keys = Object.keys(litigationsCheckData.institution)

        return keys
            .filter((key) => !_.isNil(litigationsCheckData.institution[key]))
            .map((key) => ({
                value: litigationsCheckData.institution[key],
                label: key
            }))
    }, [])

    const {values, touched, setFieldValue, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            phase: phasesArray[0],
            phaseText: '',
            object: '',
            court: institutions[0],
            date: new Date(),
            category: '',
            department: ''
        },
        onSubmit: () => {
            const finalValue = {
                ...objectKeysToSnakeCase(values),
                litigation_id: subscriptionSelectOption.value,
                number: subscriptionSelectOption.label,
                phase: getPhase(),
                court: values.court.value
            }

            onSubmit(finalValue)
        }
    })

    const onSubmit = useCallback(
        async (values) => {
            setIsLoading(true)
            performRequest(RESOURCES.phases.create(values)).then((res: any) => {
                setIsLoading(false)

                if (!res.errors) {
                    toast.success('Salvat')
                    setCurrentPhase(res.data)
                    goToNextStep()
                } else {
                    _.forEach(_.values(res.errors), (error) => {
                        toast.error(error.message)
                    })
                }
            })
        },
        [goToNextStep, setCurrentPhase]
    )

    const hasCustomPhase = useMemo(() => {
        return values.phase.value === customPhase.value
    }, [values.phase.value])

    const getPhase = useCallback(() => {
        if (hasCustomPhase && values.phaseText.length > 0) {
            return values.phaseText
        }

        return values.phase.value
    }, [hasCustomPhase, values.phase.value, values.phaseText])

    return (
        <form className="add-md-manual-subscription-phase-form">
            <Select
                label="Dosar*"
                value={subscriptionSelectOption}
                options={_.values(subscriptionSelectOption)}
                fullWidth
                disabled
            />
            <Select
                label="Fază*"
                value={values.phase}
                options={phasesArray}
                onChange={(value: any) => setFieldValue('phase', value)}
                fullWidth
            />
            {hasCustomPhase && (
                <Input
                    label="Nume fază"
                    value={values.phaseText}
                    onChange={handleChange('phaseText')}
                    onBlur={handleBlur('phaseText')}
                    name="phaseText"
                    touched={touched.phaseText}
                    fullWidth
                />
            )}
            <Input
                label="Obiect*"
                value={values.object}
                onChange={handleChange('object')}
                onBlur={handleBlur('object')}
                name="object"
                touched={touched.object}
                fullWidth
            />
            <Select
                label="Instanță*"
                options={institutions}
                value={values.court}
                onChange={(value: any) => setFieldValue('court', value)}
                fullWidth
            />
            <DatePicker
                label="Data start"
                value={values.date}
                onChange={(date: any) =>
                    _.isNil(date) ? setFieldValue('date', date) : setFieldValue('date', new Date(date))
                }
                onBlur={handleBlur('date')}
                name="date"
                touched={touched.date}
                fullWidth
            />
            <Input
                label="Categorie"
                value={values.category}
                onChange={handleChange('category')}
                onBlur={handleBlur('category')}
                name="category"
                touched={touched.category}
                fullWidth
            />
            <Input
                label="Departament"
                value={values.department}
                onChange={handleChange('department')}
                onBlur={handleBlur('department')}
                name="department"
                touched={touched.department}
                fullWidth
            />
            <RequiredFieldsText />

            <Button
                title="Salvează informații și continuă spre adăugare părți"
                onClick={handleSubmit}
                loading={isLoading}
                fullWidth
            />
        </form>
    )
}

const mapStateToProps = (state: any) => ({
    currentSubscription: state.subscriptions.currentSubscription
})

export default connect(mapStateToProps)(PhaseForm)
