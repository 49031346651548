import {ArrowUpTrayIcon} from '@heroicons/react/24/outline'
import React, {useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'

import {head} from 'lodash'

import {Button} from '../../../../../../components/Button'

export default function DocumentButtonDropzone({onChange, isLoading}) {
    const [file, setFile] = useState([])

    useEffect(() => {
        onChange(file)
    }, [file, onChange])

    const handleDrop = (acceptedFiles) => setFile(head(acceptedFiles))

    const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [])

    const {getRootProps, getInputProps, open} = useDropzone({onDrop: onDrop, noClick: true, noKeyboard: true})

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
                title="Încarcă document nou"
                loading={isLoading}
                onClick={open}
                icon={() => <ArrowUpTrayIcon />}
                color="secondary"
            />
        </div>
    )
}
