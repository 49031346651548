import React from 'react'

import {isString, snakeCase, camelCase, isEmpty, head} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from '../ErrorComponents'

import './Checkbox.scss'

export default function Checkbox({
    label,
    checked,
    onChange,
    onBlur,
    size,
    labelLeft,
    fullWidth,
    name,
    errors,
    frontendErrors,
    touched,
    className
}) {
    const snakeCaseName = snakeCase(name)
    const camelCaseName = camelCase(name)

    const hasErrors =
        (errors && !isEmpty(errors[snakeCaseName])) ||
        (frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

    const chooseErrorMessageToShow = () =>
        errors && !isEmpty(errors[snakeCaseName])
            ? getErrorMessage(head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]

    return (
        <div
            className={
                'avo-checkbox-container ' +
                `${size || 'medium'} ` +
                `${fullWidth ? 'full-width' : ''} ` +
                `${labelLeft ? 'label-left' : ''} ` +
                `${className || ''}`
            }>
            <div className="avo-checkbox-center">
                {label && labelLeft && (
                    <label
                        htmlFor={name}
                        className="avo-checkbox-label">
                        {isString(label) ? label : label()}
                    </label>
                )}
                <input
                    id={name}
                    name={name}
                    checked={checked}
                    type="checkbox"
                    className="checkbox"
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {label && !labelLeft && (
                    <label
                        htmlFor={name}
                        className="avo-checkbox-label">
                        {isString(label) ? label : label()}
                    </label>
                )}
            </div>
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}
        </div>
    )
}
