import React, {useCallback, useMemo} from 'react'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {setCurrent as setCurrentPartySubscription} from 'avoapp-react-common/dist/redux/partySubscriptions'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
// @ts-ignore
import {modalTypes, openModal} from '../../../redux/modals'

import {Cell} from 'types/local'
import {useQueryResourceList} from '../../../queries/rest'
// @ts-ignore
import {useDebouncedState} from '../../../utils/hooks'
// @ts-ignore
import {sourceTypes} from '../../../utils/types'

// @ts-ignore
import {Datatable} from '../../../components/Datatable'
// @ts-ignore
import {DatatableRowButtons} from '../../../components/DatatableRowButtons'
// @ts-ignore
import {DeletePartySubscriptionModal} from '../../../components/DeletePartySubscriptionModal'
import {TableLink} from '../../../components/TableLink'
import {AddMDSubscriptionPartyModal} from '../../../components/AddMDSubscriptionPartyModal'

interface MonitorDosareDatatableProps {
    openModal: (modalType: string) => void
    openedModal: string
    selectedEntityID: string
    setCurrentPartySubscription: (partySubscription: any) => void
}

export const MonitorDosareDatatable = ({
    openModal,
    openedModal,
    selectedEntityID,
    setCurrentPartySubscription
}: MonitorDosareDatatableProps) => {
    const [partySubscriptionsSearchFilter, setPartySubscriptionsSearchFilter] = useDebouncedState('', 300)
    const [partySubscriptionsPage, setPartySubscriptionsPage] = React.useState(1)

    const partySubscriptionsFilters = useMemo(
        () => ({
            search: partySubscriptionsSearchFilter,
            entity_id: selectedEntityID,
            page_size: 15,
            page: partySubscriptionsPage
        }),
        [partySubscriptionsPage, partySubscriptionsSearchFilter, selectedEntityID]
    )

    const {data: partySubRes, isFetching: isFetchingPartySubscriptions} = useQueryResourceList(
        RESOURCES.partySubscriptions,
        partySubscriptionsFilters,
        undefined,
        //we want to refetch the data when the delete modal closes
        _.isNil(openedModal).toString()
    )

    const partySubscriptionsResult = useMemo(() => {
        // To make typescript happy
        return partySubRes as any
    }, [partySubRes])

    const handleChangePartySubscriptionsSearchField = (value: string) => setPartySubscriptionsSearchFilter(value)

    const handleChangePartySubscriptionsPage = useCallback((newPage: number) => {
        setPartySubscriptionsPage(newPage)
    }, [])

    const partySubscriptionsColumns = useMemo(() => {
        return [
            {
                Header: 'Cuvânt cheie',
                accessor: 'party_name',
                Cell: ({value}: Cell) => `"${value}"`
            },
            {
                Header: 'Sursa',
                accessor: 'source',
                Cell: ({value: source}: Cell) => sourceTypes[_.toUpper(source)] || '-'
            },
            {
                Header: 'Instituția',
                accessor: 'institution'
            },
            {
                Header: 'Client',
                accessor: 'client_name',
                Cell: ({value, row: {original}}: Cell) => (
                    <TableLink to={`/clients/${original.client_id}`}>{value}</TableLink>
                )
            },
            {
                Header: 'Ultima sincronizare',
                accessor: 'sync_moment',
                Cell: ({value}: Cell) => (!_.isNil(value) ? lightFormat(new Date(value), 'dd/MM/yyyy HH:mm') : '-')
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: partySubscription}}: Cell) => (
                    <DatatableRowButtons
                        onDeleteButtonClick={() => {
                            setCurrentPartySubscription(partySubscription)
                            openModal(modalTypes.DELETE_PARTY_SUBSCRIPTION)
                        }}
                    />
                )
            }
        ]
    }, [openModal, setCurrentPartySubscription])

    return (
        <div className="page-external-data-show">
            <Datatable
                title="Listă părți monitorizate"
                data={partySubscriptionsResult?.data.results || []}
                columns={partySubscriptionsColumns}
                loading={isFetchingPartySubscriptions}
                previousPage={partySubscriptionsResult?.data?.pages?.previous}
                currentPage={partySubscriptionsResult?.data?.page}
                nextPage={partySubscriptionsResult?.data?.pages?.next}
                totalPages={partySubscriptionsResult?.data?.number_of_pages}
                onChangePage={(page: number) => handleChangePartySubscriptionsPage(page)}
                searchable
                searchPlaceholder="Caută monitorizări juridice"
                onSearch={(event: any) => handleChangePartySubscriptionsSearchField(event.target.value)}
            />
            <AddMDSubscriptionPartyModal />
            <DeletePartySubscriptionModal />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    openedModal: state.modals.type,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    openModal: (modalType: string) => dispatch(openModal(modalType)),
    setCurrentPartySubscription: (partySubscription: any) => dispatch(setCurrentPartySubscription(partySubscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(MonitorDosareDatatable)
