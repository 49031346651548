import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {keepPreviousData, useQuery} from '@tanstack/react-query'
import {useFormik} from 'formik'

import _ from 'lodash'
import queryString from 'query-string'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {empowermentsSchema} from '../../assets/validations'
import {objectKeysToSnakeCase} from '../../utils'
import {CANCELED_STATE, debounceWait} from '../../utils/constants'
import {datatablePageSize} from '../../utils/datatables'
import {useDebouncedState, useDebouncedValue} from '../../utils/hooks'
import {documentSeriesTypes} from '../../utils/types'

import {ErrorsList} from '../../components/ErrorComponents'
import {FormSection} from '../../components/FormSection'
import {Input} from '../../components/Input'
import {RequiredFieldsText} from '../../components/RequiredFieldsText'
import {Select} from '../../components/Select'

import {documentTemplateTypes} from './constants'

import './EmpowermentAdd.scss'

const fetchData = async (resource, filters) => {
    const {data} = await performRequest(resource.list(filters))
    return data.results || []
}

export const EmpowermentAdd = ({
    location,
    selectedEntityID,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    documentSeries,
    isLoadingDocumentSeries,
    listAllDocumentSeries,
    createEmpowerment
}) => {
    const initialProjectID = useMemo(() => {
        const params = queryString.parse(location.search)
        return _.get(params, 'initialProjectId', null)
    }, [location.search])

    const [initialProject, setInitialProject] = useState(undefined)
    useEffect(() => {
        if (initialProjectID) {
            performRequest(RESOURCES_V2.projects.retrieve(initialProjectID)).then((res) => {
                setInitialProject(res.data)
            })
        }
    }, [initialProjectID])

    const initialContractID = useMemo(() => {
        const params = queryString.parse(location.search)
        return _.get(params, 'initialContractId', null)
    }, [location.search])

    const [initialContract, setInitialContract] = useState(undefined)
    useEffect(() => {
        if (initialContractID) {
            performRequest(RESOURCES.contracts.retrieve(initialContractID)).then((res) => {
                setInitialContract(res.data)
            })
        }
    }, [initialContractID])

    const {handleChange, handleBlur, setFieldValue, handleSubmit, values, touched, errors, isValid} = useFormik({
        initialValues: {
            projectId: initialProject,
            contractId: initialContract,
            templateId: null,
            name: '',
            content: '',
            in_front_of: '',
            internalSeriesId:
                _.find(
                    documentSeries,
                    (series) => series.type === documentSeriesTypes.EMPOWERMENT_INTERNAL.value && series.default
                ) || null,
            barSeriesId:
                _.find(
                    documentSeries,
                    (series) => series.type === documentSeriesTypes.EMPOWERMENT_BAR.value && series.default
                ) || null
        },
        enableReinitialize: true,
        validationSchema: empowermentsSchema.create,
        onSubmit: (values) => {
            const empowermentData = {
                ...objectKeysToSnakeCase(values),
                entity_id: selectedEntityID,
                project_id: values.projectId.id,
                contract_id: values.contractId.id,
                internal_series_id: values.internalSeriesId.id,
                bar_series_id: values.barSeriesId.id,
                template_id: values.templateId.id
            }

            createEmpowerment(empowermentData)
        }
    })

    const [projectsQuery, setProjectsQuery] = useDebouncedState('', debounceWait)
    const {
        data: projects,
        isFetching: isFetchingProjects,
        isPlaceholderData: isPlaceholderDataProjects
    } = useQuery({
        queryKey: [RESOURCES_V2.projects.name, projectsQuery],
        queryFn: async () => {
            const filters = {
                contract_id: initialContractID,
                search: projectsQuery,
                active: true,
                entity_id: selectedEntityID,
                page_size: datatablePageSize
            }

            const projectsResult = await fetchData(RESOURCES_V2.projects, filters)

            // If we have the initial contract, we want to select the project if only option is available
            if (
                !_.isNil(initialContractID) &&
                !_.isEmpty(projectsResult) &&
                projectsResult.length === 1 &&
                _.isNil(values.projectId)
            ) {
                setFieldValue('projectId', _.head(projectsResult))
            }

            return projectsResult
        },
        placeholderData: keepPreviousData
    })

    const handleChangeProjectsSearchField = useCallback((value) => setProjectsQuery(value), [setProjectsQuery])

    const [contractsQuery, setContractsQuery] = useDebouncedState('', debounceWait)
    const {
        data: contracts,
        isFetching: isFetchingContracts,
        isPlaceholderData: isPlaceholderDataContracts
    } = useQuery({
        queryKey: [RESOURCES.contracts.name, contractsQuery, values.projectId?.id],
        queryFn: async () => {
            const filters = {
                search: contractsQuery,
                project_id: values.projectId?.id,
                entity_id: selectedEntityID,
                page_size: datatablePageSize,
                state_not: [CANCELED_STATE]
            }

            const contractsResult = await fetchData(RESOURCES.contracts, filters)

            if (
                !_.isNil(initialContractID) &&
                !_.isEmpty(contractsResult) &&
                contractsResult.length === 1 &&
                _.isNil(values.contractId)
            ) {
                setFieldValue('contractId', _.head(contractsResult))
            }

            console.log('contractsResult: ', contractsResult)
            return contractsResult
        },
        placeholderData: keepPreviousData
    })

    const handleChangeContractSearchField = useCallback((value) => setContractsQuery(value), [setContractsQuery])

    const [documentTemplatesQuery, setDocumentTemplatesQuery] = useState('')
    const debouncedDocumentTemplatesQuery = useDebouncedValue(documentTemplatesQuery, debounceWait)
    const {
        data: documentTemplates,
        isFetching: isFetchingDocumentTemplates,
        isPlaceholderData: isPlaceholderDataDocumentTemplates
    } = useQuery({
        queryKey: [RESOURCES.documentTemplates.name, debouncedDocumentTemplatesQuery],
        queryFn: async () => {
            const filters = {
                search: debouncedDocumentTemplatesQuery,
                type: _.find(documentTemplateTypes, ['value', 'empowerment'])?.value,
                entity_id: selectedEntityID,
                page_size: datatablePageSize
            }

            return await fetchData(RESOURCES.documentTemplates, filters)
        },
        placeholderData: keepPreviousData
    })

    const handleChangeDocumentTemplateSearchField = useCallback((value) => setDocumentTemplatesQuery(value), [])

    useEffect(() => {
        listAllDocumentSeries({entity_id: selectedEntityID})
    }, [listAllDocumentSeries, selectedEntityID])

    const handleChangeContract = useCallback(
        (contract) => {
            setFieldValue('contractId', contract)

            if (!_.isNull(contract)) {
                setFieldValue('name', 'Împuternicire')
            }
        },
        [setFieldValue]
    )

    return (
        <form className="empowerment-add-form-container">
            <ErrorsList errors={nonFieldErrors} />
            <div className="space-y-6">
                <FormSection
                    autoHeight
                    renderForm={() => (
                        <>
                            <p className="form-section-title"> Setări generale </p>
                            <Select
                                label="Proiect*"
                                value={values.projectId}
                                options={projects}
                                loading={isFetchingProjects || isPlaceholderDataProjects}
                                disabled={initialProjectID}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                onChange={(e) => {
                                    setFieldValue('projectId', e)

                                    if (!initialContractID) {
                                        setFieldValue('contractId', null)
                                    }
                                }}
                                onInputChange={handleChangeProjectsSearchField}
                                onBlur={handleBlur('projectId')}
                                name="projectId"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.projectId}
                                fullWidth
                            />
                            {(!_.isNil(values.projectId) || !_.isNil(values.contractId)) && (
                                <>
                                    <Select
                                        label="Contract*"
                                        value={values.contractId}
                                        options={contracts}
                                        loading={isFetchingContracts || isPlaceholderDataContracts}
                                        disabled={initialContractID}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => {
                                            setFieldValue('contractId', e)
                                            handleChangeContract(e)
                                        }}
                                        onInputChange={handleChangeContractSearchField}
                                        onBlur={handleBlur('contractId')}
                                        name="contractId"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.contractId}
                                        fullWidth
                                    />
                                    <Select
                                        label="Șablon*"
                                        value={values.templateId}
                                        options={documentTemplates}
                                        loading={isFetchingDocumentTemplates || isPlaceholderDataDocumentTemplates}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => setFieldValue('templateId', e)}
                                        onInputChange={handleChangeDocumentTemplateSearchField}
                                        onBlur={handleBlur('templateId')}
                                        disabled={_.isEmpty(documentTemplates)}
                                        name="templateId"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.templateId}
                                        fullWidth
                                    />
                                    <Input
                                        label="Nume împuternicire*"
                                        value={values.name}
                                        onChange={handleChange('name')}
                                        onBlur={handleBlur('name')}
                                        name="name"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.name}
                                        fullWidth
                                    />
                                    <Input
                                        label="Conținut*"
                                        value={values.content}
                                        onChange={handleChange('content')}
                                        onBlur={handleBlur('content')}
                                        name="content"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.content}
                                        fullWidth
                                    />
                                    <Input
                                        label="În fața"
                                        value={values.in_front_of}
                                        onChange={handleChange('in_front_of')}
                                        onBlur={handleBlur('in_front_of')}
                                        name="in_front_of"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.in_front_of}
                                        fullWidth
                                    />
                                    <div className="split-row">
                                        <Select
                                            label="Serie interna*"
                                            value={values.internalSeriesId}
                                            options={_.filter(documentSeries, [
                                                'type',
                                                documentSeriesTypes.EMPOWERMENT_INTERNAL.value
                                            ])}
                                            loading={isLoadingDocumentSeries}
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => `${option.name} (${option.current_number})`}
                                            onChange={(e) => setFieldValue('internalSeriesId', e)}
                                            onBlur={handleBlur('internalSeriesId')}
                                            name="internalSeriesId"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.internalSeriesId}
                                            fullWidth
                                        />
                                        <Select
                                            label="Serie barou*"
                                            value={values.barSeriesId}
                                            options={_.filter(documentSeries, [
                                                'type',
                                                documentSeriesTypes.EMPOWERMENT_BAR.value
                                            ])}
                                            loading={isLoadingDocumentSeries}
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => `${option.name} (${option.current_number})`}
                                            onChange={(e) => setFieldValue('barSeriesId', e)}
                                            onBlur={handleBlur('barSeriesId')}
                                            name="barSeriesId"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.barSeriesId}
                                            fullWidth
                                        />
                                    </div>
                                    <RequiredFieldsText />
                                </>
                            )}
                        </>
                    )}
                    buttonDisabled={!isValid}
                    onSubmit={values.contractId ? handleSubmit : null}
                    submitButtonTitle="Generează împuternicire"
                    loading={isLoading}
                />
            </div>
        </form>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.empowerments.isLoading,
    fieldErrors: state.empowerments.fieldErrors,
    nonFieldErrors: state.empowerments.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    documentSeries: _.values(state.documentSeries.data),
    isLoadingDocumentSeries: state.documentSeries.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll({...params, active: true})),
    createEmpowerment: (empowermentData) => dispatch(RESOURCES.empowerments.create(empowermentData))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpowermentAdd)
