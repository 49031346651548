import React, {useCallback, useMemo, useState} from 'react'
import {toast} from 'react-toastify'

import {lightFormat} from 'date-fns'
import _, {orderBy} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {getFieldOptions, toApiDateFormat} from '../../utils'
import {debounceWait} from '../../utils/constants'
import {datatablePageSize} from '../../utils/datatables'
import {useDebouncedEffect} from '../../utils/hooks'
import {taxPercentageOptions, unitOptions} from '../../utils/types'

import {Button} from '../Button'
import {Datatable} from '../Datatable'
import {Modal} from '../Modal'

import {roundFixed} from '../../utils/math'
import './AddStornoModal.scss'

export const AddStornoModal = ({
    open,
    closeModal,
    invoiceIssueDate,
    invoiceClient,
    invoiceCurrency,
    handleAddStorno,
    invoicesOptions,
    invoices,
    isLoading,
    nextPage,
    previousPage,
    currentPage,
    totalPages,
    selectedEntityID,
    listInvoices
}) => {
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchInvoices = useCallback(
        (search = searchFilter, page = 1) => {
            if (!_.isNil(invoiceClient)) {
                const filters = {
                    client_id: invoiceClient.id,
                    currency: invoiceCurrency?.value,
                    date__lte: toApiDateFormat(new Date(invoiceIssueDate))
                }

                listInvoices(selectedEntityID, search, filters, page)
            }
        },
        [invoiceClient, invoiceCurrency?.value, invoiceIssueDate, listInvoices, searchFilter, selectedEntityID]
    )

    useDebouncedEffect(handleFetchInvoices, [searchFilter, invoiceClient], debounceWait)

    const handleChangePage = (page) => !isLoading && handleFetchInvoices(searchFilter, page)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const currencies = useMemo(() => {
        return getFieldOptions(invoicesOptions, 'currency')
    }, [invoicesOptions])

    const handleStornoInvoice = useCallback(
        (invoice) => {
            const hasMultiTVA = _.some(
                invoice.entries,
                (entry) => entry.tax_percentage !== _.head(invoice.entries).tax_percentage
            )

            if (!hasMultiTVA) {
                const newQuantity = -1
                const stornoData = {
                    name:
                        `Stornare factura seria ${invoice.series_name} nr. ${invoice.series_number} din data ` +
                        `${lightFormat(new Date(invoice.issue_date), 'dd/MM/yyyy')}`,
                    unit: unitOptions.BUC,
                    quantity: newQuantity,
                    currency: _.find(currencies, ['value', invoice.currency]),
                    tax: _.find(taxPercentageOptions, (opt) => {
                        return parseInt(opt.value) === parseInt(_.head(invoice.entries).tax_percentage)
                    }),
                    taxValue: _.reduce(
                        invoice.entries,
                        (sum, entry) => {
                            return parseFloat(sum - entry.tax_value)
                        },
                        0
                    ),
                    taxValueRON: _.reduce(
                        invoice.entries,
                        (sum, entry) => {
                            return parseFloat(sum - entry.tax_value_RON)
                        },
                        0
                    ),
                    price: parseFloat(invoice.total),
                    priceRON: parseFloat(invoice.total_RON),
                    exchangeRate: parseFloat(invoice.exchange_rate),
                    stornoID: invoice.id,
                    stornoEntryIDs: invoice.entries.map((entry) => entry.id),
                    total: parseFloat(invoice.total) * newQuantity,
                    totalRON: parseFloat(invoice.total_RON) * newQuantity
                }
                handleAddStorno(stornoData)
                closeModal()
            } else {
                const stornoData = _.map(invoice.entries, (entry) => {
                    const priceRON = !_.isNil(entry.exchange_rate)
                        ? roundFixed(entry.exchange_rate * entry.price, 2)
                        : entry.price

                    const newQuantity = entry.quantity * -1

                    return {
                        name: entry.name,
                        unit: _.find(unitOptions, ['value', entry.unit]),
                        quantity: newQuantity,
                        currency: _.find(currencies, ['value', entry.currency]),
                        tax: _.find(
                            taxPercentageOptions,
                            (taxOption) => parseInt(taxOption.value) === parseInt(entry.tax_percentage)
                        ),
                        taxValue: entry.tax_value * -1,
                        taxValueRON: entry.tax_value_RON * -1,
                        price: entry.price,
                        priceRON: priceRON,
                        exchangeRate: entry.exchange_rate || '',
                        total: entry.price * newQuantity,
                        totalRON: priceRON * newQuantity,
                        stornoID: invoice.id,
                        stornoEntryIDs: [entry.id]
                    }
                })
                handleAddStorno(stornoData)
                closeModal()
                toast.warning('Factura selectată are mai multe cote TVA.')
            }
        },
        [closeModal, currencies, handleAddStorno]
    )

    const invoicesColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'issue_date',
                Cell: ({value: issueDate}) => (issueDate ? lightFormat(new Date(issueDate), 'dd/MM/yyyy') : '-')
            },
            {
                Header: 'Serie și număr',
                accessor: 'series_id',
                Cell: ({row: {original: invoice}}) => `${invoice.series_name} ${invoice.series_number}`
            },
            {
                Header: 'Client',
                accessor: 'client',
                Cell: ({value: client}) => (client ? client.name : '-')
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value: project}) => (project ? project.name : '-')
            },
            {
                Header: 'Suma',
                accessor: 'total_with_VAT',
                Cell: ({value: total, row: {original: invoice}}) => {
                    return `${total} ${invoice.currency}`
                }
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: invoice}}) => (
                    <div className="datatable-row-buttons-container">
                        <Button
                            title="Stornează"
                            onClick={() => handleStornoInvoice(invoice)}
                            size="small"
                            color="secondary"
                        />
                    </div>
                )
            }
        ]
    }, [handleStornoInvoice])

    return (
        <Modal
            open={open}
            title="Adăugare storno"
            onClose={closeModal}
            maxWidth="70rem">
            <Datatable
                title="Facturi fiscale"
                data={invoices}
                columns={invoicesColumns}
                loading={isLoading}
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={currentPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                searchable
                searchValue={searchFilter}
                searchPlaceholder="Caută facturi"
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.ADD_STORNO,
    invoicesOptions: state.invoices.options,
    invoices: orderBy(state.invoices.data, ['issue_date', 'created'], ['desc', 'desc']),
    isLoading: state.invoices.isLoading,
    totalPages: state.invoices.totalPages,
    nextPage: state.invoices.next,
    previousPage: state.invoices.previous,
    currentPage: state.invoices.current,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    listInvoices: (entityID, search, filters, page) =>
        dispatch(
            RESOURCES.invoices.list(
                {
                    ...filters,
                    entity_id: entityID,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(AddStornoModal)
