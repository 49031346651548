import React, {Component} from 'react'
import {connect} from 'react-redux'

import {actionIds, prefix, actions} from './constants'

import {Button} from '../../components/Button'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'
import {modalTypes, openModal} from '../../redux/modals'

import {TasksList, TaskTimeLogs} from './partials'
import {Loader} from '../../components/Loader'

export class Tasks extends Component {
    render() {
        const {match, isLoadingTasks, openAddTaskModal} = this.props

        const urlData = {prefix: prefix, params: match.params}

        switch (match.params.primaryAction) {
            case actionIds.TASKS_LIST:
                return (
                    <>
                        <div className="w-full min-w-full flex flex-row justify-between items-center">
                            <div className="flex flex-row items-center gap-4">
                                <SecondaryNavbar
                                    actions={actions}
                                    urlData={urlData}
                                />
                                <span className="pb-2">{isLoadingTasks && <Loader />}</span>
                            </div>

                            <Button
                                title="Adaugă sarcină"
                                color="secondary"
                                onClick={openAddTaskModal}
                            />
                        </div>
                        <TasksList />
                    </>
                )
            case actionIds.TIME_LOGS:
                return (
                    <>
                        <SecondaryNavbar
                            actions={actions}
                            urlData={urlData}
                        />
                        <TaskTimeLogs {...this.props} />
                    </>
                )
            default:
                return
        }
    }
}

const mapStateToProps = (state) => ({
    isLoadingTasks: state.tasks.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    openAddTaskModal: () => dispatch(openModal(modalTypes.ADD_TASK))
})

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)
