import React from 'react'

import {CheckCell} from '../../../../components/CheckCell'
import {ManualOperation} from '../../../../components/ManualOperation'
import RedX from '../../../../components/RedX/RedX'

import './InvoiceExternalSyncIcon.scss'

const InvoiceExternalSyncIcon = ({state}) => {
    switch (state) {
        case 'successful':
        case 'manual_update':
            return <CheckCell />
        case 'not_started':
        case 'failed':
            return <RedX />
        case 'manual_needs_update':
            return (
                <span className="manual-needs-update-button">
                    <ManualOperation />
                </span>
            )
        default:
            return <RedX />
    }
}

export default InvoiceExternalSyncIcon
