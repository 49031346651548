import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, debounce, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {slideoverTypes} from '../../../../../redux/slideovers'

import {debounceWait} from '../../../../../utils/constants'

import {Input} from '../../../../../components/Input'
import {Select} from '../../../../../components/Select'
import {Button} from '../../../../../components/Button'
import {Slideover} from '../../../../../components/Slideover'
import {ErrorsList} from '../../../../../components/ErrorComponents'

import '../../../../../assets/scss/SlideoverForms.scss'

export class EditProjectInfoSlideover extends Component {
    constructor() {
        super()

        this.state = {
            clientsQuery: ''
        }
    }

    componentDidMount = () => this.handleFetchClients()

    handleFetchClients = (query = this.state.clientsQuery) => {
        const {searchClients} = this.props

        searchClients(query)
    }

    debounceSearchProjects = debounce(this.handleFetchClients, debounceWait)

    handleChangeClientsSearchField = (value) => {
        this.setState({clientsQuery: value})
        this.debounceSearchProjects(value)
    }

    render() {
        const {open, project, isLoading, fieldErrors, nonFieldErrors, clients, isLoadingClients, updateProject} =
            this.props

        return (
            <Slideover
                open={open && !isEmpty(project)}
                title="Editează proiect">
                <div className="slideover-form-container">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            name: project.name || '',
                            clients: project.clients
                        }}
                        onSubmit={(values) => {
                            const updateData = {
                                name: values.name,
                                clients_ids: values.clients.map((client) => client.id)
                            }

                            updateProject(updateData, project.id)
                        }}>
                        {({handleChange, setFieldValue, values, handleSubmit}) => (
                            <>
                                <Form className="slideover-form">
                                    <Input
                                        label="Nume"
                                        value={values.name}
                                        onChange={handleChange('name')}
                                        name="name"
                                        errors={fieldErrors}
                                        fullWidth
                                    />
                                    <Select
                                        label="Clienți"
                                        value={values.clients}
                                        options={clients}
                                        loading={isLoadingClients}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => setFieldValue('clients', e)}
                                        onInputChange={(value) => this.handleChangeClientsSearchField(value)}
                                        isMulti
                                        fullWidth
                                    />
                                </Form>
                                <div className="buttons-container">
                                    <Button
                                        title="Salvează"
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        type="submit"
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </Slideover>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.EDIT_PROJECT_INFO,
    project: state.projects.currentProject,
    fieldErrors: state.projects.fieldErrors,
    nonFieldErrors: state.projects.nonFieldErrors,
    isLoading: state.projects.isLoading,
    clients: _values(state.clients.searchData),
    isLoadingClients: state.clients.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    updateProject: (values, projectID) => dispatch(RESOURCES_V1.projects.update(values, projectID)),
    searchClients: (search) => dispatch(RESOURCES.clients.search(search))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectInfoSlideover)
