import {ArrowLeftIcon} from '@heroicons/react/24/outline'
import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'

import {RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {Button} from '../../components/Button'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {actionIds, actions} from './constants'
import {
    Addendums,
    Contracts,
    Documents,
    Empowerments,
    ExternalDataBPI,
    ExternalDataMD,
    History,
    InfoContactPersons,
    InfoGeneral,
    InfoProjectNotes,
    Invoices,
    Tasks
} from './partials'

import {Expenses} from '../Expenses'

import './ProjectDetails.scss'

export class ProjectDetails extends Component {
    componentDidMount = () => {
        const {retrieveProject, match} = this.props
        const {projectID} = match.params

        retrieveProject(projectID)
    }

    render() {
        const {project, match} = this.props
        const {projectID} = match.params

        const urlData = {prefix: `/projects/${projectID}`, params: match.params}

        const goToInfo = () => {
            switch (match.params.secondaryAction) {
                case actionIds.INFO_GENERAL:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<InfoGeneral match={match} />}
                        />
                    )
                case actionIds.INFO_CONTACT_PERSONS:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<InfoContactPersons match={match} />}
                        />
                    )
                case actionIds.INFO_NOTES:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<InfoProjectNotes match={match} />}
                        />
                    )

                default:
                    return <Redirect to={`${urlData.prefix}/${actionIds.INFO}/${actionIds.INFO_GENERAL}`} />
            }
        }

        const goToExternal = () => {
            switch (match.params.secondaryAction) {
                case actionIds.EXTERNAL_DATA_MD:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<ExternalDataMD match={match} />}
                        />
                    )
                case actionIds.EXTERNAL_DATA_BPI:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<ExternalDataBPI match={match} />}
                        />
                    )

                default:
                    return (
                        <Redirect to={`${urlData.prefix}/${actionIds.EXTERNAL_DATA}/${actionIds.EXTERNAL_DATA_MD}`} />
                    )
            }
        }

        const goToTasks = () => {
            switch (match.params.secondaryAction) {
                case actionIds.TASKS_ACTIVE:
                case actionIds.TASKS_FINISHED:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<Tasks match={match} />}
                        />
                    )
                default:
                    return <Redirect to={`${urlData.prefix}/${actionIds.TASKS}/${actionIds.TASKS_ACTIVE}`} />
            }
        }

        const goToContracts = () => {
            switch (match.params.secondaryAction) {
                case actionIds.CONTRACTS_CONTRACTS:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<Contracts match={match} />}
                        />
                    )

                case actionIds.CONTRACTS_EMPOWERMENTS:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<Empowerments match={match} />}
                        />
                    )

                case actionIds.CONTRACTS_ADDENDUMS:
                    return (
                        <ProjectDetailsLayout
                            project={project}
                            urlData={urlData}
                            component={<Addendums match={match} />}
                        />
                    )

                default:
                    return <Redirect to={`${urlData.prefix}/${actionIds.CONTRACTS}/${actionIds.CONTRACTS_CONTRACTS}`} />
            }
        }

        switch (match.params.primaryAction) {
            case actionIds.INFO:
                return goToInfo()

            case actionIds.EXTERNAL_DATA:
                return goToExternal()

            case actionIds.TASKS:
                return goToTasks()

            case actionIds.CONTRACTS:
                return goToContracts()

            case actionIds.DOCUMENTS:
                return (
                    <ProjectDetailsLayout
                        project={project}
                        urlData={urlData}
                        component={<Documents match={match} />}
                    />
                )

            case actionIds.EXPENSES:
                return (
                    <ProjectDetailsLayout
                        project={project}
                        urlData={urlData}
                        component={
                            <Expenses
                                match={match}
                                hasCreateOption={true}
                            />
                        }
                    />
                )

            case actionIds.INVOICES:
                return (
                    <ProjectDetailsLayout
                        project={project}
                        urlData={urlData}
                        component={<Invoices match={match} />}
                    />
                )

            case actionIds.HISTORY:
                return (
                    <ProjectDetailsLayout
                        project={project}
                        urlData={urlData}
                        component={<History match={match} />}
                    />
                )

            default:
                return <Redirect to={`${urlData.prefix}/${actionIds.INFO}/${actionIds.INFO_GENERAL}`} />
        }
    }
}

export function ProjectDetailsLayout({project, component, urlData}) {
    return (
        <div className="resource-details-content">
            <div className="back-button-container">
                <Link to="/projects">
                    <Button
                        title="Mergi înapoi la listă proiecte"
                        size="small"
                        color="gray"
                        variant="outlined"
                        icon={() => <ArrowLeftIcon />}
                        iconLeft
                    />
                </Link>
            </div>
            <div className="header-resource-title-container">
                <p className="header-resource-title">{project.name}</p>
                <div
                    className={`project-active-badge-container ${
                        project.active ? 'project-active' : 'project-inactive'
                    }`}>
                    <p className="project-active-badge-text">{project.active ? 'Project Activ' : 'Project Inactiv'}</p>
                </div>
            </div>
            <SecondaryNavbar
                actions={actions}
                urlData={urlData}
            />
            <div className="details-div">
                <div className="details-content">{component}</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    project: state.projects.currentProject
})

const mapDispatchToProps = (dispatch) => ({
    retrieveProject: (projectID) => dispatch(RESOURCES_V1.projects.retrieve(projectID))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails)
