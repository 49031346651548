import React, {Component} from 'react'

import {ArrowLongLeftIcon, ArrowLongRightIcon} from '@heroicons/react/24/solid'

import {isNull} from 'lodash'

import {connect} from 'react-redux'

import {pagesBadges} from './constants'

import {Select} from '../../Select'

import './Pagination.scss'

export class Pagination extends Component {
    renderPages = () => {
        const {total, currentPage, onChangePage} = this.props

        const pagesArray = pagesBadges({currentPage: currentPage, pages: total})

        return pagesArray.map((page, pageIdx) =>
            isNull(page) ? (
                <Separator key={pageIdx} />
            ) : (
                <PageButton
                    page={page}
                    current={currentPage === page}
                    onChangePage={() => {
                        onChangePage(page)
                    }}
                    key={pageIdx}
                />
            )
        )
    }

    render() {
        const {canNextPage, onNext, canPreviousPage, onPrevious, pageSize, onChangePageSize} = this.props

        return (
            <nav className="pagination-container">
                <div className="pagination-button-container previous">
                    <button
                        className="pagination-button"
                        onClick={() => {
                            if (canPreviousPage) {
                                onPrevious()
                            }
                        }}>
                        <ArrowLongLeftIcon
                            className="pagination-button-icon"
                            aria-hidden="true"
                        />
                        <p className="pagination-button-text">Înapoi</p>
                    </button>
                </div>
                <div className="pagination-pages-container">{this.renderPages()}</div>
                {pageSize && (
                    <div className="pagination-page-size-container">
                        <div className="vl"></div>
                        <Select
                            value={{value: pageSize, label: pageSize}}
                            options={[5, 10, 50].map((value) => ({value: value, label: value}))}
                            onChange={(item) => {
                                onChangePageSize?.(item.value)
                            }}
                        />
                        <span> pe pagină</span>
                    </div>
                )}
                <div className="pagination-button-container next">
                    <button
                        className="pagination-button"
                        onClick={() => {
                            if (canNextPage) {
                                onNext()
                            }
                        }}>
                        <p className="pagination-button-text">Înainte</p>
                        <ArrowLongRightIcon
                            className="pagination-button-icon"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </nav>
        )
    }
}

function PageButton(props) {
    const {page, current, onChangePage} = props

    return (
        <button
            className={`pagination-page-button ${current ? 'current' : ''}`}
            onClick={onChangePage}>
            <p className="pagination-page-button-text">{page}</p>
        </button>
    )
}

function Separator() {
    return <span className="pagination-page-separator">...</span>
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
