import React, {useEffect} from 'react'
import {Form, Formik, useFormikContext} from 'formik'

import {isEmpty, values as _values, find} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {slideoverTypes} from '../../redux/slideovers'

import {objectKeysToSnakeCase} from '../../utils'
import {contactPersonsSchema} from '../../assets/validations'

import {Input} from '../Input'
import {Button} from '../Button'
import {Toggle} from '../Toggle'
import {Creatable} from '../Creatable'
import {PhoneInput} from '../PhoneInput'
import {ErrorsList} from '../ErrorComponents'
import {RequiredFieldsText} from '../RequiredFieldsText'
import {Slideover} from '../Slideover'

import '../../assets/scss/SlideoverForms.scss'

export const EditContactPersonSlideover = ({
    open,
    contactPerson,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    updateContactPerson,
    selectedEntityID,
    personRoles,
    currentPersonRole,
    isLoadingPersonRoles,
    createPersonRole,
    listAllPersonRoles
}) => {
    useEffect(() => {
        listAllPersonRoles(selectedEntityID)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, open)

    const getInitialRoleId = () => find(personRoles, (role) => role.value === contactPerson.role_id) || null

    const AutoSelectRole = () => {
        const {setFieldValue} = useFormikContext()

        useEffect(() => {
            if (!isEmpty(currentPersonRole)) {
                setFieldValue('roleId', find(personRoles, (role) => role.value === currentPersonRole.id) || null)
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentPersonRole])

        return null
    }

    return (
        <Slideover
            open={open && !isEmpty(contactPerson)}
            title="Editează persoană de contact">
            <div className="slideover-form-container">
                {!isEmpty(contactPerson) && (
                    <>
                        <ErrorsList errors={nonFieldErrors} />
                        <Formik
                            initialValues={{
                                lastName: contactPerson.last_name,
                                firstName: contactPerson.first_name,
                                roleId: getInitialRoleId(),
                                email: contactPerson.email || '',
                                phone: contactPerson.phone || '',
                                legalRepresentative: contactPerson.legal_representative || false,
                                receiveMdNotifications: contactPerson.receive_md_notifications || false,
                                receiveBpiNotifications: contactPerson.receive_bpi_notifications || false
                            }}
                            validationSchema={contactPersonsSchema}
                            validateOnMount
                            onSubmit={(values) => {
                                const updateData = {
                                    ...objectKeysToSnakeCase(values),
                                    phone: values.phone ? `+${values.phone}` : '',
                                    role_id: values.roleId?.value || null
                                }

                                updateContactPerson(updateData, contactPerson.id)
                            }}>
                            {({
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                values,
                                handleSubmit,
                                touched,
                                errors,
                                isValid
                            }) => (
                                <>
                                    <Form className="slideover-form">
                                        <Input
                                            label="Nume*"
                                            value={values.lastName}
                                            onChange={handleChange('lastName')}
                                            onBlur={handleBlur('lastName')}
                                            name="lastName"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.lastName}
                                            fullWidth
                                        />
                                        <Input
                                            label="Prenume*"
                                            value={values.firstName}
                                            onChange={handleChange('firstName')}
                                            onBlur={handleBlur('firstName')}
                                            name="firstName"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.firstName}
                                            fullWidth
                                        />
                                        <Creatable
                                            label="Rol în client"
                                            value={values.roleId}
                                            options={personRoles}
                                            onChange={(e) => setFieldValue('roleId', e)}
                                            onBlur={handleBlur('roleId')}
                                            onCreateNew={(roleName) =>
                                                createPersonRole({name: roleName, entity_id: selectedEntityID})
                                            }
                                            loading={isLoadingPersonRoles}
                                            name="roleId"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.roleId}
                                            isClearable
                                            fullWidth
                                        />
                                        <AutoSelectRole />
                                        <Input
                                            label="Email"
                                            value={values.email}
                                            onChange={handleChange('email')}
                                            onBlur={handleBlur('email')}
                                            name="email"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.email}
                                            fullWidth
                                        />
                                        <PhoneInput
                                            label="Telefon"
                                            value={values.phone}
                                            onChange={handleChange('phone')}
                                            name="phone"
                                            errors={fieldErrors}
                                            fullWidth
                                        />
                                        <Toggle
                                            label="Reprezentant legal"
                                            checked={values.legalRepresentative}
                                            onChange={(e) => setFieldValue('legalRepresentative', e)}
                                            onBlur={handleBlur('legalRepresentative')}
                                            name="legalRepresentative"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.legalRepresentative}
                                        />
                                        <Toggle
                                            label="Notificări Monitor Dosare"
                                            checked={values.receiveMdNotifications}
                                            onChange={(e) => setFieldValue('receiveMdNotifications', e)}
                                            onBlur={handleBlur('receiveMdNotifications')}
                                            name="receiveMdNotifications"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.receiveMdNotifications}
                                        />
                                        <Toggle
                                            label="Notificări Monitor BPI"
                                            checked={values.receiveBpiNotifications}
                                            onChange={(e) => setFieldValue('receiveBpiNotifications', e)}
                                            onBlur={handleBlur('receiveBpiNotifications')}
                                            name="receiveBpiNotifications"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.receiveBpiNotifications}
                                        />
                                        <RequiredFieldsText />
                                    </Form>
                                    <div className="buttons-container">
                                        <Button
                                            title="Salvează"
                                            onClick={handleSubmit}
                                            loading={isLoading}
                                            disabled={!isValid}
                                            type="submit"
                                            fullWidth
                                        />
                                    </div>
                                </>
                            )}
                        </Formik>
                    </>
                )}
            </div>
        </Slideover>
    )
}

const mapStateToProps = (state) => {
    const personRoles = _values(state.personRoles.data).map((role) => ({value: role.id, label: role.name}))

    return {
        open: state.slideovers.type === slideoverTypes.EDIT_CONTACT_PERSON,
        selectedEntityID: state.localConfigs.selectedEntityID,
        contactPerson: state.contactPersons.currentContactPerson,
        fieldErrors: state.contactPersons.fieldErrors,
        nonFieldErrors: state.contactPersons.nonFieldErrors,
        isLoading: state.contactPersons.isLoading,
        personRoles: personRoles,
        currentPersonRole: state.personRoles.currentPersonRole,
        isLoadingPersonRoles: state.personRoles.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    listAllPersonRoles: (entityID) => dispatch(RESOURCES.personRoles.listAll({entity_id: entityID})),
    createPersonRole: (personRoleData) => dispatch(RESOURCES.personRoles.create(personRoleData)),
    updateContactPerson: (values, contactPersonID) => dispatch(RESOURCES.contactPersons.update(values, contactPersonID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditContactPersonSlideover)
