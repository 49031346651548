import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQueryArgs() {
    const {search} = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

export const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay)

        return () => clearTimeout(handler)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay])
}

export const useDebouncedAsyncEffect = (effect, deps, delay) => {
    useEffect(() => {
        const handler = setTimeout(async () => await effect(), delay)

        return () => clearTimeout(handler)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay])
}

export function useDebouncedValue(value, delay = 300) {
    const [debouncedValue, setDebouncedValue] = React.useState(value)

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        // Cancel the timeout if value changes (also on delay change or unmount)
        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return debouncedValue
}

export function useDebouncedState(initialValue, delay = 300) {
    const [value, setValue] = React.useState(initialValue)
    const debouncedValue = useDebouncedValue(value, delay)

    return [debouncedValue, setValue]
}
