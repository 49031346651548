import React, {Component, Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {push} from 'connected-react-router'

import {values, isEmpty, head, isNull, toUpper} from 'lodash'

import {connect} from 'react-redux'
import {closeSimilarClientModal} from '../../redux/clients'
import {forceCreateClient} from 'avoapp-react-common/dist/redux/clients'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {classNames} from '../../utils'
import {personTypes} from '../../utils/types'

import {Loader} from '../Loader'
import {Button} from '../Button'

import './SimilarClientModal.scss'

export class SimilarClientModal extends Component {
    constructor() {
        super()

        this.state = {
            selectedClientID: null
        }
    }

    componentDidUpdate = (prevProps) => {
        const {open, selectedEntityID, similarClientData, clients, listAllClients} = this.props

        if (prevProps.open !== open && open) {
            listAllClients(similarClientData.name, selectedEntityID)
        }

        if (prevProps.clients !== clients && !isEmpty(clients)) {
            this.setState({selectedClientID: head(clients).id})
        }
    }

    handleSelectClient = (client) => this.setState({selectedClientID: client.id})

    handleViewSelectedClient = () => {
        const {viewSelectedClientID, closeModal} = this.props
        const {selectedClientID} = this.state

        viewSelectedClientID(selectedClientID)
        closeModal()
    }

    render() {
        const {selectedClientID} = this.state
        const {open, closeModal, forceCreateClient, clients, similarClientData, isLoading} = this.props

        return (
            <Transition.Root
                show={open}
                as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={closeModal}>
                    <div
                        className={classNames(
                            'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'
                        )}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-200"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="similar-client-modal-container">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg leading-6 font-medium text-center text-gray-900 mb-5">
                                    Am găsit clienți asemănători
                                </Dialog.Title>
                                {!isEmpty(clients) && !isLoading ? (
                                    <>
                                        <div className="similar-client-modal-content-container">
                                            {clients.map((client) => (
                                                <ClientCard
                                                    client={client}
                                                    selected={client.id === selectedClientID}
                                                    onClick={(client) => this.handleSelectClient(client)}
                                                    key={client.id}
                                                />
                                            ))}
                                        </div>
                                        <div className="similar-client-modal-buttons-container">
                                            <Button
                                                title="Vreau să creez clientul nou"
                                                variant="outlined"
                                                onClick={() => {
                                                    forceCreateClient(similarClientData)
                                                    closeModal()
                                                }}
                                                fullWidth
                                            />
                                            <Button
                                                // check if user has access to client
                                                title={`${true ? 'Vezi clientul' : 'Cere access în client'}`}
                                                disabled={isNull(selectedClientID)}
                                                onClick={this.handleViewSelectedClient}
                                                fullWidth
                                            />
                                        </div>
                                    </>
                                ) : isLoading ? (
                                    <div className="loader-container">
                                        <Loader size="large" />
                                    </div>
                                ) : null}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

function ClientCard({client, onClick, selected}) {
    return (
        <div
            className={`similar-client-info-card ${selected ? 'selected' : ''}`}
            onClick={() => onClick(client)}>
            <div className="similar-client-info-card-header">
                <p className="client-name">{client.name}</p>
            </div>
            <div className="similar-client-info-card-content">
                <div className="client-info-row">
                    <p className="client-info-key">Tip:</p>
                    <p className="client-info-value">{client.type ? personTypes[toUpper(client.type)].label : '-'}</p>
                </div>
                <div className="client-info-row">
                    <p className="client-info-key">CUI/CNP:</p>
                    <p className="client-info-value">{client.vat_code ? client.vat_code : '-'}</p>
                </div>
                <div className="client-info-row">
                    <p className="client-info-key">Reg. Com.:</p>
                    <p className="client-info-value">
                        {client.business_registration_number ? client.business_registration_number : '-'}
                    </p>
                </div>
                <div className="client-info-row">
                    <p className="client-info-key">Email:</p>
                    <p className="client-info-value">{client.email ? client.email : '-'}</p>
                </div>
                <div className="client-info-row">
                    <p className="client-info-key">Telefon:</p>
                    <p className="client-info-value">{client.phone ? client.phone : '-'}</p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    open: state.localClientsSettings.openSimilarClientModal,
    similarClientData: state.localClientsSettings.similarClientData,
    clients: values(state.clients.data),
    isLoading: state.clients.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeSimilarClientModal()),
    listAllClients: (name, entity_id) => dispatch(RESOURCES.clients.listAll({entity_id: entity_id, name: name})),
    viewSelectedClientID: (clientID) => dispatch(push(`/clients/${clientID}`)),
    forceCreateClient: (data) => dispatch(forceCreateClient(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SimilarClientModal)
