import React from 'react'

const ValueChanged = ({oldValue, newValue, mappedFieldName, label}) => (
    <p className="log-text">
        {label} {mappedFieldName && <span className="log-field-name-span">{mappedFieldName}</span>}:{' '}
        <span className="log-text-span">{oldValue}</span>
        {' în '}
        <span className="log-text-span">{newValue}</span>
    </p>
)

export default ValueChanged
