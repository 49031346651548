import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal} from './modals'

const {legalObjects: resource} = RESOURCES

function* handleCreateContactPersonSuccess() {
    toast.success('Obiectul legal a fost creat cu succes')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateContactPersonSuccess)
}
