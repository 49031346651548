import React, {useCallback, useMemo, useState} from 'react'

import {connect} from 'react-redux'
// @ts-ignore
import {closeModal, modalTypes} from '../../redux/modals'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

// @ts-ignore
import {Modal} from '../Modal'
// @ts-ignore
import {Steps} from '../Steps'

import AppealForm from './AppealForm'
import HearingForm from './HearingForm'
import PartyForm from './PartyForm'
import PhaseForm from './PhaseForm'

interface AddMDManualSubscriptionModalProps {
    open: boolean
    closeModal: () => void
}

export const AddMDManualSubscriptionModal = ({open, closeModal}: AddMDManualSubscriptionModalProps) => {
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [currentPhase, setCurrentPhase] = useState<any>(undefined)

    const goToNextStep = useCallback(() => {
        if (currentStep < 3) {
            setCurrentStep((prevCurrentStep) => {
                return prevCurrentStep + 1
            })
        } else {
            closeModal()
        }
    }, [closeModal, currentStep])

    const renderPhasesForm = useCallback(() => {
        return (
            <PhaseForm
                goToNextStep={goToNextStep}
                setCurrentPhase={setCurrentPhase}
            />
        )
    }, [goToNextStep])

    const renderPartiesForm = useCallback(() => {
        return (
            <PartyForm
                goToNextStep={goToNextStep}
                currentPhaseID={currentPhase?.id}
                isMultistepForm={true}
            />
        )
    }, [currentPhase?.id, goToNextStep])

    const onCreateHearing = useCallback((hearingData) => {
        return performRequest(RESOURCES.hearings.create(hearingData))
    }, [])

    const renderHearingsForm = useCallback(() => {
        return (
            <HearingForm
                goToNextStep={goToNextStep}
                onSubmit={onCreateHearing}
                currentPhaseID={currentPhase?.id}
                isMultistepForm={true}
            />
        )
    }, [currentPhase?.id, goToNextStep, onCreateHearing])

    const renderAppealsForm = useCallback(() => {
        return (
            <AppealForm
                goToNextStep={goToNextStep}
                currentPhase={currentPhase}
                isMultistepForm={true}
            />
        )
    }, [currentPhase, goToNextStep])

    const steps = useMemo(() => {
        return [
            {
                id: 0,
                stepNumber: '01',
                name: 'Informații dosar manual',
                renderForm: renderPhasesForm
            },
            {
                id: 1,
                stepNumber: '02',
                name: 'Adăugare parte',
                renderForm: renderPartiesForm
            },
            {
                id: 2,
                stepNumber: '03',
                name: 'Adăugare termen',
                renderForm: renderHearingsForm
            },
            {
                id: 3,
                stepNumber: '04',
                name: 'Adăugare cale de atac',
                renderForm: renderAppealsForm
            }
        ]
    }, [renderAppealsForm, renderHearingsForm, renderPartiesForm, renderPhasesForm])

    const renderFormForCurrentStep = useCallback(() => {
        return steps[currentStep].renderForm()
    }, [currentStep, steps])

    return (
        <Modal
            open={open}
            title="Adaugă dosar manual"
            minWidth="950px">
            <Steps
                steps={steps}
                currentStepId={currentStep}
            />
            <div className="add-md-manual-subsctiption-modal-forms-container">{renderFormForCurrentStep()}</div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_MONITOR_DOSARE_MANUAL_SUBSCRIPTION
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddMDManualSubscriptionModal)
