import React, {useEffect} from 'react'

import _ from 'lodash'

import {Notification} from 'types/api'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {connect} from 'react-redux'
// @ts-ignore
import {slideoverTypes} from '../../../../redux/slideovers'

import BPINotificationDetails from './BPINotificationDetails'
import MDNotificationDetails from './MDNotificationDetails'
import BPISubscriptionProcessedNotificationDetails from './BPISubscriptionProcessedNotificationDetails'

import './NotificationDetailsSlideover.scss'

type NotificationDetailsSlideoverProps = {
    open: boolean
    notification: Notification
    retrieveNotification: (notificationID: number) => void
    markNotificationAsSeen: (notificationID: number) => void
}

export const NotificationDetailsSlideover = ({
    open,
    notification,
    retrieveNotification,
    markNotificationAsSeen
}: NotificationDetailsSlideoverProps) => {
    useEffect(() => {
        if (notification.id) {
            if (open) {
                retrieveNotification(notification.id)
            }

            if (!notification.seen && open) {
                markNotificationAsSeen(notification.id)
            }
        }
    }, [markNotificationAsSeen, notification.id, notification.seen, open, retrieveNotification])

    if (!notification || _.isEmpty(notification)) {
        return <React.Fragment />
    }

    if (notification.type === 'bpi') {
        return <BPINotificationDetails />
    }

    if (notification.type === 'bpi_subscription_processed') {
        return <BPISubscriptionProcessedNotificationDetails />
    }

    return <MDNotificationDetails />
}

const mapStateToProps = (state: any) => ({
    open: state.slideovers.type === slideoverTypes.NOTIFICATION_DETAILS,
    notification: state.notifications.currentNotification
})

const mapDispatchToProps = (dispatch: any) => ({
    retrieveNotification: (notificationID: any) => dispatch(RESOURCES.notifications.retrieve(notificationID)),
    markNotificationAsSeen: (notificationID: any) =>
        dispatch(
            RESOURCES.notifications.update({
                id: notificationID,
                seen: 'true'
            })
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetailsSlideover)
