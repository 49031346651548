import React from 'react'

import './Tabs.scss'

interface TabsProps {
    tabs: string[]
    selectedTab: string
    onChangeTab: (tab: string) => void
}

export default function Tabs({tabs, selectedTab, onChangeTab}: TabsProps) {
    return (
        <div className="avo-tabs-container">
            <nav className="avo-tabs-nav">
                {tabs.map((tab) => (
                    <div
                        key={tab}
                        onClick={() => onChangeTab(tab)}
                        className={`avo-tab ${selectedTab === tab ? 'selected' : ''}`}>
                        {tab}
                    </div>
                ))}
            </nav>
        </div>
        //  Responsive
        // <div className="sm:hidden">
        //     <label htmlFor="tabs" className="sr-only">
        //         Select a tab
        //     </label>
        //     Use an "onChange" listener to redirect the user to the selected tab URL.
        //     <select
        //         id="tabs"
        //         name="tabs"
        //         className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base
        //                          focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        //         defaultValue={tabs[0]}
        //     >
        //         {tabs.map((tab) => (
        //             <option key={tab.value}>{tab.label}</option>
        //         ))}
        //     </select>
        // </div>
    )
}
