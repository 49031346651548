import React from 'react'
// @ts-ignore
import {Select} from '../../../components/Select'
import {SelectFilterProps} from './types'

const SelectFilter: React.FC<SelectFilterProps> = ({filterName, filterConfig, value, setFieldValue}) => {
    return (
        <Select
            label={filterConfig.label}
            value={value}
            options={filterConfig.options}
            onChange={(option: any) => setFieldValue(filterName, option)}
            name={filterName}
            isMulti={filterConfig.isMulti}
            fullWidth
        />
    )
}

export default SelectFilter
