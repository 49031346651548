import React from 'react'

import {TableLink} from '../../components/TableLink'
import {ViewDetailsLink} from '../../components/ViewDetailsLink'

export const prefix = '/clients'

export const getClientsColumns = () => [
    {
        Header: 'Nume',
        accessor: 'name',
        Cell: ({row: {values}}) => <TableLink to={`/clients/${values.id}`}>{values.name}</TableLink>
    },
    {
        Header: 'CUI/CNP',
        accessor: 'vat_code'
    },
    {
        Header: 'Loc./Oraș',
        accessor: 'city',
        Cell: ({row}) => (row.original.primary_address?.locality ? row.original.primary_address?.locality : '')
    },
    {
        Header: 'Județ',
        accessor: 'county',
        Cell: ({row}) => (row.original.primary_address?.county ? row.original.primary_address?.county : '')
    },
    {
        Header: 'Țară',
        accessor: 'country',
        Cell: ({row}) => row.original.primary_address?.country || ''
    },
    // {
    //     Header: 'Rolul meu',
    //     accessor: 'role',
    //     Cell: ({row}) => {
    //         const myPermission = find(row.original.client_permissions, ['entity_profile_id', entityProfileID])
    //
    //         return myPermission ? roleTypes[toUpper(myPermission.role)]?.label : ''
    //     }
    // },
    // {
    //     Header: 'Manager',
    //     accessor: (client) => getEntityProfilesWithClientPermissions(client, roleTypes.MANAGER.value)
    // },
    {
        Header: '',
        accessor: 'id',
        Cell: ({value}) => <ViewDetailsLink to={`/clients/${value}`} />,
        disableSortBy: true
    }
]

export const clientGroupsColumns = [
    {
        Header: 'Nume',
        accessor: 'name'
    },
    {
        Header: 'Clienți',
        accessor: 'client_ids',
        Cell: ({value}) => value.length
    },
    {
        Header: '',
        accessor: 'id',
        Cell: ({value}) => <ViewDetailsLink to={`/clients/groups/${value}`} />,
        disableSortBy: true
    }
]

export const actionIds = {
    ACTIVE_CLIENTS: 'active',
    GROUPS: 'groups',
    INACTIVE_CLIENTS: 'inactive'
}

export const actions = {
    [actionIds.ACTIVE_CLIENTS]: {
        id: actionIds.ACTIVE_CLIENTS,
        label: 'Clienți activi'
    },
    [actionIds.GROUPS]: {
        id: actionIds.GROUPS,
        label: 'Grupuri clienți'
    },
    [actionIds.INACTIVE_CLIENTS]: {
        id: actionIds.INACTIVE_CLIENTS,
        label: 'Clienți inactivi'
    }
}
