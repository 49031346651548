import {ArrowLeftIcon, PencilSquareIcon} from '@heroicons/react/24/outline'
import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'

import {endsWith, findLast, isEmpty, isUndefined, startsWith} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'

import {actionIds, actions} from './constants'

import {Button} from '../../components/Button'
import {Loader} from '../../components/Loader'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {
    EditEmpowermentNameForm,
    EmpowermentMetaInfo,
    EmpowermentPreview,
    EmpowermentSettings,
    SignedEmpowermentPreview
} from './partials'

import './EmpowermentDetails.scss'

export const EmpowermentDetails = ({
    empowerment,
    isLoading,
    contract,
    isLoadingContracts,
    match,
    sourceLocation,
    navigate,
    location,
    history,
    retrieveEmpowerment,
    retrieveContract
}) => {
    const {empowermentID, primaryAction} = match.params

    useEffect(() => {
        retrieveEmpowerment(empowermentID)
    }, [empowermentID, retrieveEmpowerment])

    useEffect(() => {
        if (
            !isEmpty(empowerment) &&
            parseInt(contract.id) !== parseInt(empowerment.contract_id) &&
            !isLoadingContracts
        ) {
            retrieveContract(empowerment.contract_id)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empowerment])

    const urlData = {prefix: `/contracts/empowerments/${empowermentID}`, params: match.params, location, history}

    switch (primaryAction) {
        case actionIds.INFO:
            return (
                <EmpowermentDetailsLayout
                    urlData={urlData}
                    empowerment={empowerment}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<EmpowermentMetaInfo match={match} />}
                />
            )
        case actionIds.SETTINGS:
            return (
                <EmpowermentDetailsLayout
                    urlData={urlData}
                    empowerment={empowerment}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={
                        <EmpowermentSettings
                            match={match}
                            urlData={urlData}
                        />
                    }
                />
            )
        case actionIds.PREVIEW:
            return (
                <EmpowermentDetailsLayout
                    urlData={urlData}
                    empowerment={empowerment}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={
                        <EmpowermentPreview
                            match={match}
                            urlData={urlData}
                        />
                    }
                />
            )
        case actionIds.SIGNED:
            return (
                <EmpowermentDetailsLayout
                    urlData={urlData}
                    empowerment={empowerment}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<SignedEmpowermentPreview match={match} />}
                />
            )

        default:
            return <Redirect to={`${urlData.prefix}/${actionIds.INFO}`} />
    }
}

export function EmpowermentDetailsLayout({empowerment, loading, component, urlData, goBack}) {
    const [editMode, setEditMode] = useState(false)

    return (
        <div className="resource-details-content">
            <div className="back-button-container">
                <Button
                    title="Mergi înapoi"
                    size="small"
                    color="gray"
                    variant="outlined"
                    onClick={goBack}
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            <div className="header-resource-title-container">
                {!loading && !editMode ? (
                    <div className="empowerment-name-container">
                        <p className="header-resource-title">{empowerment.name}</p>
                        <Button
                            icon={() => <PencilSquareIcon />}
                            onClick={() => setEditMode(true)}
                            size="small"
                        />
                    </div>
                ) : !loading && editMode ? (
                    <EditEmpowermentNameForm handleCancel={() => setEditMode(false)} />
                ) : loading ? (
                    <div className="loader-container">
                        <Loader />
                    </div>
                ) : null}
            </div>
            <SecondaryNavbar
                actions={actions}
                urlData={urlData}
            />
            <div>{component}</div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const sourceLocation = findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/contracts/empowerments' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/empowerments')) ||
            (startsWith(pathname, '/contracts') && endsWith(pathname, '/empowerments'))
    )

    return {
        empowerment: state.empowerments.currentEmpowerment,
        isLoading: state.empowerments.isLoading,
        contract: state.contracts.currentContract,
        isLoadingContracts: state.contracts.isLoading,
        sourceLocation: !isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/contracts/empowerments'
    }
}

const mapDispatchToProps = (dispatch) => ({
    navigate: (route) => dispatch(push(route)),
    retrieveEmpowerment: (empowermentID) => dispatch(RESOURCES.empowerments.retrieve(empowermentID)),
    retrieveContract: (contractID) => dispatch(RESOURCES.contracts.retrieve(contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpowermentDetails)
