import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'

import _, {includes, isUndefined, last} from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {createSmartBillInvoice} from 'avoapp-react-common/dist/redux/smartbill'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'

import {datatablePageSize} from '../utils/datatables'
import {generateFiltersForAPI} from './filters/utils'
import {closeModal, modalTypes, openModal} from './modals'
import {store} from './store'

import {externalSyncActions} from '../pages/Invoices/constants'

const {invoices: resource} = RESOURCES

function* handleCreateSuccess({payload: invoice}) {
    toast.success('Factura a fost adaugată cu succes.')

    performRequest(createSmartBillInvoice(invoice.id)).then((response) => {
        const {errors, status} = response
        if (status === 403) {
            // SmartBill is not enabled for this entity. Missing API key.
            return
        }

        if (status >= 400) {
            const errorMessage = _.get(
                errors,
                'non_field_errors.0.message',
                'A apărut o eroare la sincronizarea cu SmartBill'
            )
            store.dispatch(
                openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {
                    invoiceID: invoice.id,
                    action: externalSyncActions.CREATE,
                    errorMessage: errorMessage
                })
            )
        } else {
            toast('Factura a fost sincronizată cu succes în SmartBill', {type: 'success'})
            store.dispatch(RESOURCES.invoices.retrieve(invoice.id))
        }
    })

    const currentLocation = yield select((state) => last(state.routerLocations))

    if (
        !isUndefined(currentLocation.location.search) &&
        includes(currentLocation.location.search, 'initialProjectId')
    ) {
        yield put(push(`/projects/${invoice.project_id}/invoices`))
    } else {
        yield put(push('/invoices'))
    }
}

function* handleUpdateSuccess({payload: invoice}) {
    toast.success('Salvat.')
    const userPreferences = yield select((state) => state.usersPreferences.data)
    if (userPreferences.has_smartbill_integration && invoice.state !== 'canceled') {
        store.dispatch(
            openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {
                invoiceID: invoice.id,
                action: externalSyncActions.MANUAL_UPDATE
            })
        )
    }

    yield put(push(`/invoices/${invoice.id}`))
}

function* handleDestroyInvoiceSuccess() {
    toast.success('Factura a fost ștearsă')

    yield put(closeModal())

    if (window.location.pathname !== '/invoices/invoices') {
        yield put(push('/invoices'))
    } else {
        const invoiceFilters = yield select((state) => state.filters.invoices)
        const appliedFilters = generateFiltersForAPI(invoiceFilters)
        const selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)

        yield put(
            RESOURCES.invoices.list({
                ...appliedFilters,
                entity_id: selectedEntityID,
                page: 1,
                page_size: datatablePageSize
            })
        )
    }
}

function* handleDestroyInvoiceFail() {
    toast.error('Am întâmpinat o eroare. Dacă eroare persistă te rugăm se ne contactezi!')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyInvoiceSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyInvoiceFail)
}
