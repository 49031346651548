import React, {useCallback, useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'

import {differenceInDays, lightFormat} from 'date-fns'
import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {getFieldOptions} from '../../../../utils'

import {Button} from '../../../../components/Button'
import {PageLoader} from '../../../../components/PageLoader'
import {ResourceLogsList} from '../../../../components/ResourceLogsList'

import './InvoiceMetaInfo.scss'
import InvoiceExternalSyncIcon from '../InvoiceExternalSyncIcon/InvoiceExternalSyncIcon'
import {ManualUpdateInvoiceButton} from '../../../../components/ManualUpdateInvoiceButton'
import {modalTypes, openModal} from '../../../../redux/modals'
import {InvoiceExternalSyncResolveModal} from '../../../../components/InvoiceExternalSyncResolveModal'

const InvoiceMetaInfo = ({
    match: {
        params: {invoiceID}
    },
    invoice,
    isLoading,
    invoicesOptions,
    getInvoicesOptions,
    usersPreferences,
    openInvoiceExternalSyncResolveModal,
    retrieveInvoice,
    invoiceReports
}) => {
    useEffect(() => {
        getInvoicesOptions()
    }, [getInvoicesOptions])

    // const invoiceState = useMemo(() => {
    //     const invoiceStates = getFieldOptions(invoicesOptions, 'state')
    //     let state
    //
    //     if(!_.isEmpty(invoice) && !_.isEmpty(invoiceStates)) {
    //         state = _.find(invoiceStates, ['value', invoice.state])?.value
    //     }
    //
    //     return state || '-'
    // }, [invoice, invoicesOptions])

    const invoiceCurrency = useMemo(() => {
        const currencies = getFieldOptions(invoicesOptions, 'currency')
        let currency

        if (!_.isEmpty(invoice) && !_.isEmpty(currencies)) {
            currency = _.find(currencies, ['value', invoice.currency])?.value
        }

        return currency || '-'
    }, [invoice, invoicesOptions])

    const invoiceSeries = useMemo(() => {
        if (invoice.series_name && invoice.series_number) {
            return `${invoice.series_name} ${invoice.series_number}`
        }

        return '-'
    }, [invoice])

    const dueDate = useMemo(() => {
        if (!_.isNil(invoice.due_date)) {
            return `${differenceInDays(new Date(), new Date(invoice.due_date))} zile`
        }

        return '-'
    }, [invoice.due_date])

    const formatDate = useCallback((dateToFormat) => {
        if (dateToFormat) {
            return lightFormat(new Date(dateToFormat), 'dd/MM/yyyy')
        }

        return '-'
    }, [])

    const reportsInterval = useMemo(() => {
        let interval = ''

        if (!_.isNil(invoice.reports_start) && !_.isNil(invoice.reports_stop)) {
            interval = `${lightFormat(new Date(invoice.reports_start), 'dd/MM/yyyy')} - ${lightFormat(
                new Date(invoice.reports_stop),
                'dd/MM/yyyy'
            )}`
        } else if (!_.isNil(invoice.reports_start)) {
            interval = `De la ${lightFormat(new Date(invoice.reports_start), 'dd/MM/yyyy')}`
        } else if (!_.isNil(invoice.reports_stop)) {
            interval = `Până la ${lightFormat(new Date(invoice.reports_stop), 'dd/MM/yyyy')}`
        }

        if (interval === '') {
            interval = '-'
        }

        return interval
    }, [invoice.reports_start, invoice.reports_stop])

    const invoiceAuthor = useMemo(() => {
        let author = invoice.author?.full_name || ''

        if (!_.isNil(invoice.author?.email)) {
            if (author === '') {
                author = invoice.author?.email
            } else {
                author = `${author} (${invoice.author.email})`
            }
        }

        if (author === '') {
            author = '-'
        }

        return author
    }, [invoice.author])

    if (!_.isEmpty(invoice) && !isLoading) {
        return (
            <>
                {/*<div className="invoice-info-card invoice-status-container">*/}
                {/*    <div className="invoice-info-card-header">*/}
                {/*        <p className='header-title'>Status factură</p>*/}
                {/*    </div>*/}
                {/*    <div className="invoice-info-card-content">*/}
                {/*        <div className="invoice-info-card-row">*/}
                {/*            <p className='label'>*/}
                {/*                Ultima trimitere în data de <span>"date"</span> ora <span>"time"</span>.{' '}*/}
                {/*                Statusul curent este <span>{invoiceState}</span>,{' '}*/}
                {/*                verificat în data de <span>"date"</span>, ora <span>"time"</span>*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="content-container-row">
                    <div className="invoice-info-card">
                        <div className="invoice-info-card-header">
                            <p className="header-title">Date factură</p>
                        </div>
                        <div className="invoice-info-card-content">
                            <div className="invoice-info-card-row">
                                <p className="label">Client</p>
                                <p className="value">{invoice.client?.name || '-'}</p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Proiect</p>
                                <p className="value">{invoice.project?.name || '-'}</p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Contract</p>
                                <p className="value">{invoice.contract?.name || '-'}</p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Serie și număr</p>
                                <p className="value">{invoiceSeries}</p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Data emiterii</p>
                                <p className="value">{formatDate(invoice.issue_date)}</p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Data scadență</p>
                                <p className="value">{formatDate(invoice.due_date)}</p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Perioada de raportare</p>
                                <p className="value">{reportsInterval}</p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Șablon document</p>
                                <p className="value">{invoice.template?.name || '-'}</p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Factură emisă de</p>
                                <p className="value">{invoiceAuthor}</p>
                            </div>
                            {usersPreferences.has_smartbill_integration && (
                                <div className="invoice-info-card-row">
                                    <p className="label">SmartBill</p>
                                    <p
                                        className="value"
                                        style={{position: 'relative'}}>
                                        {
                                            <>
                                                <span style={{display: 'inline-block'}}>
                                                    <InvoiceExternalSyncIcon state={invoice.sync_state_smartbill} />
                                                </span>
                                                <span
                                                    style={{display: 'inline-block', position: 'absolute', top: '2px'}}>
                                                    <ManualUpdateInvoiceButton
                                                        invoice={invoice}
                                                        onClick={() => {
                                                            openInvoiceExternalSyncResolveModal()
                                                        }}
                                                    />
                                                </span>
                                            </>
                                        }
                                    </p>
                                    <InvoiceExternalSyncResolveModal
                                        selectedInvoice={invoice}
                                        reloadData={() => {
                                            retrieveInvoice(invoiceID)
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="invoice-info-card">
                        <div className="invoice-info-card-header">
                            <p className="header-title">Date financiare</p>
                        </div>
                        <div className="invoice-info-card-content">
                            <div className="invoice-info-card-row">
                                <p className="label">Sumă totală factură</p>
                                <p className="value">
                                    {invoice.total_with_VAT || '-'} {invoiceCurrency}
                                </p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Sumă de încasat</p>
                                <p className="value">
                                    {invoiceReports ? `${invoice.total_unpaid} ${invoiceCurrency}` : '-'}
                                </p>
                            </div>
                            <div className="invoice-info-card-row">
                                <p className="label">Zile scadență</p>
                                <p className="value">{dueDate}</p>
                            </div>
                            <div className="invoice-payments-section">
                                <div className="invoice-info-card-row">
                                    <p className="label">Sumă încasată distribuită</p>
                                    <p className="value">
                                        {invoiceReports
                                            ? `${invoiceReports.amount_distributed} ${invoiceCurrency}`
                                            : '-'}
                                    </p>
                                </div>
                                <Link to={`/invoices/${invoiceID}/payments`}>
                                    <Button title="Vezi încasări" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="details-card log-details-card">
                    <ResourceLogsList
                        resourceID={invoiceID}
                        resourceName={RESOURCES.invoices.name}
                    />
                </div>
            </>
        )
    }

    if (isLoading) return <PageLoader />
}

const mapStateToProps = (state) => ({
    invoicesOptions: state.invoices.options,
    invoice: state.invoices.currentInvoice,
    isLoading: state.invoices.isLoading,
    invoiceReports: state.invoiceReports.data,
    usersPreferences: state.usersPreferences.data,
    isLoadingInvoiceReports: state.invoiceReports.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    getInvoicesOptions: () => dispatch(RESOURCES.invoices.getOptions()),
    openInvoiceExternalSyncResolveModal: () => dispatch(openModal(modalTypes.INVOICE_EXTERNAL_MANUAL_RESOLVE_MODAL)),
    retrieveInvoice: (invoiceID) => dispatch(RESOURCES.invoices.retrieve(invoiceID))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceMetaInfo)
