import React, {useEffect, useState} from 'react'

import {toApiTimeFormat} from '../../../../utils'

import './Clock.scss'

export default function Clock() {
    const [date, setDate] = useState(new Date())

    useEffect(() => {
        const timerID = setInterval(() => {
            setDate(new Date())
        }, 1000)

        return () => clearInterval(timerID)
    }, [])

    return (
        <div id="clock">
            <p className="clock-text">{toApiTimeFormat(date, true)}</p>
        </div>
    )
}
