import React, {useCallback} from 'react'

interface CompaniesListProps {
    companies: any[]
    onAddSubscription: (values: any) => void
}

export default function CompaniesList({companies, onAddSubscription}: CompaniesListProps) {
    const handleCompanyClick = useCallback(
        (company: any) => {
            const companyData = {
                vatNumber: company.vat_number,
                companyName: company.name
            }

            onAddSubscription(companyData)
        },
        [onAddSubscription]
    )

    return (
        <div className="companies-list">
            {companies.map((company, companyIdx) => (
                <div
                    className="company-card"
                    onClick={() => handleCompanyClick(company)}
                    key={companyIdx}>
                    <div className="card-header">
                        <p className="card-title">{company.name}</p>
                    </div>
                    <div className="card-content">
                        <div className="card-content-child">
                            <p className="title"> CUI: </p>
                            <p className="subtitle"> {company.vat_number} </p>
                        </div>
                        <div className="card-content-child">
                            <p className="title"> Județ: </p>
                            <p className="subtitle"> {company.county} </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
