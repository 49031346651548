import React, {useEffect, useRef, useState} from 'react'

import {EllipsisHorizontalIcon} from '@heroicons/react/24/outline'

import {isNil} from 'lodash'
import './DatatableActions.scss'

interface DatatableAction {
    title: string
    onClick: () => void
    icon: any
}

interface DatatableActionsProps {
    actions: DatatableAction[]
}

const DatatableActions: React.FC<DatatableActionsProps> = ({actions}) => {
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

    const boxRef = useRef<HTMLDivElement>(null)
    const optionsRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
            setMenuIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div
            ref={boxRef}
            className="actions">
            <div
                className="actions-icon"
                onClick={() => setMenuIsOpen(!menuIsOpen)}>
                <EllipsisHorizontalIcon />
            </div>
            <div
                ref={optionsRef}
                className="actions-options"
                style={!menuIsOpen ? {display: 'none', zIndex: 99999} : {zIndex: 99999}}>
                {actions
                    .filter((action) => !isNil(action))
                    .map(({title, onClick, icon}, index) => (
                        <div
                            className="option"
                            onClick={onClick}
                            key={index}>
                            {title} <div className="option-icon">{icon}</div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default DatatableActions
