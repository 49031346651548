import React, {useEffect} from 'react'

import {isEmpty, omitBy, values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../../redux/modals'

import {Modal} from '../../../../../../components/Modal'
import {Button} from '../../../../../../components/Button'

import './RemoveOrDeleteContractModal.scss'

export const RemoveOrDeleteContractModal = ({
    open,
    contractID,
    project,
    contract,
    isLoading,
    retrieveContract,
    updateContract,
    deleteContract,
    closeModal
}) => {
    useEffect(() => {
        if (contractID) retrieveContract(contractID)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractID])

    const handleRemoveFromProject = () =>
        updateContract(
            {
                projects_ids: values(omitBy(contract.projects_ids, (id) => id === project.id))
            },
            contractID
        )

    return (
        <Modal
            open={open && !isEmpty(contract) && contract.id === contractID}
            title={contract?.projects?.length > 1 ? 'Elimină sau șterge contract' : 'Șterge contract'}>
            {contract?.projects?.length > 1 ? (
                <div className="delete-modal">
                    <p className="message">
                        Vrei să elimini contractul <span>{contract.name}</span> din proiectul{' '}
                        <span>{project.name}</span> sau vrei să ștergi <span>definitiv</span> contractul?
                    </p>
                    <div className="remove-or-delete-contract-modal-buttons-container">
                        <div className="top-buttons">
                            <Button
                                title="Șterge DEFINITIV"
                                onClick={() => deleteContract(contract)}
                                loading={isLoading}
                                variant="text"
                                color="red"
                                fullWidth
                            />
                            <Button
                                title="Elimină din proiect"
                                onClick={handleRemoveFromProject}
                                loading={isLoading}
                                color="red"
                                fullWidth
                            />
                        </div>
                        <Button
                            title="Nu, renunță"
                            onClick={closeModal}
                            loading={isLoading}
                            variant="outlined"
                            color="gray"
                            fullWidth
                        />
                    </div>
                </div>
            ) : (
                <div className="delete-modal">
                    <p className="message">
                        Ești sigur că vrei să ștergi contractul "<span>{contract.name}</span>"?
                    </p>
                    <div className="buttons-container">
                        <Button
                            title="Nu, renunță"
                            onClick={closeModal}
                            variant="outlined"
                            loading={isLoading}
                            color="gray"
                        />
                        <Button
                            title="Da, șterge"
                            onClick={() => deleteContract(contract)}
                            loading={isLoading}
                            color="red"
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.REMOVE_OR_DELETE_CONTRACT,
    project: state.projects.currentProject,
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    retrieveContract: (contractID) => dispatch(RESOURCES.contracts.retrieve(contractID)),
    updateContract: (contractData, contractID) => dispatch(RESOURCES.contracts.update(contractData, contractID)),
    deleteContract: (contract) => dispatch(RESOURCES.contracts.destroy(contract))
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoveOrDeleteContractModal)
