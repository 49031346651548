export enum EventType {
    TASK = 'task',
    HEARING = 'hearing'
}

export interface Event {
    id: number | undefined
    title: string
    url: string
    start: string | null | undefined
    end: string | null | undefined
    allDay: boolean | null | undefined
    color: string
    extendedProps: {eventType: EventType}
}
