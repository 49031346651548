import React from 'react'
import {Link} from 'react-router-dom'

import './ViewDetailsLink.scss'

export default function ViewDetailsLink({to}) {
    return (
        <Link
            to={to}
            className="view-details-link">
            Vizualizare
        </Link>
    )
}
