import React, {useEffect} from 'react'

import _ from 'lodash'
import './Dashboard.scss'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {pastTasksFilters, todayTasksFilters} from './constants'
import {ClockCard, TasksCard, TimeCards, ContractStats} from './partials'

import './Dashboard.scss'
import InvoicesStats from './partials/InvoicesStats/InvoicesStats'

export const Dashboard = ({
    user,
    retrieveUser,
    selectedEntityID
}: {
    user: any
    retrieveUser: any
    selectedEntityID: number
}) => {
    useEffect(() => {
        if (_.isEmpty(user)) {
            retrieveUser()
        }
    }, [retrieveUser, user])

    return (
        <div className="dashboard-container">
            <ClockCard name={user?.first_name} />
            <TimeCards />
            <div className="dashboard-card-container stats-card">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Sumar administrativ</h3>
                <ContractStats selectedEntityID={selectedEntityID} />
                <InvoicesStats selectedEntityID={selectedEntityID} />
            </div>
            <TasksCard
                title="Sarcinile tale de astăzi"
                filters={todayTasksFilters}
            />
            <TasksCard
                title="Sarcinile tale nefinalizate, din trecut"
                filters={pastTasksFilters}
            />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.users.user,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    retrieveUser: () => dispatch(RESOURCES.users.retrieve('me'))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
