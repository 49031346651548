import {Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'

import {debounce} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters} from '../../../../redux/filters/filters'
import {closeSlideover} from '../../../../redux/slideovers'

import {debounceWait} from '../../../../utils/constants'

import {Button} from '../../../../components/Button'
import {ErrorsList} from '../../../../components/ErrorComponents'
import {Select} from '../../../../components/Select'

import {performRequest} from 'avoapp-react-common/dist/redux/api'

import '../../../../assets/scss/SlideoverForms.scss'

const FilterProformasForm = ({
    isLoading,
    fieldErrors,
    nonFieldErrors,
    addFilters,
    selectedEntityID,
    filterProformas,
    closeSlideover
}) => {
    const [isLoadingClients, setIsLoadingClients] = useState(false)
    const [clients, setClients] = useState([])

    const handleFilter = (filters) => {
        addFilters(filters)
        filterProformas()
        closeSlideover()
    }

    const handleFetchClients = async (search) => {
        setIsLoadingClients(true)
        const {data} = await performRequest(RESOURCES.clients.list({entity_id: selectedEntityID, search: search}))
        setClients(data.results)
        setIsLoadingClients(false)
    }

    const debounceSearchClients = debounce(handleFetchClients, debounceWait)

    useEffect(() => {
        handleFetchClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="slideover-form-container">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{client: null}}
                onSubmit={(values) => {
                    const filtersData = {
                        client: {
                            value: values.client?.id || '',
                            displayValue: values.client?.name || ''
                        }
                    }

                    handleFilter(filtersData)
                }}>
                {({setFieldValue, handleSubmit, values}) => (
                    <>
                        <Form className="slideover-form">
                            <Select
                                label="Client"
                                value={values.client}
                                options={clients}
                                onChange={(e) => setFieldValue('client', e)}
                                onInputChange={(value) => {
                                    debounceSearchClients(value)
                                }}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                loading={isLoadingClients}
                                name="clients"
                                errors={fieldErrors}
                                fullWidth
                            />
                        </Form>
                        <div className="buttons-container">
                            <Button
                                title="Aplică"
                                onClick={handleSubmit}
                                loading={isLoading}
                                type="submit"
                                fullWidth
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => ({
    filters: state.filters.proformas,
    isLoading: state.proformas.isLoading,
    fieldErrors: state.proformas.fieldErrors,
    nonFieldErrors: state.proformas.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.proformas.name, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterProformasForm)
