import React, {useCallback, useMemo} from 'react'

import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/solid'
import _ from 'lodash'

import {Notification} from 'types/api'

// @ts-ignore
import {connect} from 'react-redux'
// @ts-ignore
import {slideoverTypes} from '../../../../redux/slideovers'

// @ts-ignore
import {Slideover} from '../../../../components/Slideover'
// @ts-ignore
import {Button} from '../../../../components/Button'

type BPINotificationDetailsProps = {
    open: boolean
    notification: Notification
}

export const BPINotificationDetails = ({open, notification}: BPINotificationDetailsProps) => {
    const notificationData = useMemo(() => {
        return notification.data as any
    }, [notification?.data])

    const buttonIconRenderer = useCallback(() => {
        return <ArrowTopRightOnSquareIcon />
    }, [])

    if (_.isNil(notificationData)) {
        return <React.Fragment />
    }

    return (
        <Slideover
            open={open && !_.isEmpty(notification)}
            title={notification.subject}>
            <div className="notification-details-container">
                <div className="notification-details-texts-container">
                    <p className="notification-details-changes-text">Mesaj</p>
                    <p className="notification-details-message">{notificationData.text_shortened}</p>
                </div>
                <a
                    href={notificationData.document?.file_url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Button
                        title="Vezi documentul"
                        icon={buttonIconRenderer}
                        className="button-cta"
                        fullWidth
                    />
                </a>
            </div>
        </Slideover>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.slideovers.type === slideoverTypes.NOTIFICATION_DETAILS,
    notification: state.notifications.currentNotification
})

export default connect(mapStateToProps)(BPINotificationDetails)
