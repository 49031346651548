import React, {useEffect, useState} from 'react'
import {Form, Formik, useFormikContext} from 'formik'

import {isEmpty, omit, pick, capitalize, values as _values, find} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {slideoverTypes} from '../../../../../redux/slideovers'

import {objectKeysToSnakeCase, splitStringIntoNames} from '../../../../../utils'
import {contactPersonsSchema} from '../../../../../assets/validations'

import {Input} from '../../../../../components/Input'
import {Button} from '../../../../../components/Button'
import {Slideover} from '../../../../../components/Slideover'
import {Creatable} from '../../../../../components/Creatable'
import {PhoneInput} from '../../../../../components/PhoneInput'
import {DatePicker} from '../../../../../components/DatePicker'
import {ErrorsList} from '../../../../../components/ErrorComponents'
import {RequiredFieldsText} from '../../../../../components/RequiredFieldsText'

import '../../../../../assets/scss/SlideoverForms.scss'
import './ContactPersonsSlideovers.scss'

export const AddContactPersonSlideover = ({
    open,
    selectedEntityID,
    project,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    persons,
    isLoadingPersons,
    createContactPerson,
    listAllPersons,
    personRoles,
    currentPersonRole,
    isLoadingPersonRoles,
    createPersonRole,
    listAllPersonRoles
}) => {
    const [createNew, setCreateNew] = useState(false)

    useEffect(() => {
        listAllPersons(selectedEntityID)
        listAllPersonRoles(selectedEntityID)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCreateNewPerson = (newPersonName, setFieldValue, setFieldTouched) => {
        const names = splitStringIntoNames(newPersonName)
        const lastName = names.lastName
        const firstName = names.firstName

        setFieldValue('lastName', capitalize(lastName))
        setFieldTouched('lastName', true)

        setFieldValue('firstName', capitalize(firstName))
        setFieldTouched('firstName', true)

        setCreateNew(true)
    }

    const AutoSelectRole = () => {
        const {setFieldValue} = useFormikContext()

        useEffect(() => {
            if (!isEmpty(currentPersonRole)) {
                setFieldValue('roleId', find(personRoles, (role) => role.value === currentPersonRole.id) || null)
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentPersonRole])

        return null
    }

    return (
        <Slideover
            open={open}
            title="Adaugă persoană de contact">
            <div className="slideover-form-container">
                <ErrorsList errors={nonFieldErrors} />
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        projectID: project.id,

                        //
                        person: null,
                        nationalIdNumber: '',
                        birthdate: new Date(),
                        roleId: null,
                        receiveMdNotifications: false,
                        receiveBpiNotifications: false
                    }}
                    validationSchema={createNew ? contactPersonsSchema : null}
                    onSubmit={(values) => {
                        if (createNew) {
                            const newPersonData = objectKeysToSnakeCase({
                                ...omit(values, 'person'),
                                phone: values.phone ? `+${values.phone}` : '',
                                role_id: values.roleId?.value || null
                            })

                            createContactPerson(newPersonData)
                        } else {
                            const existingPersonData = objectKeysToSnakeCase({
                                ...pick(values, ['projectID', 'receiveMdNotifications', 'receiveBpiNotifications']),
                                person_id: values.person.value
                            })

                            createContactPerson(existingPersonData)
                        }
                    }}>
                    {({
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        values,
                        resetForm,
                        touched,
                        errors,
                        isValid
                    }) => (
                        <>
                            <Form className="slideover-form">
                                {!createNew ? (
                                    <>
                                        <p className="form-description-text">
                                            Selectează sau creează o persoană ca persoană de contact
                                        </p>
                                        <Creatable
                                            label="Persoană"
                                            placeholder="Scrie aici numele persoanei..."
                                            value={values.person}
                                            options={persons}
                                            onChange={(e) => setFieldValue('person', e)}
                                            onCreateNew={(newPersonName) =>
                                                handleCreateNewPerson(newPersonName, setFieldValue, setFieldTouched)
                                            }
                                            loading={isLoadingPersons}
                                            fullWidth
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Input
                                            label="Nume*"
                                            value={values.lastName}
                                            onChange={handleChange('lastName')}
                                            onBlur={handleBlur('lastName')}
                                            name="lastName"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.lastName}
                                            fullWidth
                                        />
                                        <Input
                                            label="Prenume*"
                                            value={values.firstName}
                                            onChange={handleChange('firstName')}
                                            onBlur={handleBlur('firstName')}
                                            name="firstName"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.firstName}
                                            fullWidth
                                        />
                                        <Creatable
                                            label="Rol în client"
                                            value={values.roleId}
                                            options={personRoles}
                                            onChange={(e) => setFieldValue('roleId', e)}
                                            onBlur={handleBlur('roleId')}
                                            onCreateNew={(roleName) =>
                                                createPersonRole({name: roleName, entity_id: selectedEntityID})
                                            }
                                            name="roleId"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.roleId}
                                            loading={isLoadingPersonRoles}
                                            isClearable
                                            fullWidth
                                        />
                                        <AutoSelectRole />
                                        <DatePicker
                                            label="Data nașterii"
                                            value={values.birthdate}
                                            onChange={(value) => setFieldValue('birthdate', new Date(value))}
                                            name="birthdate"
                                            errors={fieldErrors}
                                            fullWidth
                                        />
                                        <Input
                                            label="CNP"
                                            value={values.nationalIdNumber}
                                            onChange={handleChange('nationalIdNumber')}
                                            name="nationalIdNumber"
                                            errors={fieldErrors}
                                            fullWidth
                                        />
                                        <Input
                                            label="Email"
                                            value={values.email}
                                            onChange={handleChange('email')}
                                            onBlur={handleBlur('email')}
                                            name="email"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.email}
                                            fullWidth
                                        />
                                        <PhoneInput
                                            label="Telefon"
                                            value={values.phone}
                                            onChange={handleChange('phone')}
                                            name="phone"
                                            errors={fieldErrors}
                                            fullWidth
                                        />
                                        <RequiredFieldsText />
                                        <div className="secondary-button-container">
                                            <Button
                                                title="Vreau să aleg o persoană existentă"
                                                onClick={() => {
                                                    setCreateNew(false)
                                                    resetForm()
                                                }}
                                                disableTabSelect
                                                variant="outlined"
                                                size="small"
                                            />
                                        </div>
                                    </>
                                )}
                            </Form>
                            <div className="buttons-container">
                                <Button
                                    title="Adaugă persoană de contact"
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    disabled={!isValid && createNew}
                                    type="submit"
                                    fullWidth
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </Slideover>
    )
}

const mapStateToProps = (state) => {
    const persons = _values(state.persons.data).map((person) => ({
        value: person.id,
        label: `${person.first_name} ${person.last_name}`
    }))

    const personRoles = _values(state.personRoles.data).map((role) => ({value: role.id, label: role.name}))

    return {
        open: state.slideovers.type === slideoverTypes.ADD_CONTACT_PERSON,
        selectedEntityID: state.localConfigs.selectedEntityID,
        project: state.projects.currentProject,
        persons: persons,
        isLoadingPersons: state.persons.isLoading,
        isLoading: state.contactPersons.isLoading,
        fieldErrors: state.contactPersons.fieldErrors,
        nonFieldErrors: state.contactPersons.nonFieldErrors,
        personRoles: personRoles,
        currentPersonRole: state.personRoles.currentPersonRole,
        isLoadingPersonRoles: state.personRoles.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    listAllPersons: (entityID) => dispatch(RESOURCES.persons.listAll({entity_id: entityID})),
    createContactPerson: (contactPersonData) => dispatch(RESOURCES.contactPersons.create(contactPersonData)),
    listAllPersonRoles: (entityID) => dispatch(RESOURCES.personRoles.listAll({entity_id: entityID})),
    createPersonRole: (personRoleData) => dispatch(RESOURCES.personRoles.create(personRoleData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddContactPersonSlideover)
