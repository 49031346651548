import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {endOfWeek, format, nextMonday, previousMonday, startOfWeek} from 'date-fns'
import {ro} from 'date-fns/locale'
import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {calendarViewTypes} from '../../constants'

import {AddTaskTimeLogModal} from '../../../../components/AddTaskTimeLogModal'
import {DeleteTaskTimeLogModal} from '../../../../components/DeleteTaskTimeLogModal'
import {EditTaskTimeLogModal} from '../../../../components/EditTaskTimeLogModal'
import {Select} from '../../../../components/Select'

import {CalendarDayView, CalendarMonthView, CalendarPagination, CalendarWeekView} from './partials'

import './TaskTimeLogs.scss'
import {toApiDateFormat} from '../../../../utils'

export const TaskTimeLogs = ({
    match: {
        params: {secondaryAction: calendarViewType}
    },
    user,
    entityProfiles,
    selectedEntityID,
    listEntityProfiles,
    listTimeLogs,
    openModal
}) => {
    const [selectedDay, setSelectedDay] = useState(new Date())
    const [dayToAdd, setDayToAdd] = useState(new Date())
    const [selectedLog, setSelectedLog] = useState(null)

    useEffect(() => {
        listEntityProfiles()
    }, [listEntityProfiles])

    const initialSelectedEntityProfile = useMemo(() => {
        if (!_.isEmpty(entityProfiles)) {
            const test = _.find(entityProfiles, (profile) => _.toString(profile.user_id) === _.toString(user.id))
            return test
        }

        return undefined
    }, [entityProfiles, user.id])

    const [selectedEntityProfile, setSelectedEntityProfile] = useState(initialSelectedEntityProfile)

    useEffect(() => {
        if (_.isNil(selectedEntityProfile) || _.isEmpty(selectedEntityProfile)) {
            setSelectedEntityProfile(initialSelectedEntityProfile)
        }
    }, [initialSelectedEntityProfile, selectedEntityProfile])

    const handleFetchData = useCallback(() => {
        if (
            !_.isNil(selectedEntityID) &&
            !_.isEmpty(_.toString(selectedEntityID)) &&
            !_.isNil(selectedEntityProfile) &&
            !_.isEmpty(_.toString(selectedEntityProfile))
        ) {
            switch (calendarViewType) {
                case calendarViewTypes.WEEK_VIEW:
                    listTimeLogs({
                        entity_id: selectedEntityID,
                        owner_id: selectedEntityProfile.id,
                        date__gte: toApiDateFormat(startOfWeek(selectedDay, {weekStartsOn: 1})),
                        date__lte: toApiDateFormat(endOfWeek(selectedDay, {weekStartsOn: 1}))
                    })
                    break
                case calendarViewTypes.DAY_VIEW:
                    return console.log('fetch day')
                case calendarViewTypes.MONTH_VIEW:
                    return console.log('fetch month')
                default:
                    return null
            }
        }

        return null
    }, [calendarViewType, selectedDay, listTimeLogs, selectedEntityID, selectedEntityProfile])

    useEffect(() => {
        handleFetchData()
    }, [handleFetchData])

    const getCalendar = () => {
        switch (calendarViewType) {
            case calendarViewTypes.WEEK_VIEW:
                return (
                    <CalendarWeekView
                        selectedDay={selectedDay}
                        onSelectDay={setSelectedDay}
                        onClickLog={(task) => {
                            setSelectedLog(task)
                            openModal(modalTypes.EDIT_TASK_TIME_LOG)
                        }}
                        onAddLog={(date) => {
                            setDayToAdd(new Date(date))
                            openModal(modalTypes.ADD_TASK_TIME_LOG)
                        }}
                    />
                )
            case calendarViewTypes.DAY_VIEW:
                return <CalendarDayView />
            case calendarViewTypes.MONTH_VIEW:
                return <CalendarMonthView />
            default:
                return
        }
    }

    const handleOnToday = () => setSelectedDay(new Date())

    const handleOnNext = (params) => {
        switch (calendarViewType) {
            case calendarViewTypes.WEEK_VIEW:
                return nextWeek(params)
            case calendarViewTypes.DAY_VIEW:
                return console.log('next day')
            case calendarViewTypes.MONTH_VIEW:
                return console.log('next month')
            default:
                return
        }
    }

    const handleOnPrevious = (params) => {
        switch (calendarViewType) {
            case calendarViewTypes.WEEK_VIEW:
                return previousWeek(params)
            case calendarViewTypes.DAY_VIEW:
                return console.log('prev day')
            case calendarViewTypes.MONTH_VIEW:
                return console.log('prev month')
            default:
                return
        }
    }

    const nextWeek = () => setSelectedDay(nextMonday(new Date(selectedDay)))

    const previousWeek = () => setSelectedDay(previousMonday(new Date(selectedDay)))

    return (
        <div className="task-time-logs-page-container">
            <header className="task-time-logs-page-header">
                <div className="flex items-center gap-2">
                    <h1 className="selected-date">{format(new Date(selectedDay), 'MMMM yyyy', {locale: ro})}</h1>
                    {!_.isEmpty(entityProfiles) && (
                        <Select
                            value={selectedEntityProfile}
                            options={entityProfiles}
                            onChange={(profile) => setSelectedEntityProfile(profile)}
                            getOptionLabel={(profile) => `${profile.first_name} ${profile.last_name}`}
                            getOptionValue={(profile) => profile.id}
                        />
                    )}
                </div>
                <div className="flex items-center">
                    <CalendarPagination
                        onPrevious={handleOnPrevious}
                        onToday={handleOnToday}
                        onNext={handleOnNext}
                    />
                    {/* <CalendarViewSelect calendarViewType={calendarViewType} onToday={handleOnToday} /> */}
                </div>
            </header>
            <div className="calendar-container">
                {getCalendar()}
                <AddTaskTimeLogModal dayToAdd={dayToAdd} />
                <EditTaskTimeLogModal selectedLog={selectedLog} />
                <DeleteTaskTimeLogModal selectedLog={selectedLog} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.users.user,
    entityProfiles: _.values(state.entityProfiles.data),
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    listEntityProfiles: () => dispatch(RESOURCES.entityProfiles.list()),
    listTimeLogs: (params) => dispatch(RESOURCES.taskTimeLogs.list(params)),
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskTimeLogs)
