import {getDocumentTypes} from '../Settings/constants'

export const actionIds = {
    INFO: 'info',
    SETTINGS: 'settings',
    PREVIEW: 'preview',
    SIGNED: 'signed',
    ADDENDUMS: 'addendums',
    EMPOWERMENTS: 'empowerments'
}

export const actions = {
    [actionIds.INFO]: {
        id: actionIds.INFO,
        label: 'Informații'
    },
    [actionIds.SETTINGS]: {
        id: actionIds.SETTINGS,
        label: 'Setări contract'
    },
    [actionIds.PREVIEW]: {
        id: actionIds.PREVIEW,
        label: 'Document Editabil'
    },
    [actionIds.SIGNED]: {
        id: actionIds.SIGNED,
        label: 'Contract semnat'
    },
    [actionIds.ADDENDUMS]: {
        id: actionIds.ADDENDUMS,
        label: 'Acte adiționale'
    },
    [actionIds.EMPOWERMENTS]: {
        id: actionIds.EMPOWERMENTS,
        label: 'Împuterniciri'
    }
}

export const documentTemplateTypes = getDocumentTypes()
