import React, {useCallback, useMemo} from 'react'

import {TrashIcon} from '@heroicons/react/24/outline'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {setCurrent as setCurrentSubscription} from 'avoapp-react-common/dist/redux/subscriptions'
import {connect} from 'react-redux'
// @ts-ignore
import {modalTypes, openModal} from '../../../redux/modals'

import {Cell} from 'types/local'
import {useQueryResourceList} from '../../../queries/rest'
// @ts-ignore
import {useDebouncedState} from '../../../utils/hooks'
// @ts-ignore
import {sourceTypes} from '../../../utils/types'

// @ts-ignore
import {Button} from '../../../components/Button'
// @ts-ignore
import {Datatable} from '../../../components/Datatable'
// @ts-ignore
import {DeleteSubscriptionModal} from '../../../components/DeleteSubscriptionModal'
import {Link} from 'react-router-dom'
import {TableLink} from '../../../components/TableLink'
import {AddMDSubscriptionModal} from '../../../components/AddMDSubscriptionModal'
import {AddMDManualSubscriptionModal} from '../../../components/AddMDManualSubscriptionModal'

interface MonitorDosareDatatableProps {
    openModal: (modalType: string) => void
    openedModal: string
    selectedEntityID: string
    setCurrentSubscription: (subscription: any) => void
}

export const MonitorDosareDatatable = ({
    openModal,
    openedModal,
    selectedEntityID,
    setCurrentSubscription
}: MonitorDosareDatatableProps) => {
    const [subscriptionsSearchFilter, setSubscriptionsSearchFilter] = useDebouncedState('', 300)
    const [subscriptionsPage, setSubscriptionsPage] = React.useState<number>(1)

    const subscriptionsFilters = useMemo(
        () => ({
            search: subscriptionsSearchFilter,
            entity_id: selectedEntityID,
            page_size: 15,
            page: subscriptionsPage
        }),
        [subscriptionsPage, subscriptionsSearchFilter, selectedEntityID]
    )

    const {data: subRes, isFetching: isFetchingSubscriptions} = useQueryResourceList(
        RESOURCES.subscriptions,
        subscriptionsFilters,
        undefined,
        //we want to refetch the data when the delete modal closes
        _.isNil(openedModal).toString()
    )

    const subscriptionsResult = useMemo(() => {
        // To make typescript happy
        return subRes as any
    }, [subRes])

    const handleChangeSubscriptionsSearchField = (value: string) => setSubscriptionsSearchFilter(value)

    const handleChangeSubscriptionsPage = useCallback((newPage: number) => {
        setSubscriptionsPage(newPage)
    }, [])

    const subscriptionsColumns = useMemo(() => {
        return [
            {
                Header: 'Număr dosar',
                accessor: 'litigation_number',
                Cell: ({value, row: {original}}: Cell) => (
                    <TableLink to={`/external-data/monitor-dosare/${original.id}`}>{value}</TableLink>
                )
            },
            {
                Header: 'Sursa',
                accessor: 'source',
                Cell: ({value: source}: Cell) => sourceTypes[_.toUpper(source)] || '-'
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value}: Cell) => <TableLink to={`/projects/${value?.id}`}>{value?.name}</TableLink>
            },
            {
                Header: 'Clienți',
                accessor: 'project.clients',
                Cell: ({value}: Cell) => (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {value.map((client: any, index: number) => (
                            <>
                                <TableLink to={`/clients/${client.id}`}>{client.name}</TableLink>
                                {index + 1 !== value.length && ', '}
                            </>
                        ))}
                    </div>
                )
            },
            {
                Header: 'Ultimul update',
                accessor: 'latest_update',
                Cell: ({value: latestUpdate}: Cell) =>
                    !_.isNil(latestUpdate) ? lightFormat(new Date(latestUpdate), 'dd/MM/yyyy HH:mm') : '-'
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({value: subscriptionID, row: {original: subscription}}: Cell) => (
                    <div className="flex gap-2 items-center">
                        <div className="datatable-row-buttons-container">
                            <Link to={`/external-data/monitor-dosare/${subscriptionID}`}>
                                <Button
                                    title="Vizualizare"
                                    size="small"
                                />
                            </Link>
                            <Button
                                icon={() => <TrashIcon />}
                                onClick={() => {
                                    setCurrentSubscription(subscription)
                                    openModal(modalTypes.DELETE_SUBSCRIPTION)
                                }}
                                size="small"
                                color="red"
                            />
                        </div>
                    </div>
                )
            }
        ]
    }, [openModal, setCurrentSubscription])

    return (
        <div className="page-external-data-show">
            <Datatable
                title="Listă dosare monitorizate"
                data={subscriptionsResult?.data.results || []}
                headerButton={() => (
                    <Button
                        title="Adaugă abonare"
                        onClick={() => openModal(modalTypes.ADD_MONITOR_DOSARE_SUBSCRIPTION)}
                        color="secondary"
                    />
                )}
                columns={subscriptionsColumns}
                loading={isFetchingSubscriptions}
                previousPage={subscriptionsResult?.data?.pages?.previous}
                currentPage={subscriptionsResult?.data?.page}
                nextPage={subscriptionsResult?.data?.pages?.next}
                totalPages={subscriptionsResult?.data?.number_of_pages}
                onChangePage={(page: number) => handleChangeSubscriptionsPage(page)}
                searchable
                searchPlaceholder="Caută surse de date"
                onSearch={(event: any) => handleChangeSubscriptionsSearchField(event.target.value)}
            />
            <AddMDSubscriptionModal />
            <AddMDManualSubscriptionModal />
            <DeleteSubscriptionModal />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    openedModal: state.modals.type,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    openModal: (modalType: string) => dispatch(openModal(modalType)),
    setCurrentSubscription: (subscription: any) => dispatch(setCurrentSubscription(subscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(MonitorDosareDatatable)
