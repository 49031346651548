import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../../../../redux/modals'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

function InactivateClientModal({open, client, closeModal, updateClientActiveStatus}) {
    return (
        <Modal
            open={open && !isEmpty(client)}
            title="Marchează clientul ca inactiv">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să marchezi ca inactiv clientul <span>"{client.name}"</span>?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        color="gray"
                    />
                    <Button
                        title="Da, marchează ca inactiv"
                        onClick={() => updateClientActiveStatus(client)}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.MARK_CLIENT_INACTIVE,
    client: state.clients.currentClient
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    updateClientActiveStatus: (client) => dispatch(RESOURCES.clients.update({active: false}, client.id))
})

export default connect(mapStateToProps, mapDispatchToProps)(InactivateClientModal)
