import React, {Component} from 'react'

import {PlusIcon} from '@heroicons/react/24/solid'
import {Link} from 'react-router-dom'

import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../redux/modals'

import {Button} from '../../components/Button'
import {Loader} from '../../components/Loader'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {actionIds, actions, prefix} from './constants'
import {InvoicePaymentsList, InvoicesList, ProformasList} from './partials'

import './Invoices.scss'

export class Invoices extends Component {
    render() {
        const {match, isLoadingInvoices, isLoadingProformas, openModal} = this.props
        const urlData = {prefix: prefix, params: match.params}

        switch (match.params.primaryAction) {
            case actionIds.INVOICES:
                return (
                    <div>
                        <div className="w-full min-w-full flex flex-row justify-between items-center">
                            <div className="flex flex-row items-center gap-4">
                                <SecondaryNavbar
                                    actions={actions}
                                    urlData={urlData}
                                />
                                <span className="pb-2">{isLoadingInvoices && <Loader />}</span>
                            </div>

                            <Link to="/invoices/add">
                                <Button
                                    title="Adaugă factură"
                                    icon={() => <PlusIcon />}
                                    color="secondary"
                                />
                            </Link>
                        </div>
                        <div className="page-info">
                            <InvoicesList />
                        </div>
                    </div>
                )
            case actionIds.PROFORMAS:
                return (
                    <div>
                        <div className="w-full min-w-full flex flex-row justify-between items-center">
                            <div className="flex flex-row items-center gap-4">
                                <SecondaryNavbar
                                    actions={actions}
                                    urlData={urlData}
                                />
                                <span className="pb-2">{isLoadingProformas && <Loader />}</span>
                            </div>

                            <Link to="/proformas/add">
                                <Button
                                    title="Adaugă factură"
                                    icon={() => <PlusIcon />}
                                    color="secondary"
                                />
                            </Link>
                        </div>
                        <div className="page-info">
                            <ProformasList />
                        </div>
                    </div>
                )
            case actionIds.PAYMENTS:
                return (
                    <div>
                        <div className="w-full min-w-full flex flex-row justify-between items-center">
                            <div className="flex flex-row items-center gap-4">
                                <SecondaryNavbar
                                    actions={actions}
                                    urlData={urlData}
                                />
                                <span className="pb-2">{isLoadingInvoices && <Loader />}</span>
                            </div>
                            <Button
                                title="Adaugă încasare"
                                icon={() => <PlusIcon />}
                                onClick={() => {
                                    openModal(modalTypes.ADD_INVOICE_PAYMENT)
                                }}
                                color="secondary"
                            />
                        </div>
                        <div className="page-info">
                            <InvoicePaymentsList />
                        </div>
                    </div>
                )
            default:
                return
        }
    }
}

const mapStateToProps = (state) => ({
    isLoadingInvoices: state.invoices.isLoading,
    isLoadingProformas: state.proformas.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
