import React, {Component} from 'react'

import {debounce, values, isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'
import {openSlideover, slideoverTypes} from '../../../../redux/slideovers'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {setCurrent as setCurrentContactPerson} from 'avoapp-react-common/dist/redux/contactPersons'

import {columns} from './constants'
import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DatatableRowButtons} from '../../../../components/DatatableRowButtons'
import {DeleteContactPersonModal} from '../../../../components/DeleteContactPersonModal'
import {EditContactPersonSlideover} from '../../../../components/EditContactPersonSlideover'

import {AddContactPersonSlideover} from './slideovers'

import './InfoContactPersons.scss'

export class InfoContactPersons extends Component {
    constructor() {
        super()

        this.state = {
            searchFilter: ''
        }
    }

    componentDidMount() {
        const {match, listContactPersons} = this.props
        const {projectID} = match.params

        listContactPersons(projectID)
    }

    debounceSearchContactPersons = debounce((value) => {
        const {project, listContactPersons} = this.props

        listContactPersons(project.id, value)
    }, debounceWait)

    handleChangeSearchField = (value) => {
        this.setState({searchFilter: value})
        this.debounceSearchContactPersons(value)
    }

    handleChangePage = (page) => {
        const {project, isLoadingContactPersons, listContactPersons} = this.props
        const {searchFilter} = this.state

        if (!isLoadingContactPersons) {
            listContactPersons(project.id, searchFilter, page)
        }
    }

    render() {
        const {searchFilter} = this.state
        const {
            contactPersons,
            isLoadingContactPersons,
            totalPages,
            nextPage,
            previousPage,
            currentPage,
            setCurrentContactPerson,
            openDeleteContactPersonModal,
            openSlideover
        } = this.props

        return (
            <div className="page-project-show">
                <Datatable
                    title="Listă persoane de contact"
                    data={contactPersons}
                    columns={[
                        ...columns,
                        {
                            Header: 'Acțiuni',
                            accessor: 'id',
                            Cell: ({row}) => (
                                <DatatableRowButtons
                                    mainButtonTitle="Editează"
                                    onMainButtonClick={() => {
                                        setCurrentContactPerson(row.original)
                                        openSlideover(slideoverTypes.EDIT_CONTACT_PERSON)
                                    }}
                                    onDeleteButtonClick={() => {
                                        setCurrentContactPerson(row.original)
                                        openDeleteContactPersonModal()
                                    }}
                                />
                            ),
                            disableSortBy: true
                        }
                    ]}
                    loading={isLoadingContactPersons}
                    headerButton={() => (
                        <Button
                            title="Adaugă"
                            color="secondary"
                            onClick={() => openSlideover(slideoverTypes.ADD_CONTACT_PERSON)}
                        />
                    )}
                    totalPages={totalPages}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    currentPage={currentPage}
                    onChangePage={(page) => this.handleChangePage(page)}
                    searchable
                    searchValue={searchFilter}
                    searchPlaceholder="Caută clienți"
                    onSearch={(event) => this.handleChangeSearchField(event.target.value)}
                    emptyText={isEmpty(searchFilter) && 'Nu există nicio persoană de contact pentru acest project'}
                />
                <DeleteContactPersonModal />
                <AddContactPersonSlideover />
                <EditContactPersonSlideover />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    project: state.projects.currentProject,
    contactPersons: values(state.contactPersons.data),
    isLoadingContactPersons: state.contactPersons.isLoading,
    totalPages: state.contactPersons.totalPages,
    nextPage: state.contactPersons.next,
    previousPage: state.contactPersons.previous,
    currentPage: state.contactPersons.current
})

const mapDispatchToProps = (dispatch) => ({
    openSlideover: (slideoverType) => dispatch(openSlideover(slideoverType)),
    openDeleteContactPersonModal: () => dispatch(openModal(modalTypes.DELETE_CONTACT_PERSON)),
    setCurrentContactPerson: (contactPerson) => dispatch(setCurrentContactPerson(contactPerson)),
    listContactPersons: (projectID, search = '', page = 1) =>
        dispatch(
            RESOURCES.contactPersons.list(
                {
                    project_id: projectID,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoContactPersons)
