import React from 'react'
import {Form, Formik} from 'formik'
import {Link} from 'react-router-dom'

import {connect} from 'react-redux'
import {login} from 'avoapp-react-common/dist/redux/auth'

import {authSchema} from '../../assets/validations'

import {Input} from '../../components/Input'
import {Button} from '../../components/Button'
import {NoAccount} from '../../components/NoAccount'
import {ErrorsList} from '../../components/ErrorComponents'

import logo from '../../assets/images/avoapp-logo-albastru.png'

import './Login.scss'
import OTPLoginModal from './OTPLoginModal'
import {getFingerprint} from '@thumbmarkjs/thumbmarkjs'

export const Login = ({isLoading, isLoadingEntityProfiles, fieldErrors, nonFieldErrors, login}) => {
    return (
        <div id="login-container">
            <OTPLoginModal />
            <div className="login-left">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="logo"
                            src={logo}
                            alt="AvoApp"
                        />
                        <h2 className="title">Intră în contul tău AvoApp</h2>
                    </div>
                    <div className="mt-12">
                        <ErrorsList errors={nonFieldErrors} />
                        <Formik
                            initialValues={{email: '', password: ''}}
                            validationSchema={authSchema.login}
                            onSubmit={async (values) => {
                                const fingerprint = await getFingerprint()

                                const data = {
                                    ...values,
                                    ...(fingerprint && {
                                        device: {
                                            app: 'avoapp',
                                            unique_id: fingerprint,
                                            name: navigator.userAgent,
                                            os: 'web'
                                        }
                                    })
                                }

                                login(data)
                            }}>
                            {({handleChange, handleBlur, values, handleSubmit, errors, touched}) => (
                                <Form className="space-y-6">
                                    <Input
                                        label="Adresa de email"
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        name="email"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.email}
                                        autoComplete="email"
                                        type="email"
                                        dataTest="loginUserEmail"
                                        fullWidth
                                    />
                                    <Input
                                        label="Parola"
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        onBlur={handleBlur('password')}
                                        name="password"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.password}
                                        autoComplete="password"
                                        type="password"
                                        dataTest="loginUserPassword"
                                        fullWidth
                                    />
                                    <div className="forgot-password-component-container">
                                        <div className="text-sm">
                                            <Link
                                                to="/forgot-password"
                                                className="forgot-password-link">
                                                Am uitat parola. Vreau să o resetez.
                                            </Link>
                                        </div>
                                    </div>
                                    <Button
                                        title="Autentificare"
                                        onClick={handleSubmit}
                                        loading={isLoading || isLoadingEntityProfiles}
                                        fullWidth
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <NoAccount />
            </div>
            <div className="login-right">
                <img
                    // eslint-disable-next-line max-len
                    src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    fieldErrors: state.auth.fieldErrors,
    nonFieldErrors: state.auth.nonFieldErrors,
    isLoading: state.auth.isLoading,
    isLoadingEntityProfiles: state.entityProfiles.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    login: (values) => dispatch(login(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
