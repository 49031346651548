import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {values as _values, debounce} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {debounceWait} from '../../utils/constants'
import {clientGroupsSchema} from '../../assets/validations'

import {Input} from '../../components/Input'
import {Select} from '../../components/Select'
import {FormSection} from '../../components/FormSection'
import {ErrorsList} from '../../components/ErrorComponents'

import './ClientGroup.scss'

export class ClientGroupAdd extends Component {
    constructor() {
        super()

        this.state = {
            clientsQuery: ''
        }
    }

    componentDidMount = () => this.handleFetchClients()

    handleFetchClients = (query = this.state.clientsQuery) => {
        const {searchClients} = this.props

        searchClients(query)
    }

    debounceSearchProjects = debounce(this.handleFetchClients, debounceWait)

    handleChangeProjectsSearchField = (value) => {
        this.setState({clientsQuery: value})
        this.debounceSearchProjects(value)
    }

    render() {
        const {clients, isLoadingClients, isLoading, createClientGroup, selectedEntityID, fieldErrors, nonFieldErrors} =
            this.props

        return (
            <div className="client-group-add-container">
                <Formik
                    initialValues={{name: '', clientIds: []}}
                    validateOnMount
                    validationSchema={clientGroupsSchema}
                    onSubmit={(values) => {
                        const clientData = {
                            name: values.name,
                            client_ids: values.clientIds.map((client) => client.id),
                            entity_id: selectedEntityID
                        }

                        createClientGroup(clientData)
                    }}>
                    {({handleChange, handleBlur, setFieldValue, handleSubmit, values, errors, touched, isValid}) => (
                        <Form>
                            <ErrorsList errors={nonFieldErrors} />
                            <FormSection
                                renderForm={() => (
                                    <>
                                        <p className="form-section-title">Adaugă un grup de clienți</p>
                                        <Input
                                            label="Nume"
                                            value={values.name}
                                            onChange={handleChange('name')}
                                            onBlur={handleBlur('name')}
                                            name="name"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.name}
                                            fullWidth
                                        />
                                        <Select
                                            label="Clienți"
                                            value={values.clientIds}
                                            options={clients}
                                            onChange={(e) => setFieldValue('clientIds', e)}
                                            onInputChange={(value) => this.handleChangeProjectsSearchField(value)}
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => option.name}
                                            onBlur={handleBlur('clientIds')}
                                            name="clientIds"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.clientIds}
                                            loading={isLoadingClients}
                                            isMulti
                                            fullWidth
                                        />
                                    </>
                                )}
                                onSubmit={handleSubmit}
                                submitButtonTitle="Crează grup"
                                buttonDisabled={!isValid}
                                loading={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clients: _values(state.clients.searchData),
    isLoadingClients: state.clients.isLoading,
    isLoading: state.clientGroups.isLoading,
    fieldErrors: state.clientGroups.fieldErrors,
    nonFieldErrors: state.clientGroups.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    searchClients: (search) => dispatch(RESOURCES.clients.search(search)),
    createClientGroup: (values) => dispatch(RESOURCES.clientGroups.create(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientGroupAdd)
