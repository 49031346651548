import {Form, Formik} from 'formik'
import React, {useEffect} from 'react'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {empowermentsSchema} from '../../../../../../../assets/validations'
import {objectKeysToSnakeCase} from '../../../../../../../utils'
import {documentSeriesTypes} from '../../../../../../../utils/types'
import {documentTemplateTypes} from '../../../../../constants'

import {ErrorsList} from '../../../../../../../components/ErrorComponents'
import {FormSection} from '../../../../../../../components/FormSection'
import {Input} from '../../../../../../../components/Input'
import {RequiredFieldsText} from '../../../../../../../components/RequiredFieldsText'
import {Select} from '../../../../../../../components/Select'

export const EditEmpowermentForm = ({
    empowerment,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    selectedEntityID,
    documentSeries,
    isLoadingDocumentSeries,
    documentTemplates,
    isLoadingDocumentTemplates,
    projects,
    isLoadingProjects,
    retrieveProject,
    contract,
    contracts,
    isLoadingContracts,
    retrieveContract,
    updateEmpowerment,
    handleExitEditMode,
    listAllDocumentSeries,
    retrieveDocumentTemplate
}) => {
    useEffect(() => {
        listAllDocumentSeries({entity_id: selectedEntityID})
        retrieveProject(empowerment.project_id)
        retrieveContract(empowerment.contract_id)
        retrieveDocumentTemplate(empowerment.template_id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="empowerment-add-form-container">
            {!_.isEmpty(projects) && !_.isEmpty(contracts) && !_.isEmpty(documentTemplates) && (
                <Formik
                    initialValues={{
                        projectId: _.find(projects, (c) => parseInt(c.id) === parseInt(empowerment.project_id)),
                        contractId: _.find(contracts, (c) => parseInt(c.id) === parseInt(empowerment.contract_id)),
                        templateId: _.find(
                            documentTemplates,
                            (template) => parseInt(template.id) === parseInt(empowerment.template_id)
                        ),
                        internalSeriesId: _.find(
                            documentSeries,
                            (series) => parseInt(series.id) === parseInt(contract.internal_series_id)
                        ),
                        barSeriesId: _.find(
                            documentSeries,
                            (series) => parseInt(series.id) === parseInt(contract.bar_series_id)
                        ),
                        name: empowerment.name,
                        content: empowerment.content
                    }}
                    validationSchema={empowermentsSchema.update}
                    onSubmit={(values) => {
                        const empowermentData = {
                            ...objectKeysToSnakeCase(
                                _.omit(values, [
                                    'projectId',
                                    'contractId',
                                    'internalSeriesId',
                                    'barSeriesId',
                                    'templateId'
                                ])
                            )
                        }

                        updateEmpowerment(empowermentData, empowerment.id)
                    }}>
                    {({handleChange, handleBlur, handleSubmit, values, touched, errors, isValid}) => (
                        <Form>
                            <ErrorsList errors={nonFieldErrors} />
                            <div className="space-y-6">
                                <FormSection
                                    autoHeight
                                    renderForm={() => (
                                        <>
                                            <p className="form-section-title">Setări generale</p>
                                            <Select
                                                label="Proiect*"
                                                value={values.projectId}
                                                options={projects}
                                                loading={isLoadingProjects}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                fullWidth
                                                disabled
                                            />
                                            <Select
                                                label="Contract*"
                                                value={values.contractId}
                                                options={contracts}
                                                loading={isLoadingContracts}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                fullWidth
                                                disabled
                                            />
                                            <Select
                                                label="Șablon*"
                                                value={values.templateId}
                                                options={documentTemplates}
                                                loading={isLoadingDocumentTemplates}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                fullWidth
                                                disabled
                                            />
                                            <Input
                                                label="Nume împuternicire*"
                                                value={values.name}
                                                onChange={handleChange('name')}
                                                onBlur={handleBlur('name')}
                                                name="name"
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.name}
                                                fullWidth
                                            />
                                            <Input
                                                label="Conținut*"
                                                value={values.content}
                                                onChange={handleChange('content')}
                                                onBlur={handleBlur('content')}
                                                name="content"
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.content}
                                                fullWidth
                                            />
                                            <div className="split-row">
                                                <Select
                                                    label="Serie interna*"
                                                    value={values.internalSeriesId}
                                                    options={_.filter(documentSeries, [
                                                        'type',
                                                        documentSeriesTypes.CONTRACT_INTERNAL.value
                                                    ])}
                                                    loading={isLoadingDocumentSeries}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} (${option.current_number})`
                                                    }
                                                    fullWidth
                                                    disabled
                                                />
                                                <Select
                                                    label="Serie barou*"
                                                    value={values.barSeriesId}
                                                    options={_.filter(documentSeries, [
                                                        'type',
                                                        documentSeriesTypes.CONTRACT_BAR.value
                                                    ])}
                                                    loading={isLoadingDocumentSeries}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} (${option.current_number})`
                                                    }
                                                    fullWidth
                                                    disabled
                                                />
                                            </div>
                                            <RequiredFieldsText />
                                        </>
                                    )}
                                    buttonDisabled={!isValid}
                                    onSubmit={handleSubmit}
                                    submitButtonTitle="Salvează"
                                    onSkip={handleExitEditMode}
                                    skipButtonTitle="Renunță"
                                    loading={isLoading}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    empowerment: state.empowerments.currentEmpowerment,
    isLoading: state.empowerments.isLoading,
    fieldErrors: state.empowerments.fieldErrors,
    nonFieldErrors: state.empowerments.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    contract: state.contracts.currentContract,
    contracts: _.values(state.contracts.data),
    isLoadingContracts: state.contracts.isLoading,
    projects: _.values(state.projects.data),
    isLoadingProjects: state.projects.isLoading,
    documentSeries: _.values(state.documentSeries.data),
    isLoadingDocumentSeries: state.documentSeries.isLoading,
    documentTemplates: _.filter(state.documentTemplates.data, [
        'type',
        _.find(documentTemplateTypes, ['value', 'empowerment']).value
    ]),
    isLoadingDocumentTemplates: state.documentTemplates.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll({...params, active: true})),
    retrieveProject: (projectId) => dispatch(RESOURCES.projects.retrieve(projectId)),
    retrieveContract: (contractId) => dispatch(RESOURCES.contracts.retrieve(contractId)),
    retrieveDocumentTemplate: (templateId) => dispatch(RESOURCES.documentTemplates.retrieve(templateId)),
    updateEmpowerment: (empowermentData, empowermentId) =>
        dispatch(RESOURCES.empowerments.update(empowermentData, empowermentId))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditEmpowermentForm)
