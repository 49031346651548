import React from 'react'
import {connect} from 'react-redux'

export const CalendarMonthView = () => {
    return <div>CalendarMonthView</div>
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMonthView)
