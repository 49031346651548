import React, {useState} from 'react'

import {isEmpty, toString} from 'lodash'

import {connect} from 'react-redux'

import {Button} from '../../../../components/Button'
import {DetailsCard} from '../../../../components/DetailsCard'
import {EditContractSettingsDisclaimerModal} from '../../../../components/EditContractSettingsDisclaimerModal'

import {EditEmpowermentForm} from './partials'

export const EmpowermentSettings = ({empowerment, isLoading}) => {
    const [editMode, setEditMode] = useState(false)

    const empowermentGeneralInfoRows = (empowerment) => [
        {label: 'Proiect', value: empowerment.project?.name || ''},
        {label: 'Contract', value: empowerment.contract?.name || ''},
        {label: 'Conținut', value: empowerment.content},
        {label: 'Serie internă', value: `${empowerment.internal_series_name} (${empowerment.internal_series_number})`},
        {label: 'Serie barou', value: `${empowerment.bar_series_name} (${empowerment.bar_series_number})`},
        {label: 'Șablon', value: toString(empowerment.template.name)}
    ]

    return (
        <div className="empowerment-details-page-container">
            <EditContractSettingsDisclaimerModal />
            {!isEmpty(empowerment) ? (
                <div className="empowerment-info-cards-container">
                    {editMode ? (
                        <EditEmpowermentForm handleExitEditMode={() => setEditMode(false)} />
                    ) : (
                        <DetailsCard
                            title="Setări împuternicire"
                            rows={empowermentGeneralInfoRows(empowerment)}
                            headerButton={() => (
                                <Button
                                    title="Editează"
                                    onClick={() => setEditMode(true)}
                                    size="small"
                                />
                            )}
                            loading={isLoading}
                        />
                    )}
                </div>
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    empowerment: state.empowerments.currentEmpowerment,
    isLoading: state.empowerments.isLoading
})

export default connect(mapStateToProps)(EmpowermentSettings)
