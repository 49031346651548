import {Form, Formik} from 'formik'
import React, {Component} from 'react'

import {values as _values, debounce, filter, includes} from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {slideoverTypes} from '../../../../redux/slideovers'

import {debounceWait} from '../../../../utils/constants'

import {Button} from '../../../../components/Button'
import {ErrorsList} from '../../../../components/ErrorComponents'
import {Select} from '../../../../components/Select'
import {Slideover} from '../../../../components/Slideover'

import '../../../../assets/scss/SlideoverForms.scss'

export class EditDocumentLocationsSlideover extends Component {
    constructor() {
        super()

        this.state = {
            projectsQuery: ''
        }
    }

    componentDidUpdate(prevProps) {
        const {documentID, retrieveDocument} = this.props

        if (documentID && documentID !== prevProps.documentID) {
            retrieveDocument(documentID)
            this.handleFetchProjects()
        }
    }

    handleFetchProjects = (query = this.state.projectsQuery) => {
        const {searchProjects} = this.props

        searchProjects(query)
    }

    debounceSearchProjects = debounce(this.handleFetchProjects, debounceWait)

    handleChangeProjectsSearchField = (value) => {
        this.setState({projectsQuery: value})
        this.debounceSearchProjects(value)
    }

    getInitialLocationsArray = () => {
        const {document, projects} = this.props

        return filter(projects, (project) => includes(document.projects_ids, parseInt(project.id)))
    }

    render() {
        const {open, documentID, isLoading, fieldErrors, nonFieldErrors, projects, isLoadingProjects, updateDocument} =
            this.props

        return (
            <Slideover
                open={open}
                title="Editează locații document">
                <div className="slideover-form-container">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{projectsIds: this.getInitialLocationsArray()}}
                        onSubmit={(values) => {
                            updateDocument(
                                {
                                    projects_ids: values.projectsIds.map((project) => project.id)
                                },
                                documentID
                            )
                        }}>
                        {({handleBlur, setFieldValue, handleSubmit, values, touched}) => (
                            <>
                                <Form className="slideover-form">
                                    <Select
                                        label="Proiecte"
                                        value={values.projectsIds}
                                        placeholder="Alege un proiect"
                                        options={projects}
                                        onChange={(option) => setFieldValue('projectsIds', option)}
                                        onInputChange={(value) => this.handleChangeProjectsSearchField(value)}
                                        onBlur={handleBlur('projectsIds')}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        name="projectsIds"
                                        errors={fieldErrors}
                                        touched={touched.projectsIds}
                                        loading={isLoadingProjects}
                                        isMulti
                                    />
                                </Form>
                                <div className="buttons-container">
                                    <Button
                                        title="Salvează"
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        type="submit"
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </Slideover>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.EDIT_DOCUMENT_LOCATIONS,
    document: state.documents.currentDocument,
    projects: _values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    client: state.clients.currentClient,
    isLoading: state.documents.isLoading,
    fieldErrors: state.documents.fieldErrors,
    nonFieldErrors: state.documents.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search)),
    retrieveDocument: (documentID) => dispatch(RESOURCES.documents.retrieve(documentID)),
    updateDocument: (documentData, documentID) => dispatch(RESOURCES.documents.update(documentData, documentID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentLocationsSlideover)
