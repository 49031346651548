import {buildFilter} from '../../../../components/FilterForm/utils'
import {FilterType} from '../../../../components/FilterForm/types'

export const tableListFilter = buildFilter({
    created__gte: {
        filterType: FilterType.DATE,
        label: 'Data început'
    },
    created__lte: {
        filterType: FilterType.DATE,
        label: 'Data sfârșit'
    },
    search: {filterType: FilterType.STRING, label: 'Search', excludeFromForm: true},
    page: {filterType: FilterType.NUMBER, label: 'Page', excludeFromForm: true}
})
