import React from 'react'

import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {closeErrorsModal} from '../../redux/errorsModal'

import {Button} from '../Button'
import {Modal} from '../Modal'

import './ErrorsModal.scss'

export const ErrorsModal = ({open, messageContent, contactAvailable, closeErrorsModal, navigate}) => {
    return (
        <Modal open={open}>
            <div className="avo-errors-modal-content-container">
                <div className="avo-errors-modal-message-container">{messageContent()}</div>
                <div className="avo-errors-modal-buttons-container">
                    {contactAvailable && (
                        <Button
                            title="Contactează-ne"
                            onClick={() => {
                                navigate('/support')
                                closeErrorsModal()
                            }}
                            fullWidth
                        />
                    )}
                    <Button
                        title="Ok"
                        onClick={closeErrorsModal}
                        fullWidth
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
    closeErrorsModal: () => dispatch(closeErrorsModal()),
    navigate: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsModal)
