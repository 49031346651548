import React from 'react'

import {ExpensePaymentsList, ExpensesList} from './partials'
import {actions, prefix, actionIds} from './constants'
import ExpenseBalance from './partials/ExpenseBalance'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

const ExpensesPage = (props) => {
    const {match} = props
    const urlData = {prefix: prefix, params: match.params}

    return (
        <>
            <SecondaryNavbar
                actions={actions}
                urlData={urlData}
            />
            <ExpenseBalance {...props} />
            {match.params.primaryAction === actionIds.LIST ? (
                <ExpensesList
                    {...props}
                    fullscreen={true}
                />
            ) : (
                <ExpensePaymentsList
                    {...props}
                    fullscreen={true}
                />
            )}
        </>
    )
}

export default ExpensesPage
