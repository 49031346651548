import React from 'react'
import {Link} from 'react-router-dom'

import {classNames} from '../../utils'

import './SecondaryNavbar.scss'

export default function SecondaryNavbar(props) {
    const {actions, urlData} = props
    const {primaryAction, secondaryAction} = urlData.params
    const secondaryActions = actions[primaryAction].secondaryActions

    let isCurrent = (action, primary = true) => {
        var paramAction = primary ? primaryAction : secondaryAction
        return action.id === paramAction
    }

    const actionsValues = Object.values(actions)

    return (
        <div>
            <div className="mb-2">
                <div className="sm:hidden">
                    <label
                        htmlFor="tabs"
                        className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md">
                        {actionsValues.map((action) => (
                            <option key={action.id}>{action.label}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav
                        className="relative z-0 rounded-lg shadow inline-flex divide-x divide-gray-200"
                        aria-label="Tabs">
                        {actionsValues.map((action, tabIndex) => (
                            <Link
                                key={action.label}
                                to={`${urlData.prefix}/${action.id}`}
                                className={classNames(
                                    isCurrent(action) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                                    tabIndex === 0 ? 'rounded-l-lg' : '',
                                    tabIndex === actionsValues.length - 1 ? 'rounded-r-lg' : '',
                                    'group relative overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center',
                                    'hover:bg-gray-50 focus:z-10'
                                )}
                                aria-current={isCurrent(action) ? 'page' : undefined}>
                                <span>{action.label}</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        isCurrent(action) ? 'bg-primary' : 'bg-transparent',
                                        'absolute inset-x-0 bottom-0 h-0.5'
                                    )}
                                />
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>

            {secondaryActions && secondaryActions.length ? (
                <div>
                    <div className="sm:hidden">
                        <label
                            htmlFor="tabs"
                            className="sr-only">
                            Select a tab
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            className={classNames(
                                'block w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md'
                            )}>
                            {secondaryActions.map((action) => (
                                <option key={action.id}>{action.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav
                            className="flex space-x-4"
                            aria-label="Tabs">
                            {secondaryActions.map((action) => (
                                <Link
                                    key={action.label}
                                    to={`${urlData.prefix}/${primaryAction}/${action.id}`}
                                    className={classNames(
                                        isCurrent(action, false)
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 ' +
                                                  'hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-2 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={isCurrent(action, false) ? 'page' : undefined}>
                                    <span>{action.label}</span>
                                </Link>
                            ))}
                        </nav>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}
