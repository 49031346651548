import React from 'react'

import {CheckCircleIcon} from '@heroicons/react/24/outline'

export const documentSeriesColumns = [
    {
        Header: 'Prefix',
        accessor: 'name',
        Cell: ({value: name, row: {original: series}}) => (
            <div className="document-series-name-cell-container">
                {name}
                {series.default && <CheckCircleIcon className="default-series-icon" />}
            </div>
        )
    },
    {
        Header: 'Eticheta',
        accessor: 'label'
    },
    {
        Header: 'Start',
        accessor: 'start'
    },
    {
        Header: 'Stop',
        accessor: 'stop'
    },
    {
        Header: 'Curent',
        accessor: 'current_number'
    }
]
