import React from 'react'
import {CheckCircleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Form, Formik} from 'formik'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {Input} from '../../../../components/Input'
import {Button} from '../../../../components/Button'

import './EditEmpowermentNameForm.scss'

export const EditEmpowermentNameForm = ({empowerment, isLoading, fieldErrors, handleCancel, updateEmpowerment}) => {
    return (
        <Formik
            initialValues={{name: empowerment.name}}
            onSubmit={(values) => {
                updateEmpowerment({name: values.name}, empowerment.id)
                handleCancel()
            }}>
            {({handleChange, handleSubmit, values}) => (
                <Form className="edit-empowerment-name-form-container">
                    <Input
                        label="Nume act adițional"
                        value={values.name}
                        onChange={handleChange('name')}
                        name="name"
                        errors={fieldErrors}
                        fullWidth
                    />
                    <div className="buttons-container">
                        <Button
                            title="Salvează"
                            icon={() => <CheckCircleIcon />}
                            onClick={handleSubmit}
                            color="secondary"
                        />
                        <Button
                            icon={() => <XMarkIcon />}
                            onClick={handleCancel}
                            loading={isLoading}
                            color="red"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const mapStateToProps = (state) => ({
    empowerment: state.empowerments.currentEmpowerment,
    isLoading: state.empowerments.isLoading,
    fieldErrors: state.empowerments.fieldErrors,
    nonFieldErrors: state.empowerments.nonFieldErrors
})

const mapDispatchToProps = (dispatch) => ({
    updateEmpowerment: (empowermentData, empowermentID) =>
        dispatch(RESOURCES.empowerments.update(empowermentData, empowermentID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditEmpowermentNameForm)
