import * as Yup from 'yup'

const patternFourDigitsAfterComa = /^-?\d+(\.\d{0,4})?$/

export const validationSchema = {
    main: Yup.object().shape({
        clientId: Yup.object().label('Client').nullable().required('Clientul este obligatoriu!'),
        projectId: Yup.object().label('Proiect').nullable().required('Proiectul este obligatoriu!'),
        issueDate: Yup.date().label('Data emitere').nullable().required('Data este obligatorie')
    }),
    edit: Yup.object().shape({
        issueDate: Yup.date().label('Data emitere').nullable().required('Data este obligatorie')
    }),
    entries: Yup.object().shape({
        name: Yup.string().label('Nume').required('Numele este obligatoriu!'),
        unit: Yup.object().label('Unitate de măsură').nullable().required('Unitatea de măsură este obligatorie'),
        quantity: Yup.number()
            .test('is-valid-decimal', 'Prețul nu poate avea mai mult de patru zecimale.', (val) => {
                if (val) {
                    return patternFourDigitsAfterComa.test(val)
                }
                return true
            })
            .test('is-different-than-zero', 'Cantitatea trebuie să fie diferita de 0!', (val) => val !== 0)
            .label('Cantitate')
            .required('Cantitatea este obligatorie!'),
        currency: Yup.object().label('Moneda').nullable().required('Moneda este obligatorie!'),
        price: Yup.number()
            .test('is-valid-decimal', 'Prețul nu poate avea mai mult de patru zecimale.', (val) => {
                if (val) {
                    return patternFourDigitsAfterComa.test(val)
                }
                return true
            })
            .label('Preț')
            .required('Prețul este obligatoriu!')
    })
}
