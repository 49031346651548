import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {Form, Formik} from 'formik'
import _ from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters} from '../../../../redux/filters/filters'
import {closeSlideover} from '../../../../redux/slideovers'

import {getFieldOptions, toApiDateFormat} from '../../../../utils'
import {debounceWait} from '../../../../utils/constants'
import {useDebouncedEffect} from '../../../../utils/hooks'
import {documentSeriesTypes} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DatePicker} from '../../../../components/DatePicker'
import {ErrorsList} from '../../../../components/ErrorComponents'
import {Select} from '../../../../components/Select'

import '../../../../assets/scss/SlideoverForms.scss'

const FilterInvoicePaymentsForm = ({
    filters,
    invoicePaymentsOptions,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    addFilters,
    filterInvoicePayments,
    closeSlideover,
    clients,
    isLoadingClients,
    searchClients,
    projects,
    isLoadingProjects,
    documentSeries,
    isLoadingDocumentSeries,
    listAllDocumentSeries,
    getInvoicePaymentOptions,
    searchProjects,
    selectedEntityID
}) => {
    const [clientsQuery, setClientsQuery] = useState('')
    const [projectsQuery, setProjectsQuery] = useState('')

    const handleFilter = (filters) => {
        addFilters(filters)
        filterInvoicePayments()
        closeSlideover()
    }

    useEffect(() => {
        getInvoicePaymentOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        listAllDocumentSeries({entity_id: selectedEntityID})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntityID])

    const paymentTypes = useMemo(() => {
        return getFieldOptions(invoicePaymentsOptions, 'type')
    }, [invoicePaymentsOptions])

    const receiptSeries = useMemo(() => {
        return _.filter(documentSeries, ['type', documentSeriesTypes.RECEIPT.value])
    }, [documentSeries])

    const invoiceSeries = useMemo(() => {
        return _.filter(documentSeries, ['type', documentSeriesTypes.FISCAL_INVOICE.value])
    }, [documentSeries])

    const handleFetchClients = useCallback(
        (query = clientsQuery) => {
            searchClients(query)
        },
        [clientsQuery, searchClients]
    )

    useDebouncedEffect(handleFetchClients, [clientsQuery], debounceWait)

    const handleChangeClientsSearchField = useCallback((value) => setClientsQuery(value), [])

    const handleFetchProjects = useCallback(
        (query = projectsQuery) => {
            searchProjects(query)
        },
        [projectsQuery, searchProjects]
    )

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    const handleChangeProjectsSearchField = useCallback((value) => setProjectsQuery(value), [])

    const handleChangeDate = useCallback((field, date, setFieldValue) => {
        if (!_.isNil(date)) {
            setFieldValue(field, new Date(date))
        } else {
            setFieldValue(field, date)
        }
    }, [])

    const initialClient = useMemo(() => {
        return _.find(clients, (client) => filters.client.value === client.id)
    }, [clients, filters.client.value])

    const initialProject = useMemo(() => {
        return _.find(projects, (project) => filters.project.value === project.id)
    }, [projects, filters.project.value])

    const initialSeries = useMemo(() => {
        return _.find(documentSeries, (series) => filters.series.value === series.id)
    }, [documentSeries, filters.series.value])

    const initialInvoiceSeries = useMemo(() => {
        return _.find(documentSeries, (series) => filters.invoice_series.value === series.id)
    }, [documentSeries, filters.invoice_series.value])

    const initialType = useMemo(() => {
        return _.find(paymentTypes, (type) => filters.type.value === type.id)
    }, [filters.type.value, paymentTypes])

    return (
        <div className="slideover-form-container">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    dateGTE: filters.dateGTE?.value ? new Date(filters.dateGTE.value) : null,
                    dateLTE: filters.dateLTE?.value ? new Date(filters.dateLTE.value) : null,
                    type: initialType,
                    client: initialClient,
                    project: initialProject,
                    series: initialSeries,
                    invoice_series: initialInvoiceSeries
                }}
                onSubmit={(values) => {
                    const filtersData = {
                        dateGTE: {
                            value: toApiDateFormat(values.dateGTE),
                            displayValue: toApiDateFormat(values.dateGTE)
                        },
                        dateLTE: {
                            value: toApiDateFormat(values.dateLTE),
                            displayValue: toApiDateFormat(values.dateLTE)
                        },
                        client: {
                            value: values.client?.id || '',
                            displayValue: values.client?.name || ''
                        },
                        project: {
                            value: values.project?.id || '',
                            displayValue: values.project?.name || ''
                        },
                        series: {
                            value: values.series?.id || '',
                            displayValue: values.series?.name || ''
                        },
                        invoice_series: {
                            value: values.invoice_series?.id || '',
                            displayValue: values.invoice_series?.name || ''
                        },
                        type: {
                            value: values.type?.value || '',
                            displayValue: values.type?.label || ''
                        }
                    }

                    handleFilter(filtersData)
                }}>
                {({setFieldValue, values, handleSubmit}) => (
                    <>
                        <Form className="slideover-form">
                            <DatePicker
                                label="Dată start"
                                value={values.dateGTE}
                                onChange={(date) => handleChangeDate('dateGTE', date, setFieldValue)}
                                fullWidth
                            />
                            <DatePicker
                                label="Dată stop"
                                value={values.dateLTE}
                                onChange={(date) => handleChangeDate('dateLTE', date, setFieldValue)}
                                fullWidth
                            />
                            <Select
                                label="Tip încasare"
                                value={values.type}
                                options={paymentTypes}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                onChange={(e) => setFieldValue('type', e)}
                                disabled={_.isEmpty(paymentTypes)}
                                isClearable
                                fullWidth
                            />
                            <Select
                                label="Client"
                                value={values.client}
                                options={clients}
                                onChange={(e) => setFieldValue('client', e)}
                                onInputChange={(value) => handleChangeClientsSearchField(value)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                disabled={_.isEmpty(clients)}
                                loading={isLoadingClients}
                                name="clients"
                                errors={fieldErrors}
                                isClearable
                                fullWidth
                            />
                            <Select
                                label="Proiect"
                                value={values.project}
                                options={projects}
                                onChange={(e) => setFieldValue('project', e)}
                                onInputChange={(value) => handleChangeProjectsSearchField(value)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                disabled={_.isEmpty(projects)}
                                loading={isLoadingProjects}
                                name="projects"
                                errors={fieldErrors}
                                isClearable
                                fullWidth
                            />
                            <Select
                                label="Serie chitanță"
                                value={values.series}
                                options={receiptSeries}
                                onChange={(e) => setFieldValue('series', e)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                disabled={_.isEmpty(receiptSeries)}
                                loading={isLoadingDocumentSeries}
                                name="series"
                                errors={fieldErrors}
                                isClearable
                                fullWidth
                            />
                            <Select
                                label="Serie factură"
                                value={values.invoice_series}
                                options={invoiceSeries}
                                onChange={(e) => setFieldValue('invoice_series', e)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                disabled={_.isEmpty(invoiceSeries)}
                                loading={isLoadingDocumentSeries}
                                name="invoice_series"
                                errors={fieldErrors}
                                isClearable
                                fullWidth
                            />
                        </Form>
                        <div className="buttons-container">
                            <Button
                                title="Aplică"
                                onClick={handleSubmit}
                                loading={isLoading}
                                type="submit"
                                fullWidth
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => ({
    filters: state.filters.invoicePayments,
    invoicePaymentsOptions: state.invoicePayments.options,
    isLoading: state.invoicePayments.isLoading,
    fieldErrors: state.invoicePayments.fieldErrors,
    nonFieldErrors: state.invoicePayments.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    clients: _.values(state.clients.searchData),
    isLoadingClients: state.clients.isLoading,
    projects: _.values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    documentSeries: _.values(state.documentSeries.data),
    isLoadingDocumentSeries: state.documentSeries.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.invoicePayments.name, filters)),
    getInvoicePaymentOptions: () => dispatch(RESOURCES.invoicePayments.getOptions()),
    searchClients: (search) => dispatch(RESOURCES.clients.search(search)),
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search)),
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll({...params, active: true}))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterInvoicePaymentsForm)
