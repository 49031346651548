import React, {useState} from 'react'

import './ToolTipWrapper.scss'

interface ToolTipWrapperProps {
    text: string
}

const ToolTipWrapper: React.FC<ToolTipWrapperProps> = ({text, children}) => {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <>
            <div
                className="tooltip-wrapper"
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}>
                {children}
                {isVisible && (
                    <div className="tooltip-container">
                        {text}
                        <div className="tooltip-point"></div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ToolTipWrapper
