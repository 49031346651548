import React from 'react'

import {connect} from 'react-redux'
// @ts-ignore
import {closeModal, modalTypes} from '../../redux/modals'

// @ts-ignore
import {Modal} from '../Modal'

import './BPISubscriptionInformationModal.scss'
// @ts-ignore
import {Button} from '../Button'

interface BPISubscriptionInformationModalProps {
    open: boolean
    closeModal: () => void
}

export const BPISubscriptionInformationModal = ({open, closeModal}: BPISubscriptionInformationModalProps) => {
    return (
        <Modal
            open={open}
            title="Subscripția BPI a fost înregistrată">
            <div className="avo-errors-modal-content-container">
                <div className="avo-errors-modal-message-container">
                    <span>
                        Subscripția BPI a fost înregistrată. Prelucrăm în acest moment toate aparițiile aferente acestui
                        CUI și te vom notifica în momentul în care procesarea este finalizată.
                    </span>
                </div>
                <div className="avo-errors-modal-buttons-container">
                    <Button
                        title="Am înțeles"
                        onClick={closeModal}
                        fullWidth
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.BPI_SUBSCRIPTION_INFORMATION
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(BPISubscriptionInformationModal)
