import React from 'react'
import {CheckBadgeIcon} from '@heroicons/react/24/outline'

import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {closeModal, modalTypes} from '../../../../redux/modals'

import {Button} from '../../../../components/Button'
import {Modal} from '../../../../components/Modal'

import './TickedCreatedModal.scss'

export const TicketCreatedModal = ({open, user, closeModal}) => {
    return (
        <Modal open={open}>
            <div className="ticked-created-modal-container">
                <div className="ticket-created-modal-header">
                    <CheckBadgeIcon className="ticket-created-icon" />
                    <p className="ticket-created-hodal-title">
                        Tichetul a fost creat <span>cu succes!</span>
                    </p>
                </div>
                <div className="ticket-created-modal-user-info-container">
                    <p>Vei fi contactat la următoarele date:</p>
                    <div className="user-info-container">
                        <p className="user-info">{user.full_name}</p>
                        <p className="user-info">Email: {user.email}</p>
                        <p className="user-info">Telefon: {user.phone}</p>
                    </div>
                </div>
                <Link to="/">
                    <Button
                        title="Mergi la dashboard"
                        onClick={closeModal}
                        size="large"
                        fullWidth
                    />
                </Link>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.TICKET_CREATED,
    user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(TicketCreatedModal)
