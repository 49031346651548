import {takeEvery} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {proformas: resource} = RESOURCES

function* handleCreateSuccess() {
    // toast.success('Factura a fost adaugată cu succes.')
    // const currentLocation = yield select((state) => last(state.routerLocations))
    // if(!isUndefined(currentLocation.location.search) && includes(currentLocation.location.search,
    // 'initialProjectId')) {
    //     yield put(push(`/projects/${proforma.project_id}/proformas`))
    // } else {
    //     yield put(push('/proformas'))
    // }
}

function handleUpdateSuccess() {
    // toast.success('Salvat.')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateSuccess)
}
