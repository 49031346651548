import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function DeleteDocumentModal({open, document, isLoading, closeModal, deleteDocument}) {
    return (
        <Modal
            open={open && !isEmpty(document)}
            title="Șterge document">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să ștergi documentul "<span>{document.name}</span>" și toate versiunile asociate?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={() => deleteDocument(document)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_DOCUMENT,
    document: state.documents.currentDocument,
    isLoading: state.documents.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteDocument: (document) => dispatch(RESOURCES.documents.destroy(document))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDocumentModal)
