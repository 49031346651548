import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {lightFormat} from 'date-fns'
import {isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'
import {openSlideover, slideoverTypes} from '../../../../redux/slideovers'

import {logsOrderingOptions} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DeleteDocumentModal} from '../../../../components/DeleteDocumentModal'
import {DetailsCard} from '../../../../components/DetailsCard'
import {Loader} from '../../../../components/Loader'
import {ResourceLogsList} from '../../../../components/ResourceLogsList'

import {EditDocumentLocationsSlideover} from '../slideovers'

import './DocumentMetaInfo.scss'

export const DocumentMetaInfo = ({
    document,
    isLoading,
    documentLastVersion,
    isLoadingDocumentVersions,
    listLogs,
    openDeleteDocumentModal,
    openSlideover,
    match: {
        params: {documentID}
    }
}) => {
    useEffect(() => {
        listLogs(documentID)
    }, [documentID, listLogs])

    const documentInfoRows = (document) => [
        {label: 'Nume', value: document.name},
        {
            label: 'Mărime',
            value: document.last_version?.file_meta?.size ? `${document.last_version.file_meta.size} Mb` : '-'
        },
        {label: 'Dată', value: document.created ? lightFormat(new Date(document.created), 'dd/MM/yyyy') : ''},
        {label: 'Versiune', value: `${documentLastVersion.version}`}
    ]

    return (
        <div className="document-details-page-container">
            <div className="document-info-cards-container">
                <div className="row">
                    <div className="left">
                        <DetailsCard
                            title="Informații document"
                            rows={documentInfoRows(document)}
                            loading={isLoading || isLoadingDocumentVersions}
                        />
                    </div>
                    <div className="right">
                        <div className="document-info-card document-locations-card">
                            <div className="header">
                                <p className="header-title">Locații</p>
                                <Button
                                    title="Editează"
                                    onClick={() => openSlideover(slideoverTypes.EDIT_DOCUMENT_LOCATIONS)}
                                    size="small"
                                />
                            </div>
                            {!isLoading ? (
                                <div className="content">
                                    <div className="section">
                                        <p className="section-title">Proiecte</p>
                                        {!isEmpty(document.projects) ? (
                                            <div className="location-rows-container">
                                                {document.projects.map((project) => (
                                                    <LocationContainer
                                                        project={project}
                                                        key={project.id}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="no-locations">
                                                Acest document nu este folosit pentru niciun proiect
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="center-loader">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ResourceLogsList
                        resourceID={documentID}
                        resourceName={RESOURCES.documents.name}
                    />
                </div>
                <div className="mt-10 flex gap-5 justify-end">
                    <Button
                        title="Șterge document"
                        onClick={openDeleteDocumentModal}
                        size="large"
                        color="red"
                    />
                </div>
            </div>
            <DeleteDocumentModal />
            <EditDocumentLocationsSlideover documentID={documentID} />
        </div>
    )
}

const LocationContainer = ({project}) => {
    return (
        <Link
            to={`/projects/${project.id}`}
            className="location-container">
            <p className="location-name">{project.name}</p>
        </Link>
    )
}

const mapStateToProps = (state) => {
    return {
        project: state.projects.currentProject,
        document: state.documents.currentDocument,
        isLoading: state.documents.isLoading,
        documentLastVersion: state.documentVersions.currentDocumentVersion,
        isLoadingDocumentVersions: state.documentVersions.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    openDeleteDocumentModal: () => dispatch(openModal(modalTypes.DELETE_DOCUMENT)),
    openSlideover: (slideoverType) => dispatch(openSlideover(slideoverType)),
    listLogs: (documentID) =>
        dispatch(
            RESOURCES.logs.list({
                resource_id: documentID,
                resource_name: RESOURCES.documents.name,
                ordering: logsOrderingOptions.desc
            })
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentMetaInfo)
