import {Form, Formik} from 'formik'
import React, {useEffect, useMemo, useState} from 'react'

import {lightFormat} from 'date-fns'
import {filter, get, includes, isEmpty, sumBy, toNumber, uniq, values} from 'lodash'

// @ts-ignore
import {ExpenseTypes} from 'avoapp-react-common/dist/constants'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
// @ts-ignore
import {closeModal, modalTypes} from '../../redux/modals'
// @ts-ignore
import {Button} from '../Button'
// @ts-ignore
import {Datatable} from '../Datatable'
// @ts-ignore
import {Modal} from '../Modal'
// @ts-ignore
import {Toggle} from '../Toggle'

import {useQuery} from '@tanstack/react-query'
import './AddExpenseAllocationModal.scss'

interface AddExpenseAllocationModalProps {
    open: boolean
    expenseID: number
    expenseAmountRON: number
    projectID: number
    allocatedExpensePayments: any[]
    selectedEntityID: number
    entityProfiles: any[]
    closeModal: () => void
}

const AddExpenseAllocationModal: React.FC<AddExpenseAllocationModalProps> = ({
    open,
    expenseID,
    expenseAmountRON,
    projectID,
    allocatedExpensePayments,
    selectedEntityID,
    entityProfiles,
    closeModal
}) => {
    const [expensePaymentsIDs, setExpensePaymentsIDs] = useState<number[]>([])
    const [expensePaymentsAmount, setExpensePaymentsAmount] = useState<number>(0)
    const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false)

    useEffect(() => {
        setExpensePaymentsIDs(allocatedExpensePayments.map(({id}) => id))
        setExpensePaymentsAmount(
            sumBy(
                allocatedExpensePayments.map(({current_instance_allocation}) =>
                    toNumber(current_instance_allocation?.amount_RON || 0)
                )
            )
        )
    }, [allocatedExpensePayments])

    const {data: expensePayments, isFetching: expensePaymentsLoading} = useQuery({
        queryKey: [RESOURCES.expensePayments.name, selectedEntityID, projectID],
        queryFn: async () => {
            const response = await performRequest(
                RESOURCES.expensePayments.list({
                    entity_id: selectedEntityID,
                    project_id: projectID,
                    status: [ExpenseTypes.NOT_COVERED, ExpenseTypes.PARTIALLY_COVERED],
                    page_size: 100
                })
            )
            return response.data.results
        },
        enabled: open,
        placeholderData: []
    })

    const expensesColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'date',
                Cell: ({value: start}: {value: string}) => {
                    return start ? lightFormat(new Date(start), 'dd/MM/yyyy') : '-'
                }
            },
            {
                Header: 'Suma',
                accessor: 'amount',
                Cell: ({value: amount, row: {original: expense}}: {value: number; row: {original: any}}) => {
                    const amountLeft = get(expense, ['uncovered_allocation', 'amount'], 0)
                    const currency = get(expense, ['uncovered_allocation', 'currency'], expense.currency)

                    const sameAmount = amountLeft === amount
                    return sameAmount
                        ? amount
                        : `${amountLeft} ${currency} 
                        (din ${amount} ${expense.currency})`
                }
            },
            {
                Header: '',
                accessor: 'id',
                Cell: ({value: expenseID}: {value: number}) => {
                    console.log(expensePaymentsAmount, expenseAmountRON)
                    const shouldDisable =
                        !includes(expensePaymentsIDs, expenseID) && expensePaymentsAmount >= expenseAmountRON

                    return (
                        <Toggle
                            checked={includes(expensePaymentsIDs, expenseID)}
                            disabled={shouldDisable}
                            tooltipText={shouldDisable && 'Suma nu este suficientă.'}
                            onChange={(e: any) => {
                                let newExpensePaymentsIDs: number[]

                                if (e === true) {
                                    newExpensePaymentsIDs = uniq([...expensePaymentsIDs, expenseID])
                                    setExpensePaymentsIDs(newExpensePaymentsIDs)
                                } else {
                                    newExpensePaymentsIDs = filter(expensePaymentsIDs, (item) => item !== expenseID)
                                    setExpensePaymentsIDs(newExpensePaymentsIDs)
                                }

                                setExpensePaymentsAmount(
                                    sumBy(
                                        filter([...allocatedExpensePayments, ...expensePayments], (item) =>
                                            includes(newExpensePaymentsIDs, item.id)
                                        ),
                                        (o) => {
                                            let amount = get(o, ['current_instance_allocation', 'amount_RON'])
                                            if (!amount) {
                                                amount = get(o, ['uncovered_allocation', 'amount_RON'], o.amount_RON)
                                            }

                                            return toNumber(amount)
                                        }
                                    )
                                )
                            }}
                        />
                    )
                }
            }
        ]
    }, [expensePayments, allocatedExpensePayments, expenseAmountRON, expensePaymentsAmount, expensePaymentsIDs])

    return (
        <Modal
            open={open}
            title="Adaugă alocare"
            maxWidth="450px">
            {!isEmpty(entityProfiles) && (
                <>
                    <Formik
                        initialValues={{}}
                        onSubmit={async () => {
                            const expenseData = {
                                expense_payments_ids: expensePaymentsIDs
                            }
                            setSubmitIsLoading(true)
                            try {
                                await performRequest(RESOURCES.expenses.update(expenseData, expenseID))
                                closeModal()
                            } finally {
                                setSubmitIsLoading(false)
                            }
                        }}>
                        {({handleSubmit, isValid}) => (
                            <Form className="add-item-form-container">
                                <Datatable
                                    title="Încasări"
                                    data={
                                        !expensePaymentsLoading ? [...allocatedExpensePayments, ...expensePayments] : []
                                    }
                                    columns={expensesColumns}
                                    loading={expensePaymentsLoading}
                                    totalPages={1}
                                />
                                <Button
                                    title="Adaugă plată"
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={expensePaymentsLoading || submitIsLoading}
                                    color="secondary"
                                    type="submit"
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_EXPENSE_ALLOCATION,
    entityProfiles: values(state.entityProfiles.data),
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddExpenseAllocationModal)
