import {PlusIcon} from '@heroicons/react/24/solid'
import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {debounce, isEmpty, isNull, orderBy} from 'lodash'

import {uploadSignedFile} from 'avoapp-react-common/dist/redux/empowerments'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'

import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {columns} from './constants'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {Dropdown} from '../../../../components/Dropdown'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'

// import FilterEmpowermentsForm from '../forms/FilterEmpowermentsForm'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import {DeleteEmpowermentModal} from '../../../../components/DeleteEmpowermentModal'
import {modalTypes, openModal} from '../../../../redux/modals'
import {getTokenAndUploadFile} from '../../../../utils/files'
import './Empowerments.scss'

export const Empowerments = ({
    empowerments,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    openDeleteEmpowermentModal,
    listEmpowerments,
    match: {
        params: {projectID}
    },
    selectedEntityID
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedEmpowerment, setSelectedEmpowerment] = useState(null)

    const handleFetchEmpowerments = useCallback(
        (search = searchFilter, page = 1) => {
            const appliedFilters = generateFiltersForAPI(filters)

            listEmpowerments(projectID, search, appliedFilters, page)
        },
        [filters, listEmpowerments, projectID, searchFilter]
    )

    useEffect(() => {
        handleFetchEmpowerments()
    }, [handleFetchEmpowerments])

    const debounceSearchEmpowerments = debounce((search) => handleFetchEmpowerments(search), debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchEmpowerments(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchEmpowerments(searchFilter, page)

    const handleDropSignedFile = async (selectedFile, empowermentID) => {
        if (!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.empowerments.update({signed_file: fileURL}, empowermentID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    return (
        <div className="page-project-show project-addendums-list">
            <Datatable
                title="Listă împuterniciri"
                data={empowerments}
                headerButton={() => (
                    <Link to={`/contracts/empowerments/add?initialProjectId=${projectID}`}>
                        <Button
                            title="Adaugă împuternicire"
                            icon={() => <PlusIcon />}
                        />
                    </Link>
                )}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: empowermentID, row: {original: empowerment}}) => (
                            <div className="flex gap-2 items-center">
                                <Link to={`/contracts/empowerments/${empowermentID}/preview`}>
                                    <Button
                                        title="Vizualizare"
                                        variant="text"
                                        size="small"
                                    />
                                </Link>
                                <Dropdown
                                    title="Descarcă"
                                    disabled={
                                        isNull(empowerment.file_meta?.url_public) && isNull(empowerment.signed_file)
                                    }
                                    items={[
                                        {
                                            customContent: () =>
                                                empowerment.file_meta?.url_public ? (
                                                    <a
                                                        href={empowerment.file_meta.url_public}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        },
                                        {
                                            customContent: () =>
                                                empowerment.signed_file ? (
                                                    <a
                                                        href={empowerment.signed_file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă fișier semnat
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        }
                                    ]}
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                />
                                <Dropdown
                                    title="Încarcă"
                                    items={[
                                        {
                                            customContent: () => (
                                                <SignedFileDropzone
                                                    onChange={(selectedFile) =>
                                                        handleDropSignedFile(selectedFile, empowermentID)
                                                    }
                                                />
                                            )
                                        }
                                    ]}
                                    variant="text"
                                    color="orange"
                                    size="small"
                                />
                                <Button
                                    title="Șterge"
                                    onClick={() => {
                                        setSelectedEmpowerment(empowerment)
                                        openDeleteEmpowermentModal()
                                    }}
                                    size="small"
                                    color="red"
                                    variant="text"
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={isLoading}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                // filterable
                // filters={filters}
                // filtersForm={() => <FilterEmpowermentsForm filterEmpowerments={handleFetchEmpowerments} />}
                // handleRemoveFilter={(filter) => {
                //     removeFilter(filter)
                //     handleFetchEmpowerments()
                // }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder="Caută împuterniciri"
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <DeleteEmpowermentModal empowerment={selectedEmpowerment} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    empowerments: orderBy(state.empowerments.data, [(e) => e.signed_date || '', 'id'], ['desc', 'desc']),
    isLoading: state.empowerments.isLoading,
    totalPages: state.empowerments.totalPages,
    nextPage: state.empowerments.next,
    previousPage: state.empowerments.previous,
    currentPage: state.empowerments.current,
    filters: state.filters.empowerments
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteEmpowermentModal: () => dispatch(openModal(modalTypes.DELETE_EMPOWERMENT)),
    uploadSignedFile: (empowermentData, empowermentID) => dispatch(uploadSignedFile(empowermentData, empowermentID)),
    listEmpowerments: (projectID, search, filters, page) =>
        dispatch(
            RESOURCES.empowerments.list(
                {
                    ...filters,
                    project_id: projectID,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(Empowerments)
