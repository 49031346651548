import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../redux/modals'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

function DeleteClientAddressModal({open, clientAddress, isLoading, closeModal, deleteClientAddress}) {
    return (
        <Modal
            open={open && !isEmpty(clientAddress)}
            title="Șterge adresă">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să ștergi adresa <span>"{clientAddress.address_description}"</span>?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={() => deleteClientAddress(clientAddress)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_CLIENT_ADDRESS,
    clientAddress: state.clientAddresses.currentClientAddress,
    isLoading: state.clientAddresses.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteClientAddress: (clientAddress) => dispatch(RESOURCES.clientAddresses.destroy(clientAddress))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientAddressModal)
