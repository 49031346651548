import React from 'react'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {StatsItem} from '../../../../components/StatsItem'
// @ts-ignore
import {Loader} from '../../../../components/Loader'
import {useQueryResourceList} from '../../../../queries/rest'
import {keepPreviousData} from '@tanstack/react-query'
import {hasPermission} from '../../../../utils/permissions'
import {RequestListingResponse, RequestResponse} from '../../../../types/api'
import {AccessPolicyResource, AccessPolicyPermission, AccessPolicyAction} from '../../../../types/openapi'
import {endOfMonth, startOfMonth, startOfYear} from 'date-fns'
import * as URI from 'uri-js'

import './InvoicesStats.scss'
// @ts-ignore
import {toApiDateFormat} from 'utils'

const InvoicesStats = ({selectedEntityID}: {selectedEntityID: number}) => {
    // @ts-ignore
    const {
        data: accessPoliciesResponse,
        isFetching: accessPoliciesFetching
    }: {
        data: RequestListingResponse
        isFetching: boolean
    } = useQueryResourceList(
        RESOURCES.accessPolicies,
        {
            entity_id: selectedEntityID,
            resource: AccessPolicyResource.invoices
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 60 * 30
        }
    )

    const hasInvoicesPermission = hasPermission(
        accessPoliciesResponse?.data?.results,
        AccessPolicyResource.invoices,
        AccessPolicyPermission.ALL,
        AccessPolicyAction.READ
    )

    const startDate = toApiDateFormat(startOfMonth(new Date()))
    const endDate = toApiDateFormat(endOfMonth(new Date()))
    const startOfYearDate = toApiDateFormat(startOfYear(new Date()))

    // @ts-ignore
    const {
        data: invoicesReportsResponse,
        isFetching: invoicesReportsFetching
    }: {
        data: RequestResponse
        isFetching: boolean
    } = useQueryResourceList(
        RESOURCES.invoicesReports,
        {
            entity_id: selectedEntityID,
            date__gte: startDate,
            date__lte: endDate
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 10,
            enabled: hasInvoicesPermission
        }
    )

    // @ts-ignore
    const {
        data: invoicesReportsAllTimeResponse,
        isFetching: invoicesReportsAllTimeFetching
    }: {
        data: RequestResponse
        isFetching: boolean
    } = useQueryResourceList(
        RESOURCES.invoicesReports,
        {
            entity_id: selectedEntityID,
            date__gte: startOfYearDate
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 10,
            enabled: hasInvoicesPermission
        }
    )

    if (!hasInvoicesPermission) {
        return null
    }

    if (accessPoliciesFetching || invoicesReportsFetching || invoicesReportsAllTimeFetching) {
        return (
            <div className="dashboard-card-container stats-card">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Statistici contracte</h3>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <Loader />
                </dl>
            </div>
        )
    }

    const totalIssuedInvoices = invoicesReportsResponse?.data?.total_with_VAT_RON || 0
    const countIssuedInvoices = invoicesReportsResponse?.data?.count_issued || 0
    const totalIssuedUnpaidInvoices = invoicesReportsAllTimeResponse?.data?.total_unpaid_RON || 0
    const countIssuedUnpaidInvoices = invoicesReportsAllTimeResponse?.data?.count_unpaid || 0

    const issuedInvoicesPath = URI.serialize({
        path: 'invoices/invoices',
        query: new URLSearchParams({
            date__gte: startDate,
            date__lte: endDate
        }).toString()
    })
    const unpaidInvoicesPath = URI.serialize({
        path: 'invoices/invoices',
        query: new URLSearchParams({
            date__gte: startOfYearDate,
            onlyNonCollected: '1'
        }).toString()
    })

    return (
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            <StatsItem
                name="Facturi emise luna aceasta"
                stat={countIssuedInvoices}
                link={issuedInvoicesPath}
                linkText="Vezi facturi emise"
            />
            <StatsItem
                name="Total facturi emise luna aceasta"
                stat={`${totalIssuedInvoices} RON`}
                link={issuedInvoicesPath}
                linkText="Vezi facturi emise"
            />
            <StatsItem
                name="Facturi neîncasate"
                stat={countIssuedUnpaidInvoices}
                link={unpaidInvoicesPath}
                linkText="Vezi facturi neîncasate"
            />
            <StatsItem
                name="Total sume neîncasate"
                stat={`${totalIssuedUnpaidInvoices} RON`}
                link={unpaidInvoicesPath}
                linkText="Vezi facturi neîncasate"
            />
        </dl>
    )
}

export default InvoicesStats
