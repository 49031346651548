import {Form, Formik} from 'formik'
import React, {useEffect, useMemo, useState} from 'react'

import {lightFormat} from 'date-fns'
import {filter, get, includes, isEmpty, sumBy, toNumber, uniq, values} from 'lodash'

// @ts-ignore
import {ExpenseTypes} from 'avoapp-react-common/dist/constants'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
// @ts-ignore
import {closeModal, modalTypes} from '../../redux/modals'
// @ts-ignore
import {Button} from '../Button'
// @ts-ignore
import {Datatable} from '../Datatable'
// @ts-ignore
import {Modal} from '../Modal'
// @ts-ignore
import {Toggle} from '../Toggle'

import {useQuery} from '@tanstack/react-query'
import './AddExpensePaymentAllocationModal.scss'

interface AddExpensePaymentAllocationModalProps {
    open: boolean
    expensePaymentID: number
    expensePaymentAmountRON: number
    projectID: number
    allocatedExpenses: any[]
    selectedEntityID: number
    entityProfiles: any[]
    closeModal: () => void
}

const AddExpensePaymentAllocationModal: React.FC<AddExpensePaymentAllocationModalProps> = ({
    open,
    expensePaymentID,
    expensePaymentAmountRON,
    projectID,
    allocatedExpenses,
    selectedEntityID,
    entityProfiles,
    closeModal
}) => {
    const [expensesIDs, setExpensesIDs] = useState<number[]>([])
    const [expensesAmount, setExpensesAmount] = useState<number>(0)
    const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false)

    useEffect(() => {
        setExpensesIDs(allocatedExpenses.map(({id}) => id))
        setExpensesAmount(
            sumBy(
                allocatedExpenses.map(({current_instance_allocation}) =>
                    toNumber(current_instance_allocation?.amount_RON || 0)
                )
            )
        )
    }, [allocatedExpenses])

    const {data: expenses, isFetching: expensesLoading} = useQuery({
        queryKey: [RESOURCES.expenses.name, selectedEntityID, projectID],
        queryFn: async () => {
            const response = await performRequest(
                RESOURCES.expenses.list({
                    entity_id: selectedEntityID,
                    project_id: projectID,
                    status: [ExpenseTypes.NOT_COVERED, ExpenseTypes.PARTIALLY_COVERED],
                    page_size: 100
                })
            )
            return response.data.results
        },
        enabled: open,
        placeholderData: []
    })

    const expensesColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'date',
                Cell: ({value: start}: {value: string}) => {
                    return start ? lightFormat(new Date(start), 'dd/MM/yyyy') : '-'
                }
            },
            {
                Header: 'Suma',
                accessor: 'amount',
                Cell: ({value: amount, row: {original: expense}}: {value: number; row: {original: any}}) => {
                    const amountLeft = get(expense, ['uncovered_allocation', 'amount'], 0)
                    const currency = get(expense, ['uncovered_allocation', 'currency'], expense.currency)

                    const sameAmount = amountLeft === amount
                    return sameAmount
                        ? amount
                        : `${amountLeft} ${currency} 
                        (din ${amount} ${expense.currency})`
                }
            },
            {
                Header: '',
                accessor: 'id',
                Cell: ({value: expenseID}: {value: number}) => {
                    const shouldDisable = !includes(expensesIDs, expenseID) && expensesAmount >= expensePaymentAmountRON

                    return (
                        <Toggle
                            checked={includes(expensesIDs, expenseID)}
                            disabled={shouldDisable}
                            tooltipText={shouldDisable && 'Suma nu este suficientă.'}
                            onChange={(e: any) => {
                                let newExpensesIDS: number[]

                                if (e === true) {
                                    newExpensesIDS = uniq([...expensesIDs, expenseID])
                                    setExpensesIDs(newExpensesIDS)
                                } else {
                                    newExpensesIDS = filter(expensesIDs, (item) => item !== expenseID)
                                    setExpensesIDs(newExpensesIDS)
                                }

                                setExpensesAmount(
                                    sumBy(
                                        filter([...allocatedExpenses, ...expenses], (item) =>
                                            includes(newExpensesIDS, item.id)
                                        ),
                                        (o) => {
                                            let amount = get(o, ['current_instance_allocation', 'amount_RON'])
                                            if (!amount) {
                                                amount = get(o, ['uncovered_allocation', 'amount_RON'], o.amount_RON)
                                            }

                                            return toNumber(amount)
                                        }
                                    )
                                )
                            }}
                        />
                    )
                }
            }
        ]
    }, [expenses, allocatedExpenses, expensePaymentAmountRON, expensesAmount, expensesIDs])

    return (
        <Modal
            open={open}
            title="Adaugă alocare"
            maxWidth="450px">
            {!isEmpty(entityProfiles) && (
                <>
                    <Formik
                        initialValues={{}}
                        onSubmit={async () => {
                            const expenseData = {
                                expenses_ids: expensesIDs
                            }
                            setSubmitIsLoading(true)
                            try {
                                await performRequest(RESOURCES.expensePayments.update(expenseData, expensePaymentID))
                                closeModal()
                            } finally {
                                setSubmitIsLoading(false)
                            }
                        }}>
                        {({handleSubmit, isValid}) => (
                            <Form className="add-item-form-container">
                                <Datatable
                                    title="Cheltuieli"
                                    data={!expensesLoading ? [...allocatedExpenses, ...expenses] : []}
                                    columns={expensesColumns}
                                    loading={expensesLoading}
                                    totalPages={1}
                                />
                                <Button
                                    title="Adaugă plată"
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={expensesLoading || submitIsLoading}
                                    color="secondary"
                                    type="submit"
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_EXPENSE_PAYMENT_ALLOCATION,
    entityProfiles: values(state.entityProfiles.data),
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddExpensePaymentAllocationModal)
