import {lightFormat} from 'date-fns'
import _ from 'lodash'

export const isLate = (task: any) => {
    if (_.isNil(task?.stop)) {
        return false
    }

    return new Date(task.stop) < new Date()
}

export const renderInterval = (task: any) => {
    const localFormatDate = (date: Date) => lightFormat(date, 'dd/MM/yyyy')
    const localFormatTime = (date: Date) => lightFormat(date, 'HH:mm')
    const localFormatDateTime = (date: Date) => lightFormat(date, 'dd/MM/yyyy HH:mm')

    const startDate = task?.start ? new Date(task?.start) : null
    const stopDate = task?.stop ? new Date(task?.stop) : null

    if (!_.isNil(startDate) && _.isNil(stopDate)) {
        return `De la ${localFormatDateTime(startDate)}`
    } else if (_.isNil(startDate) && !_.isNil(stopDate)) {
        return `Pana la ${localFormatDateTime(stopDate)}`
    }

    if (_.isNil(startDate) || _.isNil(stopDate)) {
        return '-'
    }

    if (startDate.getDate() === stopDate.getDate() && task.all_day) {
        return `${localFormatDate(startDate)} - Toată ziua`
    } else if (startDate.getDate() === stopDate.getDate() && !task.all_day) {
        return `${localFormatDate(startDate)} ${localFormatTime(startDate)} - ${localFormatTime(stopDate)}`
    } else if (startDate.getDate() !== stopDate.getDate() && task.all_day) {
        return `${localFormatDate(startDate)} - ${localFormatDate(stopDate)}`
    } else if (startDate.getDate() !== stopDate.getDate() && !task.all_day) {
        return `${localFormatDateTime(startDate)} - ${localFormatDateTime(stopDate)}`
    }

    return '-'
}
