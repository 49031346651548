import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {ArrowUpTrayIcon, PlusIcon} from '@heroicons/react/24/solid'

import {isEmpty, isNull, orderBy} from 'lodash'

import {uploadSignedFile} from 'avoapp-react-common/dist/redux/documents'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {removeFilter} from '../../redux/filters/filters'
import {generateFiltersForAPI} from '../../redux/filters/utils'
import {modalTypes, openModal} from '../../redux/modals'

import {debounceWait} from '../../utils/constants'
import {datatablePageSize} from '../../utils/datatables'
import {useDebouncedEffect} from '../../utils/hooks'

import {Button} from '../../components/Button'
import {CreateDocumentModal} from '../../components/CreateDocumentModal'
import {Datatable} from '../../components/Datatable'
import {Dropdown} from '../../components/Dropdown'
import {SignedFileDropzone} from '../../components/SignedFileDropzone'
import {UploadDocumentModal} from '../../components/UploadDocumentModal'

import {columns} from './constants'
import {FilterDocumentsForm, UploadNewDocumentModal} from './partials'

import './DocumentsList.scss'
import {getTokenAndUploadFile} from '../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import {Loader} from '../../components/Loader'

export const DocumentsList = ({
    documents,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    removeFilter,
    listDocuments,
    selectedEntityID,
    openModal
}) => {
    const [selectedDocumentID, setSelectedDocumentID] = useState('')
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchDocuments = (search = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listDocuments(search, appliedFilters, page)
    }

    useDebouncedEffect(handleFetchDocuments, [searchFilter], debounceWait)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const handleChangePage = (page) => !isLoading && handleFetchDocuments(searchFilter, page)

    const handleDropSignedFile = async (selectedFile, documentID) => {
        if (!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.documents.update({signed_file: fileURL}, documentID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    return (
        <>
            <UploadDocumentModal documentID={selectedDocumentID} />
            <UploadNewDocumentModal />
            <CreateDocumentModal />
            <div className="min-w-full flex flex-row justify-between items-center mb-2">
                <div className="flex flex-row items-center gap-2">
                    <h1 className="text-xl font-bold">Listă documente</h1>
                    {isLoading && <Loader />}
                </div>

                <div className="documents-datatable-buttons">
                    <Button
                        title="Încarcă document"
                        onClick={() => openModal(modalTypes.UPLOAD_NEW_DOCUMENT)}
                        icon={() => <ArrowUpTrayIcon />}
                        color="secondary"
                    />
                    <Button
                        title="Crează document"
                        onClick={() => openModal(modalTypes.CREATE_DOCUMENT)}
                        icon={() => <PlusIcon />}
                    />
                </div>
            </div>
            <Datatable
                data={documents}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: documentID, row: {original: document}}) => (
                            <div className="flex gap-2 items-center">
                                <Link to={`/documents/${documentID}/preview`}>
                                    <Button
                                        title="Vizualizare"
                                        variant="text"
                                        size="small"
                                    />
                                </Link>
                                <Dropdown
                                    title="Descarcă"
                                    disabled={
                                        isNull(document.last_version?.file_meta?.url_public) &&
                                        isNull(document.signed_file)
                                    }
                                    items={[
                                        {
                                            customContent: () =>
                                                document.last_version?.file_meta?.url_public ? (
                                                    <a
                                                        href={document.last_version?.file_meta?.url_public}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        },
                                        {
                                            customContent: () =>
                                                document.signed_file ? (
                                                    <a
                                                        href={document.signed_file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă fișier semnat
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        }
                                    ]}
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                />
                                <Dropdown
                                    title="Încarcă"
                                    items={[
                                        {
                                            title: 'Încarcă fișier',
                                            onClick: () => {
                                                setSelectedDocumentID(documentID)
                                                openModal(modalTypes.UPLOAD_DOCUMENT)
                                            }
                                        },
                                        {
                                            customContent: () => (
                                                <SignedFileDropzone
                                                    onChange={(selectedFile) =>
                                                        handleDropSignedFile(selectedFile, documentID)
                                                    }
                                                />
                                            )
                                        }
                                    ]}
                                    variant="text"
                                    color="orange"
                                    size="small"
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                filterable
                filters={filters}
                filtersForm={() => <FilterDocumentsForm filterDocuments={handleFetchDocuments} />}
                handleRemoveFilter={(filter) => {
                    removeFilter(filter)
                    handleFetchDocuments()
                }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder="Caută documente"
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    documents: orderBy(state.documents.data, 'id', 'desc'),
    isLoading: state.documents.isLoading,
    totalPages: state.documents.totalPages,
    nextPage: state.documents.next,
    previousPage: state.documents.previous,
    currentPage: state.documents.current,
    filters: state.filters.documents
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.documents.name, filter)),
    uploadSignedFile: (documentData, documentID) => dispatch(uploadSignedFile(documentData, documentID)),
    listDocuments: (search, filters, page) =>
        dispatch(
            RESOURCES.documents.list(
                {
                    ...filters,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList)
