import React, {useCallback, useState} from 'react'
import {toast} from 'react-toastify'

import _, {isEmpty} from 'lodash'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {connect} from 'react-redux'
// @ts-ignore
import {closeModal, modalTypes} from '../../redux/modals'

// @ts-ignore
import {Button} from '../Button'
// @ts-ignore
import {Modal} from '../Modal'

import '../../assets/scss/DeleteModals.scss'

interface DeleteBpiSubscriptionModalProps {
    open: boolean
    bpiSubscription: any
    closeModal: () => void
}

function DeleteBpiSubscriptionModal({open, bpiSubscription, closeModal}: DeleteBpiSubscriptionModalProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onDeleteSubscription = useCallback(() => {
        setIsLoading(true)
        performRequest(RESOURCES.bpiSubscriptions.destroy(bpiSubscription)).then((res: any) => {
            setIsLoading(false)
            if (!res.errors) {
                closeModal()
            } else {
                _.forEach(_.values(res.errors), (error) => {
                    toast.error(error.message)
                })
            }
        })
    }, [bpiSubscription, closeModal])

    return (
        <Modal
            open={open && !isEmpty(bpiSubscription)}
            title="Șterge abonarea BPI">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să ștergi abonarea pentru <span>"{bpiSubscription.company_name}"</span>?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={onDeleteSubscription}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.DELETE_BPI_SUBSCRIPTION,
    bpiSubscription: state.bpiSubscriptions.currentBpiSubscription
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBpiSubscriptionModal)
