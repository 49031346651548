import React, {useEffect, useMemo, useState} from 'react'
import {Form, Formik} from 'formik'

import {values as _values, debounce, find, omitBy} from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters} from '../../../../redux/filters/filters'
import {closeSlideover} from '../../../../redux/slideovers'

import {getFieldOptions} from '../../../../utils'
import {debounceWait} from '../../../../utils/constants'
import {roleTypes} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {ErrorsList} from '../../../../components/ErrorComponents'
import {Input} from '../../../../components/Input'
import {Select} from '../../../../components/Select'

import '../../../../assets/scss/SlideoverForms.scss'

export const FilterProjectsForm = ({
    filters,
    clients,
    isLoadingClients,
    roles,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    projectOptions,
    getProjectOptions,
    searchClients,
    addFilters,
    filterProjects,
    closeSlideover
}) => {
    const [clientsQuery, setClientsQuery] = useState('')

    const types = useMemo(() => getFieldOptions(projectOptions, 'type'), [projectOptions])

    useEffect(() => {
        getProjectOptions()
        handleFetchClients()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFetchClients = (query = clientsQuery) => searchClients(query)

    const debounceSearchClients = debounce(handleFetchClients, debounceWait)

    const handleChangeClientsSearchField = (value) => {
        setClientsQuery(value)
        debounceSearchClients(value)
    }

    const handleFilter = (filters) => {
        addFilters(filters)
        filterProjects(filters)
        closeSlideover()
    }

    const getInitialClient = () => find(clients, (client) => filters.client.value === client.id)

    const getInitialType = () => find(types, ['value', filters.type.value])

    const getInitialRole = () => find(roles, (role) => filters.role.value === role.value)

    return (
        <div className="slideover-form-container">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    name: filters.name.value,
                    client: getInitialClient(),
                    type: getInitialType(),
                    role: getInitialRole()
                }}
                onSubmit={(values) => {
                    const filtersData = {
                        name: {
                            value: values.name
                        },
                        client: {
                            value: values.client?.id || '',
                            displayValue: values.client?.name || ''
                        },
                        type: {
                            value: values.type?.value || '',
                            displayValue: values.type?.label || ''
                        },
                        role: {
                            value: values.role?.value || '',
                            displayValue: values.role?.label || ''
                        }
                    }

                    handleFilter(filtersData)
                }}>
                {({handleChange, setFieldValue, handleSubmit, values}) => (
                    <>
                        <Form className="slideover-form">
                            <Input
                                label="Nume proiect"
                                value={values.name}
                                onChange={handleChange('name')}
                                name="name"
                                errors={fieldErrors}
                                fullWidth
                            />
                            <Select
                                label="Client"
                                value={values.client}
                                options={clients}
                                onChange={(e) => setFieldValue('client', e)}
                                onInputChange={(value) => handleChangeClientsSearchField(value)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                loading={isLoadingClients}
                                name="clients"
                                errors={fieldErrors}
                                fullWidth
                            />
                            <Select
                                label="Tip proiect"
                                value={values.type}
                                options={types}
                                onChange={(e) => setFieldValue('type', e)}
                                name="type"
                                errors={fieldErrors}
                                fullWidth
                            />
                            <Select
                                label="Rolul meu"
                                value={values.role}
                                options={roles}
                                onChange={(e) => {
                                    setFieldValue('role', e)
                                }}
                                name="role"
                                errors={fieldErrors}
                                fullWidth
                            />
                        </Form>
                        <div className="buttons-container">
                            <Button
                                title="Aplică"
                                onClick={handleSubmit}
                                loading={isLoading}
                                type="submit"
                                fullWidth
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => ({
    filters: state.filters.projects,
    projectOptions: state.projects.options,
    isLoading: state.projects.isLoading,
    fieldErrors: state.projects.fieldErrors,
    nonFieldErrors: state.projects.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    clients: _values(state.clients.searchData),
    isLoadingClients: state.clients.isLoading,
    roles: _values(omitBy(roleTypes, (role) => role.value === roleTypes.CUSTOM.value))
})

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    getProjectOptions: () => dispatch(RESOURCES_V1.projects.getOptions()),
    searchClients: (search) => dispatch(RESOURCES.clients.search(search)),
    addFilters: (filters) => dispatch(addFilters(RESOURCES_V1.projects.name, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterProjectsForm)
