import React from 'react'
import {useTable} from 'react-table'

import {head, isEmpty, isNull, omitBy, toString} from 'lodash'

import {Loader} from '../Loader'

import {Pagination} from './Pagination'

import './Datatable.scss'
import FilterHeader from './Filters/FilterHeader/FilterHeader'

function Datatable({
    title,
    data,
    columns,
    loading,
    headerButton,
    customHeader,
    filterable,
    filtersForm,
    filters,
    handleRemoveFilter,
    searchable,
    searchValue,
    searchPlaceholder,
    onSearch,
    searchContainerButton,
    emptyText,
    totalPages,
    pageSize,
    onChangePageSize,
    onChangePage,
    currentPage,
    nextPage,
    previousPage,
    hideTableHead,
    rowProps
}) {
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({data, columns})

    const tableWidth = headerGroups.length === 1 ? head(headerGroups).headers.length : headerGroups.length

    const getAppliedFilters = () => omitBy(filters, (filter) => isEmpty(toString(filter.displayValue)))

    const appliedFilters = getAppliedFilters()

    return (
        <div className="avo-datatable-container">
            <div className="avo-datatable-container-inner">
                <div className="avo-datatable-container-shadow">
                    <table
                        className="avo-datatable"
                        {...getTableProps()}>
                        <thead className="avo-datatable-head">
                            {!hideTableHead && (
                                <>
                                    <tr>
                                        <td
                                            colSpan={tableWidth}
                                            className="avo-datatable-head-td">
                                            <div className="avo-datatable-header-container">
                                                <div className="loader-container">
                                                    {title && <p className="table-title">{title}</p>}
                                                    {loading && <Loader />}
                                                </div>
                                                {headerButton && headerButton()}
                                            </div>
                                            {customHeader && customHeader()}
                                        </td>
                                    </tr>
                                    {filterable || searchable || searchContainerButton ? (
                                        <tr>
                                            <td colSpan={tableWidth}>
                                                <FilterHeader
                                                    filterable={filterable}
                                                    filtersForm={filtersForm}
                                                    searchable={searchable}
                                                    searchValue={searchValue}
                                                    onSearch={onSearch}
                                                    searchPlaceholder={searchPlaceholder}
                                                    searchContainerButton={searchContainerButton}
                                                    appliedFilters={appliedFilters}
                                                    handleRemoveFilter={handleRemoveFilter}
                                                />
                                            </td>
                                        </tr>
                                    ) : null}
                                </>
                            )}
                            {/* // Apply the header row props */}
                            <tr {...head(headerGroups).getHeaderGroupProps()}>
                                {
                                    // Loop over the headers
                                    head(headerGroups).headers.map((column, index) => (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th
                                            {...column
                                                .getHeaderProps
                                                // column.getSortByToggleProps()
                                                ()}
                                            key={index}
                                            className="column-title">
                                            {/* Render the header */}
                                            {column.render('Header')}

                                            {/* Add a sort direction indicator */}
                                            {/* <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <ArrowLongDownIcon className='h-3 float-right'/>
                                                            : <ArrowLongUpIcon className='h-3 float-right'/>
                                                        : ''}
                                                </span> */}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody
                            className="avo-datatable-body"
                            {...getTableBodyProps()}>
                            {!isEmpty(rows) ? (
                                rows.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                        <tr
                                            key={index}
                                            className="table-row"
                                            {...row.getRowProps(rowProps?.(row))}>
                                            {row.cells.map((cell, index) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps([{style: cell.column.style}])}
                                                        key={index}
                                                        className="table-cell">
                                                        {/* Render the cell contents */}
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={tableWidth}
                                        className="table-cell">
                                        {!isEmpty(emptyText) ? emptyText : 'Fără rezultate'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        {(totalPages > 1 || pageSize) && (
                            <tfoot>
                                <tr>
                                    <td colSpan={tableWidth}>
                                        <div className="avo-datatable-footer">
                                            <Pagination
                                                total={totalPages}
                                                canNextPage={!isNull(nextPage)}
                                                onNext={() => onChangePage(nextPage)}
                                                canPreviousPage={!isNull(previousPage)}
                                                onPrevious={() => onChangePage(previousPage)}
                                                onChangePage={(page) => onChangePage(page)}
                                                currentPage={currentPage}
                                                pageSize={pageSize}
                                                onChangePageSize={onChangePageSize}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        )}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Datatable
