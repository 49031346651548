import {Form, Formik} from 'formik'
import React, {useEffect} from 'react'

import {isEmpty} from 'lodash'

import {createDocumentTemplate} from 'avoapp-react-common/dist/redux/documentTemplates'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes} from '../../../../../../redux/modals'

import {documentTemplatesSchema} from '../../../../../../assets/validations'
import {getFieldOptions, removeExtension} from '../../../../../../utils'
import {getTokenAndUploadFile} from '../../../../../../utils/files'

import {Button} from '../../../../../../components/Button'
import {DocumentDropzone} from '../../../../../../components/DocumentDropzone'
import {ErrorsList} from '../../../../../../components/ErrorComponents'
import {Input} from '../../../../../../components/Input'
import {Modal} from '../../../../../../components/Modal'
import {Select} from '../../../../../../components/Select'

export const AddDocumentTemplateModal = ({
    open,
    selectedEntityID,
    nonFieldErrors,
    fieldErrors,
    isLoading,
    documentTemplateOptions,
    getDocumentTemplateOptions,
    createDocumentTemplate
}) => {
    const types = getFieldOptions(documentTemplateOptions, 'type')

    useEffect(() => {
        getDocumentTemplateOptions()
    }, [getDocumentTemplateOptions])

    return (
        <Modal
            open={open}
            title="Adaugă șablon">
            {!isEmpty(documentTemplateOptions) && (
                <>
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{name: '', type: null, file: []}}
                        validationSchema={documentTemplatesSchema}
                        validateOnMount
                        onSubmit={async (values) => {
                            const fileURL = await getTokenAndUploadFile(values.file, selectedEntityID)

                            const data = {
                                entity_id: selectedEntityID,
                                name: values.name,
                                type: values.type.value,
                                file: fileURL
                            }
                            createDocumentTemplate(data)
                        }}>
                        {({handleChange, setFieldValue, handleBlur, values, handleSubmit, errors, touched}) => (
                            <Form className="space-y-6">
                                <DocumentDropzone
                                    onChange={(file) => {
                                        setFieldValue('file', file)
                                        setFieldValue('name', !isEmpty(file) ? removeExtension(file.name) : '')
                                    }}
                                />
                                <Input
                                    label="Nume"
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    onBlur={handleBlur('name')}
                                    name="name"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.name}
                                    fullWidth
                                />
                                <Select
                                    label="Tip"
                                    value={values.type}
                                    options={types}
                                    onChange={(e) => setFieldValue('type', e)}
                                    fullWidth
                                />
                                <Button
                                    title="Adaugă sablon"
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    color="secondary"
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.ADD_DOCUMENT_TEMPLATE,
    documentTemplateOptions: state.documentTemplates.options,
    fieldErrors: state.documentTemplates.fieldErrors,
    nonFieldErrors: state.documentTemplates.nonFieldErrors,
    isLoading: state.documentTemplates.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    getDocumentTemplateOptions: () => dispatch(RESOURCES.documentTemplates.getOptions()),
    createDocumentTemplate: (documentTemplateData) => dispatch(createDocumentTemplate(documentTemplateData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentTemplateModal)
