import {getDocumentTypes} from '../Settings/constants'

export const actionIds = {
    INFO: 'info',
    SETTINGS: 'settings',
    PREVIEW: 'preview',
    SIGNED: 'signed'
}

export const actions = {
    [actionIds.INFO]: {
        id: actionIds.INFO,
        label: 'Informații'
    },
    [actionIds.SETTINGS]: {
        id: actionIds.SETTINGS,
        label: 'Setări împuternicire'
    },
    [actionIds.PREVIEW]: {
        id: actionIds.PREVIEW,
        label: 'Document Editabil'
    },
    [actionIds.SIGNED]: {
        id: actionIds.SIGNED,
        label: 'Împuternicire semnată'
    }
}

export const documentTemplateTypes = getDocumentTypes()
