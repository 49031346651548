import React, {useMemo} from 'react'

import {Button} from '../Button'
import {HandRaisedIcon} from '@heroicons/react/24/outline'

export default function ManualUpdateInvoiceButton({invoice, onClick}) {
    const isButtonHidden = useMemo(() => {
        return invoice.sync_state_smartbill !== 'manual_needs_update'
    }, [invoice.sync_state_smartbill])

    if (isButtonHidden) return

    return (
        <Button
            title="Marchează ca sincronizat"
            icon={() => <HandRaisedIcon />}
            onClick={onClick}
            size="small"
            color="orange"
        />
    )
}
