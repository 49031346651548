import React from 'react'
import {CheckCell} from '../../../../components/CheckCell'

import './CellWithCheck.scss'

export default function CellWithCheck({value, isPrimary}) {
    return (
        <div className="cell-with-primary-check-container">
            {value}
            {isPrimary && <CheckCell />}
        </div>
    )
}
