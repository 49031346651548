import React from 'react'

import StackSection from './StackSection'
import {StackSectionConfig} from './types'

import './StackDatatable.scss'

interface StackDatatableProps {
    sectionsConfig: StackSectionConfig[]
}

const StackDatatable: React.FC<StackDatatableProps> = ({sectionsConfig}) => {
    return (
        <div className="stack-datatable">
            {sectionsConfig.map((sectionConfig, index) => (
                <StackSection
                    sectionConfig={sectionConfig}
                    key={index}
                />
            ))}
        </div>
    )
}

export default StackDatatable
