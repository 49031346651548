import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {useFormik} from 'formik'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes} from '../../redux/modals'

import {getFieldOptions, objectKeysToSnakeCase} from '../../utils'
import {debounceWait} from '../../utils/constants'
import {useDebouncedEffect} from '../../utils/hooks'

import {Button} from '../Button'
import {ErrorsList, InputError} from '../ErrorComponents'
import {Input} from '../Input'
import {Modal} from '../Modal'
import {Select} from '../Select'

import {UNSPECIFIED_OBJECT} from './constants'

import './AddInvoicePaymentDistributionModal.scss'
import {calcMethods} from '../../utils/types'

export const AddInvoicePaymentDistributionModal = ({
    open,
    invoiceID,
    createInvoicePaymentDistribution,
    fieldErrors,
    nonFieldErrors,
    invoice,
    projects,
    isLoadingProjects,
    searchProjects,
    invoicePaymentDistributionsOptions,
    getInvoicePaymentDistributionsOptions,
    invoicePayments,
    isLoadingInvoicePayments,
    searchInvoicePayments,
    selectedEntityID,
    entityProfiles,
    isLoadingEntityProfiles,
    listAllEntityProfiles
}) => {
    const [projectsQuery, setProjectsQuery] = useState('')
    const [invoicePaymentsQuery, setInvoicePaymentsQuery] = useState('')

    useEffect(() => {
        listAllEntityProfiles(selectedEntityID)
    }, [listAllEntityProfiles, selectedEntityID])

    useEffect(() => {
        getInvoicePaymentDistributionsOptions()
    }, [getInvoicePaymentDistributionsOptions])

    const handleFetchProjects = useCallback(
        (query = projectsQuery) => {
            searchProjects(query)
        },
        [projectsQuery, searchProjects]
    )

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    const handleChangeProjectsSearchField = (value) => setProjectsQuery(value)

    const handleFetchInvoicePayments = useCallback(
        (query = invoicePaymentsQuery) => {
            const params = {
                invoice_id: invoiceID
            }

            searchInvoicePayments(query, params)
        },
        [invoiceID, invoicePaymentsQuery, searchInvoicePayments]
    )

    useEffect(() => {
        if (open) handleFetchInvoicePayments()
    }, [open, handleFetchInvoicePayments])

    useDebouncedEffect(handleFetchInvoicePayments, [invoicePaymentsQuery], debounceWait)

    const handleChangeInvoicePaymentsSearchField = (value) => setInvoicePaymentsQuery(value)

    const getInvoicePaymentOptionLabel = useCallback(
        (option) => {
            let label = ''

            if (!_.isEmpty(invoicePayments) && !_.isNil(option.series_name) && !_.isNil(option.series_number)) {
                label = `${option.series_name}${option.series_number}`
            }

            if (!_.isNil(option.date)) {
                label = `${label} din  ${lightFormat(new Date(option.date), 'dd/MM/yyyy')}`
            }

            return label
        },
        [invoicePayments]
    )

    const invoicePaymentDistributionTypes = useMemo(() => {
        return getFieldOptions(invoicePaymentDistributionsOptions, 'type')
    }, [invoicePaymentDistributionsOptions])

    const invoicePaymentDistributionExternalTypes = useMemo(() => {
        return getFieldOptions(invoicePaymentDistributionsOptions, 'external_type')
    }, [invoicePaymentDistributionsOptions])

    const invoicePaymentDistributionUnits = useMemo(() => {
        return getFieldOptions(invoicePaymentDistributionsOptions, 'unit')
    }, [invoicePaymentDistributionsOptions])

    const invoicePaymentDistributionResons = useMemo(() => {
        const rawReasonOptions = getFieldOptions(invoicePaymentDistributionsOptions, 'reason')
        return _.concat(rawReasonOptions, UNSPECIFIED_OBJECT)
    }, [invoicePaymentDistributionsOptions])

    const {values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, handleReset} = useFormik({
        initialValues: {
            projectId: null,
            invoicePaymentId: null,
            type: null,
            externalType: null,
            entityProfileId: null,
            unit: null,
            reason: UNSPECIFIED_OBJECT,
            amount: ''
        },
        onSubmit: (values) => {
            createInvoicePaymentDistribution({
                ...objectKeysToSnakeCase(values),
                entity_id: selectedEntityID,
                project_id: values.projectId.id,
                invoice_payment_id: values.invoicePaymentId.id,
                type: values.type.value,
                external_type: values.externalType?.value || null,
                entity_profile_id: values.entityProfileId?.value || null,
                unit: values.unit.value,
                reason: values.reason.value
            })
        }
    })

    useEffect(() => {
        if (!open) handleReset()
    }, [handleReset, open])

    useEffect(() => {
        if (!_.isNil(invoiceID) && !_.isNil(invoice.project) && _.isNil(values.projectId)) {
            setFieldValue('projectId', invoice.project)
        }
    }, [invoice.project, invoiceID, setFieldValue, values.projectId])

    useEffect(() => {
        if (!_.isNil(invoiceID) && !_.isEmpty(invoice) && _.isNil(values.invoiceId)) {
            setFieldValue('invoiceId', invoice)
        }
    }, [invoice, invoiceID, setFieldValue, values.invoiceId])

    useEffect(() => {
        if (open && _.isNil(values.invoicePaymentId)) {
            setFieldValue('invoicePaymentId', invoicePayments.length === 1 ? invoicePayments[0] : null)
        }
    }, [open, invoicePayments, setFieldValue, values.invoicePaymentId])

    const isInvoiceCanceled = useMemo(() => {
        return invoice.state === 'canceled'
    }, [invoice.state])

    const amountToBeDistributed = useMemo(() => {
        switch (values.unit ? values.unit.value : null) {
            case calcMethods.SUM.value:
                return Number(values.amount) || 0
            case calcMethods.PERCENT.value:
                return _.round((values.amount / 100) * values.invoicePaymentId.amount, 2)
            default:
                return 0
        }
    }, [values.unit, values.amount, values.invoicePaymentId])

    const remainingAmountToBeDistributed = useMemo(() => {
        return values.invoicePaymentId
            ? _.round(values.invoicePaymentId.amount - values.invoicePaymentId.amount_distributed, 2)
            : 0
    }, [values.invoicePaymentId])

    return (
        <Modal
            open={open && !isInvoiceCanceled}
            title="Adăugare distribuire">
            {!_.isEmpty(projects) && !_.isEmpty(invoicePayments) && (
                <>
                    <ErrorsList errors={nonFieldErrors} />
                    <form className="add-invoice-payment-distribution-form-container">
                        <Select
                            label="Proiect*"
                            value={values.projectId}
                            options={projects}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(option) => setFieldValue('projectId', option)}
                            onInputChange={(value) => handleChangeProjectsSearchField(value)}
                            onBlur={handleBlur('projectId')}
                            disabled={!_.isNil(invoiceID)}
                            name="projectId"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.projectId}
                            loading={isLoadingProjects}
                            isClearable
                            fullWidth
                        />
                        <Select
                            label="Încasare de distribuit*"
                            value={values.invoicePaymentId}
                            options={!isLoadingInvoicePayments ? invoicePayments : []}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={getInvoicePaymentOptionLabel}
                            onChange={(option) => setFieldValue('invoicePaymentId', option)}
                            onInputChange={(value) => handleChangeInvoicePaymentsSearchField(value)}
                            onBlur={handleBlur('invoicePaymentId')}
                            name="invoicePaymentId"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.invoicePaymentId}
                            loading={isLoadingInvoicePayments}
                            isClearable
                            fullWidth
                        />
                        <Select
                            label="Către cine*"
                            value={values.type}
                            options={invoicePaymentDistributionTypes}
                            onChange={(e) => setFieldValue('type', e)}
                            onBlur={handleBlur('type')}
                            name="type"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.type}
                            fullWidth
                        />
                        {values.type?.value === 'external' && (
                            <Select
                                label="Tip*"
                                value={values.externalType}
                                options={invoicePaymentDistributionExternalTypes}
                                onChange={(option) => setFieldValue('externalType', option)}
                                onBlur={handleBlur('externalType')}
                                name="externalType"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.externalType}
                                isClearable
                                fullWidth
                            />
                        )}
                        {values.type?.value === 'lawyer' && (
                            <Select
                                label="Avocat*"
                                value={values.entityProfileId}
                                options={entityProfiles}
                                onChange={(option) => setFieldValue('entityProfileId', option)}
                                onBlur={handleBlur('entityProfileId')}
                                name="entityProfileId"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.entityProfileId}
                                loading={isLoadingEntityProfiles}
                                isClearable
                                fullWidth
                            />
                        )}
                        <Select
                            label="Tip calcul*"
                            value={values.unit}
                            options={invoicePaymentDistributionUnits}
                            onChange={(e) => setFieldValue('unit', e)}
                            onBlur={handleBlur('unit')}
                            name="unit"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.unit}
                            fullWidth
                        />
                        <Input
                            label="Valoare*"
                            value={values.amount}
                            onChange={handleChange('amount')}
                            onBlur={handleBlur('amount')}
                            name="amount"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.amount}
                            fullWidth
                        />
                        <Select
                            label="Motiv*"
                            value={values.reason}
                            options={invoicePaymentDistributionResons}
                            onChange={(e) => setFieldValue('reason', e)}
                            onBlur={handleBlur('reason')}
                            name="reason"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.reason}
                            fullWidth
                        />
                        <hr />
                        {values.invoicePaymentId && (
                            <>
                                <div>
                                    Suma rămasă de distribuit: {remainingAmountToBeDistributed} {invoice.currency}
                                </div>
                                <div>
                                    Suma ce urmează a fi distribuită: {amountToBeDistributed} {invoice.currency}
                                </div>
                            </>
                        )}
                        {amountToBeDistributed > remainingAmountToBeDistributed && (
                            <InputError message="Nu puteți distribui mai mult decât suma rămasă" />
                        )}
                        <Button
                            disabled={remainingAmountToBeDistributed < amountToBeDistributed}
                            title="Salvează distribuire"
                            onClick={handleSubmit}
                            color="secondary"
                        />
                    </form>
                </>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => {
    let entityProfiles = _.values(state.entityProfiles.data).map((profile) => ({
        value: profile.id,
        label: `${profile.first_name} ${profile.last_name}`
    }))

    return {
        open: state.modals.type === modalTypes.ADD_INVOICE_PAYMENT_DISTRIBUTION,
        invoicePaymentDistributionsOptions: state.invoicePaymentDistributions.options,
        nonFieldErrors: state.invoicePayments.nonFieldErrors,
        fieldErrors: state.invoicePayments.fieldErrors,
        invoice: state.invoices.currentInvoice,
        projects: _.values(state.projects.searchData),
        isLoadingProjects: state.projects.isLoading,
        invoicePayments: _.values(state.invoicePayments.searchData),
        isLoadingInvoicePayments: state.invoicePayments.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID,
        entityProfiles: entityProfiles,
        isLoadingEntityProfiles: state.entityProfiles.id
    }
}

const mapDispatchToProps = (dispatch) => ({
    createInvoicePaymentDistribution: (values) => dispatch(RESOURCES.invoicePaymentDistributions.create(values)),
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search, {active: 'true'})),
    searchInvoicePayments: (search, params) => dispatch(RESOURCES.invoicePayments.search(search, params)),
    getInvoicePaymentDistributionsOptions: () => dispatch(RESOURCES.invoicePaymentDistributions.getOptions()),
    listAllEntityProfiles: (selectedEntityID) =>
        dispatch(RESOURCES.entityProfiles.listAll({entity_id: selectedEntityID}))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoicePaymentDistributionModal)
