import {ArrowLeftIcon} from '@heroicons/react/24/outline'
import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {Button} from '../../components/Button'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {actionIds, actions} from './constants'
import {
    Addendums,
    Contracts,
    Documents,
    Empowerments,
    ExternalDataBPI,
    ExternalDataMD,
    History,
    InfoClientNotes,
    InfoContactPersons,
    InfoGeneral,
    Invoices,
    Tasks
} from './partials'

import {Expenses} from '../Expenses'

import './ClientDetails.scss'

export class ClientDetails extends Component {
    componentDidMount = () => {
        const {retrieveClient, match} = this.props
        const {clientID} = match.params

        retrieveClient(clientID)
    }

    render() {
        const {client, match} = this.props
        const {clientID} = match.params

        const urlData = {prefix: `/clients/${clientID}`, params: match.params}

        const goToInfo = () => {
            switch (match.params.secondaryAction) {
                case actionIds.INFO_GENERAL:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<InfoGeneral match={match} />}
                        />
                    )
                case actionIds.INFO_CONTACT_PERSONS:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<InfoContactPersons match={match} />}
                        />
                    )
                case actionIds.INFO_NOTES:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<InfoClientNotes match={match} />}
                        />
                    )

                default:
                    return <Redirect to={`${urlData.prefix}/${actionIds.INFO}/${actionIds.INFO_GENERAL}`} />
            }
        }

        const goToExternal = () => {
            switch (match.params.secondaryAction) {
                case actionIds.EXTERNAL_DATA_MD:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<ExternalDataMD match={match} />}
                        />
                    )
                case actionIds.EXTERNAL_DATA_BPI:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<ExternalDataBPI match={match} />}
                        />
                    )

                default:
                    return (
                        <Redirect to={`${urlData.prefix}/${actionIds.EXTERNAL_DATA}/${actionIds.EXTERNAL_DATA_MD}`} />
                    )
            }
        }

        const goToTasks = () => {
            switch (match.params.secondaryAction) {
                case actionIds.TASKS_ACTIVE:
                case actionIds.TASKS_FINISHED:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<Tasks match={match} />}
                        />
                    )
                default:
                    return <Redirect to={`${urlData.prefix}/${actionIds.TASKS}/${actionIds.TASKS_ACTIVE}`} />
            }
        }

        const goToContracts = () => {
            switch (match.params.secondaryAction) {
                case actionIds.CONTRACTS_CONTRACTS:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<Contracts match={match} />}
                        />
                    )

                case actionIds.CONTRACTS_EMPOWERMENTS:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<Empowerments match={match} />}
                        />
                    )

                case actionIds.CONTRACTS_ADDENDUMS:
                    return (
                        <ClientDetailsLayout
                            client={client}
                            urlData={urlData}
                            component={<Addendums match={match} />}
                        />
                    )

                default:
                    return <Redirect to={`${urlData.prefix}/${actionIds.CONTRACTS}/${actionIds.CONTRACTS_CONTRACTS}`} />
            }
        }

        switch (match.params.primaryAction) {
            case actionIds.INFO:
                return goToInfo()

            case actionIds.EXTERNAL_DATA:
                return goToExternal()

            case actionIds.TASKS:
                return goToTasks()

            case actionIds.CONTRACTS:
                return goToContracts()

            case actionIds.DOCUMENTS:
                return (
                    <ClientDetailsLayout
                        client={client}
                        urlData={urlData}
                        component={<Documents match={match} />}
                    />
                )

            case actionIds.EXPENSES:
                return (
                    <ClientDetailsLayout
                        client={client}
                        urlData={urlData}
                        component={<Expenses match={match} />}
                    />
                )

            case actionIds.INVOICES:
                return (
                    <ClientDetailsLayout
                        client={client}
                        urlData={urlData}
                        component={<Invoices match={match} />}
                    />
                )

            case actionIds.HISTORY:
                return (
                    <ClientDetailsLayout
                        client={client}
                        urlData={urlData}
                        component={<History match={match} />}
                    />
                )

            default:
                return <Redirect to={`${urlData.prefix}/${actionIds.INFO}/${actionIds.INFO_GENERAL}`} />
        }
    }
}

export function ClientDetailsLayout(props) {
    const {client, component, urlData} = props

    return (
        <div className="resource-details-content">
            <div className="back-button-container">
                <Link to="/clients">
                    <Button
                        title="Mergi înapoi la listă clienți"
                        size="small"
                        color="gray"
                        variant="outlined"
                        icon={() => <ArrowLeftIcon />}
                        iconLeft
                    />
                </Link>
            </div>
            <div className="header-resource-title-container">
                <p className="header-resource-title">{client.name}</p>
                <div className={`client-active-badge-container ${client.active ? 'client-active' : 'client-inactive'}`}>
                    <p className="client-active-badge-text">{client.active ? 'Client Activ' : 'Client Inactiv'}</p>
                </div>
            </div>
            <SecondaryNavbar
                actions={actions}
                urlData={urlData}
            />
            <div className="details-div">
                <div className="details-content">{component}</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    client: state.clients.currentClient
})

const mapDispatchToProps = (dispatch) => ({
    retrieveClient: (clientID) => dispatch(RESOURCES.clients.retrieve(clientID))
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails)
