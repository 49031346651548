import React, {useCallback, useEffect} from 'react'

import {ChevronRightIcon} from '@heroicons/react/20/solid'

import _ from 'lodash'

import {entityProfilesStatus} from 'avoapp-react-common/dist/constants'
import {retrieveAndSetCurrent} from 'avoapp-react-common/dist/redux/entities'
import {getBelongingEntities} from 'avoapp-react-common/dist/redux/entityProfiles'
import {selectEntityID, selectEntityProfileID} from 'avoapp-react-common/dist/redux/localConfigs'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {retrieveLastRunningTaskTimeLog} from 'avoapp-react-common/dist/redux/taskTimeLogs'
import {getEntityName} from 'avoapp-react-common/dist/utils'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'

import logo from '../../assets/images/avoapp-logo-albastru.png'

import {PageLoader} from '../../components/PageLoader'

import './SelectEntity.scss'

export const SelectEntity = ({
    entities,
    isLoading,
    user,
    getBelongingEntities,
    navigateHome,
    retrieveAndSetCurrent,
    selectEntityID,
    selectEntityProfileID,
    fetchUsersPreferences,
    retrieveLastRunningTaskTimeLog
}) => {
    useEffect(() => {
        if (!_.isNil(user) && !_.isEmpty(user)) {
            getBelongingEntities(user.id)
        }
    }, [getBelongingEntities, user])

    const isActive = useCallback((entity) => entity.profileStatus === entityProfilesStatus.ACTIVE.value, [])

    const handleEntitySelect = useCallback(
        (entity) => {
            if (isActive(entity)) {
                retrieveAndSetCurrent(entity.id)
                selectEntityID(entity.id)
                selectEntityProfileID(entity.profileID)
                retrieveLastRunningTaskTimeLog()
                fetchUsersPreferences()
                navigateHome()
            }
        },
        [
            fetchUsersPreferences,
            isActive,
            navigateHome,
            retrieveAndSetCurrent,
            retrieveLastRunningTaskTimeLog,
            selectEntityID,
            selectEntityProfileID
        ]
    )

    return (
        <div className="select-entity-page-container">
            <div className="select-entity-avoapp-logo-container">
                <img
                    className="h-16 w-auto"
                    src={logo}
                    alt="AvoApp"
                />
            </div>
            <p className="select-entity-page-title">Selectează compania în numele căreia vrei să folosești aplicația</p>
            {!_.isNil(entities) && !_.isEmpty(entities) && !isLoading ? (
                <ul className="entities-list">
                    {entities.map((entity) => (
                        <li
                            className={`entity-list-item ${!isActive(entity) ? 'disabled' : ''}`}
                            onClick={() => handleEntitySelect(entity)}
                            key={entity.id}>
                            <div className="entity-list-item-left-container">
                                <div className="min-w-0 flex-auto">
                                    <p className="entity-name">{getEntityName(entity)}</p>
                                    <p className="entity-profile-status-container">
                                        {!isActive(entity) && (
                                            <p className="entity-profile-status">
                                                {entity.profileStatus === entityProfilesStatus.PENDING.value
                                                    ? entityProfilesStatus.PENDING.action
                                                    : entityProfilesStatus[_.toUpper(entity.profileStatus)].label}
                                            </p>
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="entity-list-item-right-container">
                                <ChevronRightIcon
                                    className="h-5 w-5 flex-none text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            ) : isLoading ? (
                <PageLoader />
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    entities: _.values(state.entityProfiles.belongingEntities),
    isLoading: state.entityProfiles.isLoading,
    user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
    navigateHome: () => dispatch(push('/dashboard')),
    getBelongingEntities: (userID) => dispatch(getBelongingEntities(userID)),
    fetchUsersPreferences: () => dispatch(RESOURCES.usersPreferences.retrieve('me')),
    retrieveAndSetCurrent: (entityID) => dispatch(retrieveAndSetCurrent(entityID)),
    selectEntityID: (entityID) => dispatch(selectEntityID(entityID)),
    selectEntityProfileID: (entityProfileID) => dispatch(selectEntityProfileID(entityProfileID)),
    retrieveLastRunningTaskTimeLog: () => dispatch(retrieveLastRunningTaskTimeLog())
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectEntity)
