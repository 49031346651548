import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../../../redux/modals'
import {destroyDocumentVersion} from 'avoapp-react-common/dist/redux/documentVersions'

import {Modal} from '../../../../components/Modal'
import {Button} from '../../../../components/Button'

import '../../../../assets/scss/DeleteModals.scss'

function DeleteDocumentVersionModal({
    open,
    document,
    currentDocumentVersion,
    isLoading,
    closeModal,
    destroyDocumentVersion
}) {
    return (
        <Modal
            open={open && !isEmpty(document) && !isEmpty(currentDocumentVersion)}
            title="Șterge versiune document">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să ștergi versiunea <span>{currentDocumentVersion.version}</span> pentru
                    documentul "<span>{document.name}</span>"?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={() => destroyDocumentVersion(currentDocumentVersion, document.id)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_DOCUMENT_VERSION,
    document: state.documents.currentDocument,
    currentDocumentVersion: state.documentVersions.currentDocumentVersion,
    isLoading: state.documentVersions.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    destroyDocumentVersion: (version, documentID) => dispatch(destroyDocumentVersion(version, documentID))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDocumentVersionModal)
