import React from 'react'

import {SecondaryNavbar} from '../../components/SecondaryNavbar'
import {actionIds, actions, prefix} from './constants'

import {TimeLogList} from './partials'

import './Reports.scss'
import {Loader} from '../../components/Loader'

const Reports = ({match}) => {
    const urlData = {prefix: prefix, params: match.params}
    const isLoading = false

    switch (match.params.primaryAction) {
        case actionIds.TIME_LOGS:
            return (
                <div>
                    <div className="w-full min-w-full flex flex-row justify-between items-center">
                        <div className="flex flex-row items-center gap-4">
                            <SecondaryNavbar
                                actions={actions}
                                urlData={urlData}
                            />
                            <span className="pb-2">{isLoading && <Loader />}</span>
                        </div>
                    </div>
                    <div className="page-info">
                        <TimeLogList />
                    </div>
                </div>
            )

        default:
            return
    }
}

export default Reports
