import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {modalTypes, openModal} from '../../redux/modals'

import './Calendar.scss'
// @ts-ignore
import {toApiDateFormat} from '../../utils'
// @ts-ignore
import {AddTaskModal} from '../../components/AddTaskModal'
import {connect} from 'react-redux'
import {Hearing, Task} from '../../types/api'
import {CalendarFilter} from './filters'
import {useQueryParams} from 'use-query-params'
import {addDays} from 'date-fns'
import {EventType} from './types'
import useEventsResources from './useEventsResources'
import {SelectFilter} from '../../components/FilterForm/filters'
import {SelectFilterConfig} from '../FilterForm/types'

interface CalendarProps {
    selectedEntityID: number
    modalIsOpen: boolean
    updateTask: (data: any, itemID: number) => Task
    updateHearing: (data: any, itemID: number) => Hearing
    openAddTaskModal: () => void
}

const Calendar: React.FC<CalendarProps> = ({
    selectedEntityID,
    modalIsOpen,
    updateTask,
    updateHearing,
    openAddTaskModal
}) => {
    const [query, setQuery] = useQueryParams(CalendarFilter.mapping)
    const {events} = useEventsResources({selectedEntityID, modalIsOpen, query})

    const [newEventData, setNewEventData] = useState<any>()

    useEffect(() => {
        setQuery({
            resources: [
                {value: EventType.TASK, label: 'Sarcini'},
                {value: EventType.HEARING, label: 'Termene'}
            ]
        })
    }, [setQuery])

    const handleDateSelect = (selectInfo: any) => {
        setNewEventData({
            start: selectInfo.start,
            stop: selectInfo.allDay ? addDays(selectInfo.end, 1) : selectInfo.end,
            allDay: selectInfo.allDay
        })

        openAddTaskModal()
    }

    const handleEventUpdated = ({event}: any) => {
        if (event.extendedProps.eventType === EventType.TASK) {
            updateTask(
                {
                    start: toApiDateFormat(event.start),
                    stop: toApiDateFormat(event.end)
                },
                event.id
            )
        } else if (event.extendedProps.eventType === EventType.HEARING) {
            updateHearing(
                {
                    date: toApiDateFormat(event.start)
                },
                event.id
            )
        }
    }

    const handleDatesSet = (arg: any) => {
        const {start, end} = arg

        setQuery({
            start__lte: end,
            stop__gte: start,
            moment__lte: end,
            moment__gte: start
        })
    }

    return (
        <>
            <div className="resource-select">
                <SelectFilter
                    filterName={'resources'}
                    filterConfig={CalendarFilter.config.resources as SelectFilterConfig}
                    value={query.resources}
                    setFieldValue={(filterName, e) => {
                        setQuery({...query, [filterName]: e})
                    }}
                    selectedEntityID={selectedEntityID}
                />
            </div>
            <FullCalendar
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                buttonText={{
                    today: 'Astăzi',
                    month: 'Lună',
                    week: 'Săptămână',
                    day: 'Zi'
                }}
                moreLinkText="mai mult"
                moreLinkContent={({num}) => `+ alte ${num}`}
                displayEventTime={true}
                displayEventEnd={true}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                // droppable={true}
                // editable={true}
                // selectable={true}
                initialView="dayGridMonth"
                events={events}
                select={handleDateSelect}
                timeZone="local"
                locale="ro"
                dayMaxEventRows={true}
                datesSet={handleDatesSet}
                eventDrop={handleEventUpdated}
            />
            {newEventData && <AddTaskModal initialData={newEventData} />}
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        modalIsOpen: state.modals.type === modalTypes.ADD_TASK,
        entityProfileID: state.localConfigs.entityProfileID,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    updateTask: (data: any, itemID: number) => dispatch(RESOURCES.tasks.update(data, itemID)),
    updateHearing: (data: any, itemID: number) => dispatch(RESOURCES.hearings.update(data, itemID)),
    openAddTaskModal: () => dispatch(openModal(modalTypes.ADD_TASK))
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
