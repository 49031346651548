import React, {useEffect, useMemo, useState} from 'react'
import {Form, Formik} from 'formik'

import {values as _values, isEmpty, find, differenceBy, debounce, isNull, head, get} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'

import {getFieldOptions} from '../../utils'
import {roleTypes} from '../../utils/types'
import {debounceWait} from '../../utils/constants'
import {projectsSchema} from '../../assets/validations'

import {Input} from '../../components/Input'
import {Select} from '../../components/Select'
import {FormSection} from '../../components/FormSection'
import {SimilarClientModal} from '../../components/SimilarClientModal'
import {LegalObjectCreatable} from '../../components/LegalObjectCreatable'
import {RequiredFieldsText} from '../../components/RequiredFieldsText'
import {ErrorsList} from '../../components/ErrorComponents'
import {PageLoader} from '../../components/PageLoader'

import {AutoSelectClient} from './partials'

import './ProjectAdd.scss'
import queryString from 'query-string'
import {performRequest} from 'avoapp-react-common/dist/redux/api'

export const ProjectAdd = ({
    personalEntityProfile,
    entityProfiles,
    isLoadingEntityProfiles,
    createProject,
    projectOptions,
    isLoadingProjects,
    selectedEntityID,
    fieldErrors,
    nonFieldErrors,
    clients,
    isLoadingClients,
    createClient,
    searchClients,
    listAllEntityProfiles,
    getProjectOptions,
    location
}) => {
    const [clientsQuery, setClientsQuery] = useState('')
    const [initialClient, setInitialClient] = useState(null)
    const [isLoadingInitialClient, setIsLoadingInitialClient] = useState(false)

    const types = useMemo(() => getFieldOptions(projectOptions, 'type'), [projectOptions])

    const fetchInitialClient = async (clientID) => {
        setIsLoadingInitialClient(true)
        const {data} = await performRequest(RESOURCES.clients.retrieve(clientID))
        setIsLoadingInitialClient(false)
        return data
    }

    useEffect(() => {
        const params = queryString.parse(location.search)
        const initialClientID = get(params, 'clientID', null)
        if (initialClientID) {
            fetchInitialClient(initialClientID).then((client) => {
                setInitialClient(client)
            })
        }

        getProjectOptions()
        listAllEntityProfiles(selectedEntityID)
        handleFetchClients()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFetchClients = (query = clientsQuery) => searchClients(query)

    const debounceSearchClients = debounce(handleFetchClients, debounceWait)

    const handleChangeClientsSearchField = (value) => {
        setClientsQuery(value)
        debounceSearchClients(value)
    }

    const handleCreateClient = () => {
        const clientData = {
            name: clientsQuery,
            entity_id: selectedEntityID
        }

        createClient(clientData)
    }

    const shouldRenderForm =
        !isEmpty(projectOptions) && !isEmpty(entityProfiles) && !isLoadingEntityProfiles && !isLoadingInitialClient

    return (
        <div className="project-add-form-container">
            {shouldRenderForm ? (
                <Formik
                    initialValues={{
                        type: head(types),
                        name: '',
                        legalObjectId: null,
                        managers: [personalEntityProfile],
                        collaborators: [],
                        clientsIds: initialClient ? [initialClient] : []
                    }}
                    validationSchema={projectsSchema}
                    onSubmit={(values) => {
                        const managers = values.managers.map((manager) => ({
                            entity_profile_id: manager.value,
                            role: roleTypes.MANAGER.value
                        }))

                        const collaborators = values.collaborators.map((colaborator) => ({
                            entity_profile_id: colaborator.value,
                            role: roleTypes.COLLABORATOR.value
                        }))

                        const projectData = {
                            entity_id: selectedEntityID,
                            name: values.name,
                            type: values.type.value,
                            legal_object_id: !isNull(values.legalObjectId) ? values.legalObjectId.value : null,
                            project_permissions: [...collaborators, ...managers],
                            clients_ids: values.clientsIds.map((client) => client.id)
                        }

                        createProject(projectData)
                    }}>
                    {({handleChange, handleBlur, setFieldValue, handleSubmit, values, touched, errors, isValid}) => (
                        <Form>
                            <ErrorsList errors={nonFieldErrors} />
                            <FormSection
                                renderForm={() => (
                                    <>
                                        {initialClient && (
                                            <p className="form-section-title">
                                                Adaugă primul proiect pentru clientul "{initialClient.name}"
                                            </p>
                                        )}
                                        <Select
                                            label="Tip proiect*"
                                            value={values.type}
                                            options={types}
                                            onChange={(e) => setFieldValue('type', e)}
                                            onBlur={handleBlur('type')}
                                            name="type"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.type}
                                            fullWidth
                                        />
                                        <Input
                                            label="Nume proiect*"
                                            value={values.name}
                                            onChange={handleChange('name')}
                                            onBlur={handleBlur('name')}
                                            name="name"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.name}
                                            fullWidth
                                        />
                                        <Select
                                            label="Clienți"
                                            value={values.clientsIds}
                                            options={clients}
                                            loading={isLoadingClients}
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e) => setFieldValue('clientsIds', e)}
                                            onInputChange={(value) => handleChangeClientsSearchField(value)}
                                            onBlur={handleBlur('clients')}
                                            noOptionsMessage={() => (
                                                <div
                                                    className="avoapp-select-no-options-custom-message"
                                                    onClick={handleCreateClient}>
                                                    Crează clientul <span>"{clientsQuery}"</span>
                                                </div>
                                            )}
                                            name="clients"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.clients}
                                            isMulti
                                            fullWidth
                                        />
                                        <SimilarClientModal />
                                        <AutoSelectClient handleResetClientsQuery={() => setClientsQuery('')} />
                                        <LegalObjectCreatable
                                            value={values.legalObjectId}
                                            onChange={(value) => setFieldValue('legalObjectId', value)}
                                            onBlur={handleBlur('legalObjectId')}
                                            name="legalObjectId"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.legalObjectId}
                                        />
                                        <div className="split-row">
                                            <Select
                                                label="Manageri*"
                                                value={values.managers}
                                                options={differenceBy(entityProfiles, values.collaborators, 'value')}
                                                onChange={(e) => setFieldValue('managers', e)}
                                                name="clientPermissions"
                                                errors={fieldErrors}
                                                menuPlacement="top"
                                                fullWidth
                                                isMulti
                                            />
                                            <Select
                                                label="Colaboratori"
                                                value={values.collaborators}
                                                options={differenceBy(entityProfiles, values.managers, 'value')}
                                                onChange={(e) => setFieldValue('collaborators', e)}
                                                name="clientPermissions"
                                                errors={fieldErrors}
                                                menuPlacement="top"
                                                fullWidth
                                                isMulti
                                            />
                                        </div>
                                        <RequiredFieldsText />
                                    </>
                                )}
                                buttonDisabled={!isValid}
                                onSubmit={handleSubmit}
                                submitButtonTitle="Mai departe"
                                loading={isLoadingProjects}
                            />
                        </Form>
                    )}
                </Formik>
            ) : (
                <PageLoader />
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    const entityProfiles = _values(state.entityProfiles.data).map((profile) => ({
        value: profile.id,
        label: `${profile.first_name} ${profile.last_name}`
    }))

    return {
        projectOptions: state.projects.options,
        isLoadingProjects: state.projects.isLoading,
        fieldErrors: state.projects.fieldErrors,
        nonFieldErrors: state.projects.nonFieldErrors,
        entityProfiles: entityProfiles,
        isLoadingEntityProfiles: state.entityProfiles.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID,
        personalEntityProfile: find(entityProfiles, ['value', state.localConfigs.entityProfileID]),
        clients: _values(state.clients.searchData),
        isLoadingClients: state.clients.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    getProjectOptions: () => dispatch(RESOURCES_V1.projects.getOptions()),
    createProject: (values) => dispatch(RESOURCES_V1.projects.create(values)),
    searchClients: (search) => dispatch(RESOURCES.clients.search(search)),
    listAllEntityProfiles: (selectedEntityID) =>
        dispatch(RESOURCES.entityProfiles.listAll({entity_id: selectedEntityID})),
    createClient: (clientData) => dispatch(RESOURCES.clients.create(clientData))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAdd)
