import React from 'react'

import './ColorPreview.scss'

export default function ColorPreview({color, size = 'small', shape = 'square', className}) {
    return (
        <div
            className={`avo-color-preview ${size} ${shape} ${className}`}
            style={{backgroundColor: color}}
        />
    )
}
