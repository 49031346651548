import React, {useEffect} from 'react'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {logsOrderingOptions} from '../../utils/types'

import {Loader} from '../Loader'

import {LogRow} from './partials'

import './ResourceLogsList.scss'

export const ResourceLogsList = ({logs, isLoading, listLogs}) => {
    useEffect(() => {
        listLogs()
    }, [listLogs])

    return (
        <div className="details-card log-details-card">
            <div className="header">
                <p className="header-title">Ultimele activități</p>
            </div>
            {isLoading ? (
                <div className="center-loader">
                    <Loader />
                </div>
            ) : (
                <div className="details-card-content log-details-card-content">
                    {logs.map((log, logIdx) => (
                        <LogRow
                            log={log}
                            isLast={logIdx === logs.length - 1}
                            key={log.id}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

// TODO: Implement also next page handling
// <div className="empowerment-info-card empowerment-history-card">
//     <div className="header">
//         <p className="header-title">Istoric</p>
//     </div>
//     {!isLoading ? (
//         <div className="content">
//             <div className="logs-list-container">
//                 <ul className="logs-list">
//                     {logs.map((log, logIdx) => (
//                         <LogRow log={log} isLast={logIdx === logs.length - 1} key={log.id} />
//                     ))}
//                 </ul>
//             </div>
//             {nextPage && (
//             <div className="button-container">
//                 <Button title='Încarcă mai multe' fullWidth/>
//             </div>
//             )}
//         </div>
//     ) : (
//         <div className="center-loader">
//             <Loader />
//         </div>
//     )}
// </div>
// TODO END

const mapStateToProps = (state) => {
    const logs = _.orderBy(
        _.filter(state.logs.data, (log) => !(log.action === 'update' && _.isNil(log.changes))),
        'created',
        'desc'
    )
    const last5Logs = _.take(logs, 5)

    return {
        isLoading: state.logs.isLoading,
        logs: last5Logs
    }
}

const mapDispatchToProps = (dispatch, {resourceName, resourceID}) => ({
    listLogs: () =>
        dispatch(
            RESOURCES.logs.list({
                resource_id: resourceID,
                resource_name: resourceName,
                ordering: logsOrderingOptions.desc
            })
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(ResourceLogsList)
