import choices from './choices.json'

export const litigationsCheckData = choices['litigations-check']

export const partySubscriptionsData = choices['party-subscriptions']

export const entitiesData = choices['entities']

export const documentTemplatesData = choices['document-templates']

export const documentSeriesData = choices['series']
