import _ from 'lodash'

import {sourceTypes} from '../../../../utils/types'

export const searchTabs = {
    SIMPLE: 'Căutare simplă',
    ADVANCED: 'Căutare avansată'
}

export const mdColumns = [
    {
        Header: 'Număr dosar',
        accessor: 'litigation_number'
    },
    {
        Header: 'Sursa',
        accessor: 'source',
        Cell: ({value: source}) => sourceTypes[_.toUpper(source)] || '-'
    }
]

export const bpiAddTabs = {
    WITH_VAT_CODE: 'După CUI',
    WITH_NAME: 'După nume'
}

export const bpiColumns = [
    {
        Header: 'Nume companie',
        accessor: 'company_name'
    },
    {
        Header: 'CUI',
        accessor: 'vat_number'
    }
]
