import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {debounce, isNil, orderBy} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'

import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {columns} from './constants'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {Dropdown} from '../../../../components/Dropdown'

import {ClientDetailsDisclaimer} from '../ClientDetailsDisclaimer'

import './Addendums.scss'

export const Addendums = ({
    addendums,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    listAddendums,
    match: {
        params: {clientID}
    }
}) => {
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchAddendums = useCallback(
        (search = searchFilter, page = 1) => {
            const appliedFilters = generateFiltersForAPI(filters)

            listAddendums(clientID, search, appliedFilters, page)
        },
        [clientID, filters, listAddendums, searchFilter]
    )

    useEffect(() => {
        handleFetchAddendums()
    }, [handleFetchAddendums])

    const debounceSearchAddendums = debounce((search) => handleFetchAddendums(search), debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchAddendums(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchAddendums(searchFilter, page)

    return (
        <div className="page-client-show client-addendums-list">
            <ClientDetailsDisclaimer resource="actele adiționale" />
            <Datatable
                title="Listă acte adiționale"
                data={addendums}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: addendumID, row: {original: addendum}}) => (
                            <div className="flex gap-2 items-center">
                                <Link to={`/contracts/addendums/${addendumID}/preview`}>
                                    <Button
                                        title="Vizualizare"
                                        variant="text"
                                        size="small"
                                    />
                                </Link>
                                <Dropdown
                                    title="Descarcă"
                                    disabled={isNil(addendum.file_meta?.url_public) && isNil(addendum.signed_file)}
                                    items={[
                                        {
                                            customContent: () =>
                                                addendum.file_meta?.url_public ? (
                                                    <a
                                                        href={addendum.file_meta.url_public}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        },
                                        {
                                            customContent: () =>
                                                addendum.signed_file ? (
                                                    <a
                                                        href={addendum.signed_file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă fișier semnat
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        }
                                    ]}
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={isLoading}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                // filterable
                // filters={filters}
                // filtersForm={() => <FilterAddendumsForm filterAddendums={handleFetchAddendums} />}
                // handleRemoveFilter={(filter) => {
                //     removeFilter(filter)
                //     handleFetchAddendums()
                // }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder="Caută acte adiționale"
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    addendums: orderBy(state.addendums.data, ['signed_date', 'id'], ['desc', 'desc']),
    isLoading: state.addendums.isLoading,
    totalPages: state.addendums.totalPages,
    nextPage: state.addendums.next,
    previousPage: state.addendums.previous,
    currentPage: state.addendums.current,
    filters: state.filters.addendums
})

const mapDispatchToProps = (dispatch) => ({
    listAddendums: (clientID, search, filters, page) =>
        dispatch(
            RESOURCES.addendums.list(
                {
                    ...filters,
                    client_id: clientID,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(Addendums)
