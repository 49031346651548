import React from 'react'

import {lightFormat} from 'date-fns'
import {isNull, toUpper} from 'lodash'

import {FileTypeBadge} from '../../components/FileTypeBadge'
import {TableLink} from '../../components/TableLink'

export const columns = [
    {
        Header: 'Nume',
        accessor: 'name',
        Cell: ({row: {values}}) => <TableLink to={`/documents/${values.id}/preview`}>{values.name}</TableLink>,
        style: {maxWidth: '20vw', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Proiecte',
        accessor: 'projects',
        Cell: ({value: projects}) => (
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {projects.map((project, index) => (
                    <>
                        <TableLink to={`/projects/${project.id}`}>{project.name}</TableLink>
                        {index + 1 !== projects.length && ', '}
                    </>
                ))}
            </div>
        ),
        style: {maxWidth: '20vw', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Format',
        accessor: 'last_version',
        Cell: ({value: last_version}) => {
            const getFileExtension = () =>
                last_version && last_version.file_meta ? toUpper(last_version.file_meta.extension) : '-'

            return <FileTypeBadge fileType={getFileExtension()} />
        },
        style: {maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Ultima modificare',
        accessor: 'updated',
        Cell: ({value: updated}) => lightFormat(new Date(updated), 'dd/MM/yyyy'),
        style: {maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Document semnat',
        accessor: 'signed_file',
        Cell: ({value: signed_file}) => (!isNull(signed_file) ? 'Da' : 'Nu'),
        style: {maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}
    }
]
