import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function DeleteContactPersonModal({open, contactPerson, isLoading, deleteContactaPerson, closeModal}) {
    return (
        <Modal
            open={open && !isEmpty(contactPerson)}
            title="Șterge persoană de contact">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să ștergi persoana de contact{' '}
                    <span>
                        "{contactPerson.last_name} {contactPerson.first_name}"
                    </span>
                    ?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={() => deleteContactaPerson(contactPerson)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_CONTACT_PERSON,
    contactPerson: state.contactPersons.currentContactPerson,
    isLoading: state.contactPersons.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteContactaPerson: (contactPerson) => dispatch(RESOURCES.contactPersons.destroy(contactPerson))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContactPersonModal)
