import React, {useEffect} from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../../redux/modals'

import {Modal} from '../../../../../../components/Modal'
import {Button} from '../../../../../../components/Button'

import '../../../../../../assets/scss/DeleteModals.scss'
import {CANCELED_STATE} from '../../../../../../utils/constants'

export const InvalidateContractModal = ({
    open,
    contractID,
    contract,
    isLoading,
    retrieveContract,
    updateContract,
    closeModal
}) => {
    useEffect(() => {
        if (contractID) retrieveContract(contractID)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractID])

    const handleInvalidateContract = () => updateContract({state: CANCELED_STATE}, contractID)

    return (
        <Modal
            open={open && !isEmpty(contract) && contract.id === contractID}
            title="Anulează contract">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să anulezi contractul "<span>{contract.name}</span>"?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Anulează"
                        onClick={() => handleInvalidateContract(contractID)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.INVALIDATE_CONTRACT,
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    retrieveContract: (contractID) => dispatch(RESOURCES.contracts.retrieve(contractID)),
    updateContract: (contractData, contractID) => dispatch(RESOURCES.contracts.update(contractData, contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvalidateContractModal)
