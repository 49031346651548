import {Form, Formik} from 'formik'
import React, {useEffect} from 'react'

import {find} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters} from '../../../../../../redux/filters/filters'
import {closeSlideover} from '../../../../../../redux/slideovers'

import {getFieldOptions} from '../../../../../../utils'

import {Button} from '../../../../../../components/Button'
import {ErrorsList} from '../../../../../../components/ErrorComponents'
import {Select} from '../../../../../../components/Select'

import '../../../../../../assets/scss/SlideoverForms.scss'

export const FilterDocumentTemplatesForm = ({
    filters,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    addFilters,
    documentTemplatesOptions,
    getDocumentTemplatesOptions,
    filterDocumentTemplates,
    closeSlideover
}) => {
    const types = getFieldOptions(documentTemplatesOptions, 'type')

    useEffect(() => {
        getDocumentTemplatesOptions()
    }, [getDocumentTemplatesOptions])

    const handleFilter = (filters) => {
        addFilters(filters)
        filterDocumentTemplates()
        closeSlideover()
    }

    const getInitialType = () => find(types, ['value', filters.type.value])

    return (
        <div className="slideover-form-container">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{type: getInitialType()}}
                onSubmit={(values) => {
                    const filtersData = {
                        type: {
                            value: values.type?.value || '',
                            displayValue: values.type?.label || ''
                        }
                    }

                    handleFilter(filtersData)
                }}>
                {({setFieldValue, handleSubmit, values}) => (
                    <>
                        <Form className="slideover-form">
                            <Select
                                label="Tip"
                                value={values.type}
                                options={types}
                                onChange={(e) => setFieldValue('type', e)}
                                name="type"
                                errors={fieldErrors}
                                fullWidth
                            />
                        </Form>
                        <div className="buttons-container">
                            <Button
                                title="Aplică"
                                onClick={handleSubmit}
                                loading={isLoading}
                                type="submit"
                                fullWidth
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => ({
    documentTemplatesOptions: state.documentTemplates.options,
    filters: state.filters.documentTemplates,
    isLoading: state.documentTemplates.isLoading,
    fieldErrors: state.documentTemplates.fieldErrors,
    nonFieldErrors: state.documentTemplates.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    getDocumentTemplatesOptions: () => dispatch(RESOURCES.documentTemplates.getOptions()),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.documentTemplates.name, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterDocumentTemplatesForm)
