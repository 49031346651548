import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'

import {
    eachDayOfInterval,
    format,
    getHours,
    getMinutes,
    isAfter,
    isMonday,
    isSameDay,
    isSunday,
    isToday,
    nextSunday,
    previousMonday
} from 'date-fns'
import {capitalize, filter, includes, isNil, isNull, round, some, values} from 'lodash'

import {connect} from 'react-redux'

import {classNames, invertColor, roLocaleFormat, toApiDateFormat, toApiTimeFormat} from '../../../../../../utils'
import {hours} from '../../constants'

import './CalendarWeekView.scss'

export const CalendarWeekView = ({tasks, hocSelectedDay, handleSelectHocSelectedDay}) => {
    const container = useRef(null)
    const containerNav = useRef(null)
    const containerOffset = useRef(null)

    const [days, setDays] = useState([])
    const [weekTasks, setWeekTasks] = useState([])

    useEffect(() => {
        // Set the container scroll position based on the current time.
        const currentMinute = new Date().getHours() * 60

        container.current.scrollTop =
            ((container.current.scrollHeight -
                containerNav.current.offsetHeight -
                containerOffset.current.offsetHeight) *
                currentMinute) /
            1440
    }, [])

    const createDaysArray = useCallback((date = new Date()) => {
        const start = isMonday(new Date(date)) ? new Date(date) : previousMonday(new Date(date))
        const end = isSunday(new Date(date)) ? new Date(date) : nextSunday(new Date(date))

        return eachDayOfInterval({start, end}).map((day) => toApiDateFormat(day))
    }, [])

    const isSelectedDay = useCallback(
        (date) => {
            return isSameDay(new Date(hocSelectedDay), new Date(date))
        },
        [hocSelectedDay]
    )

    const getWeekTasks = useCallback(
        (days) => {
            // start is in week interval
            // a day in start-stop interval is in week interval
            return filter(tasks, (task) => {
                if (!isNil(task.start)) {
                    if (includes(days, toApiDateFormat(task.start))) {
                        if (!isNil(task.stop) && isAfter(new Date(task.stop), new Date(task.start))) {
                            const taskDays = eachDayOfInterval({
                                start: new Date(task.start),
                                end: new Date(task.stop)
                            }).map((day) => toApiDateFormat(day))

                            if (some(taskDays, (day) => includes(days, day))) {
                                return true
                            }
                        }
                    }
                }

                return false
            })
        },
        [tasks]
    )

    useEffect(() => {
        const days = createDaysArray(new Date(hocSelectedDay))

        setDays(days)
        setWeekTasks(getWeekTasks(days))
    }, [createDaysArray, getWeekTasks, hocSelectedDay])

    const getDateTime = useCallback((date) => {
        const d = toApiDateFormat(date)
        const t = toApiTimeFormat(date, true)

        return `${d}T${t}`
    }, [])

    const getGridRow = useCallback((task) => {
        // first number is the starting line. First line is 2, and each line is 6 more after that (8, 14, 20, 26...)
        // second number is how long the task's li should go down

        const h = getHours(new Date(task.start))
        const m = getMinutes(new Date(task.start))

        const line = h * 12 + round(m / 5) + 2

        return `${line} / span 12`
    }, [])

    return (
        <div
            ref={container}
            className="avo-calendar-week-view-container">
            <div
                style={{width: '165%'}}
                className="avo-calendar-week-view-inner-container">
                <div
                    ref={containerNav}
                    className="avo-calendar-week-view-calendar-header">
                    <div className="avo-calendar-week-view-calendar-header-days-mobile">
                        {days.map((day) => (
                            <button
                                type="button"
                                onClick={() => handleSelectHocSelectedDay(new Date(day))}
                                className="avo-calendar-week-view-calendar-day-button"
                                key={day}>
                                {capitalize(roLocaleFormat(new Date(day), 'EEEEE'))}{' '}
                                <span
                                    className={classNames(
                                        'avo-calendar-week-view-calendar-day-number',
                                        isSelectedDay(day) && 'is-selected',
                                        isToday(new Date(day)) && 'is-today'
                                    )}>
                                    {roLocaleFormat(new Date(day), 'd')}
                                </span>
                            </button>
                        ))}
                    </div>
                    <div className="avo-calendar-week-view-calendar-header-days-desktop">
                        <div className="avo-calendar-week-view-calendar-header-placeholder" />
                        {days.map((day) => (
                            <div
                                className="avo-calendar-week-view-calendar-day-container"
                                key={day}>
                                <span className="avo-calendar-week-view-calendar-day-text">
                                    {capitalize(roLocaleFormat(new Date(day), 'EEE'))}
                                    <span
                                        className={classNames(
                                            'avo-calendar-week-view-calendar-day-number',
                                            isToday(new Date(day)) && 'is-today'
                                        )}>
                                        {roLocaleFormat(new Date(day), 'd')}
                                    </span>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="avo-calendar-week-view-calendar-body-container">
                    <div className="avo-calendar-week-view-calendar-body-sticky-container" />
                    <div className="avo-calendar-week-view-calendar-body-lines-tasks-container">
                        {/* Horizontal lines */}
                        <div
                            className="avo-calendar-week-view-calendar-body-horizontal-lines-container"
                            style={{gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))'}}>
                            <div
                                ref={containerOffset}
                                className="avo-calendar-week-view-calendar-body-container-offset"
                            />
                            {hours.map((hour) => (
                                <React.Fragment key={hour}>
                                    <div>
                                        <div className="avo-calendar-week-view-calendar-body-horizontal-line">
                                            {hour}
                                        </div>
                                    </div>
                                    <div />
                                </React.Fragment>
                            ))}
                        </div>

                        {/* Vertical lines */}
                        <div className="avo-calendar-week-view-calendar-body-vertical-lines-container">
                            {[...Array(9)].map((_x, idx) => (
                                <div
                                    key={idx}
                                    className={`col-start-${idx} row-span-full ${idx === 8 && 'w-8'}`}
                                />
                            ))}
                        </div>

                        {/* Events */}
                        <ol
                            className="avo-calendar-week-view-calendar-body-tasks-list"
                            style={{gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto'}}>
                            {weekTasks.map((task) => (
                                <li
                                    className="avo-calendar-week-view-calendar-body-task"
                                    style={{gridRow: getGridRow(task)}}
                                    key={task.id}>
                                    <Link
                                        to={`/tasks/${task.id}`}
                                        className="avo-calendar-week-view-calendar-body-task-link"
                                        style={{backgroundColor: task.color || ''}}>
                                        <p
                                            className="avo-calendar-week-view-calendar-body-task-title"
                                            style={{color: !isNull(task.color) ? invertColor(task.color, true) : ''}}>
                                            {task.title}
                                        </p>
                                        <p
                                            className="avo-calendar-week-view-calendar-body-task-time"
                                            style={{color: !isNull(task.color) ? invertColor(task.color, true) : ''}}>
                                            <time dateTime={getDateTime(task.start)}>
                                                {format(new Date(task.start), 'H:mm')}
                                            </time>
                                        </p>
                                    </Link>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tasks: values(state.tasks.data)
})

export default connect(mapStateToProps)(CalendarWeekView)
