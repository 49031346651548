import React, {Component} from 'react'
import {Loader} from '../Loader'

import './PageLoader.scss'

export default class PageLoader extends Component {
    render() {
        return (
            <div className="page-loader-container">
                <Loader size="large" />
            </div>
        )
    }
}
