import React, {useCallback, useEffect, useMemo, useRef} from 'react'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {useInfiniteQuery} from '@tanstack/react-query'

import {Button} from '../../../components/Button'
import {Loader} from '../../../components/Loader'

import './BPIAppearancesSection.scss'

export const BPIAppearancesSection = ({projectID, clientID, selectedEntityID, openedModal}) => {
    const loaderRef = useRef(null)

    const filters = useMemo(
        () => ({
            project_id: projectID || null,
            client_id: clientID || null,
            entity_id: selectedEntityID,
            ordering: '-document_date'
        }),
        [projectID, clientID, selectedEntityID]
    )

    const fetchAppearances = useCallback(
        async ({pageParam = 1}) => {
            return performRequest(RESOURCES.bpiAppearances.list({...filters, page: pageParam}))
        },
        [filters]
    )

    const {
        data: notificationsResult,
        isFetching,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery({
        queryKey: [
            RESOURCES.bpiAppearances,
            filters,
            //we want to refetch the data when the add or delete modal closes
            _.isNil(openedModal)
        ],
        queryFn: fetchAppearances,
        getNextPageParam: (response) => response?.data?.pages?.next
    })

    const handleFetchNextPage = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage()
        }
    }, [fetchNextPage, hasNextPage])

    const orderedAppearancesData = useMemo(() => {
        if (_.isNil(notificationsResult)) {
            return []
        }

        const allData = notificationsResult.pages.flatMap((page) => page.data.results)
        return _.orderBy(allData, 'sent_moment', 'desc')
    }, [notificationsResult])

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0]
            if (target.isIntersecting) {
                handleFetchNextPage()
            }
        })

        if (loaderRef.current) {
            observer.observe(loaderRef.current)
        }

        return () => {
            if (loaderRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(loaderRef.current)
            }
        }
    }, [handleFetchNextPage])

    return (
        <div className="bpi-notifications-container">
            <div className="bpi-notifications-list-container">
                <p className="section-title">Apariții BPI</p>
                {orderedAppearancesData && orderedAppearancesData.length > 0 ? (
                    <ul className="space-y-6">
                        {orderedAppearancesData.map((appearance, index) => (
                            <BPIAppearanceItem
                                appearance={appearance}
                                key={index}
                            />
                        ))}
                        <div ref={loaderRef}>
                            {isFetching && (
                                <div className="center-loader">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </ul>
                ) : (
                    !isFetching && <p>Nu există apariții BPI.</p>
                )}
            </div>
        </div>
    )
}

function BPIAppearanceItem({appearance}) {
    return (
        <li
            className="notification-info-item-container"
            key={appearance.unique_id}>
            <div className="notification-info-item-line-container">
                <div className="notification-info-item-line" />
            </div>
            <div className="notification-info-item-dot-container">
                <div className="notification-info-item-dot" />
            </div>
            <div className="notification-details-container">
                <div className="notification-details-header">
                    <div className="text-sm">
                        <p className="notification-time">
                            {lightFormat(new Date(appearance.document.date), 'dd/MM/yyyy')}
                        </p>
                    </div>
                    <p className="notification-phase">BPI</p>
                </div>

                <div className="notification-details-section mb-6">
                    {!_.isNil(appearance.text_shortened) && (
                        <p className="notification-detail-key  line-clamp-5">
                            <span className="font-bold">Mesaj:</span> {appearance.text_shortened}
                        </p>
                    )}
                </div>

                <a
                    href={appearance.document?.file_url}
                    target="_blank"
                    rel="noreferrer">
                    <Button
                        title="Vezi documentul"
                        size="small"
                    />
                </a>
            </div>
        </li>
    )
}

const mapStateToProps = (state) => ({
    openedModal: state.modals.type,
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps)(BPIAppearancesSection)
