import {documentTemplatesData} from '../../assets/data/choices'

export const prefix = '/settings'

export const actionIds = {
    PROFILE: 'profile',
    DOCUMENTS: 'documents',
    DOCUMENTS_TEMPLATES: 'templates',
    DOCUMENTS_SERIES: 'series'
}

export const actions = {
    [actionIds.PROFILE]: {
        id: actionIds.PROFILE,
        label: 'Profil'
    },
    [actionIds.DOCUMENTS]: {
        id: actionIds.DOCUMENTS,
        label: 'Document',
        secondaryActions: [
            {
                id: actionIds.DOCUMENTS_TEMPLATES,
                label: 'Șabloane'
            },
            {
                id: actionIds.DOCUMENTS_SERIES,
                label: 'Serii'
            }
        ]
    }
}

export const getDocumentTypes = () => {
    return Object.keys(documentTemplatesData.type).map((key) => ({
        value: key,
        label: documentTemplatesData.type[key]
    }))
}
