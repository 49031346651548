import React, {useCallback, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'

import {values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {removeFilter} from '../../redux/filters/filters'
import {generateFiltersForAPI} from '../../redux/filters/utils'

import {debounceWait} from '../../utils/constants'
import {datatablePageSize} from '../../utils/datatables'
import {useDebouncedEffect} from '../../utils/hooks'
import {anyRoleType} from '../../utils/types'

import {Datatable} from '../../components/Datatable'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'
import {Toggle} from '../../components/Toggle'

import {actionIds, actions, clientGroupsColumns, getClientsColumns, prefix} from './constants'
import {FilterClientsForm} from './partials'

import './ClientsList.scss'

export const ClientsList = ({
    entityProfileID,
    selectedEntityID,
    match: {params},
    clients,
    isLoadingClients,
    totalClients,
    totalPages,
    nextPage,
    previousPage,
    currentPage,
    listClients,
    clientGroups,
    isLoadingClientGroups,
    totalClientGroupsPages,
    nextClientGroupsPage,
    previousClientGroupsPage,
    currentClientGroupsPage,
    listClientGroups,
    filters,
    removeFilter
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [onlyMyClients, setOnlyMyClients] = useState(true)

    const urlData = {prefix: prefix, params: params}

    const clientsColumns = useMemo(() => getClientsColumns(entityProfileID), [entityProfileID])

    const handleFetchClients = useCallback(
        (search = searchFilter, page = 1) => {
            const {primaryAction} = params

            const fetchActive = primaryAction !== actionIds.INACTIVE_CLIENTS
            const fetchGroups = primaryAction === actionIds.GROUPS

            let appliedFilters = {
                my_role: onlyMyClients ? anyRoleType.value : null
            }

            appliedFilters = {...appliedFilters, ...generateFiltersForAPI(filters)}

            if (fetchGroups) {
                listClientGroups(selectedEntityID, page)
            } else {
                listClients(selectedEntityID, fetchActive, search, appliedFilters, page)
            }
        },
        [filters, listClientGroups, listClients, onlyMyClients, params, searchFilter, selectedEntityID]
    )

    useDebouncedEffect(handleFetchClients, [searchFilter, onlyMyClients, params.primaryAction], debounceWait)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const handleChangePage = (page) => {
        if (!isLoadingClients && !isLoadingClientGroups) {
            handleFetchClients(searchFilter, page)
        }
    }

    switch (params.primaryAction) {
        case actionIds.ACTIVE_CLIENTS:
            return (
                <div>
                    <div className="clients-header">
                        <SecondaryNavbar
                            actions={actions}
                            urlData={urlData}
                        />
                        <Link
                            to="/clients/add"
                            className="add-clients-link">
                            Adaugă client
                        </Link>
                    </div>
                    <div className="page-info">
                        <Datatable
                            title={`Listă clienți activi (${totalClients})`}
                            data={clients}
                            columns={clientsColumns}
                            customHeader={() => (
                                <div className="mt-5">
                                    <Toggle
                                        checked={onlyMyClients}
                                        label="Arată doar clienții mei"
                                        onChange={() => setOnlyMyClients((prevState) => !prevState)}
                                    />
                                </div>
                            )}
                            loading={isLoadingClients}
                            totalPages={totalPages}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            currentPage={currentPage}
                            onChangePage={(page) => handleChangePage(page)}
                            filterable
                            filters={filters}
                            filtersForm={() => <FilterClientsForm filterClients={handleFetchClients} />}
                            handleRemoveFilter={(filter) => {
                                removeFilter(filter)
                                handleFetchClients()
                            }}
                            searchable
                            searchValue={searchFilter}
                            searchPlaceholder="Caută clienți"
                            onSearch={(event) => handleChangeSearchField(event.target.value)}
                        />
                    </div>
                </div>
            )
        case actionIds.GROUPS:
            return (
                <div>
                    <div className="clients-header">
                        <SecondaryNavbar
                            actions={actions}
                            urlData={urlData}
                        />
                        <Link
                            to="/clients/groups/add"
                            className="add-clients-link">
                            Adaugă grup clienți
                        </Link>
                    </div>
                    <div className="page-info">
                        <Datatable
                            title="Grupuri clienți"
                            data={clientGroups}
                            columns={clientGroupsColumns}
                            loading={isLoadingClientGroups}
                            nextPage={nextClientGroupsPage}
                            previousPage={previousClientGroupsPage}
                            currentPage={currentClientGroupsPage}
                            totalPages={totalClientGroupsPages}
                            onChangePage={(page) => handleChangePage(page)}
                            searchable
                            searchValue={searchFilter}
                            searchPlaceholder="Caută grupuri clienți"
                            onSearch={(event) => handleChangeSearchField(event.target.value)}
                        />
                    </div>
                </div>
            )
        case actionIds.INACTIVE_CLIENTS:
            return (
                <div>
                    <SecondaryNavbar
                        actions={actions}
                        urlData={urlData}
                    />
                    <div className="page-info">
                        <Datatable
                            title="Clienți inactivi"
                            data={clients}
                            columns={clientsColumns}
                            customHeader={() => (
                                <div className="mt-5">
                                    <Toggle
                                        checked={onlyMyClients}
                                        label="Arată doar clienții mei"
                                        onChange={() => setOnlyMyClients((prevState) => !prevState)}
                                    />
                                </div>
                            )}
                            loading={isLoadingClients}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onChangePage={(page) => handleChangePage(page)}
                            filterable
                            filters={filters}
                            filtersForm={() => <FilterClientsForm filterClients={handleFetchClients} />}
                            handleRemoveFilter={(filter) => {
                                removeFilter(filter)
                                handleFetchClients()
                            }}
                            searchable
                            searchValue={searchFilter}
                            searchPlaceholder="Caută clienți inactivi"
                            onSearch={(event) => this.handleChangeSearchField(event.target.value)}
                        />
                    </div>
                </div>
            )
        default:
            return
    }
}

const mapStateToProps = (state) => ({
    clients: values(state.clients.data),
    isLoadingClients: state.clients.isLoading,
    totalClients: state.clients.count,
    totalPages: state.clients.totalPages,
    nextPage: state.clients.next,
    previousPage: state.clients.previous,
    currentPage: state.clients.current,
    clientGroups: values(state.clientGroups.data),
    isLoadingClientGroups: state.clientGroups.isLoading,
    totalClientGroupsPages: state.clientGroups.totalPages,
    nextClientGroupsPage: state.clientGroups.next,
    previousClientGroupsPage: state.clientGroups.previous,
    currentClientGroupsPage: state.clientGroups.current,
    selectedEntityID: state.localConfigs.selectedEntityID,
    entityProfileID: state.localConfigs.entityProfileID,
    filters: state.filters.clients
})

const mapDispatchToProps = (dispatch) => ({
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.clients.name, filter)),
    listClients: (entity_id, active, search, filters, page) =>
        dispatch(
            RESOURCES.clients.list(
                {
                    ...filters,
                    entity_id: entity_id,
                    active: active,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        ),
    listClientGroups: (entity_id, page) =>
        dispatch(
            RESOURCES.clientGroups.list(
                {
                    entity_id: entity_id,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList)
