import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function DeleteTaskTimeLogModal({open, selectedLog, isLoading, deleteTimeLog, closeModal}) {
    return (
        <Modal
            open={open && !isEmpty(selectedLog)}
            title="Șterge pontaj">
            <div className="delete-modal">
                <p className="message">Ești sigur că vrei să ștergi acest pontaj?</p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={() => deleteTimeLog(selectedLog)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_TASK_TIME_LOG,
    isLoading: state.taskTimeLogs.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteTimeLog: (selectedLog) => dispatch(RESOURCES.taskTimeLogs.destroy(selectedLog))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTaskTimeLogModal)
