import React from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, head, find, values as _values, isNull} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getCountries, getStates} from 'avoapp-react-common/dist/redux/geo'
import {slideoverTypes} from '../../../../../redux/slideovers'

import {objectKeysToSnakeCase} from '../../../../../utils'
import {clientAddressesSchema} from '../../../../../assets/validations'

import {Input} from '../../../../../components/Input'
import {Select} from '../../../../../components/Select'
import {Button} from '../../../../../components/Button'
import {Toggle} from '../../../../../components/Toggle'
import {ErrorsList} from '../../../../../components/ErrorComponents'
import {Slideover} from '../../../../../components/Slideover'
import {Loader} from '../../../../../components/Loader'

import '../../../../../assets/scss/SlideoverForms.scss'

export const EditClientAddressSlideover = ({
    open,
    selectedAddressId,
    setSelectedAddressId,
    currentAddress,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    updateClientAddress,
    countries,
    states,
    getStates
}) => {
    const getInitialCountryOption = (currentCountry) => find(countries, ['code', currentCountry])

    const getInitialCountyOption = (currentCounty) => find(states, ['name', currentCounty])

    return (
        <Slideover
            title="Editează adresă"
            open={open && !isEmpty(currentAddress) && currentAddress.id === selectedAddressId}
            onClose={() => setSelectedAddressId(null)}>
            {!isEmpty(currentAddress) && !isLoading ? (
                <div className="slideover-form-container">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            clientID: currentAddress.client_id,
                            postalCode: currentAddress.postal_code || '',
                            country: getInitialCountryOption(currentAddress.country),
                            county: getInitialCountyOption(currentAddress.county),
                            locality: currentAddress.locality,
                            address: currentAddress.address,
                            addressDescription: currentAddress.address_description,
                            isPrimary: currentAddress.is_primary
                        }}
                        validationSchema={clientAddressesSchema}
                        validateOnMount
                        onSubmit={(values) => {
                            const updateData = {
                                ...objectKeysToSnakeCase(values),
                                country: values.country.code,
                                county: values.county.name
                            }

                            updateClientAddress(updateData, currentAddress.id)
                        }}>
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            values,
                            handleSubmit,
                            touched,
                            errors,
                            isValid
                        }) => (
                            <>
                                <Form className="slideover-form">
                                    <Select
                                        label="Țara*"
                                        value={values.country}
                                        options={countries}
                                        onChange={(e) => {
                                            setFieldValue('country', e)
                                            setFieldValue('county', '')

                                            if (!isNull(e)) {
                                                getStates(e.code)
                                            }
                                        }}
                                        onBlur={handleBlur('country')}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.code}
                                        name="country"
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.country}
                                        isClearable
                                        fullWidth
                                    />
                                    {!isEmpty(states) || isNull(values.country) ? (
                                        <Select
                                            label="Județ*"
                                            value={values.county}
                                            options={states}
                                            onChange={(e) => setFieldValue('county', e)}
                                            onBlur={handleBlur('county')}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.code}
                                            name="county"
                                            errors={head(fieldErrors.client_addresses)}
                                            frontendErrors={errors}
                                            touched={touched.county}
                                            disabled={isNull(values.country)}
                                            isClearable
                                            fullWidth
                                        />
                                    ) : (
                                        <Input
                                            label="Județ*"
                                            value={values.county}
                                            onChange={handleChange('county')}
                                            onBlur={handleBlur('county')}
                                            name="county"
                                            errors={head(fieldErrors.client_addresses)}
                                            frontendErrors={errors}
                                            touched={touched.county}
                                            fullWidth
                                        />
                                    )}
                                    <Input
                                        label="Localitate / Oraș*"
                                        value={values.locality}
                                        onChange={handleChange('locality')}
                                        onBlur={handleBlur('locality')}
                                        name="locality"
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.locality}
                                        fullWidth
                                    />
                                    <Input
                                        label="Cod Poștal"
                                        value={values.postalCode}
                                        onChange={handleChange('postalCode')}
                                        onBlur={handleBlur('postalCode')}
                                        name="postalCode"
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.postalCode}
                                        fullWidth
                                    />
                                    <Input
                                        label="Adresa*"
                                        value={values.address}
                                        onChange={handleChange('address')}
                                        onBlur={handleBlur('address')}
                                        name="address"
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.address}
                                        fullWidth
                                    />
                                    <Input
                                        label="Descriere adresă*"
                                        value={values.addressDescription}
                                        onChange={handleChange('addressDescription')}
                                        onBlur={handleBlur('addressDescription')}
                                        name="addressDescription"
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.addressDescription}
                                        fullWidth
                                    />
                                    <Toggle
                                        label="Adresă principală"
                                        checked={values.isPrimary}
                                        onChange={(e) => setFieldValue('isPrimary', e)}
                                    />
                                </Form>
                                <div className="buttons-container">
                                    <Button
                                        title="Salvează"
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        disabled={!isValid}
                                        type="submit"
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            ) : isLoading ? (
                <div className="loader-container">
                    <Loader size="large" />
                </div>
            ) : null}
        </Slideover>
    )
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.EDIT_CLIENT_ADDRESS,
    currentAddress: state.clientAddresses.currentClientAddress,
    isLoading: state.clientAddresses.isLoading,
    fieldErrors: state.clientAddresses.fieldErrors,
    nonFieldErrors: state.clientAddresses.nonFieldErrors,
    countries: _values(state.geo.countries),
    states: _values(state.geo.states)
})

const mapDispatchToProps = (dispatch) => ({
    getCountries: () => dispatch(getCountries()),
    getStates: (countryCode) => dispatch(getStates({country_code: countryCode, page_size: 50})),
    updateClientAddress: (values, clientAddressID) =>
        dispatch(RESOURCES.clientAddresses.update(values, clientAddressID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditClientAddressSlideover)
