import React from 'react'
import DatePicker from 'react-datepicker'

import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid'

import {format} from 'date-fns'
import {camelCase, head, isEmpty, snakeCase} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from '../ErrorComponents'

import './DatePicker.scss'

export default function CustomDatePicker({
    value,
    label,
    onChange,
    onBlur,
    name,
    errors,
    frontendErrors,
    timeSelect,
    timeFormat,
    touched,
    disabled,
    style,
    fullWidth
}) {
    const snakeCaseName = snakeCase(name)
    const camelCaseName = camelCase(name)

    const hasErrors =
        (errors && !isEmpty(errors[snakeCaseName])) ||
        (frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

    const chooseErrorMessageToShow = () =>
        errors && !isEmpty(errors[snakeCaseName])
            ? getErrorMessage(head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]

    return (
        <div
            style={style}
            className={`avo-datepicker-container ${fullWidth ? 'full-width' : ''}`}>
            {label && <label className="avo-datepicker-label">{label}</label>}
            <DatePicker
                name={name}
                selected={value}
                onChange={(date) => onChange(date)}
                onBlur={onBlur}
                showTimeSelect={timeSelect}
                showTimeSelectOnly={timeSelect}
                dateFormat={timeSelect ? timeFormat : 'dd/MM/yyyy'}
                timeFormat={timeFormat}
                timeIntervals={15}
                timeCaption=""
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                    <div className="avo-datepicker-custom-header-container">
                        <span className="avo-datepicker-month">{format(date, 'MMMM yyyy')}</span>
                        <div className="avo-datepicker-header-buttons-container">
                            <button
                                type="button"
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                className={`avo-datepicker-header-month-button ${
                                    prevMonthButtonDisabled ? 'disabled' : ''
                                }
                                `}>
                                <ChevronLeftIcon className="avo-datepicker-header-month-button-icon" />
                            </button>
                            <button
                                type="button"
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                className={`avo-datepicker-header-month-button ${
                                    nextMonthButtonDisabled ? 'disabled' : ''
                                }
                                `}>
                                <ChevronRightIcon className="avo-datepicker-header-month-button-icon" />
                            </button>
                        </div>
                    </div>
                )}
                disabled={disabled}
            />
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}
        </div>
    )
}
