import React from 'react'
import Creatable from 'react-select/creatable'

import {isEmpty, isUndefined, snakeCase, camelCase, head} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'
import {InputError} from '../ErrorComponents'

import './Creatable.scss'

export default function CustomCreatable({
    label,
    value,
    options,
    placeholder,
    onChange,
    onCreateNew,
    onBlur,
    isMulti,
    isClearable,
    loading,
    size,
    fullWidth,
    menuPlacement,
    getOptionLabel,
    getOptionValue,
    labelInline,
    name,
    disabled,
    errors,
    frontendErrors,
    touched
}) {
    const snakeCaseName = snakeCase(name)
    const camelCaseName = camelCase(name)

    const hasErrors =
        (errors && !isEmpty(errors[snakeCaseName])) ||
        (frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

    const chooseErrorMessageToShow = () =>
        errors && !isEmpty(errors[snakeCaseName])
            ? getErrorMessage(head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]

    return (
        <div className={`avo-creatable-container ${labelInline ? 'label-inline' : ''}`}>
            {label && <label className="avo-creatable-label">{label}</label>}
            <Creatable
                value={value}
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder || ''}
                getOptionLabel={(option) => (!isUndefined(getOptionLabel) ? getOptionLabel(option) : option.label)}
                getOptionValue={(option) => (!isUndefined(getOptionValue) ? getOptionValue(option) : option.value)}
                classNamePrefix="avo-creatable"
                className={`avo-creatable ${size || 'medium'} ${fullWidth ? 'full-width' : ''}`}
                onCreateOption={onCreateNew}
                formatCreateLabel={(inputValue) => `Creează "${inputValue}"`}
                menuPlacement={menuPlacement ? menuPlacement : 'auto'}
                menuPortalTarget={document.body}
                styles={{
                    input: (base) => ({...base, 'input:focus': {boxShadow: 'none'}}),
                    menuPortal: (base) => ({...base, zIndex: 9999})
                }}
                isLoading={loading}
                isDisabled={disabled}
                isClearable={isClearable}
                isMulti={isMulti}
            />
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}
        </div>
    )
}
