import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function DeleteAddendumModal({open, addendum, isLoading, closeModal, deleteAddendum}) {
    return (
        <Modal
            open={open && !isEmpty(addendum)}
            title="Șterge act adițional">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să ștergi actul adițional "<span>{addendum.name}</span>"?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={() => deleteAddendum(addendum)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_ADDENDUM,
    addendum: state.addendums.currentAddendum,
    isLoading: state.addendums.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteAddendum: (addendum) => dispatch(RESOURCES.addendums.destroy(addendum))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAddendumModal)
