import React, {Component} from 'react'

import {connect} from 'react-redux'
import {resetClientAddingStep} from '../../redux/clients'

import {ClientAddingSteps} from '../../components/ClientAddingSteps'

import GeneralInformations from './partials/GeneralInformations'
import InitialInformations from './partials/InitialInformations'
import LegalRepresentative from './partials/LegalRepresentative'

import './ClientAdd.scss'

class ClientAdd extends Component {
    constructor() {
        super()

        this.state = {
            steps: [
                {id: 0, stepNumber: '01', name: 'Informații inițiale'},
                {id: 1, stepNumber: '02', name: 'Entitate juridică'},
                {id: 2, stepNumber: '03', name: 'Reprezentant legal'}
            ]
        }
    }

    componentDidMount = () => {
        const {resetSteps} = this.props

        resetSteps()
    }

    render() {
        const {steps} = this.state
        const {clientAddingCurrentStep} = this.props

        return (
            <div className="client-add-form-container">
                <ClientAddingSteps steps={steps} />
                {(function () {
                    switch (clientAddingCurrentStep) {
                        case 0:
                            return <InitialInformations />
                        case 1:
                            return <GeneralInformations />
                        case 2:
                            return <LegalRepresentative />
                        default:
                            break
                    }
                })()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    clientAddingCurrentStep: state.localClientsSettings.clientAddingCurrentStep
})

const mapDispatchToProps = (dispatch) => ({
    resetSteps: () => dispatch(resetClientAddingStep())
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientAdd)
