import {endOfMonth, isMonday, isSunday, nextSunday, previousMonday, startOfMonth} from 'date-fns'

export const getMonthEnds = (date) => {
    let start = startOfMonth(new Date(date))
    let end = endOfMonth(new Date(date))

    if (!isMonday(start)) {
        start = previousMonday(start)
    }

    if (!isSunday(end)) {
        end = nextSunday(end)
    }

    return {start, end}
}

export const hours = [
    '12AM',
    '1AM',
    '2AM',
    '3AM',
    '4AM',
    '5AM',
    '6AM',
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM',
    '6PM',
    '7PM',
    '8PM',
    '9PM',
    '10PM',
    '11PM'
]
