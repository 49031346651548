import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../redux/modals'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

export const DeleteProjectModal = ({open, project, isLoading, closeModal, deleteProject}) => {
    return (
        <Modal
            open={open && !isEmpty(project)}
            title="Șterge proiect">
            <div className="delete-modal">
                <p className="message">
                    Ești sigur că vrei să ștergi proiectul "<span>{project.name}</span>"?
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={() => deleteProject(project)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_PROJECT,
    project: state.projects.currentProject,
    isLoading: state.projects.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteProject: (project) => dispatch(RESOURCES_V1.projects.destroy(project))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProjectModal)
