import {useQuery, UseQueryOptions} from '@tanstack/react-query'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {Resource} from 'avoapp-react-common/dist/redux'

// This is needed because, in typescipt files, when we wanted to pass the overrides object, typescript would
// complain that the queryKey is required, but it was already passed as a prop to the hook
type OveridesProp = Omit<UseQueryOptions, 'queryKey'>

export const useQueryResourceList = (
    resource: Resource,
    params: object,
    overrides?: OveridesProp,
    queryKey: string | null = null
) => {
    return useQuery({
        queryKey: [resource.name, params, ...(queryKey ? [queryKey] : [])],
        queryFn: () => performRequest(resource.list(params)),
        ...overrides
    })
}

export const useQueryResourceSearch = (
    resource: Resource,
    search: string,
    params?: object,
    overrides?: OveridesProp,
    queryKey: string | null = null
) => {
    return useQuery({
        queryKey: [resource.name, search, params, ...(queryKey ? [queryKey] : [])],
        queryFn: async () => {
            const response = await performRequest(resource.list({...params, search: search}))
            return response.data.results
        },
        ...overrides
    })
}

export const useQueryResourceDetail = (
    resource: Resource,
    id: number,
    overrides?: OveridesProp,
    queryKey: string | null = null
) => {
    return useQuery({
        queryKey: [resource.name, id, ...(queryKey ? [queryKey] : [])],
        queryFn: async () => {
            const response = await performRequest(resource.retrieve(id))
            return response.data
        },
        ...overrides
    })
}
