import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'

import {connect} from 'react-redux'

import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {actionIds, actions, prefix} from './constants'
import {DocumentSeries, DocumentTemplates, Profile} from './partials'

import './Settings.scss'

export class Settings extends Component {
    render() {
        const {match} = this.props

        const urlData = {prefix: prefix, params: match.params}

        const goToDocuments = () => {
            switch (match.params.secondaryAction) {
                case actionIds.DOCUMENTS_TEMPLATES:
                    return (
                        <SettingsLayout
                            urlData={urlData}
                            component={<DocumentTemplates match={match} />}
                        />
                    )
                case actionIds.DOCUMENTS_SERIES:
                    return (
                        <SettingsLayout
                            urlData={urlData}
                            component={<DocumentSeries match={match} />}
                        />
                    )
                default:
                    return <Redirect to={`${urlData.prefix}/${actionIds.DOCUMENTS}/${actionIds.DOCUMENTS_TEMPLATES}`} />
            }
        }

        switch (match.params.primaryAction) {
            case actionIds.PROFILE:
                return (
                    <div>
                        <div className="settings-header">
                            <SecondaryNavbar
                                actions={actions}
                                urlData={urlData}
                            />
                        </div>
                        <Profile />
                    </div>
                )
            case actionIds.DOCUMENTS:
                return goToDocuments()
            default:
                return <Redirect to={`${urlData.prefix}/${actionIds.PROFILE}`} />
        }
    }
}

export function SettingsLayout(props) {
    const {component, urlData} = props

    return (
        <div className="resource-details-content">
            <div className="header-resource-title-container">
                <p className="header-resource-title">Setări</p>
            </div>
            <SecondaryNavbar
                actions={actions}
                urlData={urlData}
            />
            <div className="details-div">
                <div className="details-content">{component}</div>
            </div>
        </div>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
