import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {isEmpty, isNull, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {uploadSignedFile} from 'avoapp-react-common/dist/redux/empowerments'
import {removeFilter} from '../../../../redux/filters/filters'

import {columns} from './constants'
import {datatablePageSize} from '../../../../utils/datatables'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import {modalTypes, openModal} from '../../../../redux/modals'
import {DeleteEmpowermentModal} from '../../../../components/DeleteEmpowermentModal'
import {FilterForm} from '../../../../components/FilterForm'
import FilterHeader from '../../../../components/Datatable/Filters/FilterHeader/FilterHeader'
import {useQueryParams} from 'use-query-params'
import {useQueryResourceList} from '../../../../queries/rest'
import {keepPreviousData} from '@tanstack/react-query'
import {tableListFilter} from './filters'

// import FilterEmpowermentsForm from '../forms/FilterEmpowermentsForm'

export const EmpowermentsList = ({openDeleteEmpowermentModal, selectedEntityID}) => {
    const [selectedEmpowerment, setSelectedEmpowerment] = useState(null)
    const [query, setQuery] = useQueryParams(tableListFilter.mapping)

    const handleDropSignedFile = async (selectedFile, empowermentID) => {
        if (!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.empowerments.update({signed_file: fileURL}, empowermentID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    console.log(query)

    const {data: empowermentsResponse} = useQueryResourceList(
        RESOURCES.empowerments,
        {
            entity_id: selectedEntityID,
            ...tableListFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData
        }
    )

    return (
        <div className="page-info">
            <div className="page-info-extended">
                <div className="page-filters-container">
                    <FilterHeader
                        filterable
                        appliedFilters={tableListFilter.getUIFilters(query)}
                        filtersForm={() => <FilterForm filterClass={tableListFilter} />}
                        handleRemoveFilter={(filter) => {
                            setQuery({
                                ...query,
                                [filter.key]: null
                            })
                        }}
                        searchable
                        searchPlaceholder="Caută împuterniciri"
                        searchDefaultValue={query.search}
                        onSearch={(event) =>
                            setQuery({
                                ...query,
                                search: event.target.value
                            })
                        }
                    />
                </div>
                <Datatable
                    // title='Listă împuterniciri'
                    data={empowermentsResponse?.data?.results || []}
                    columns={[
                        ...columns,
                        {
                            Header: 'Acțiuni',
                            accessor: 'id',
                            Cell: ({value: empowermentID, row: {original: empowerment}}) => {
                                // this return is needed for indentaion
                                return (
                                    <div className="flex gap-2 items-center">
                                        <Link to={`/contracts/empowerments/${empowermentID}/preview`}>
                                            <Button
                                                title="Vizualizare"
                                                variant="text"
                                                size="small"
                                            />
                                        </Link>
                                        <Dropdown
                                            title="Descarcă"
                                            disabled={
                                                isNull(empowerment.file_meta?.url_public) &&
                                                isNull(empowerment.signed_file)
                                            }
                                            items={[
                                                {
                                                    customContent: () =>
                                                        empowerment.file_meta?.url_public ? (
                                                            <a
                                                                href={empowerment.file_meta.url_public}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="avo-dropdown-menu-item">
                                                                Descarcă
                                                            </a>
                                                        ) : (
                                                            <React.Fragment />
                                                        )
                                                },
                                                {
                                                    customContent: () =>
                                                        empowerment.signed_file ? (
                                                            <a
                                                                href={empowerment.signed_file}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="avo-dropdown-menu-item">
                                                                Descarcă fișier semnat
                                                            </a>
                                                        ) : (
                                                            <React.Fragment />
                                                        )
                                                }
                                            ]}
                                            variant="text"
                                            color="secondary"
                                            size="small"
                                        />
                                        <Dropdown
                                            title="Încarcă"
                                            items={[
                                                {
                                                    customContent: () => (
                                                        <SignedFileDropzone
                                                            onChange={(selectedFile) =>
                                                                handleDropSignedFile(selectedFile, empowermentID)
                                                            }
                                                        />
                                                    )
                                                }
                                            ]}
                                            variant="text"
                                            color="orange"
                                            size="small"
                                        />
                                        <Button
                                            title="Șterge"
                                            onClick={() => {
                                                setSelectedEmpowerment(empowerment)
                                                openDeleteEmpowermentModal()
                                            }}
                                            size="small"
                                            color="red"
                                            variant="text"
                                        />
                                    </div>
                                )
                            }
                        }
                    ]}
                    nextPage={empowermentsResponse?.data?.pages.next}
                    previousPage={empowermentsResponse?.data?.pages.previous}
                    currentPage={empowermentsResponse?.data?.page}
                    totalPages={empowermentsResponse?.data?.number_of_pages}
                    onChangePage={(page) => {
                        setQuery({
                            ...query,
                            page: page
                        })
                    }}
                />
                <DeleteEmpowermentModal empowerment={selectedEmpowerment} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    empowerments: orderBy(state.empowerments.data, [(e) => e.signed_date || '', 'id'], ['desc', 'desc']),
    isLoading: state.empowerments.isLoading,
    totalPages: state.empowerments.totalPages,
    nextPage: state.empowerments.next,
    previousPage: state.empowerments.previous,
    currentPage: state.empowerments.current,
    filters: state.filters.empowerments
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteEmpowermentModal: () => dispatch(openModal(modalTypes.DELETE_EMPOWERMENT)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.empowerments.name, filter)),
    uploadSignedFile: (empowermentData, empowermentID) => dispatch(uploadSignedFile(empowermentData, empowermentID)),
    listEmpowerments: (search, filters, page) =>
        dispatch(
            RESOURCES.empowerments.list(
                {
                    ...filters,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpowermentsList)
