import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {debounce, filter, orderBy} from 'lodash'

import {RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {removeFilter} from '../../redux/filters/filters'
import {generateFiltersForAPI} from '../../redux/filters/utils'

import {debounceWait} from '../../utils/constants'
import {datatablePageSize} from '../../utils/datatables'
import {anyRoleType} from '../../utils/types'
import {actionIds, actions, getProjectsColumns, prefix} from './constants'

import {Datatable} from '../../components/Datatable'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'
import {Toggle} from '../../components/Toggle'

import {FilterProjectsForm} from './partials'

import './ProjectsList.scss'

export class ProjectsList extends Component {
    constructor() {
        super()

        this.state = {
            searchFilter: '',
            onlyMyProjects: true
        }
    }

    componentDidMount() {
        this.handleFetchProjects()
    }

    componentDidUpdate(prevProps, prevState) {
        const {onlyMyProjects} = this.state
        const {primaryAction} = this.props.match.params

        if (prevProps.match.params.primaryAction !== primaryAction) {
            this.handleFetchProjects()
        }

        if (prevState.onlyMyProjects !== onlyMyProjects) {
            this.handleFetchProjects()
        }
    }

    handleFetchProjects = (page = 1) => {
        const {searchFilter, onlyMyProjects} = this.state
        const {selectedEntityID, listProjects, filters, match} = this.props
        const {primaryAction} = match.params

        const fetchActive = primaryAction !== actionIds.INACTIVE_PROJECTS

        let appliedFilters = {
            my_role: onlyMyProjects ? anyRoleType.value : null
        }

        appliedFilters = {...appliedFilters, ...generateFiltersForAPI(filters)}

        listProjects(selectedEntityID, fetchActive, searchFilter, appliedFilters, page)
    }

    debounceSearchProjects = debounce(() => this.handleFetchProjects(), debounceWait)

    handleChangeSearchField = (value) => {
        this.setState({searchFilter: value})
        this.debounceSearchProjects()
    }

    handleChangePage = (page) => {
        const {isLoadingProjects} = this.props

        if (!isLoadingProjects) {
            this.handleFetchProjects(page)
        }
    }

    render() {
        const {searchFilter, onlyMyProjects} = this.state
        const {entityProfileID, match} = this.props
        const {
            projects,
            isLoadingProjects,
            totalProjects,
            totalPages,
            nextPage,
            previousPage,
            currentPage,
            filters,
            removeFilter
        } = this.props

        const urlData = {prefix: prefix, params: match.params}

        const projectsColumns = getProjectsColumns(entityProfileID)

        switch (match.params.primaryAction) {
            case actionIds.ACTIVE_PROJECTS:
                return (
                    <div>
                        <div className="projects-header">
                            <SecondaryNavbar
                                actions={actions}
                                urlData={urlData}
                            />
                            <Link
                                to="/projects/add"
                                className="add-projects-link">
                                Adaugă proiect
                            </Link>
                        </div>
                        <div className="page-info">
                            <Datatable
                                title={`Listă proiecte active (${totalProjects})`}
                                data={filter(projects, (project) => project.active)}
                                customHeader={() => (
                                    <div className="mt-5">
                                        <Toggle
                                            label="Arată doar proiectele mele"
                                            checked={onlyMyProjects}
                                            onChange={() =>
                                                this.setState((prevState) => ({
                                                    onlyMyProjects: !prevState.onlyMyProjects
                                                }))
                                            }
                                        />
                                    </div>
                                )}
                                columns={projectsColumns}
                                loading={isLoadingProjects}
                                totalPages={totalPages}
                                nextPage={nextPage}
                                previousPage={previousPage}
                                currentPage={currentPage}
                                onChangePage={(page) => this.handleChangePage(page)}
                                filterable
                                filters={filters}
                                filtersForm={() => <FilterProjectsForm filterProjects={this.handleFetchProjects} />}
                                handleRemoveFilter={(filter) => {
                                    removeFilter(filter)
                                    this.handleFetchProjects()
                                }}
                                searchable
                                searchValue={searchFilter}
                                searchPlaceholder="Caută proiecte"
                                onSearch={(event) => this.handleChangeSearchField(event.target.value)}
                            />
                        </div>
                    </div>
                )
            case actionIds.INACTIVE_PROJECTS:
                return (
                    <div>
                        <SecondaryNavbar
                            actions={actions}
                            urlData={urlData}
                        />
                        <div className="page-info">
                            <Datatable
                                title="Proiecte inactive"
                                data={filter(projects, (project) => !project.active)}
                                columns={projectsColumns}
                                loading={isLoadingProjects}
                                nextPage={nextPage}
                                previousPage={previousPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onChangePage={(page) => this.handleChangePage(page)}
                                filterable
                                searchable
                                searchValue={searchFilter}
                                searchPlaceholder="Caută proiecte inactive"
                                onSearch={(event) => this.handleChangeSearchField(event.target.value)}
                            />
                        </div>
                    </div>
                )
            default:
                return
        }
    }
}

const mapStateToProps = (state) => ({
    projects: orderBy(state.projects.data, [(e) => e.update_moment_crm || '', 'updated'], ['desc', 'desc']),
    isLoadingProjects: state.projects.isLoading,
    totalProjects: state.projects.count,
    totalPages: state.projects.totalPages,
    nextPage: state.projects.next,
    previousPage: state.projects.previous,
    currentPage: state.projects.current,
    selectedEntityID: state.localConfigs.selectedEntityID,
    entityProfileID: state.localConfigs.entityProfileID,
    filters: state.filters.projects
})

const mapDispatchToProps = (dispatch) => ({
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES_V1.projects.name, filter)),
    listProjects: (entity_id, active, search, filters, page) =>
        dispatch(
            RESOURCES_V1.projects.list(
                {
                    ...filters,
                    entity_id: entity_id,
                    active: active,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList)
