import {Action} from 'avoapp-react-common/dist/redux'

const ACTIONS = {
    OPEN_SLIDEOVER: new Action('OPEN_SLIDEOVER'),
    CLOSE_SLIDEOVER: new Action('CLOSE_SLIDEOVER')
}

export const slideoverTypes = {
    DATATABLE_FILTERS: 'DATATABLE_FILTERS',
    ADD_CONTACT_PERSON: 'ADD_CONTACT_PERSON',
    EDIT_CONTACT_PERSON: 'EDIT_CONTACT_PERSON',
    NOTIFICATION_DETAILS: 'NOTIFICATION_DETAILS',
    BPI_APPEARANCE_DETAILS: 'BPI_APPEARANCE_DETAILS',
    EDIT_CILENT_GROUP: 'EDIT_CLIENT_GROUP',
    EDIT_PROJECT_INFO: 'EDIT_PROJECT_INFO',
    EDIT_PROJECT_USERS_ACCESS: 'EDIT_PROJECT_USERS_ACCESS',
    EDIT_CLIENT_INFO: 'EDIT_CLIENT_INFO',
    EDIT_CLIENT_USERS_ACCESS: 'EDIT_CLIENT_USERS_ACCESS',
    ADD_CLIENT_ADDRESS: 'ADD_CLIENT_ADDRESS',
    EDIT_CLIENT_ADDRESS: 'EDIT_CLIENT_ADDRESS',
    ADD_CLIENT_BANK_ACCOUNT: 'ADD_CLIENT_BANK_ACCOUNT',
    EDIT_CLIENT_BANK_ACCOUNT: 'EDIT_CLIENT_BANK_ACCOUNT',
    ADD_BPI_SUBSCRIPTION: 'ADD_BPI_SUBSCRIPTION',
    EDIT_BPI_SUBSCRIPTION: 'EDIT_BPI_SUBSCRIPTION',
    TASK_DETAILS: 'TASK_DETAILS',
    EDIT_DOCUMENT_LOCATIONS: 'EDIT_DOCUMENT_LOCATIONS'
}

const initialState = {
    type: null
}

export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ACTIONS.OPEN_SLIDEOVER.main:
            return {
                type: action.payload.slideoverType
            }
        case ACTIONS.CLOSE_SLIDEOVER.main:
            return {
                type: null
            }
        default:
            return state
    }
}

export const openSlideover = (slideoverType) => ({
    type: ACTIONS.OPEN_SLIDEOVER.main,
    payload: {
        slideoverType: slideoverType
    }
})

export const closeSlideover = () => ({
    type: ACTIONS.CLOSE_SLIDEOVER.main
})
