import {omitBy, isNull, isUndefined} from 'lodash'

export const generateFiltersForAPI = (rawFilters) => {
    const filters = {}
    const nonEmptyFilters = omitBy(
        rawFilters,
        (filter) => isNull(filter.value) || isUndefined(filter.value) || filter.value === ''
    )

    Object.keys(nonEmptyFilters).forEach((key) => {
        filters[rawFilters[key].key] = rawFilters[key].value
    })

    return filters
}
