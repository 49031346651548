import React, {useEffect} from 'react'

import {Form, Formik} from 'formik'
import {values as _values, differenceBy, filter, isEmpty} from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {slideoverTypes} from '../../../../../redux/slideovers'

import {roleTypes} from '../../../../../utils/types'

import {Button} from '../../../../../components/Button'
import {ErrorsList} from '../../../../../components/ErrorComponents'
import {Select} from '../../../../../components/Select'
import {Slideover} from '../../../../../components/Slideover'

import '../../../../../assets/scss/SlideoverForms.scss'

export const EditProjectUsersAccessSlideover = ({
    open,
    project,
    isLoadingProjects,
    selectedEntityID,
    entityProfiles,
    listAllEntityProfiles,
    nonFieldErrors,
    updateProject
}) => {
    useEffect(() => {
        listAllEntityProfiles(selectedEntityID)
    }, [listAllEntityProfiles, open, selectedEntityID])

    const getInitialUsers = (role) => {
        const permissionsWithRole = filter(project.project_permissions, ['role', role])

        if (!isEmpty(permissionsWithRole)) {
            const selectedManagers = permissionsWithRole.map((permission) => ({
                value: permission.entity_profile_id,
                label: `${permission.entity_profile.first_name} ${permission.entity_profile.last_name}`
            }))

            return selectedManagers
        }

        return []
    }

    return (
        <Slideover
            open={open && !isEmpty(project)}
            title="Editează acces utilizatori">
            <div className="slideover-form-container">
                <ErrorsList errors={nonFieldErrors} />
                <Formik
                    initialValues={{
                        managers: getInitialUsers(roleTypes.MANAGER.value),
                        collaborators: getInitialUsers(roleTypes.COLLABORATOR.value)
                    }}
                    onSubmit={(values) => {
                        const managers = values.managers.map((manager) => ({
                            entity_profile_id: manager.value,
                            role: roleTypes.MANAGER.value
                        }))

                        const collaborators = values.collaborators.map((colaborator) => ({
                            entity_profile_id: colaborator.value,
                            role: roleTypes.COLLABORATOR.value
                        }))

                        updateProject({project_permissions: [...managers, ...collaborators]}, project.id)
                    }}>
                    {({setFieldValue, values, handleSubmit}) => (
                        <>
                            <Form className="slideover-form">
                                <Select
                                    label="Manageri"
                                    value={values.managers}
                                    options={differenceBy(entityProfiles, values.collaborators, 'value')}
                                    onChange={(e) => setFieldValue('managers', e)}
                                    isMulti
                                    fullWidth
                                />
                                <Select
                                    label="Colaboratori"
                                    value={values.collaborators}
                                    options={differenceBy(entityProfiles, values.managers, 'value')}
                                    onChange={(e) => setFieldValue('collaborators', e)}
                                    isMulti
                                    fullWidth
                                />
                            </Form>
                            <div className="buttons-container">
                                <Button
                                    title="Salvează"
                                    onClick={handleSubmit}
                                    loading={isLoadingProjects}
                                    type="submit"
                                    fullWidth
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </Slideover>
    )
}

const mapStateToProps = (state) => {
    let entityProfiles = []
    _values(state.entityProfiles.data).map((profile) =>
        entityProfiles.push({value: profile.id, label: `${profile.first_name} ${profile.last_name}`})
    )

    return {
        open: state.slideovers.type === slideoverTypes.EDIT_PROJECT_USERS_ACCESS,
        project: state.projects.currentProject,
        isLoadingProjects: state.projects.isLoading,
        fieldErrors: state.projects.fieldErrors,
        nonFieldErrors: state.projects.nonFieldErrors,
        entityProfiles: entityProfiles,
        isLoadingEntityProfiles: state.entityProfiles.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateProject: (values, projectID) => dispatch(RESOURCES_V1.projects.update(values, projectID)),
    listAllEntityProfiles: (selectedEntityID) =>
        dispatch(RESOURCES.entityProfiles.listAll({entity_id: selectedEntityID}))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectUsersAccessSlideover)
