import React, {useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'

import {lightFormat} from 'date-fns'
import {isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {logsOrderingOptions} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DeleteContractModal} from '../../../../components/DeleteContractModal'
import {DetailsCard} from '../../../../components/DetailsCard'
import {Loader} from '../../../../components/Loader'
import {ResourceLogsList} from '../../../../components/ResourceLogsList'

import {EditContractLocationsSlideover} from '../slideovers'

import {CANCELED_STATE} from '../../../../utils/constants'
import './ContractMetaInfo.scss'

export const ContractMetaInfo = ({
    contract,
    isLoading,
    listLogs,
    openDeleteContractModal,
    openCancelContractModal,
    match: {
        params: {contractID}
    }
}) => {
    useEffect(() => {
        listLogs(contractID)
    }, [contractID, listLogs])

    const contractInfoRows = useMemo(() => {
        return [
            {label: 'Nume', value: contract.name},
            {label: 'Mărime', value: contract.size ? `${contract.size} Mb` : '-'},
            {label: 'Dată', value: contract.created ? lightFormat(new Date(contract.created), 'dd/MM/yyyy') : ''}
        ]
    }, [contract.created, contract.name, contract.size])

    return (
        <div className="contract-details-page-container">
            <div className="contract-info-cards-container">
                <div className="row">
                    <div className="left">
                        <DetailsCard
                            title="Informații contract"
                            rows={contractInfoRows}
                            loading={isLoading}
                        />
                    </div>
                    <div className="right">
                        <div className="contract-info-card contract-locations-card">
                            <div className="header">
                                <p className="header-title">Locații</p>
                                {/*<Button*/}
                                {/*    title='Editează'*/}
                                {/*    onClick={() => openSlideover(slideoverTypes.EDIT_CONTRACT_LOCATIONS)}*/}
                                {/*    size='small'*/}
                                {/*/>*/}
                            </div>
                            {!isLoading ? (
                                <div className="content">
                                    <div className="section">
                                        <p className="section-title">Proiecte</p>
                                        {!isEmpty(contract.projects) ? (
                                            <div className="location-rows-container">
                                                {contract.projects.map((project) => (
                                                    <LocationContainer
                                                        project={project}
                                                        key={project.id}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="no-locations">
                                                Acest contract nu este folosit pentru niciun proiect
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="center-loader">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ResourceLogsList
                        resourceID={contractID}
                        resourceName={RESOURCES.contracts.name}
                    />
                </div>
                <div className="mt-10 flex gap-5 justify-end">
                    <Button
                        title={'Anulează contract'}
                        onClick={openCancelContractModal}
                        disabled={contract.state === CANCELED_STATE}
                        size="large"
                        color="red"
                    />
                    {!contract.deletion_prevented && (
                        <Button
                            title={'Șterge contract'}
                            onClick={openDeleteContractModal}
                            size="large"
                            color="red"
                        />
                    )}
                </div>
            </div>
            <DeleteContractModal />
            <EditContractLocationsSlideover contractID={contractID} />
        </div>
    )
}

const LocationContainer = ({project}) => {
    return (
        <Link
            to={`/projects/${project.id}`}
            className="location-container">
            <p className="location-name">{project.name}</p>
        </Link>
    )
}

const mapStateToProps = (state) => ({
    project: state.projects.currentProject,
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteContractModal: () => dispatch(openModal(modalTypes.DELETE_CONTRACT)),
    openCancelContractModal: () => dispatch(openModal(modalTypes.CANCEL_CONTRACT)),
    listLogs: (contractID) =>
        dispatch(
            RESOURCES.logs.list({
                resource_id: contractID,
                resource_name: RESOURCES.contracts.name,
                ordering: logsOrderingOptions.desc
            })
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractMetaInfo)
