import React from 'react'
import {Calendar} from '../../components/Calendar'

const CalendarPage: React.FC = () => {
    return (
        <>
            <Calendar />
        </>
    )
}

export default CalendarPage
