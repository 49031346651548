import {DocumentIcon, PlusIcon, XMarkIcon} from '@heroicons/react/24/outline'
import React, {useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'

import {head, isEmpty} from 'lodash'

import {Button} from '../Button'

import {getErrorMessage} from '../../utils/errorHelper'
import {InputError} from '../ErrorComponents'
import './DocumentDropzone.scss'

export default function Dropzone({onChange, error}) {
    const [file, setFiles] = useState([])
    const chooseErrorMessageToShow = () => error && getErrorMessage(head(error))

    useEffect(() => {
        onChange(file)
    }, [file, onChange])

    const handleDrop = (acceptedFiles) => setFiles(head(acceptedFiles))

    const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [])

    const {getRootProps, getInputProps, open} = useDropzone({onDrop: onDrop, noClick: true, noKeyboard: true})

    return (
        <div {...getRootProps({className: 'avo-document-dropzone'})}>
            {error && <InputError message={chooseErrorMessageToShow()} />}
            <input {...getInputProps()} />
            {isEmpty(file) ? (
                <div className="upload-file-container">
                    <Button
                        title="Adaugă fișier"
                        onClick={open}
                        icon={() => <PlusIcon />}
                        type="button"
                    />
                    <p className="upload-file-text">sau mută fișierele aici pentru a încărca.</p>
                </div>
            ) : (
                <div className="uploaded-file-preview-container">
                    <div className="file-info">
                        <DocumentIcon className="file-icon" />
                        <p className="file-name">{file.name}</p>
                    </div>
                    <Button
                        icon={() => <XMarkIcon />}
                        onClick={() => onDrop([])}
                        size="small"
                        color="red"
                    />
                </div>
            )}
        </div>
    )
}
