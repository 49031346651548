import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {debounce, filter, isNull, orderBy} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {actionIds, tasksColumns} from './constants.ts'

import {AddTaskModal} from '../../../../components/AddTaskModal'
import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {EditTaskModal} from '../../../../components/EditTaskModal'

import {toApiDateFormat, toApiTimeFormat} from '../../../../utils'
import './Tasks.scss'

export class Tasks extends Component {
    constructor() {
        super()

        this.state = {
            searchFilter: '',
            selectedTask: null
        }
    }

    componentDidMount() {
        this.handleFetchTasks()
    }

    componentDidUpdate(prevProps) {
        const {secondaryAction} = this.props.match.params

        if (prevProps.match.params.secondaryAction !== secondaryAction) {
            this.handleFetchTasks()
        }
    }

    handleFetchTasks = (page = 1) => {
        const {
            selectedEntityID,
            listTasks,
            match: {
                params: {projectID, secondaryAction}
            }
        } = this.props
        const {searchFilter} = this.state

        const fetchCompleted = secondaryAction === actionIds.FINISHED_TASKS

        listTasks(selectedEntityID, projectID, fetchCompleted, searchFilter, page)
    }

    debounceSearchTasks = debounce(this.handleFetchTasks, debounceWait)

    handleChangeSearchField = (value) => {
        this.setState({searchFilter: value})
        this.debounceSearchTasks()
    }

    handleChangePage = (page) => {
        const {isLoadingTasks} = this.props

        if (!isLoadingTasks) {
            this.handleFetchTasks(page)
        }
    }

    handleChangeCompletion = (task) => {
        const {updateTask} = this.props

        let taskData = {completion: null}

        if (isNull(task.completion)) {
            const datetime = `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`

            taskData = {completion: datetime}
        }

        updateTask(taskData, task.id)
    }

    render() {
        const {searchFilter, selectedTask} = this.state
        const {tasks, isLoadingTasks, totalTasks, totalPages, nextPage, previousPage, currentPage, match, openModal} =
            this.props

        const {projectID} = match.params

        const openAddTaskModal = () => openModal(modalTypes.ADD_TASK)

        switch (match.params.secondaryAction) {
            case actionIds.ACTIVE_TASKS:
                return (
                    <div className="page-project-show">
                        <Datatable
                            title={`Listă sarcini active (${totalTasks})`}
                            data={filter(tasks, (task) => isNull(task.completion))}
                            columns={[
                                ...tasksColumns,
                                {
                                    Header: '',
                                    accessor: 'id',
                                    Cell: ({value: taskID, row: {original: task}}) => (
                                        <div className="tasks-datatable-action-buttons-column">
                                            <Link to={`/tasks/${taskID}`}>
                                                <Button
                                                    title="Vizualizare"
                                                    variant="text"
                                                    size="small"
                                                />
                                            </Link>
                                            <Button
                                                title="Finalizează"
                                                color="secondary"
                                                onClick={() => this.handleChangeCompletion(task)}
                                                variant="text"
                                                size="small"
                                            />
                                        </div>
                                    )
                                }
                            ]}
                            loading={isLoadingTasks}
                            headerButton={() => (
                                <Button
                                    title="Adaugă"
                                    color="secondary"
                                    onClick={openAddTaskModal}
                                />
                            )}
                            totalPages={totalPages}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            currentPage={currentPage}
                            onChangePage={(page) => this.handleChangePage(page)}
                            searchable
                            searchValue={searchFilter}
                            searchPlaceholder="Caută sarcini"
                            onSearch={(event) => this.handleChangeSearchField(event.target.value)}
                        />
                        <AddTaskModal projectId={projectID} />
                        {selectedTask && <EditTaskModal task={selectedTask} />}
                    </div>
                )
            case actionIds.FINISHED_TASKS:
                return (
                    <div className="page-project-show">
                        <Datatable
                            title="Sarcini finalizate"
                            data={filter(tasks, (task) => !isNull(task.completion))}
                            columns={[
                                ...tasksColumns,
                                {
                                    Header: '',
                                    accessor: 'id',
                                    Cell: ({value: taskID, row: {original: task}}) => (
                                        <div className="tasks-datatable-action-buttons-column">
                                            <Link to={`/tasks/${taskID}`}>
                                                <Button
                                                    title="Vizualizare"
                                                    variant="text"
                                                    size="small"
                                                />
                                            </Link>
                                            <Button
                                                title="Activează"
                                                color="secondary"
                                                onClick={() => this.handleChangeCompletion(task)}
                                                variant="text"
                                                size="small"
                                            />
                                        </div>
                                    )
                                }
                            ]}
                            loading={isLoadingTasks}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onChangePage={(page) => this.handleChangePage(page)}
                            searchable
                            searchValue={searchFilter}
                            searchPlaceholder="Caută sarcini inactive"
                            onSearch={(event) => this.handleChangeSearchField(event.target.value)}
                        />
                    </div>
                )
            default:
                return
        }
    }
}

const mapStateToProps = (state) => ({
    tasks: orderBy(state.tasks.data, ['start', 'created'], ['desc', 'desc']),
    isLoadingTasks: state.tasks.isLoading,
    totalTasks: state.tasks.count,
    totalPages: state.tasks.totalPages,
    nextPage: state.tasks.next,
    previousPage: state.tasks.previous,
    currentPage: state.tasks.current,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    updateTask: (taskData, taskID) => dispatch(RESOURCES.tasks.update(taskData, taskID)),
    listTasks: (entityID, projectID, isCompleted, search, page) =>
        dispatch(
            RESOURCES.tasks.list(
                {
                    entity_id: entityID,
                    project_id: projectID,
                    search: search,
                    is_completed: isCompleted,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)
