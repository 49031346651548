import {PlusIcon} from '@heroicons/react/24/solid'
import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {debounce, filter, includes, isEmpty, isNil, orderBy} from 'lodash'

import {uploadSignedFile} from 'avoapp-react-common/dist/redux/documents'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {createDocument} from '../../../../redux/documents'
import {modalTypes, openModal} from '../../../../redux/modals'

import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {columns} from './constants'

import {Button} from '../../../../components/Button'
import {CreateDocumentModal} from '../../../../components/CreateDocumentModal'
import {Datatable} from '../../../../components/Datatable'
import {Dropdown} from '../../../../components/Dropdown'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'
import {UploadDocumentModal} from '../../../../components/UploadDocumentModal'

import {DocumentButtonDropzone, RemoveOrDeleteDocumentModal} from './partials'

import {removeExtension} from '../../../../utils'
import {getTokenAndUploadFile} from '../../../../utils/files'
import './Documents.scss'

export const Documents = ({
    documents,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    selectedEntityID,
    match: {
        params: {projectID}
    },
    openModal,
    createDocument,
    uploadSignedFile,
    listDocuments
}) => {
    const [selectedDocumentID, setSelectedDocumentID] = useState('')
    const [searchFilter, setSearchFilter] = useState('')
    const [uploadInProgres, setUploadInProgres] = useState(false)

    const handleFetchDocuments = useCallback(
        (query = searchFilter, page = 1) => {
            listDocuments(projectID, query, page)
        },
        [listDocuments, projectID, searchFilter]
    )

    useEffect(() => {
        handleFetchDocuments()
    }, [handleFetchDocuments])

    const debounceSearchDocuments = debounce(handleFetchDocuments, debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchDocuments(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchDocuments(searchFilter, page)

    const handleDrop = async (selectedFile) => {
        if (!isEmpty(selectedFile)) {
            setUploadInProgres(true)
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            setUploadInProgres(false)
            createDocument({
                entityID: selectedEntityID,
                projectsIDs: [projectID],
                file: fileURL,
                name: removeExtension(selectedFile.name)
            })
        }
    }

    const handleDropSignedFile = (selectedFile, documentID) => {
        const documentData = new FormData()

        documentData.append('signed_file', selectedFile)

        if (!isEmpty(selectedFile)) {
            uploadSignedFile(documentData, documentID)
        }
    }

    return (
        <div className="project-documents-list">
            <CreateDocumentModal projectsIds={[projectID]} />
            <UploadDocumentModal documentID={selectedDocumentID} />
            <RemoveOrDeleteDocumentModal documentID={selectedDocumentID} />
            <Datatable
                title="Documente"
                data={filter(documents, (document) => includes(document.projects_ids, parseInt(projectID)))}
                headerButton={() => (
                    <div className="documents-datatable-buttons">
                        <DocumentButtonDropzone
                            isLoading={isLoading || uploadInProgres}
                            onChange={(selectedFile) => handleDrop(selectedFile)}
                        />
                        <Button
                            title="Crează document"
                            onClick={() => openModal(modalTypes.CREATE_DOCUMENT)}
                            icon={() => <PlusIcon />}
                        />
                    </div>
                )}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: documentID, row: {original: document}}) => (
                            <div className="flex gap-2 items-center">
                                <Link to={`/documents/${documentID}/preview`}>
                                    <Button
                                        title="Vizualizare"
                                        variant="text"
                                        size="small"
                                    />
                                </Link>
                                <Dropdown
                                    title="Descarcă"
                                    disabled={
                                        isNil(document.last_version?.file_meta?.url_public) &&
                                        isNil(document.signed_file)
                                    }
                                    items={[
                                        {
                                            customContent: () =>
                                                document.last_version?.file_meta?.url_public ? (
                                                    <a
                                                        href={document.last_version.file_meta.url_public}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        },
                                        {
                                            customContent: () =>
                                                document.signed_file ? (
                                                    <a
                                                        href={document.signed_file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă fișier semnat
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        }
                                    ]}
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                />
                                <Dropdown
                                    title="Încarcă"
                                    items={[
                                        {
                                            title: 'Încarcă fișier',
                                            onClick: () => {
                                                setSelectedDocumentID(documentID)
                                                openModal(modalTypes.UPLOAD_DOCUMENT)
                                            }
                                        },
                                        {
                                            customContent: () => (
                                                <SignedFileDropzone
                                                    onChange={(selectedFile) =>
                                                        handleDropSignedFile(selectedFile, documentID)
                                                    }
                                                />
                                            )
                                        }
                                    ]}
                                    variant="text"
                                    color="orange"
                                    size="small"
                                />
                                <Button
                                    title="Șterge"
                                    onClick={() => {
                                        setSelectedDocumentID(documentID)
                                        openModal(modalTypes.REMOVE_OR_DELETE_DOCUMENT)
                                    }}
                                    variant="text"
                                    size="small"
                                    color="red"
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={isLoading}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                searchable
                searchValue={searchFilter}
                searchPlaceholder="Caută documente"
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    documents: orderBy(state.documents.data, 'id', 'desc'),
    isLoading: state.documents.isLoading,
    totalPages: state.documents.totalPages,
    nextPage: state.documents.next,
    previousPage: state.documents.previous,
    currentPage: state.documents.current,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    createDocument: (documentData) => dispatch(createDocument(documentData)),
    uploadSignedFile: (documentData, documentID) => dispatch(uploadSignedFile(documentData, documentID)),
    listDocuments: (projectID, search, page) =>
        dispatch(
            RESOURCES.documents.list(
                {
                    project_id: projectID,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
