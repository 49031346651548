import React from 'react'

import './ToolTip.scss'

export default function ToolTip({text, style}) {
    return (
        <span
            style={style}
            className="tooltiptext">
            {text}
        </span>
    )
}
