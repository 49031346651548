import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {endOfDay, endOfMonth, startOfDay, startOfMonth} from 'date-fns'
import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {durationToFloat, toApiDateFormat} from '../../../../utils'

import {Button} from '../../../../components/Button'
import {Loader} from '../../../../components/Loader'

import './TimeCards.scss'

export const TimeCards = ({selectedEntityID, entityProfileID}) => {
    const [isLoadingToday, setIsLoadingToday] = useState(false)
    const [todayReports, setTodayReports] = useState(undefined)

    const [isLoadingMonth, setIsLoadingMonth] = useState(false)
    const [monthReports, setMonthReports] = useState(undefined)

    const fetchTodayReports = useCallback(async () => {
        const params = {
            entity_id: selectedEntityID,
            owner_id: entityProfileID,
            date__gte: toApiDateFormat(startOfDay(new Date())),
            date__lte: toApiDateFormat(endOfDay(new Date()))
        }

        setIsLoadingToday(true)

        await performRequest(RESOURCES.taskTimeLogReports.list(params))
            .then(({data}) => {
                setTodayReports({
                    total: _.head(data)?.duration_as_hours,
                    billable: durationToFloat('000 0:0:0'),
                    nonBillable: durationToFloat('000 0:0:0')
                })
            })
            .catch((err) => {
                console.warn('Error while fetching task time logs for TODAY', err)
            })

        setIsLoadingToday(false)
    }, [selectedEntityID, entityProfileID])

    useEffect(() => {
        fetchTodayReports()
    }, [fetchTodayReports])

    const fetchMonthReports = useCallback(async () => {
        const params = {
            entity_id: selectedEntityID,
            owner_id: entityProfileID,
            date__gte: toApiDateFormat(startOfMonth(new Date())),
            date__lte: toApiDateFormat(endOfMonth(new Date()))
        }

        setIsLoadingMonth(true)

        await performRequest(RESOURCES.taskTimeLogReports.list(params))
            .then(({data}) => {
                setMonthReports({
                    total: _.head(data)?.duration_as_hours,
                    billable: durationToFloat('000 0:0:0'),
                    nonBillable: durationToFloat('000 0:0:0')
                })
            })
            .catch((err) => {
                console.warn('Error while fetching task time logs for THIS MONTH', err)
            })

        setIsLoadingMonth(false)
    }, [selectedEntityID, entityProfileID])

    useEffect(() => {
        fetchMonthReports()
    }, [fetchMonthReports])

    return (
        <>
            <div className="dashboard-card-container time-card">
                <div className="dashboard-card-content-container">
                    {!isLoadingToday ? (
                        <>
                            <p className="dashboard-card-description">Ore pontate astăzi</p>
                            <h2 className="dashboard-card-title">
                                {todayReports?.total ? `${todayReports?.total} ore` : '-'}
                            </h2>
                            <p className="dashboard-card-description">
                                De facturat:{' '}
                                <span>{todayReports?.billable ? `${todayReports?.billable} ore` : '-'}</span>
                            </p>
                            <p className="dashboard-card-description">
                                Nefacturabile:{' '}
                                <span>{todayReports?.nonBillable ? `${todayReports?.nonBillable} ore` : '-'}</span>
                            </p>
                            <Link
                                to="/tasks/time-logs/week"
                                className="time-card-activity-link">
                                <Button
                                    title="Vezi activitate"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Link>
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
            <div className="dashboard-card-container time-card">
                <div className="dashboard-card-content-container">
                    {!isLoadingMonth ? (
                        <>
                            <p className="dashboard-card-description">Ore pontate luna aceasta</p>
                            <h2 className="dashboard-card-title">
                                {monthReports?.total ? `${monthReports?.total} ore` : '-'}
                            </h2>
                            <p className="dashboard-card-description">
                                De facturat:{' '}
                                <span>{monthReports?.billable ? `${monthReports?.billable} ore` : '-'}</span>
                            </p>
                            <p className="dashboard-card-description">
                                Nefacturabile:{' '}
                                <span>{monthReports?.nonBillable ? `${monthReports?.nonBillable} ore` : '-'}</span>
                            </p>
                            <Link
                                to="/tasks/time-logs/week"
                                className="time-card-activity-link">
                                <Button
                                    title="Vezi activitate"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Link>
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    entityProfileID: state.localConfigs.entityProfileID
})

export default connect(mapStateToProps)(TimeCards)
