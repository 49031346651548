import React, {Component} from 'react'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import './TermsAndConditions.scss'

class TermsAndConditions extends Component {
    componentDidMount() {
        const {listTerms} = this.props

        listTerms()
    }

    render() {
        const {latestTerms, isLoadingTerms} = this.props

        return (
            <div className="terms-and-conditions-container">
                {!isLoadingTerms ? (
                    <div
                        className="prose prose-lg"
                        dangerouslySetInnerHTML={{__html: latestTerms.content}}
                    />
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    latestTerms: state.terms.latestTerms,
    isLoadingTerms: state.terms.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    listTerms: () => dispatch(RESOURCES.terms.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions)
