import {ArrowLeftIcon, PencilSquareIcon} from '@heroicons/react/24/outline'
import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'

import {endsWith, findLast, isUndefined, startsWith} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'

import {actionIds, actions} from './constants'

import {Button} from '../../components/Button'
import {Loader} from '../../components/Loader'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {
    Addendums,
    ContractMetaInfo,
    ContractPreview,
    ContractSettings,
    EditContractNameForm,
    Empowerments,
    SignedContractPreview
} from './partials'

import './ContractDetails.scss'

export const ContractDetails = ({
    contract,
    isLoading,
    match,
    retrieveContract,
    sourceLocation,
    navigate,
    location,
    history
}) => {
    const {contractID, primaryAction} = match.params

    useEffect(() => {
        retrieveContract(contractID)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const urlData = {prefix: `/contracts/${contractID}`, params: match.params, location, history}

    switch (primaryAction) {
        case actionIds.INFO:
            return (
                <ContractDetailsLayout
                    urlData={urlData}
                    contract={contract}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<ContractMetaInfo match={match} />}
                />
            )
        case actionIds.SETTINGS:
            return (
                <ContractDetailsLayout
                    urlData={urlData}
                    contract={contract}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={
                        <ContractSettings
                            match={match}
                            urlData={urlData}
                        />
                    }
                />
            )
        case actionIds.PREVIEW:
            return (
                <ContractDetailsLayout
                    urlData={urlData}
                    contract={contract}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={
                        <ContractPreview
                            match={match}
                            urlData={urlData}
                        />
                    }
                />
            )
        case actionIds.SIGNED:
            return (
                <ContractDetailsLayout
                    urlData={urlData}
                    contract={contract}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<SignedContractPreview match={match} />}
                />
            )
        case actionIds.ADDENDUMS:
            return (
                <ContractDetailsLayout
                    urlData={urlData}
                    contract={contract}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<Addendums match={match} />}
                />
            )
        case actionIds.EMPOWERMENTS:
            return (
                <ContractDetailsLayout
                    urlData={urlData}
                    contract={contract}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<Empowerments match={match} />}
                />
            )

        default:
            return <Redirect to={`${urlData.prefix}/${actionIds.INFO}`} />
    }
}

export function ContractDetailsLayout({contract, loading, component, urlData, goBack}) {
    const [editMode, setEditMode] = useState(false)

    return (
        <div className="resource-details-content">
            <div className="back-button-container">
                <Button
                    title="Mergi înapoi"
                    size="small"
                    color="gray"
                    variant="outlined"
                    onClick={goBack}
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            <div className="header-resource-title-container">
                {!loading && !editMode ? (
                    <div className="contract-name-container">
                        <p className="header-resource-title">{contract.name}</p>
                        <Button
                            icon={() => <PencilSquareIcon />}
                            onClick={() => setEditMode(true)}
                            size="small"
                        />
                    </div>
                ) : !loading && editMode ? (
                    <EditContractNameForm handleCancel={() => setEditMode(false)} />
                ) : loading ? (
                    <div className="loader-container">
                        <Loader />
                    </div>
                ) : null}
            </div>
            <SecondaryNavbar
                actions={actions}
                urlData={urlData}
            />
            <div>{component}</div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const sourceLocation = findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/contracts' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/contracts-contracts'))
    )

    return {
        contract: state.contracts.currentContract,
        isLoading: state.contracts.isLoading,
        sourceLocation: !isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/contracts'
    }
}

const mapDispatchToProps = (dispatch) => ({
    navigate: (route) => dispatch(push(route)),
    retrieveContract: (contractID) => dispatch(RESOURCES.contracts.retrieve(contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetails)
