import React, {useCallback} from 'react'

import _, {isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes, openModal} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import {toast} from 'react-toastify'
import '../../assets/scss/DeleteModals.scss'
import {useDeleteSmartBillInvoice, useMutationResourceDelete} from '../../mutations/rest'
import {externalSyncActions} from '../../pages/Invoices/constants'

function DeleteInvoiceModal({open, selectedInvoice, closeModal, openSyncInvoiceModal, reloadData}) {
    const onSmartBillFinished = useCallback(
        (data, action) => {
            const {errors, status} = data

            if (_.includes([403, 418], status)) {
                // SmartBill is not enabled for this entity. Missing API key or action not needed.
                return
            }

            if (status >= 400) {
                const errorMessage = _.get(
                    errors,
                    'non_field_errors.0.message',
                    'A apărut o eroare la sincronizarea cu SmartBill'
                )
                openSyncInvoiceModal(selectedInvoice.id, action, errorMessage)
            } else {
                toast('Factura a fost sincronizată cu succes în SmartBill', {type: 'success'})
            }
        },
        [selectedInvoice, openSyncInvoiceModal]
    )

    const {mutateAsync: deleteSmartBillInvoice} = useDeleteSmartBillInvoice({
        onSuccess: (data) => {
            onSmartBillFinished(data, externalSyncActions.DELETE)
        }
    })

    const mutation = useMutationResourceDelete(RESOURCES.invoices)

    return (
        <Modal
            open={open && !isEmpty(selectedInvoice)}
            title="Șterge factură fiscală">
            <div className="delete-modal">
                <p className="message">Ești sigur că vrei să ștergi această factură fiscală?</p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        loading={mutation.isPending}
                        variant="outlined"
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={async () => {
                            const {status: deleteHTTPStatus} = await deleteSmartBillInvoice(selectedInvoice)
                            if (deleteHTTPStatus === 400) {
                                return
                            }

                            await mutation.mutateAsync(selectedInvoice.id)
                            closeModal()
                            if (reloadData && _.isFunction(reloadData)) {
                                reloadData()
                            }
                        }}
                        loading={mutation.isPending}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_INVOICE
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    openSyncInvoiceModal: (invoiceID, action, errorMessage) =>
        dispatch(openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {invoiceID, action, errorMessage}))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoiceModal)
