import {BooleanParam, DateParam, NumberParam, StringParam, withDefault} from 'use-query-params'
import {FilterConfigMapping, FilterType, UseQueryFilter} from './types'
// @ts-ignore
import _ from 'lodash'
import React from 'react'
import {QueryParamConfigMap} from 'serialize-query-params'
import {QueryParamConfig} from 'serialize-query-params/src/types'
import {FilterFieldProps, ResourceFilterProps, SelectFilterProps} from './filters/types'

const Base64Param: QueryParamConfig<any | undefined, any | undefined> = {
    encode: (obj: any) => (obj ? btoa(JSON.stringify(obj)) : null),
    decode: (str: any) => {
        try {
            return JSON.parse(atob(str))
        } catch {
            return null // invalid base64 or JSON
        }
    }
}

const parseValue = (paramType: any, value: any) => {
    if (paramType === Base64Param) {
        if (_.isArray(value)) {
            return value.map((item) => item.value)
        } else {
            return value?.value
        }
    }

    return paramType.encode(value)
}

const parseLabel = (paramType: any, value: any) => {
    if (paramType === Base64Param) {
        if (_.isArray(value)) {
            return _.join(
                value.map((item) => item.label),
                ','
            )
        } else {
            return value?.label
        }
    }

    return paramType.encode(value)
}

const FilterTypeMapping = {
    [FilterType.SELECT]: Base64Param,
    [FilterType.RESOURCE]: Base64Param,
    [FilterType.NUMBER]: NumberParam,
    [FilterType.STRING]: StringParam,
    [FilterType.BOOLEAN]: BooleanParam,
    [FilterType.DATE]: DateParam
}

export const buildFilter = <QPCMap extends QueryParamConfigMap = QueryParamConfigMap>(
    filterConfig: UseQueryFilter
): FilterConfigMapping => {
    return {
        config: filterConfig,
        mapping: Object.keys(filterConfig).reduce((previousValue, currentValue) => {
            const currentFieldConfig = filterConfig[currentValue]

            const fieldsType = FilterTypeMapping[currentFieldConfig.filterType] as QueryParamConfig<
                number | null | undefined,
                any
            >

            return {
                ...previousValue,
                [currentValue]: currentFieldConfig.defaultValue
                    ? withDefault(fieldsType, currentFieldConfig.defaultValue, false)
                    : fieldsType
            }
        }, {}) as QPCMap,
        getUIFilters: (query: any) => {
            const query_entries = _.entries(query).filter(
                ([fieldName, value]) => !filterConfig[fieldName].excludeFromForm && value
            )

            return query_entries.map(([fieldName, value]: [string, any]) => ({
                displayKey: filterConfig[fieldName].label,
                displayValue: parseLabel(FilterTypeMapping[filterConfig[fieldName].filterType], value),
                key: fieldName,
                defaultValue: filterConfig[fieldName].defaultValue
            }))
        },
        getAPIFilters: (query: any) => {
            const query_entries = _.entries(query).filter((value) => value)

            return _.merge(
                {},
                ...query_entries.map(([fieldName, value]: [string, any]) => ({
                    [fieldName]: parseValue(FilterTypeMapping[filterConfig[fieldName].filterType], value)
                }))
            )
        }
    }
}

export const getComponent = (
    filterType: FilterType
): React.FC<FilterFieldProps | ResourceFilterProps | SelectFilterProps> => {
    // @ts-ignore
    // eslint-disable-next-line
    return filterMapping[filterType]
}
