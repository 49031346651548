import React, {useCallback, useMemo, useState} from 'react'

import {SwatchIcon} from '@heroicons/react/24/outline'
import {XMarkIcon} from '@heroicons/react/24/solid'

import {camelCase, head, isEmpty, isNull, snakeCase} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'

import {ColorPreview} from '../ColorPreview'
import {InputError} from '../ErrorComponents'

import {ColorPickerModal} from './partials'

import './ColorPicker.scss'

export default function ColorPicker({
    label,
    color,
    onChange,
    onBlur,
    name,
    errors,
    frontendErrors,
    touched,
    disabled,
    isClearable
}) {
    const [showColorPicker, setShowColorPicker] = useState(false)

    const snakeCaseName = snakeCase(name)
    const camelCaseName = camelCase(name)

    const hasErrors = useMemo(() => {
        return (
            (errors && !isEmpty(errors[snakeCaseName])) ||
            (frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))
        )
    }, [camelCaseName, errors, frontendErrors, snakeCaseName, touched])

    const chooseErrorMessageToShow = useMemo(() => {
        return errors && !isEmpty(errors[snakeCaseName])
            ? getErrorMessage(head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]
    }, [camelCaseName, errors, frontendErrors, name, snakeCaseName])

    const handleToggleColorpickerModal = useCallback(() => {
        setShowColorPicker((prevState) => !prevState)
    }, [])

    const handleClear = useCallback(
        (e) => {
            e.stopPropagation()
            onChange(null)
        },
        [onChange]
    )

    return (
        <div className={`avo-color-picker-container ${disabled ? 'disabled' : ''}`}>
            {label && <label className="avo-label">{label}</label>}
            <div
                className="avo-color-picker-select-container"
                onClick={handleToggleColorpickerModal}>
                <div className="avo-color-picker-preview-section">
                    <div className="avo-color-picker-preview-container">
                        <ColorPreview color={color} />
                        <p className="avo-color-preview-color-hex">{color}</p>
                    </div>
                    <div className="avo-color-picker-select-icons-container">
                        {isClearable && !isNull(color) && (
                            <button onClick={handleClear}>
                                <XMarkIcon className="avo-color-picker-select-icon" />
                            </button>
                        )}
                        <SwatchIcon className="avo-color-picker-select-icon" />
                    </div>
                </div>
            </div>
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}

            <ColorPickerModal
                open={showColorPicker}
                initialColor={color}
                onBlur={onBlur}
                onChange={onChange}
                onClose={handleToggleColorpickerModal}
            />
        </div>
    )
}
