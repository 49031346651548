import React from 'react'

import {toString} from 'lodash'

const ValueAddedOrDeleted = ({value, mappedFieldName, label}) => (
    <p className="log-text">
        {label} {mappedFieldName ? <span className="log-field-name-span">{mappedFieldName}</span> : 'date'}:{' '}
        <span className="log-text-span">{toString(value)}</span>
    </p>
)

export default ValueAddedOrDeleted
