import React from 'react'
import {createRoot} from 'react-dom/client'

import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'

import {AppTypes} from 'avoapp-react-common/dist/constants'
import {setHeaders} from 'avoapp-react-common/dist/redux/api'

import {env} from './utils'
import {environmentTypes} from './utils/types'

import App from './App'

import './index.scss'

setHeaders({'User-Platform': 'Web', 'User-App': AppTypes.AVOAPP})

Sentry.init({
    dsn: 'https://d73f2d9def624343b82453c9e0b84e65@o336000.ingest.sentry.io/6184333',
    integrations: [new BrowserTracing()],
    environment: process.env?.REACT_APP_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
})

if (env === environmentTypes.PROD) {
    console.log = function () {}
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
