import React, {useCallback, useMemo} from 'react'

import {TrashIcon, XMarkIcon} from '@heroicons/react/24/outline'

import {CANCELED_STATE} from '../../utils/constants'

import {Button} from '../Button'

export default function DeleteInvoiceButton({invoice, onClick}) {
    const isButtonHidden = useMemo(() => {
        return invoice.state === CANCELED_STATE && invoice.deletion_prevented
    }, [invoice.deletion_prevented, invoice.state])

    const isDeletionPrevented = useMemo(() => {
        return invoice.deletion_prevented
    }, [invoice.deletion_prevented])

    const buttonTitle = useMemo(() => {
        return isDeletionPrevented ? 'Anulează' : 'Șterge'
    }, [isDeletionPrevented])

    const renderButtonIcon = useCallback(() => {
        return isDeletionPrevented ? <XMarkIcon /> : <TrashIcon />
    }, [isDeletionPrevented])

    if (isButtonHidden) return

    return (
        <Button
            title={buttonTitle}
            icon={renderButtonIcon}
            onClick={onClick}
            size="small"
            color="red"
        />
    )
}
