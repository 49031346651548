import {connectRouter, routerMiddleware} from 'connected-react-router'

import {applyMiddleware, combineReducers, createStore as createReduxStore} from 'redux'

import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createSagaMiddleware from 'redux-saga'
import {all} from 'redux-saga/effects'

import {createBrowserHistory} from 'history'

import {environmentTypes} from '../utils/types'

import {
    addendums,
    api,
    auth,
    bpiSubscriptions,
    clientAddresses,
    clientBankAccounts,
    clientGroups,
    clientNotes,
    clients,
    companies,
    contactPersons,
    contracts,
    documentSeries,
    documentTemplates,
    documentVersions,
    documents,
    empowerments,
    entities,
    entityBankAccounts,
    entityProfiles,
    exchangeRate,
    expensePayments,
    expenses,
    geo,
    invoicePaymentDistributions,
    invoicePayments,
    invoicePaymentsReports,
    invoiceReports,
    invoices,
    invoicesReports,
    legalObjectCategories,
    legalObjects,
    localConfigs,
    logs,
    notifications,
    partySubscriptions,
    personRoles,
    persons,
    proformas,
    projectNotes,
    projects,
    subscriptions,
    taskTimeLogs,
    tasks,
    terms,
    tickets,
    users,
    usersPreferences
} from 'avoapp-react-common/dist'

// Local reducers
import {reducer as appReducer} from './app'
import {reducer as clientsLocalReducer} from './clients'
import {reducer as errorsModalReducer} from './errorsModal'
import {reducer as filtersReducer} from './filters/filters.js'
import {reducer as modalsReducer} from './modals'
import {reducer as routerLocations} from './router'
import {reducer as slideoversReducer} from './slideovers'

// Sagas
import {saga as addendumsSaga} from './addendums'
import {saga as appSaga} from './app'
import {saga as authSaga} from './auth'
import {saga as bpiSubscriptionsSaga} from './bpiSubscriptions'
import {saga as clientAddressesSaga} from './clientAddresses'
import {saga as clientBankAccountsSaga} from './clientBankAccounts'
import {saga as clientGroupsSaga} from './clientGroups'
import {saga as clientNotesSaga} from './clientNotes'
import {saga as clientsSaga} from './clients'
import {saga as companiesSaga} from './companies'
import {saga as contactPersonsSaga} from './contactPersons'
import {saga as contractsSaga} from './contracts'
import {saga as documentSeriesSaga} from './documentSeries'
import {saga as documentTemplatesSaga} from './documentTemplates'
import {saga as documentVersionsSaga} from './documentVersions'
import {saga as documentsSaga} from './documents'
import {saga as empowermentsSaga} from './empowerments'
import {saga as expensePaymentsSaga} from './expensePayments'
import {saga as expensesSaga} from './expenses'
import {saga as invoicePaymentDistributionsSaga} from './invoicePaymentDistributions'
import {saga as invoicePaymentsSaga} from './invoicePayments'
import {saga as invoicesSaga} from './invoices'
import {saga as legalObjectsSaga} from './legalObjects'
import {saga as proformasSaga} from './proformas'
import {saga as projectNotesSaga} from './projectNotes'
import {saga as projectsSaga} from './projects'
import {saga as subscriptionsSaga} from './subscriptions'
import {saga as taskTimeLogsSaga} from './taskTimeLogs'
import {saga as tasksSaga} from './tasks'
import {saga as ticketsSaga} from './tickets'
import {saga as usersSaga} from './users'

function* rootSaga() {
    yield all([
        api.saga(),
        appSaga(),
        authSaga(),
        addendumsSaga(),
        bpiSubscriptionsSaga(),
        clientAddressesSaga(),
        clientBankAccountsSaga(),
        clientGroupsSaga(),
        clientNotesSaga(),
        clientsSaga(),
        companiesSaga(),
        contactPersonsSaga(),
        contractsSaga(),
        documentsSaga(),
        documentSeriesSaga(),
        documentTemplatesSaga(),
        documentVersionsSaga(),
        empowermentsSaga(),
        expensesSaga(),
        expensePaymentsSaga(),
        invoicesSaga(),
        invoicePaymentDistributionsSaga(),
        invoicePaymentsSaga(),
        legalObjectsSaga(),
        projectNotesSaga(),
        projectsSaga(),
        proformasSaga(),
        subscriptionsSaga(),
        tasksSaga(),
        taskTimeLogsSaga(),
        ticketsSaga(),
        usersSaga()
    ])
}

const persistConfig = {
    timeout: 0,
    key: 'cache',
    storage: storage,
    whitelist: ['users', 'localConfigs']
}

export const history = createBrowserHistory()

const rootReducer = (history) =>
    combineReducers({
        api: api.reducer,
        app: appReducer,
        auth: auth.reducer,
        addendums: addendums.reducer,
        bpiSubscriptions: bpiSubscriptions.reducer,
        clientAddresses: clientAddresses.reducer,
        clientBankAccounts: clientBankAccounts.reducer,
        clientGroups: clientGroups.reducer,
        clientNotes: clientNotes.reducer,
        clients: clients.reducer,
        companies: companies.reducer,
        contactPersons: contactPersons.reducer,
        contracts: contracts.reducer,
        documents: documents.reducer,
        documentSeries: documentSeries.reducer,
        documentTemplates: documentTemplates.reducer,
        documentVersions: documentVersions.reducer,
        empowerments: empowerments.reducer,
        entityBankAccounts: entityBankAccounts.reducer,
        entities: entities.reducer,
        entityProfiles: entityProfiles.reducer,
        errorsModal: errorsModalReducer,
        exchangeRate: exchangeRate.reducer,
        expenses: expenses.reducer,
        expensePayments: expensePayments.reducer,
        filters: filtersReducer,
        geo: geo.reducer,
        invoicePaymentDistributions: invoicePaymentDistributions.reducer,
        invoicePayments: invoicePayments.reducer,
        invoiceReports: invoiceReports.reducer,
        invoicePaymentsReports: invoicePaymentsReports.reducer,
        invoices: invoices.reducer,
        invoicesReports: invoicesReports.reducer,
        legalObjectCategories: legalObjectCategories.reducer,
        legalObjects: legalObjects.reducer,
        localClientsSettings: clientsLocalReducer,
        localConfigs: localConfigs.reducer,
        logs: logs.reducer,
        modals: modalsReducer,
        notifications: notifications.reducer,
        partySubscriptions: partySubscriptions.reducer,
        personRoles: personRoles.reducer,
        persons: persons.reducer,
        proformas: proformas.reducer,
        projectNotes: projectNotes.reducer,
        projects: projects.reducer,
        router: connectRouter(history),
        routerLocations: routerLocations,
        slideovers: slideoversReducer,
        subscriptions: subscriptions.reducer,
        tasks: tasks.reducer,
        taskTimeLogs: taskTimeLogs.reducer,
        terms: terms.reducer,
        tickets: tickets.reducer,
        users: users.reducer,
        usersPreferences: usersPreferences.reducer
    })

const persistedRootReducer = persistReducer(persistConfig, rootReducer(history))

export function createRootStore(initialState = {}) {
    const sagaMiddleware = createSagaMiddleware()

    const store = createReduxStore(
        persistedRootReducer,
        initialState,
        applyMiddleware(routerMiddleware(history), sagaMiddleware)
    )

    sagaMiddleware.run(rootSaga)

    if (process.env.NODE_ENV === environmentTypes.DEV) {
        store.subscribe(() => {
            console.log(store.getState())
        })
    }

    return store
}

export const store = createRootStore()
export const persistor = persistStore(store)
