import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {values as _values, isEmpty, debounce} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {createDocument} from '../../../../../redux/documents'
import {modalTypes} from '../../../../../redux/modals'

import {removeExtension} from '../../../../../utils'
import {debounceWait} from '../../../../../utils/constants'
import {documentsSchema} from '../../../../../assets/validations'

import {Modal} from '../../../../../components/Modal'
import {Input} from '../../../../../components/Input'
import {Select} from '../../../../../components/Select'
import {Button} from '../../../../../components/Button'
import {ErrorsList} from '../../../../../components/ErrorComponents'
import {DocumentDropzone} from '../../../../../components/DocumentDropzone'

import './UploadNewDocumentModal.scss'
import {getTokenAndUploadFile} from '../../../../../utils/files'

export class UploadNewDocumentModal extends Component {
    constructor() {
        super()

        this.state = {
            projectsQuery: ''
        }
    }

    componentDidUpdate = (prevProps) => {
        const {open} = this.props

        if (open && prevProps.open !== open) {
            this.handleFetchProjects()
        }
    }

    handleFetchProjects = (query = this.state.projectsQuery) => {
        const {searchProjects} = this.props

        searchProjects(query)
    }

    debounceSearchProjects = debounce(this.handleFetchProjects, debounceWait)

    handleChangeProjectsSearchField = (value) => {
        this.setState({projectsQuery: value})
        this.debounceSearchProjects(value)
    }

    render() {
        const {
            open,
            isLoading,
            nonFieldErrors,
            fieldErrors,
            selectedEntityID,
            projects,
            isLoadingProjects,
            createDocument
        } = this.props

        return (
            <Modal
                open={open}
                title="Încarcă document">
                <div className="upload-new-document-modal-content-container">
                    <Formik
                        initialValues={{
                            projectsIds: [],
                            file: [],
                            name: ''
                        }}
                        validationSchema={documentsSchema.documentsList}
                        onSubmit={async (values) => {
                            const fileURL = await getTokenAndUploadFile(values.file, selectedEntityID)

                            createDocument({
                                entityID: selectedEntityID,
                                projectsIDs: values.projectsIds.map((project) => project.id),
                                name: values.name,
                                file: fileURL
                            })
                        }}>
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            values,
                            touched,
                            errors,
                            isValid
                        }) => (
                            <Form className="upload-new-document-form">
                                <ErrorsList errors={nonFieldErrors} />
                                <Select
                                    label="Proiecte"
                                    value={values.projectsIds}
                                    placeholder="Alege un proiect"
                                    options={projects}
                                    onChange={(option) => setFieldValue('projectsIds', option)}
                                    onInputChange={(value) => this.handleChangeProjectsSearchField(value)}
                                    onBlur={handleBlur('projectsIds')}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    name="projectsIds"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.projectsIds}
                                    loading={isLoadingProjects}
                                    isMulti
                                />
                                <DocumentDropzone
                                    onChange={(file) => {
                                        setFieldValue('file', file)
                                        setFieldValue('name', !isEmpty(file) ? removeExtension(file.name) : '')
                                    }}
                                />
                                {!isEmpty(values.file) && (
                                    <Input
                                        value={values.name}
                                        label="Nume document"
                                        onChange={handleChange('name')}
                                        onBlur={handleBlur('name')}
                                        name="name"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.projectsIds}
                                        fullWidth
                                    />
                                )}
                                <Button
                                    title="Salvează"
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    disabled={!isValid}
                                    color="secondary"
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.UPLOAD_NEW_DOCUMENT,
    fieldErrors: state.documents.fieldErrors,
    nonFieldErrors: state.documents.nonFieldErrors,
    isLoading: state.documents.isLoading,
    projects: _values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search)),
    createDocument: (documentData) => dispatch(createDocument(documentData))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadNewDocumentModal)
