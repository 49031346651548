import React from 'react'
import {TrashIcon} from '@heroicons/react/24/outline'

import {Button} from '../Button'

import './DatatableRowButtons.scss'

export default function DatatableRowButtons({mainButtonTitle, onMainButtonClick, onDeleteButtonClick}) {
    return (
        <div className="datatable-row-buttons-container">
            {onMainButtonClick && (
                <Button
                    title={mainButtonTitle}
                    onClick={onMainButtonClick}
                    size="small"
                />
            )}
            {onDeleteButtonClick && (
                <Button
                    icon={() => <TrashIcon />}
                    onClick={onDeleteButtonClick}
                    size="small"
                    color="red"
                />
            )}
        </div>
    )
}
